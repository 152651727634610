import styled from "styled-components";
import MainCard1 from '../../images/big-69.png'
import TicketOn from '../../images/img-bg-ticket-on.png'
import TicketOff from '../../images/img-bg-ticket-off.png'


export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(26, 31, 37, 0.7);
    display:flex;
    flex-direction: column;
    margin-top:2%;
    border-radius:20px;
    backdrop-filter: blur(30px);
`;


export const Main = styled.ul`
  flex-direction: row;
  display: flex;
  flex-basis: 100%;
`;

export const MainCard = styled.div`
  top: 0;
  left: 0;
  width: 120px;
  height: 170px;
  margin-right: 10px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainCardImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
`;

export const Content = styled.div`
    width: 100%;
    top:0;
    height: 280px;
    background-color: rgba(26, 31, 37, 0.9);
    border-radius: 10% 10% 0% 0%;

`;
export const ContentInfo = styled.div`
    flex-basis: 27.3%;
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: scroll;
    white-space: nowrap;
    display:flex;
    @media only screen and (min-width: 850px) {
      flex-basis: 17.3%;
      margin-top:20px;
      margin-bottom:20px;
    }
`;


export const ContentDetail = styled.div`
    z-index:2;
    flex-basis: 17.3%;
    width: 100%;
    padding-left:5%;
    padding-right:5%;
`;


export const DetailTitle = styled.p`
    color:#fff;
    line-height: 1.67;
    font-size: 1.125rem;
    font-weight:600;
    padding-top:4%;
    padding-bottom:2%;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

`;

export const DetailText = styled.p`
    color:#fff;
    font-size: 0.8rem;
    line-height: 1.57;
    display:bolck;
    opacity: 0.9;
    padding-bottom:2%;
    overflow:auto;
    @media only screen and (min-width: 850px) {
      height: 100px;
    }
`;

export const Scroll = styled.div`
    overflow-y: scroll;
    height: 100vh;
`;


export const DetailBox = styled.div`
  display: flex;
  color:#fff;
  padding-left: 5%;
  padding-right: 5%;
  padding-top:2%;
  flex-basis: 33.3%;
  padding-bottom:350px;
`;

export const DetailInfoRight = styled.div`
  position: relative;
  flex: 1;
  padding-right:28px;
`;

export const DetailInfo = styled.div`
  position: relative;
  flex: 1;
  padding-left:28px;
`;

export const ShowInfo = styled.div`
  display: inline-block;
  width:100%;
`;

export const ShowTH = styled.div`
    float: left;
    color:#fff;
    font-size: 0.8rem;
    line-height: 1.67;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    opacity: 0.7;
    font-weight: 500;
    @media only screen and (min-width: 850px) {
      font-size: 0.8rem;
      padding-right:0%;
    }
`;
export const ShowTD = styled.pre`
    color:#fff;
    font-size: 0.8rem;
    line-height: 1.67;
    font-weight: 400;
    text-align:right;
    @media only screen and (min-width: 850px) {
      font-size: 0.8rem;
    }
`;

interface IVideo{
    landscape: boolean;
    isMobile: boolean;
}


export const Video = styled.div<IVideo>`
  width: 100%;
  height: 200px;
  float: none;
  z-index:5;
  position: relative;
  @media all and (orientation: landscape) {
    height: 100vh;
  }
  @media only screen and (min-width: 850px) {
    height: ${props => (props.isMobile ?  '400px':'100vh')};
    width: ${props => (props.isMobile ?  '100%':'70%')};
    float: ${props => (props.isMobile ?  'none':'left')};
  }
`;

interface ICountBox{
    landscape: boolean;
}
export const CountBox = styled.div<ICountBox>`
    position: absolute;
    width: 80px;
    height: 30px;
    right: 10px;
    border-radius: 5px;
    z-index:1;
    top: ${props => (props.landscape ?  '':'140px')};
    bottom: ${props => (props.landscape ?  '30px':'')};
    display:none;
`;

export const CountImg = styled.img`
    width: 20px;
    height: 20px;
    float:left;
`;

export const Count = styled.div`
    font-size:1rem;
    color:#fff;
    line-height:20px
`;
