import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentDetail, ContentDetailDetail, ContentDetailDetailIndex, ContentDetailDetailWrapper, ContentDetailIndex, ContentDetailWrapper, ContentTitle, SubTitle } from './Style';

type TTerms = {
    subtitle: string;
    content: any;
}

const Terms = () => {
    const history = useHistory();
    const [terms, setTerms] = useState<null | TTerms>(null);

    useEffect(() => {
        if (history.location.search.includes('en')) {
            const enTerms = require('./terms.en.json');
            setTerms(enTerms);
        } else {
            const koTerms = require('./terms.ko.json');
            setTerms(koTerms);
        }
    }, [history]);

    if (!terms) return null;

    return (
        <>
            <SubTitle>
                {terms && terms.subtitle}
            </SubTitle>
            {terms && terms.content.map((c, c_idx) => {
                return (
                    <>
                        <ContentTitle>{`${c_idx + 1}. ${c.title}`}</ContentTitle>
                        {c.contentDetail.map((d, d_idx) => {
                            return (
                                <ContentDetailWrapper display={d.content ? 'block' : 'flex'}>
                                    <ContentDetailIndex>
                                        {`${c_idx + 1}.${d_idx + 1}`}
                                    </ContentDetailIndex>
                                    {d.content ? (
                                        <>
                                            <ContentDetail>
                                                {`${d.content}`}
                                            </ContentDetail>
                                            <>
                                                {d.detail.map((e, e_idx) => {
                                                    return (
                                                        <ContentDetailDetailWrapper>
                                                            <ContentDetailDetailIndex>
                                                                {`${c_idx + 1}.${d_idx + 1}.${e_idx + 1}`}
                                                            </ContentDetailDetailIndex>
                                                            <ContentDetailDetail>
                                                                {e}
                                                            </ContentDetailDetail>
                                                        </ContentDetailDetailWrapper>
                                                    )
                                                })}
                                            </>
                                        </>
                                    ) : (
                                            <ContentDetail>
                                                {`${d}`}
                                            </ContentDetail>
                                        )}
                                </ContentDetailWrapper>
                            )
                        })}
                    </>
                )
            })}
        </>
    )
}

export default Terms;
