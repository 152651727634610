import { loadPaymentMethods } from 'modules/payment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import iconEnter from 'images/ic-enter.png';
import { CardInfo, CardTitle, Container, ContentCard, ContentContainer, EnterButton, PaymentCard, PaymentInfo, Paypal, PaypalImg } from './Style';
import locale from './locale.json';
import paypalImg from 'images/img-paypal.png';
import ReactGA from 'react-ga';


import Header from 'components/Common/Header';
import { logout, loadMe } from 'modules/auth';

const paymentMethodsSelector = ({ payment: { paymentMethods } }: any) => paymentMethods;
const userDataSelector = ({ auth: { userData } }: any) => userData;

const Setting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userAgent, setuserAgent] = useState("");
  const [language, setLanguage] = useState<any>({});


  const [hasPaymentMethods, setHasPaymentMethods] = useState(false);

  const paymentMethods = useSelector(paymentMethodsSelector);
  const userData = useSelector(userDataSelector);

  useEffect(() => {
    dispatch(loadPaymentMethods());
  }, []);

  useEffect(() => {
    if (!userData) dispatch(loadMe());
  }, [userData]);

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  useEffect(() => {
    //설정 페이지 진입
    ReactGA.event({ category: 'Setting', action: 'Enter_Setting', label: '' });
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  useEffect(() => {
    if (!paymentMethods || paymentMethods.length === 0) {

    } else {
      setHasPaymentMethods(true);
    }
  }, [paymentMethods]);

  const _navigateToPaymentHistory = () => {
    history.push("/paymenthistory");
  }
  const _navigateToPaymentInformation = () => {
    history.push("/paymentInformation");
  }
  const _navigateToFaq = () => {
    history.push("/faqs?locale=" + localStorage.getItem('languageCode'));
  }

  const _navigateToTerms = () => {
    history.push("/terms?locale=" + localStorage.getItem('languageCode'));
  }


  const _nextLogout = () => {
    const _success = () => {
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token');
      window.location.href = '/';
    }
    dispatch(logout(_success));
  }

  const _renderPaymentMethods = () => {
    if (!paymentMethods) return null;

    if (hasPaymentMethods) {
      if (paymentMethods[0].kind === 'stripe') return (`${paymentMethods[0].cardInfo.brand} **** **** **** ${paymentMethods[0].cardInfo.last4}`)
      else if (paymentMethods[0].kind === 'paypal') {
        return (
          <Paypal>
            <PaypalImg src={paypalImg} />
          </Paypal>
        )
      }
      return (
        `${language.NoInformation}`
      )
    } else {
      return (
        `${language.NoInformation}`
      )
    }

  }
  if (!userData) return null;

  return (
    <Container paddingTop={userAgent ? 0 : 60}>
      <Header iHeaderCenter={"Setting"} iBackLeftArrowIcon={true} iBackRightSettingIcon={false} iconClose={false} />
      {userData.role !== 'VIEWER' &&
        <ContentCard>
          <PaymentCard>
            <CardTitle>{language.PaymentInformation} </CardTitle>
            <CardInfo>
              {_renderPaymentMethods()}
            </CardInfo>
          </PaymentCard>
        </ContentCard>
      }
      {userData.role !== 'VIEWER' &&
        < ContentContainer onClick={_navigateToPaymentInformation}>
          <PaymentInfo disabled={false}>
            {hasPaymentMethods ? `${language.UpdateInformation}` : `${language.AddInformation}`}
            <EnterButton src={iconEnter} />
          </PaymentInfo>
        </ContentContainer>
      }
      {userData.role !== 'VIEWER' &&
        <ContentContainer onClick={_navigateToPaymentHistory}>
          <PaymentInfo disabled={false} >
            {language.Paymenthistory}
            <EnterButton src={iconEnter} />
          </PaymentInfo>
        </ContentContainer>
      }
      {
        userAgent ? ""
          : <ContentContainer onClick={_navigateToFaq}>
            <PaymentInfo disabled={false} >
              FAQ
            <EnterButton src={iconEnter} />
            </PaymentInfo>
          </ContentContainer>
      }
      {
        userAgent ? ""
          : <ContentContainer onClick={_navigateToTerms}>
            <PaymentInfo disabled={false} >
              {language.TermsPrivacy}
              <EnterButton src={iconEnter} />
            </PaymentInfo>
          </ContentContainer>
      }
      {
        userAgent ? ""
          : <ContentContainer onClick={_nextLogout}>
            <PaymentInfo disabled={false} >
              {language.Logout}
              <EnterButton src={iconEnter} />
            </PaymentInfo>
          </ContentContainer>
      }
      {/* <ContentContainer>
        <PaymentInfo disabled={true}>
          {language.DeleteAccount}
        </PaymentInfo>
      </ContentContainer> */}
    </Container >
  )
}

export default Setting;
