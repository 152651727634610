import Header from 'components/Common/Header';
import CustomInputBoxWithBtn from 'components/RedeemCodes/CustomInputBoxWithBtn';
import { callReadRedeemCode, callRegisterRedeemCode } from 'core/api/redeem-codes';
import { setShowLoading, setModal } from 'modules/common';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as styled from './Style';

type TConcertInfo = {
  title: string;
  mainPosterUrl: string;
  startTime: string;
}

const RedeemCodes = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [code, setCode] = useState('');
  const [checkStatus, setCheckStatus] = useState<string>('waiting');
  const [concertInfo, setConcertInfo] = useState<TConcertInfo | null>(null);

  const [userAgent, setuserAgent] = useState("");

  const _handleSubmit = async () => {
    dispatch(setShowLoading(true));
    try {
      const data = await callReadRedeemCode({ code: code });
      setConcertInfo(data);
      setCheckStatus('checked');
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data.code === 9000) dispatch(setModal(true, '잘못된 예매번호입니다.', ''));
        else if (e.response.data.code === 9001) dispatch(setModal(true,'이미 사용된 예매번호입니다.', ''));
        else if (e.response.data.code === 9002) dispatch(setModal(true, '이미 구매하였습니다.', ''));
        setCheckStatus('error');
      } else {
        dispatch(setModal(true, 'Server error', ''));
      }
    }
    dispatch(setShowLoading(false));
  }

  const _handleCancel = () => {
    setCheckStatus('waiting');
    setCode('');
  }

  const _handleRegister = async () => {
    dispatch(setShowLoading(true));
    try {
      await callRegisterRedeemCode({ code: code });
      history.push('/mypage')
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data.code === 9000) dispatch(setModal(true, '잘못된 예매번호입니다.', ''));
        else if (e.response.data.code === 9001) dispatch(setModal(true,'이미 사용된 예매번호입니다.', ''));
        else if (e.response.data.code === 9002) dispatch(setModal(true, '이미 구매하였습니다.', ''));
      } else {
        dispatch(setModal(true, 'Server error', ''));
      }
    }
    dispatch(setShowLoading(false));
  }

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  return (
    <styled.Container>
      <Header iHeaderCenter={"예매번호 조희"} iBackLeftArrowIcon={true} iBackRightSettingIcon={false} iconClose={false} />
      <styled.ContentContainer>
        <CustomInputBoxWithBtn
          value={code}
          title={'예매번호'}
          placeholder={'예매번호를 입력해주세요.'}
          checkStatus={checkStatus}
          handleValueChange={(t: string) => setCode(t)}
          handleSubmit={_handleSubmit}
          handleCancel={_handleCancel}
        />
        {(checkStatus === 'checked' && concertInfo) && (
          <styled.ConcertInfoWrapper>
            <styled.ConcertMainPoster src={concertInfo.mainPosterUrl} />
            <styled.ConcertDescriptionWrapper>
              <styled.ConcertTitle>
                {concertInfo.title}
              </styled.ConcertTitle>
              <styled.ConcertStartTime>
                {moment(concertInfo.startTime).format('YYYY.MM.DD')}
              </styled.ConcertStartTime>
            </styled.ConcertDescriptionWrapper>
          </styled.ConcertInfoWrapper>
        )}
      </styled.ContentContainer>
      <styled.DescriptionContainer>
        <styled.Description>
          {`- 구매하신 티켓의 예매번호를 입력하면 등록이 완료 됩니다.\n- 한번 등록된 예매권은 등록 취소 및 다른 ID로 재등록 할 수 없습니다.\n- 예매권 사용 기간이 남아있더라도 공연이 종료되었거나 매진된 경우 등록이 불가하니, 미리 등록해주시기 바랍니다.\n- 예매 취소 시 예매권은 자동 복원되며, 사용 기간 내 재예매 하실 수 있습니다.\n - 예매권은 현금으로 교환/환불되지 않습니다.`}
        </styled.Description>
      </styled.DescriptionContainer>
      <styled.BtnDone onClick={() => _handleRegister()} disabled={checkStatus !== 'checked'}>확인</styled.BtnDone>
    </styled.Container>
  )
}

export default RedeemCodes;
