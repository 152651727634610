import React, { useState, useEffect } from 'react';
import { Container, Title, SubTitle, ContentDetail, ContentTitle, ContentDetailIndex, ContentDetailWrapper } from './Style';
import Header from '../../components/Common/Header';
import privacy from './privacy.json';


const Privacy = () => {

  const [userAgent, setuserAgent] = useState("");

  useEffect(() => {
    setuserAgent("fanbox")
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  return (
    <Container paddingTop={userAgent ? 0 : 60}>
      <Header iHeaderCenter={"개인정보 취급방침"} iBackLeftArrowIcon={false} iBackRightSettingIcon={false} iconClose={true}/>

      {/* <Title>{privacy.title}</Title> */}
      <SubTitle style={{ marginTop: '30px' }}>
        {privacy.subtitle}
      </SubTitle>
      {privacy.content.map((c, c_idx) => {
        return (
          <>
            <ContentTitle>{`${c_idx + 1}. ${c.title}`}</ContentTitle>
            {c.contentDetail.map((d, d_idx) => {
              return (
                <ContentDetailWrapper>
                  <ContentDetailIndex>
                    {`${c_idx + 1}.${d_idx + 1}`}
                  </ContentDetailIndex>
                  <ContentDetail>
                    {`${d}`}
                  </ContentDetail>
                </ContentDetailWrapper>
              )
            })}
          </>
        )
      })}
    </Container>
  )
}

export default Privacy;
