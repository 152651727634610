import styled from "styled-components";
import backgrounOffUrl from '../../images/ic-check-blank.png'


interface ITabs {
    active: boolean;
}

interface IContainer {
    paddingTop?: number;
}

interface ICheckbox {
    backgrounUrl: string;
}


export const Container = styled.div<IContainer>`
    width: 100%;
    height: 100vh;
    background-color: #2A3139;
    padding-top: ${(props) => props.paddingTop ? props.paddingTop : 0}px;

`;


export const Header = styled.div`
    width: 100%;

    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: relative;

    height: 60px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: #1a1f25;
`;

export const HeaderCenterText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.2rem;
    font-weight:700;
`;

export const HeaderLeftButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const HeaderRightButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const ContentContainer = styled.div`
    height: 80px;
    background-color:#2A3139;
    opacity: 0.9;
    display:block;
    position:relative;
`;

export const HelpText = styled.div`
    max-width: 240px;
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
`;

export const HelpSubText = styled.div`
    max-width: 240px;
    margin: 0 auto;
    margin-top: 15px;
    color: #999;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
`;

export const Form = styled.div`
    margin-top: 25px;
    width: 100%;
`;

export const Paypal = styled.div`
    width: 85%;
    height: 50px;
    color: #000000;
    background-color: #fff;
    line-height: 50px;
    border-radius: 7px;
    text-align: center;
    margin-top: 50px;
    border: none;
    font-size:1rem;
    margin:auto;
    display:block;
    padding-top: 12px;
    float:left;
    opacity: 0.9;

`;


export const MainCard = styled.div`
    width: 90%;
    height: 200px;
    padding-top:20px;
    padding-bottom:20px;
    top: 0;
    left: 0;
    border-bottom:1px solid rgba(255, 255, 250, 0.2);
    margin: auto;
`;



export const ProfileNickNameText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 10%;
    left: 38%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.2rem;
`;

export const ProfileInfoText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    left: 38%;
    color: #fff;
    font-size: 0.8rem;
    opacity: 0.5;
    left: 27%;
`;



export const PaymentInfo = styled.div`
    display: inline-block;
    position: absolute;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 0.8rem;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width:90%;
    transform: translate(-50%, -50%);
    left: 50%;
    margin-top:30px;
`;

export const PaymentInput = styled.input`
    opacity: 0.5;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    display:block;
    padding-left: 15px;
    background-color:transparent;
    width:100%;
    height:100%;
    &::-webkit-input-placeholder {
      line-height: 50px;
      color: #ffffff;
    }
`;



export const PaymentCheckBox = styled.input.attrs({
	type: 'checkbox'
})`
	border-radius: 5px;
	color: red;
`;



export const NickNameText = styled.span`
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    font-size: 0.8rem;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    padding-top:2px;
    padding-left: 15px;
    display:block;
`;


export const Title = styled.div`
  height:90px;
  padding-left:20px;
  padding-right:20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.div`
   float:left;
   font-size: 1.2rem;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.33;
   letter-spacing: normal;
   color: #ffffff;
   line-height: 5.33;
   font-weight:600;
`;

export const Step = styled.div`
  float:right;
`;
export const Elements = styled.div`
  margin-top:60px;
`;

export const Checkbox = styled.div<ICheckbox>`
  width:20px;
  height:20px;
  background: url(${(props) => props.backgrounUrl? props.backgrounUrl : backgrounOffUrl}) no-repeat center/cover;
  background-image: url(${(props) => props.backgrounUrl? props.backgrounUrl : backgrounOffUrl});
  display:inline-block;
  top:5px;
  position: relative;
  margin-right: 20px;
  margin-top: 10px;
  float: left;
`;


export const PaymentDiv = styled.div`

`;


export const PaymentCheck = styled.div`
  font-size:1.1rem;
  color:#fff;
  bottom: 7%;
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  opacity: 1;
  padding-right:20px;
  padding-left:20px;
  padding-top:20px;
  height:60px;
  width:100%;
  line-height: 50px;
  text-align: left;
`;

export const PaypalImg = styled.img`
    width: 100px;
    height: 23px;
    margin: auto;
`;

export const BtnDone = styled.button`
  width: 92%;
  height: 50px;
  color: #000000;
  background-color: #62e979;
  line-height: 50px;
  border-radius: 7px;
  text-align: center;
  border: none;
  font-size:1rem;
  display:block;
  margin:auto;
  margin-top:50px;
  &:disabled {
    background-color: #3d434a;
    color: white;
  }
`;
