import { createReducer } from 'typesafe-actions';
import * as types from './types';

const initialState: types.MyPageState = {
  isLoadingReserves: false,
  list: [],
  watched: [],
  reserved: [],
  passed: [],
};

const mypageReducer = createReducer(initialState)
    .handleAction(types.T_MYPAGE_RESERVES.PENDING, (state: types.MyPageState, action: types.MyPageAction) => ({
        ...state,
        isLoadingReserves: false,
    }))
    .handleAction(types.T_MYPAGE_RESERVES.SUCCESS, (state: types.MyPageState, action: types.MyPageAction) => ({
        ...state,
        isLoadingReserves: false,
        list: action.payload,
        watched: action.payload.watched.results,
        reserved: action.payload.reserved.results,
        passed: action.payload.passed.results,
    }))
    .handleAction(types.T_PROFILE_UPDATE.PENDING, (state: types.MyPageState, action: types.MyPageAction) => ({
        ...state,
    }))
    .handleAction(types.T_PROFILE_UPDATE.SUCCESS, (state: types.MyPageState, action: types.MyPageAction) => ({
        ...state,
    }))

export default mypageReducer;
