import * as types from './types';
import { ThunkDispatch } from 'redux-thunk';
import { callpaymentMethod, callRegisterPaymentMethod, callChargeConcert, callLoadPaymentHistories, callRefundConcert, callUpdatePaymentMethod, callOneTimeChargeConcert } from 'core/api/payment';
import { setShowLoading } from 'modules/common';

export const setStage = (stage: number) => ({ type: types.T_SET_STAGE, payload: stage });
export const setKind = (kind: string) => ({ type: types.T_SET_KIND, payload: kind });

export const loadPaymentMethods = () => async (dispatch: ThunkDispatch<types.PaymentState, void, types.PaymentAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_LOAD_PAYMENT_METHODS.PENDING });
    try {
        const _data = await callpaymentMethod();
        dispatch({ type: types.T_LOAD_PAYMENT_METHODS.SUCCESS, payload: _data });
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_LOAD_PAYMENT_METHODS.FAILURE, payload: e });
    }
}

export const registerPaymentMethods = (
    tokenId: string,
    holderName: string,
    holderBirthDay: string,
    paymentKind: string,
    success: () => void
) => async (dispatch: ThunkDispatch<types.PaymentState, void, types.PaymentAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_REGISTER_PAYMENT_METHODS.PENDING });
    try {
        const _data = await callRegisterPaymentMethod({ tokenId: tokenId, holderName: holderName, holderBirthDay: holderBirthDay, paymentKind });
        dispatch({ type: types.T_REGISTER_PAYMENT_METHODS.SUCCESS, payload: _data });
        dispatch(setStage(1));
        success();
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_REGISTER_PAYMENT_METHODS.FAILURE, payload: e });
        window.alert("Wrong Card Info");
    }
}

export const updatePaymentMethods = (
    tokenId: string,
    paymentMethodUuid: string,
    holderName: string,
    holderBirthDay: string,
    paymentKind: string,
    success: () => void
) => async (dispatch: ThunkDispatch<types.PaymentState, void, types.PaymentAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_REGISTER_PAYMENT_METHODS.PENDING });
    try {
        const _data = await callUpdatePaymentMethod({ paymentMethodUuid: paymentMethodUuid, tokenId: tokenId, holderName: holderName, holderBirthDay: holderBirthDay, paymentKind: paymentKind });
        dispatch({ type: types.T_REGISTER_PAYMENT_METHODS.SUCCESS, payload: _data });
        success();
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_REGISTER_PAYMENT_METHODS.FAILURE, payload: e });
    }
}

export const chargeConcert = (
    concertUuid: string,
    ticketUuid: string,
    paymentMethodUuid: string,
    referralCode: string,
    email: string,
    success: () => void,
    fail: (kind: string) => void
) => async (dispatch: ThunkDispatch<types.PaymentState, void, types.PaymentAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_CHARGE_CONCERT.PENDING });
    dispatch(setShowLoading(true));
    try {
        const _data = await callChargeConcert({
            concertUuid: concertUuid,
            ticketUuid: ticketUuid,
            paymentMethodUuid: paymentMethodUuid,
            referralCode: referralCode,
            email: email
        });
        dispatch({ type: types.T_CHARGE_CONCERT.SUCCESS, payload: _data });
        success();
    } catch (e) {
        if (e.response && e.response.data) {
            if (e.response.data.code === 4009) fail('NOT_SUPPORTED');
            else fail('FAILED');
        } else {
            fail('FAILED');
        }
        dispatch({ type: types.T_CHARGE_CONCERT.FAILURE, payload: e });
    } finally {
        dispatch(setShowLoading(false));
    }
}

export const oneTimeChargeConcert = (
    concertUuid: string,
    ticketUuid: string,
    tokenId: string,
    referralCode: string,
    email: string,
    success: () => void,
    fail: (kind: string) => void
) => async (dispatch: ThunkDispatch<types.PaymentState, void, types.PaymentAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_CHARGE_CONCERT.PENDING });
    dispatch(setShowLoading(true));
    try {
        const _data = await callOneTimeChargeConcert({
            concertUuid: concertUuid,
            ticketUuid: ticketUuid,
            tokenId: tokenId,
            referralCode: referralCode,
            email: email
        });
        dispatch({ type: types.T_CHARGE_CONCERT.SUCCESS, payload: _data });
        success();
    } catch (e) {
        if (e.response && e.response.data) {
            if (e.response.data.code === 4009) fail('NOT_SUPPORTED');
            else fail('FAILED');
        } else {
            fail('FAILED');
        }
        dispatch({ type: types.T_CHARGE_CONCERT.FAILURE, payload: e });
    } finally {
        dispatch(setShowLoading(false));
    }
}

export const loadPaymentHistories = (
    success: () => void
) => async (dispatch: ThunkDispatch<types.PaymentState, void, types.PaymentAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_LOAD_PAYMENT_HISTORIES.PENDING });
    try {
        const _data = await callLoadPaymentHistories();
        dispatch({ type: types.T_LOAD_PAYMENT_HISTORIES.SUCCESS, payload: _data });
        success();
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_LOAD_PAYMENT_HISTORIES.FAILURE, payload: e });
    }
}


export const refundConcert = (
    chargedPayment: types.TPayment,
    success: () => void,
    fail: () => void
) => async (dispatch: ThunkDispatch<types.PaymentState, void, types.PaymentAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_REFUND_CONCERT.PENDING });
    dispatch(setShowLoading(true));
    try {
        const _data = await callRefundConcert({ paymentUuid: chargedPayment.uuid, paymentKind: chargedPayment.kind });
        dispatch({ type: types.T_REFUND_CONCERT.SUCCESS, payload: _data });
        success();
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_REFUND_CONCERT.FAILURE, payload: e });
        fail();
    } finally {
        dispatch(setShowLoading(false));
    }
}

export const setConcert = (concert: any) => ({ type: types.T_SET_CONCERT, payload: concert });
export const setStripeCardToken = (token: any) => ({ type: types.T_SET_STRIPE_CARD_TOKEN, payload: token });