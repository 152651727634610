import styled from 'styled-components';

export const FAQListItemWrapper = styled.div`
    padding: 20px 0px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
`

export const FAQListItemQuestionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const FAQListItemQuestion = styled.div`
    font-family: NanumSquareB;
    font-size: 0.9375rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
`

export const FAQListItemQuestionArrow = styled.img`
    width: 20px;
    height: 20px;
`

export const FAQListItemAnswer = styled.div`
    margin-top: 20px;
`
