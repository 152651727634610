import { ActionType } from 'typesafe-actions';
import { createAsyncActionTypes } from '../../core/utils/reduxUtils';
const prefix: string = 'mypage';

export const T_MYPAGE_RESERVES = createAsyncActionTypes(`${prefix}/T_MYPAGE_RESERVES`);
export const T_PROFILE_UPDATE = createAsyncActionTypes(`${prefix}/PROFILE_UPDATE`);

export type MyPageAction = ActionType<any>;

export type MyPageState = {
  isLoadingReserves: any,
  list: any,
  watched: any,
  reserved: any,
  passed: any,
};
