import styled from "styled-components";

export const Container = styled.div`
    border-top: 1px solid rgba(255,255,255,0.1);
    padding-top: 20px;
    margin-top: 20px;
`;

export const DetailWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    margin-bottom: 20px;
`;

export const Title = styled.div`
    font-size: 10px;
    font-weight: 700;
`;

export const Amount = styled.div`
    font-size: 12px;
    font-weight: 700;
`;

export const TotalAmount = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: #62e979;
`;