import React, { useEffect, useState } from 'react';
import locale from './locale.json';
import * as styled from './Style';
import PaymentPolicyModal from './PaymentPolicyModal';

interface IPaymentMethodAgreementProps {
    kind: string;
    onAgreeAll?: () => void;
    onDisagree?: () => void;
}

const PaymentMethodAgreement = ({ kind, onAgreeAll, onDisagree }: IPaymentMethodAgreementProps) => {
    const [agreeToProvide, setAgreeToProvide] = useState(false);
    const [agreeToPaymentPolicy, setAgreeToPaymentPolicy] = useState(false);
    const [agreeToRefundPolicy, setAgreeToRefundPolicy] = useState(false);
    const [agreeToExchange, setAgreeToExchange] = useState(false);
    const [showPolicy, setShowPolicy] = useState(false);

    const [language, setLanguage] = useState<any>({});

    useEffect(() => {
        setAgreeToProvide(false);
        setAgreeToPaymentPolicy(false);
        setAgreeToRefundPolicy(false);
    }, [kind])

    useEffect(() => {
        if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
        else setLanguage(locale.en)
    }, [language])

    useEffect(() => {
        if (localStorage.getItem('countryCode') === 'KR') {
            if (agreeToProvide && agreeToPaymentPolicy && agreeToRefundPolicy && agreeToExchange && onAgreeAll) onAgreeAll();
            else if (onDisagree) onDisagree();
        } else {
            if (agreeToProvide && agreeToPaymentPolicy && agreeToRefundPolicy && onAgreeAll) onAgreeAll();
            else if (onDisagree) onDisagree();
        }
    }, [agreeToProvide, agreeToPaymentPolicy, agreeToRefundPolicy, agreeToExchange]);

    return (
        <>
            {showPolicy && <PaymentPolicyModal show={showPolicy} onCancel={() => setShowPolicy(false)} />}
            <styled.CheckboxWrapper>
                <styled.CheckboxAgreeToProvide checked={agreeToPaymentPolicy} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgreeToPaymentPolicy(!agreeToPaymentPolicy)} id="terms-condition-to-provide"></styled.CheckboxAgreeToProvide>
                <styled.LabelAgreeToProvide onClick={() => setShowPolicy(true)} style={{ textDecoration: 'underline' }}>
                    {language.AgreeToPaymentPolicy}
                </styled.LabelAgreeToProvide>
            </styled.CheckboxWrapper>
            <styled.CheckboxWrapper>
                <styled.CheckboxAgreeToProvide checked={agreeToProvide} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgreeToProvide(!agreeToProvide)} id="agree-to-provide"></styled.CheckboxAgreeToProvide>
                <styled.LabelAgreeToProvide>
                    {language.Agree}
                </styled.LabelAgreeToProvide>
            </styled.CheckboxWrapper>

            <styled.CheckboxWrapper>
                <styled.CheckboxAgreeToProvide checked={agreeToRefundPolicy} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgreeToRefundPolicy(!agreeToRefundPolicy)} id="agree-to-refund-policy"></styled.CheckboxAgreeToProvide>
                <styled.LabelAgreeToProvide>
                    {language.RefundPolicy}
                </styled.LabelAgreeToProvide>
            </styled.CheckboxWrapper>

            {localStorage.getItem('countryCode') === 'KR' &&
                <styled.CheckboxWrapper>
                    <styled.CheckboxAgreeToProvide checked={agreeToExchange} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgreeToExchange(!agreeToExchange)} id="agree-to-exchange"></styled.CheckboxAgreeToProvide>
                    <styled.LabelAgreeToProvide>
                        {language.Exchange}
                    </styled.LabelAgreeToProvide>
                </styled.CheckboxWrapper>
            }
        </>
    )
}

export default PaymentMethodAgreement;
