import { combineReducers } from 'redux';
import auth from './auth';
import main from './main';
import payment from './payment';
import mypage from './mypage';
import tickets from './tickets';
import sendbird from './sendbird';
import messages from './messages';
import common from './common';
import firebase from './firebase';
import verifications from './verifications';

const rootReducer = combineReducers({
    auth,
    main,
    payment,
    mypage,
    tickets,
    sendbird,
    messages,
    common,
    firebase,
    verifications
});

export default rootReducer;