import styled from "styled-components";
import Background from '../../images/img-bacground.png'

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #2A3139;
    background: url(${Background}) no-repeat center/cover;
    background-image: url(${Background});
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
  align-items: center;
  justify-content: center;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-50%);

`;


export const Symbol = styled.img`
  width: 183px;
  height: 160px;
  margin: auto;
`;

export const Logo = styled.img`
  width: 241.1px;
  height: 48.8px;
  margin: auto;
  margin-top:12%;
`;

export const Text = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top:8%;
`;
