import { callFetchMessages } from 'core/api/messages';
import { ThunkDispatch } from 'redux-thunk';
import * as types from './types';

export const fetchMessages = () => async (dispatch: ThunkDispatch<types.MessagesState, void, types.MessagesAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_FETCH_MESSAGES.PENDING });
    try {
        const _data = await callFetchMessages();
        dispatch({ type: types.T_FETCH_MESSAGES.SUCCESS, payload: _data });
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_FETCH_MESSAGES.FAILURE, payload: e });
    }
}
