import axios from 'axios';

export async function callGetCountry(cb: () => void) {
    try {
        const URL = 'https://zj0qoy8cge.execute-api.ap-northeast-2.amazonaws.com/v1/geoip';
        const config = { };
        const response = await axios.get(`${URL}`, config);
        const { country_code } = response.data;
        localStorage.setItem('countryCode', country_code);
    } catch (e) {
    } finally {
        cb();
    }
}
