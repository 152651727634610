import Header from 'components/Common/Header';
import ReceiptPopup from 'components/Payment/ReceiptPopup';
import PaymentHistoryList from 'components/Setting/PaymentHistoryList';
import RefundPopup from 'components/Setting/RefundPopup';
import { Container } from 'constants/styles/layout';
import { setModal } from 'modules/common';
import { loadPaymentHistories, refundConcert } from 'modules/payment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import locale from '../locale.json';
import { Dim, InfoText } from './Style';
import ReactGA from 'react-ga';
import TransferPopup from 'components/TransferPopup';
import { callGetTransferTransactionCode } from 'core/api/transfer_transactions';
import { callGetOfflineTicket } from 'core/api/offline_tickets';
import OfflineTicketPopup from 'components/OfflineTicketPopup';
import { OFFLINE_TICKET_CONCERT_UUID } from 'constants/apiInfo';
import moment from 'moment';

const paymentHistorysSelector = ({ payment: { paymentHistorys } }: any) => paymentHistorys;

const PaymentList = () => {
  const dispatch = useDispatch();
  const [userAgent, setuserAgent] = useState("");
  const [showTransferPopup, setShowTransferPopup] = useState(false);
  const [showOfflineTicketPopup, setShowOfflineTicketPopup] = useState(false);
  const [offlineTicketInfo, setOfflineTicketInfo] = useState<any>(null);
  const [generatedCode, setGeneratedCode] = useState('');
  const [transferAmount, setTransferAmount] = useState(0);

  const paymentHistorys = useSelector(paymentHistorysSelector);

  const [getRefundDatas, setRefundDatas] = useState("");
  const [receiptData, setReceiptData] = useState(null);
  const [language, setLanguage] = useState<any>({});

  // Compnent did mount
  useEffect(() => {
    const _success = () => { }
    dispatch(loadPaymentHistories(_success));
    if (moment().utc() >= moment(1600052400000).utc()) _getOfflineCode();
  }, []);

  const _getOfflineCode = async () => {
    const _success = (data: any) => setOfflineTicketInfo(data);

    const concertUuid = OFFLINE_TICKET_CONCERT_UUID;
    if (concertUuid) await callGetOfflineTicket(concertUuid, _success);
  };

  const _getCode = async (concertUuid:string) => {
    const cb = (data: any) => {
      setGeneratedCode(data.code);
      setTransferAmount(data.amount);
    }
    await callGetTransferTransactionCode(concertUuid, cb);
  }

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  const _refundConcert = (e: any, chargedPayment: any) => {
    e.preventDefault();
    const _success = () => {
      dispatch(setModal(true, `${language.RefundCompleted}`, ''));
      setRefundDatas("")

      const _payment_success = () => { }
      dispatch(loadPaymentHistories(_payment_success));

      ReactGA.event({ category: 'Main', action: 'Cancel_Concert', label: chargedPayment });
    }
    const _fail = () => {
      dispatch(setModal(true, `${language.RefundFaile}`, ''));
      setRefundDatas("")
    }
    dispatch(refundConcert(chargedPayment, _success, _fail));


  }

  const _refund = (refundData: any) => {
    if (refundData) {
      if (refundData.kind === 'transfer') {
        _getCode(refundData.concertUuid);
        setShowTransferPopup(true);
      } else {
        setRefundDatas(refundData);
      }
    }
  }


  const _receipt = (receiptData: any) => {
    if (receiptData) {
      if (receiptData.kind === 'transfer') {
        _getCode(receiptData.concertUuid);
        setShowTransferPopup(true);
      } else {
        setReceiptData(receiptData);
      }
    }
  }


  const _refundDiv = () => {
    if (getRefundDatas) {
      return (
        <Dim>
          <RefundPopup refundData={getRefundDatas} onSubmit={_refundConcert} onCancel={() => setRefundDatas("")} />
        </Dim>
      )
    } else {
      return (null)
    }
  }

  const _handleOfflineTicketPopup = () => setShowOfflineTicketPopup(true);

  const _receiptPopup = () => {
    if (!receiptData) return null;
    return (
      <Dim>
        <ReceiptPopup receiptData={receiptData} onCancel={() => setReceiptData(null)} />
      </Dim>
    )
  }

  return (
    <Container paddingTop={userAgent ? 0 : 60}>
      <Header iHeaderCenter={"Payment History"} iBackLeftArrowIcon={true} iBackRightSettingIcon={false} iconClose={false} />
      <TransferPopup show={showTransferPopup} onCancel={() => setShowTransferPopup(false)} code={generatedCode} amount={transferAmount} currency={''} />
      <OfflineTicketPopup show={showOfflineTicketPopup} onCancel={() => setShowOfflineTicketPopup(false)} offlineTicketInfo={offlineTicketInfo} />
      <InfoText>{language.PaymentHistoryShow}</InfoText>
      <PaymentHistoryList data={paymentHistorys} onRefundClick={_refund} onItemClick={_receipt} onOfflineClick={_handleOfflineTicketPopup} offlineTicketInfo={offlineTicketInfo} />
      {_refundDiv()}
      {_receiptPopup()}

    </Container>
  )
}

export default PaymentList;
