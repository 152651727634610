import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import MainCard1 from '../../images/51.png';
import { ModalContent, ModalInfo, ModalPrice, ModalThumbnail, ModalTitle } from './Style';
import { amountToPrice } from 'core/utils/numberHelper';


interface IDateFormProps {
  refundData: any;
  onSubmit: (e: any, chargePaymentUuid: string) => void;
  onCancel: () => void;
}

const RefundPopup = ({ refundData, onSubmit, onCancel }: IDateFormProps) => {
  const [langugeCode, setLangugeCode] = useState<string>('');
  const localCode = localStorage.getItem('languageCode') || '';

  const [step, setStep] = useState<boolean>(false);

  useEffect(() => {
    setLangugeCode(localCode)
  }, [localCode]);

  const _handleSubmit = (e: any) => {
    onSubmit(e, refundData);
  }

  const _handleConfirm = (e: any) => {
    setStep(true)
  }

  const _handleCancel = (e: any) => {
    onCancel();
  }

  const _calculateRefundAmount = (amount: number) => {
    const refundAmount = Math.floor(amount - ((amount * 0.039) + 30));
    const refundAmountInDollar = amountToPrice(refundAmount, refundData.currency);
    return refundAmountInDollar;
  }

  if (!step) {
    return (
      <Modal.Dialog>
        <Modal.Header style={{ border: '0px' }} closeButton >
          <Modal.Title>{langugeCode == 'ko' ? '안내' : 'Notice'}</Modal.Title>
          <ModalInfo style={{ height: '170px', fontSize: '1.1rem', paddingTop: '20px', lineHeight: 2 }}>{langugeCode == 'ko' ? '결제 수수료가 차감된 금액이 환불됩니다.환불하시겠습니까?' : 'Your refund will be the amount paid minus the payment service fee. Would you like to proceed?'}</ModalInfo>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={(e: any) => _handleCancel(e)}>{langugeCode == 'ko' ? '취소' : 'Cancel'}</Button>
          <Button variant="primary" onClick={(e: any) => _handleConfirm(e)}>{langugeCode == 'ko' ? '확인' : 'Confirm'}</Button>
        </Modal.Footer>
      </Modal.Dialog>
    )
  } else {
    return (
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>{langugeCode == 'ko' ? '환불' : 'Refund'}</Modal.Title>
          <ModalInfo>{langugeCode == 'ko' ? '해당 공연을 환불 진행하시겠습니까?' : 'Proceed to refund this event?'}</ModalInfo>
        </Modal.Header>

        <Modal.Body>
          {refundData.concertMainPosterUrl ? (
            <ModalThumbnail thumbnailsUrl={refundData.concertMainPosterUrl}></ModalThumbnail>
          ) : (
              <ModalThumbnail thumbnailsUrl={MainCard1}></ModalThumbnail>
            )}
          <ModalTitle>{refundData.metadata.concertName}</ModalTitle>

          <ModalContent>{refundData.concertDescription}</ModalContent>
          <ModalPrice>{_calculateRefundAmount(refundData.amount)}</ModalPrice>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={(e: any) => _handleCancel(e)}>{langugeCode == 'ko' ? '취소' : 'Cancel'}</Button>
          <Button variant="primary" onClick={(e: any) => _handleSubmit(e)}>{langugeCode == 'ko' ? '확인' : 'Confirm'}</Button>
        </Modal.Footer>
      </Modal.Dialog>
    )
  }
}


export default RefundPopup;
