import { API_URL } from '../../constants/apiInfo';
import { getApiAxiosWithToken, getApiAxiosWithoutToken } from '../utils/api';

interface googleSigninParams {
  accessToken: string;
}

export async function callGoogleSignin({ accessToken }: googleSigninParams) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    accessToken: accessToken,
    extType: "google",
  }
  const response = await _axios.post(`${API_URL}/api/v1/users`, _body);

  return response.data;
}

export async function callFacebookSignin({ accessToken }: googleSigninParams) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    accessToken: accessToken,
    extType: "facebook",
  }
  const response = await _axios.post(`${API_URL}/api/v1/users`, _body);

  return response.data;
}

export async function callAppleSignin({ accessToken }: googleSigninParams) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    accessToken: accessToken,
    extType: "apple",
  }
  const response = await _axios.post(`${API_URL}/api/v1/users`, _body);

  return response.data;
}

export async function callKakaoSignin({ accessToken }: googleSigninParams) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    accessToken: accessToken,
    extType: "kakao",
  }
  const response = await _axios.post(`${API_URL}/api/v1/users`, _body);

  return response.data;
}

interface ICallLineSignin {
  code: string;
}
export async function callLineSignin({ code }: ICallLineSignin) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    accessToken: code,
    extType: "line",
  }
  const response = await _axios.post(`${API_URL}/api/v1/users`, _body);

  return response.data;
}

interface ICallTwitterSignin {
  oauthVerifier: string;
  oauthToken: string;
}
export async function callTwitterSignin({ oauthVerifier, oauthToken }: ICallTwitterSignin) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    accessToken: oauthToken,
    extType: "twitter",
    oauthVerifier: oauthVerifier
  }
  const response = await _axios.post(`${API_URL}/api/v1/users`, _body);

  return response.data;
}

interface ICallLineAuth {
  code: string;
}
export async function callLineAuth({ code }: ICallLineAuth) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    code: code,
  }
  const response = await _axios.post(`${API_URL}/api/v1/users/lineAuth`, _body);

  return response.data;
}

export async function callfakeSignin({ accessToken }: googleSigninParams) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    accessToken: accessToken,
    email: "user1@odeon.com"
  }
  const response = await _axios.post(`${API_URL}/api/v1/fakeLogin`, _body);

  return response.data;
}

interface verifyParams {
  queryString: any;
}

export async function callVerifyToken({ queryString }: verifyParams) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    ssoToken: queryString.token
  }
  const response = await _axios.post(`${API_URL}/api/v1/users/verifySsoToken`, _body);

  return response.data;
}

interface IRefreshTokenParams {
  refreshToken: string;
}
export async function callRefreshToken({ refreshToken }: IRefreshTokenParams) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    refreshToken: refreshToken
  }
  const response = await _axios.post(`${API_URL}/api/v1/users/token`, _body);

  return response.data;
}

export async function callLoadMe() {
  const _axios = getApiAxiosWithToken();
  const response = await _axios.get(`${API_URL}/api/v1/users/me`);

  return response.data;
}

interface ILogout {
  refreshToken: string;
}
export async function callLogout({ refreshToken }: ILogout) {
  const _axios = getApiAxiosWithToken();
  const config = {
    data: { refreshToken: refreshToken }
  }
  const response = await _axios.delete(`${API_URL}/api/v1/users/signout`, config);

  return response.data;
}

export async function callPing() {
  const _axios = getApiAxiosWithToken();
  const response = await _axios.get(`${API_URL}/api/v1/users/ping`);

  return response.data;
}

interface anotherverifyParams {
  queryString: any;
}

export async function callAnotherVerifyToken({ queryString }: anotherverifyParams) {
  const _axios = getApiAxiosWithoutToken();
  const _body = {
    ssoToken: queryString.token
  }
  const response = await _axios.post(`${API_URL}/api/yes24/verifySsoToken`, _body);

  return response.data;
}
