import * as types from './types';
import { ThunkDispatch } from 'redux-thunk';
import { callLoadTickets } from 'core/api/tickets';

export const loadTickets = (concertUuid: string) => async (dispatch: ThunkDispatch<types.TicketsAction, void, types.TicketsAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_LOAD_TICKETS.PENDING });
    try {
        const _data = await callLoadTickets({ concertUuid: concertUuid });
        dispatch({ type: types.T_LOAD_TICKETS.SUCCESS, payload: _data });

    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_LOAD_TICKETS.FAILURE, payload: e });
    }
}
