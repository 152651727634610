import styled from "styled-components";

export const NoticeListWrapper = styled.div`
    margin-top: 10px;
    height: 90px;
    padding: 0 10px;
`;

export const NoticeListItemWrapper = styled.div`
    background: rgba(255,255,255,0.05);
    border-radius: 6px;
    height: 100%;
    padding: 15px;
`;

export const NoticeListItemTitle = styled.div`
    color: white;
    font-size: 14px;
    margin-bottom: 12px;
`;

export const NoticeListItemDesc = styled.div`
    color: white;
    font-size: 12px;
`;

export const NoPushsContainer = styled.div`
    display: flex;
    width: 100%;
    height: calc(100% - 100px);
    justify-content: center;
    align-items: center;
    color: #c4c4c4;
`;

export const PushListItemWrapper = styled.div`
    background-color:#2A3139;
    display:flex;
    position:relative;
    justify-content: space-between;
    align-items: flex-end;
    width:90%;
    margin: auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
`;

export const Title = styled.pre`
    display: inline-block;
    height: 100%;
    color: #fff;
    font-size: 0.8rem;
    width:90%;
    font-size:15px;
    font-weight:600;
    float:left;
    line-height: 24px;
    white-space: break-spaces;
`;

export const Time = styled.div`
    position: absolute;
    color: #fff;
    font-size: 0.8rem;
    right: 0;
    font-size:0.8rem;
    opacity:0.4;
`;
