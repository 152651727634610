import TicketOff from 'images/img-bg-ticket-off.png';
import Line from 'images/img-main-ui.png';
import styled from "styled-components";

interface ICardWrapper{
    thumbnailsUrl: string;
}

interface ICard {
    active: boolean;
}


interface IHeaderCenterText{
  paddingRight: number;
}

interface ITicket {
  ticketUrl: string;
  isDetail: boolean;
}

export const TicketLabelWrapper = styled.div<ITicket>`
  width: 295px;
  height: 50px;
  display: inline-block;
  border-radius:5px;
  bottom: ${(props) => props.isDetail ? '300px' : '-40px'};
  position: ${(props) => props.isDetail ? 'fixed' : 'absolute'};
  background-image: url(${(props) => props.ticketUrl? props.ticketUrl : TicketOff});
  transform: translate(-50%,-50%);
  left: 50%;
  top: ${(props) => props.isDetail ? 'auto' : '100%'};
  display: flex;
  align-items: center;
  > span {
    padding-left: 10px;
  }
  @media only screen and (min-width: 850px) {
    bottom: 350px;
  }
`;

export const CardTime = styled.div<ICard>`
  width: 65%;
  border-right: 1px solid rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  opacity: ${(props) => props.active? `1` : `0.5` };
  float:left;
  padding-left: 20px;
  padding-right: 10px;
  flex-direction: column;
`;
// export const CardTime = styled.div<ICard>`
//   width: 100%;
//   border-right: 1px solid rgba(0,0,0,0.1);
//   display: flex;
//   align-items: center;
//   justify-content: space-evenly;
//   height: 30px;
//   font-size: 1.125rem;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   text-align: center;
//   color: #ffffff;
//   opacity: ${(props) => props.active? `1` : `0.5` };
//   float:left;
//   padding-left: 20px;
//   padding-right: 20px;
// `;

export const CardNumber = styled.div<ICard>`
  width: 35%;
  display:inline-block;
  text-align:center;
  color:#fff;
  opacity: ${(props) => props.active? `1` : `0.5`};
  font-size:1.125rem;
  font-weight:bold;
`;

// export const CardStatus = styled.div`
//   text-align:center;
//   color:#fff;
//   font-size:0.9375rem;
//   float:left;
//   width:50%;
//   text-align:left;
//   line-height:30px;
//   border-right: 1px solid rgba(0, 0, 0, 0.1);
// `;

export const CardStatus = styled.div`
  text-align:center;
  color:#fff;
  font-size:0.9375rem;
  float:left;
  width:50%;
  text-align:left;
  line-height:30px;
`;


export const CardDay= styled.div`
  color:#fff;
  font-size:1.25rem;
  float:left;
`;



export const ModalInfo = styled.span`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  font-size: 0.9rem;
  letter-spacing: normal;
  color: #ffffff;
  padding-top:2px;
  padding-left: 15px;
  display:block;
  opacity: 0.8;
`;

export const ModalTitle = styled.div`
  position: relative;
  font-size: 1.0rem;
  width: 100%;
  font-weight: 500;
  line-height: 2.2;
  padding-left: 100px;
  font-weight: 500;

`;
export const ModalContent = styled.div`
  position: relative;
  font-size: 0.9rem;
  width: 100%;
  font-weight: 500;
  line-height: 2.2;
  padding-left: 100px;
  opacity: 0.8;
  height: 20px;
  overflow-y: scroll;
`;

export const ModalPrice = styled.div`
  position: relative;
  font-size: 1.0rem;
  width: 100%;
  font-weight: 500;
  line-height: 2.2;
  padding-left: 100px;
  text-align:right;
  padding-right:5px;
  color: #62e979;
  font-weight: 600;
`;


export const StyledDiv = styled.div`
  > div.modal-dialog {
    height:180px !important;
    background-color: #21262c;
  }
  > div.modal-content{
    height: 100%;
  }

  > div.modal-header{
    border: 1px solid red !important;
    height: 65%;
  }
  > div.modal-title{
    font-size: 1.2rem;
  }
`;


export const Header = styled.div`
  width: 100%;
  display: flex;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  height: 60px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: #1a1f25;
`;

export const HeaderCenterText = styled.div<IHeaderCenterText>`
  display: inline-block;
  position: absolute;
  height: 60px;
  line-height: 60px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  font-size: 1.1rem;
  font-weight:700;
  padding-right: ${(props) => props.paddingRight ? props.paddingRight : 0}px;
`;

export const HeaderLeftButton = styled.img`
  width: 50px;
  height: 50px;
`;

export const HeaderRightButton = styled.img`
  width: 50px;
  height: 50px;
`;

export const HeaderCloseButton = styled.img`
  width: 50px;
  height: 50px;
  display:block;
  position: absolute;
  right:25px;
`;

export const HeaderNull = styled.img`
  margin-top:20px;
`;


export const OpenDate = styled.div`
  height: 35px;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
  float:left;
  line-height: 35px;
  padding-left: 8%;
  &:before {
    content: url(${Line});
    position: absolute;
    transform: translate(-50%, -50%);
    width: 0.6px;
    height: 30px;
    font-weight: 200;
    margin-top:30px;
    left:8%;
    background-image: url(${Line});
  }
`;

export const TimerLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 10px;
  margin-bottom: 3px;
`;

export const TimeLabel = styled.div`
  flex: 1;
  width: 30px;
`;

export const TimerValueWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 23px;
`;

export const TimeValue = styled.div`
  flex: 1;
  width: 30px;
`;

export const TimeValueColon = styled.div`
  width: 5px;
`;
