import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken } from 'modules/auth';
import { callGetCountry } from 'core/api/common';
import { v1 as uuidv1 } from 'uuid';
import LoadingIndicator from 'components/LoadingIndicator';
import ReactHelmet from 'components/Helmet';
import InfoPopup from 'components/InfoPopup';
import ReactGA from 'react-ga';
import * as firebase from 'firebase/app';
import 'firebase/database';
import FirebaseConfig from 'constants/firebase.config';
import moment from 'moment';
import "moment-timezone";
import * as fp from '@fingerprintjs/fingerprintjs';
import intervalPing from 'core/utils/ping';

declare global {
  interface Window { requestIdleCallback: any; }
}

const tokenWhiteListPathNames = [
  '/sso_login',
  '/signin',
  '/terms',
  '/privacy',
  '/opensource',
  '/appleCallback',
  '/kakaoCallback',
  '/appleSignin',
  '/twitterSignin',
  '/twitterCallback',
  '/lineCallback',
  '/faqs',
  '/sns',
  '/demo',
  '/demo360',
  '/demo2d',
  '/promotion/ab6ix/0912',
  '/yes24/ssoToken'
]

const showLoadingSelector = ({ common: { showLoading } }: any) => showLoading;
const userDataSelector = ({ auth: { userData } }: any) => userData;
firebase.initializeApp(FirebaseConfig);

const App = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [fpLoading, setFpLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const showLoadingIndicator = useSelector(showLoadingSelector);
  const userData = useSelector(userDataSelector);

  useEffect(() => {
    ReactGA.initialize('UA-53737632-12');
    const getCountryCB = () => {
      if (localStorage.getItem('refresh_token')) {
        _refreshToken();
      } else if (tokenWhiteListPathNames.includes(history.location.pathname)) {
        setLoading(false)
      } else {
        history.push('/signin');
        setLoading(false)
      }
    }

    callGetCountry(getCountryCB);
    _getOrCreateUuid();
    _handleFingerPrint();

    if (!localStorage.getItem('languageCode')) {
      localStorage.setItem('languageCode', "ko")
      localStorage.setItem('countryCode', "KR")
    }

    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, []);

  useEffect(() => {
    if (userData) intervalPing();
  }, [userData]);

  const _getOrCreateUuid = () => {
    const browser_key = localStorage.getItem('browser_key');
    if (!browser_key) {
      const uuid = uuidv1();
      localStorage.setItem('browser_key', 'web-' + uuid);
    }
  }

  const _handleFingerPrint = () => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(function () {
        fp.get(function (components: any) {
          const hash = fp.x64hash128(components.map(({ value }) => value).join(''), 31)
          localStorage.setItem('browser_fp', hash);
          setFpLoading(false);
        })
      })
    } else {
      setTimeout(function () {
        fp.get(function (components: any) {
          const hash = fp.x64hash128(components.map(({ value }) => value).join(''), 31)
          localStorage.setItem('browser_fp', hash);
          setFpLoading(false);
        })
      }, 500)
    }
  }

  const _refreshToken = () => {
    const success = () => {
      setLoading(false);
      if (history.location.pathname === '/signin') history.push('/');
    }
    const fail = () => {
      setLoading(false);
      history.push('/signin');
    }
    dispatch(refreshToken(success, fail));
  }

  if (loading || fpLoading) return null;

  if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) localStorage.setItem('userAgent', 'fanbox');
  if (window.navigator.userAgent.toLowerCase().indexOf('slsdk') > -1) localStorage.setItem('userAgent', 'slsdk');
  //if(window.navigator.userAgent.toLowerCase().indexOf('iphone') > -1 ) localStorage.setItem('userAgent', 'iphone');

  return (
    <div className="App">
      <ReactHelmet
        keywords="fanbox live k-pop concert fan"
        title="FANBOX.LIVE"
        description="Watch exclusive, live K-Pop concerts online on Fanbox. Fanbox connects fans around the world with their favorite artists and  idols through innovative streaming."
      />
      {showLoadingIndicator && <LoadingIndicator />}
      <InfoPopup />
      {props.children}
    </div>
  )
}

export default App;
