import styled from "styled-components";

interface IContainer{
    landscape: boolean;
    isMobile: boolean;
}

export const Container = styled.div<IContainer>`
    width: 100%;
    display:block;
    position:relative;
    color:#ffffff;
    padding-left:0px;
    padding-right:0px;
    margin-top: 20px;
    padding-top:2%;
    background-color:rgba(255, 255, 255, 0.1);
    background-image: '';
    border-radius: 25px 25px 0% 0%;
    height:calc(100vh - 260px);
    z-index: 150;
    float: none;

    @media only screen and (min-width: 850px) {
      height: ${props => (props.isMobile ?  '400px':'100vh')};
      width: ${props => (props.isMobile ?  '400px':'30%')};
      margin-top: 0px;
      border-radius:0;
      position: relative;
      top:0;
      float:right;


    }
`;


export const Message = styled.div`
  font-size:0.75rem;
  word-break: break-all;
  @media only screen and (min-width: 850px) {
    font-size:1rem;
  }

`;

export const NickName = styled.div`
  font-size:0.625rem;
  opacity:0.6;
  padding-bottom:5px;
  word-break: break-all;
`;

export const MessageBox = styled.div`
    padding-bottom:15px;
    padding-right:20px;
    padding-left:20px;
`;

export const MessageAdmin = styled.div`
    padding:10px;
    border-radius: 5px;
    background-color: #62e979;
    color: #000;
    top: 20px;
    position: absolute;
    width: 90%;
    max-height: 100px;
    overflow-y: scroll;
    margin-left:5%;
    z-index:1;
`;

export const MessageInput = styled.input`
    width: 83%;
    height: 52px;
    border-radius: 5px;
    background-color:#5a6069;
    border-radius: 6px;
    padding-left:20px;
    margin-right: 5%;
    color: #fff;
    ::placeholder {
      color:rgba(255,255,255,0.5);
    }
    @media only screen and (min-width: 850px) {
      width: 80%;
    }
}
`;

export const MessageFormBox = styled.div`
    margin-top:10px;
`;

interface ILandscape{
    landscape: boolean;
    isMobile: boolean;
    isHeight: number;
}

export const ChatWrapper = styled.div<ILandscape>`
    display: flex;
    flex-direction: column;
    height: ${props => (props.isHeight ? props.isHeight +'px':'55vh')};
    overflow-y: scroll;
    -ms-overflow-style: none;

    @media only screen and (min-width: 850px) {
      padding-bottom:30px;
      height: ${props => (props.isMobile ?  '':'calc(100vh - 50px)')};
    }
`;

export const IconChat = styled.div`
    display: inline-block;
    vertical-align: middle;
    position: relative;
    display: block;
`;

export const IconSendImg = styled.img`
  width: 45px;
  height: 45px;
`;

export const IconKeyboardImg = styled.img`
  width: 45px;
  height: 45px;
`;

export const IconEmoticonImg = styled.img`
  width: 45px;
  height: 45px;
`;

export const ChatImg = styled.img`
  width: 70px;
  height: 70px;
`;

interface IEmotionContent{
    isMobile: boolean;
}


export const EmotionContent = styled.div<IEmotionContent>`
  height: 200px;
  background-color: #9097a0;
  z-index: 10000;
  position: relative;
  overflow-x: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media only screen and (min-width: 850px) {
    height: 300px;
    justify-content: space-between;
  }
`;


export const Emotion = styled.div`

`;

export const EmotionNull = styled.div`
  width: 70px;
  height: 70px;
`;

export const EmotionImg = styled.img`
    width: 70px;
    height: 70px;
    float: left;
`;

interface IChatContainer{
    fullmode: boolean;
}

export const ChatContainer = styled.div<IChatContainer>`
  bottom:0;
  width: 100%;
  position:fixed;
  z-index:160;
  @media only screen and (min-width: 850px) {
    width: 30% !important;
    float: right;
    position: absolute;
    right: 0;
    bottom:0px;
  }
`;

interface IFloating{
    isChannelOpen: boolean;
}
export const Floating = styled.div<IFloating>`
	position:fixed;
	width:60px;
	height:60px;
	bottom:60px;
	right:5px;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  @media only screen and (min-width: 850px) {
    bottom: 40px;
  }

`;

export const Heart = styled.div`
  right:0;
  position: fixed;
  width: 150px;
  bottom: 15%;
  text-align: center;

`;

export const IconClose = styled.img`
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
`;


export const CloseBtn = styled.div`
  font-size:0.9rem;
  width: 40px;
  float: right;
  word-break: break-all;
  text-align: right;
`;

export const KeyboardNull = styled.div`
  height:0;
`;
