import RM360DemoPlayer from 'components/Stage/RM360DemoPlayer';
import { API_URL } from 'constants/apiInfo';
import { getApiAxiosWithoutToken } from 'core/utils/api';
import { useDetectMobile } from 'hooks/useDetectMobile';
import React, { useEffect, useState } from 'react';
import button360Json from '../../constants/lotties/button_360_white.json';
import * as styled from './Style';

const animationData = button360Json;

// const url_2d = 'https://staging.fanbox.live/public/absix/HLS/mv_all.m3u8';
// const url_360 = 'https://staging.fanbox.live/HLS/alexa/HLS/360.m3u8';

const Demo = () => {

  const [landscape, setLandscape] = useState(false);
  const [show360, setShow360] = useState(false);
  const [url2d, seturl2d] = useState('');
  const [url360, seturl360] = useState('');

  const [isMobile, isApplied] = useDetectMobile();

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.width = '100%';
      root.style.height = '100vh';
    }
  }, []);

  useEffect(() => {
    setUrl();
    window.addEventListener("load", function () {
      setTimeout(function () {
        window.scrollTo(0, 1);
      }, 0);
    });
    window.addEventListener('resize', function (event) {
      if (window.orientation === 90 || window.orientation === -90) {
        window.scrollTo(0, 1)
        setLandscape(true)
      } else {
        setLandscape(false)
      }
    });
  }, []);

  const setUrl = async () => {
    const _axios = getApiAxiosWithoutToken();
    const response = await _axios.get(`${API_URL}/api/v1/concerts/demo`);
    const data = response.data;
    seturl2d(data.streamingUrl_2d);
    seturl360(data.streamingUrl_360);
  };
  return (
    <styled.Container landscape={landscape} id="StageContainer">
      {/* <styled.StageHeader landscape={landscape}>
        <styled.StageHeaderLeftButton src={iconClose} />
        {!show360 &&
          <styled.Stage360Button onClick={() => setShow360(true)}>
            <Lottie
              width={'100%'}
              options={{
                animationData
              }}
            />
          </styled.Stage360Button>
        }
        {show360 && <styled.StageLivwButton src={iconLive} onClick={() => setShow360(false)} />}
      </styled.StageHeader> */}
      {url360 !== '' && <RM360DemoPlayer landscape_mode={landscape} currentMobile={isMobile} streamingUrl_360={url360} streamingUrl_2d={''} />}
      {/* {show360 ? (
        url360 !== '' && <RM360DemoPlayer landscape_mode={landscape} currentMobile={isMobile} streamingUrl_360={url360} streamingUrl_2d={''} />
      ) : (
         url2d !== '' && <RM2dDemoPlayer landscape_mode={landscape} currentMobile={isMobile} streamingUrl_2d={url2d} />
        )} */}
      <div style={{ marginTop: '50px', 'backgroundColor': '#000', 'paddingBottom': '0' }} ></div>
    </styled.Container>
  );
}

export default Demo;
