import styled from 'styled-components';

export const Title = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
`;

export const SubTitle = styled.div`
    margin-bottom: 20px;
    line-height: 24px;
`;

export const ContentContainer = styled.div`
    margin-top: 80px;
`;

export const ContentTitle = styled.div`
`;

interface IContentDetailWrapper {
    display: string;
}
export const ContentDetailWrapper = styled.div<IContentDetailWrapper>`
    padding-left: 20px;
    display: ${props => props.display};
    margin-bottom: 10px;
`;
export const ContentDetailIndex = styled.div`
`;
export const ContentDetail = styled.div`
    flex: 1;
    padding-left: 20px;
`;

export const ContentDetailDetailWrapper = styled.div`
    padding-left: 20px;
    display: flex;
    margin-bottom: 10px;
`;
export const ContentDetailDetailIndex = styled.div`
`;
export const ContentDetailDetail = styled.div`
    flex: 1;
    padding-left: 20px;
`;