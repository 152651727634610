import * as React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import loadingJson from '../../constants/lotties/loading.json';
const animationData = loadingJson;

const Dim = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadingIndicator = () => (
    <Dim>
        <Lottie
            width={'50%'}
            options={{
                animationData
            }}
        />
    </Dim>
);

export default LoadingIndicator;
