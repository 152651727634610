import AppleLogin from 'components/Signin/AppleLogin';
import LineLogin from 'components/Signin/LineLogin';
import { TWITTER_REQUEST_TOKEN_URL, TWITTER_SIGNIN_URL } from 'constants/apiInfo';
import backgrounOffUrl from 'images/ic-check-blank.png';
import backgrounOnUrl from 'images/ic-check.png';
import iconFacebook from 'images/ic-facebook.png';
import iconGoogle from 'images/ic-google.png';
import iconKakao from 'images/ic-kakaotalk.png';
import iconTwitterGray from 'images/ic-twitter-gray.png';
import { setTerm } from 'modules/common';
import qs from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TwitterLogin from 'react-twitter-auth';
import InfoPopup from '../../components/Main/InfoPopup';
import { facebookLogin, googleLogin, setTwitterProcessing, setUserInfo, twitterSignin } from '../../modules/auth';
import KakaoLogin from './KakaoLogin';
import * as styled from './Style1';
import { RootState } from 'modules/types';


const Signin = () => {
  const [langugeCode, setLangugeCode] = useState<string>('');
  const [infoPopup, setInfoPopup] = useState<string>("");
  const [isTerm, setIsTerm] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector((state: RootState) => state.auth.userData);

  const localCode = localStorage.getItem('languageCode') || '';

  useEffect(() => {
    if (userData) history.push("/main");
  }, [userData]);

  useEffect(() => {
    setLangugeCode(localCode)
  }, [localCode])

  const onSuccess = useCallback(
    res => {
      dispatch(googleLogin(res.tokenObj.id_token))
    },
    []
  );

  const _handleFacebookLoginClick = (e: any) => {
  }

  const _handleFacebookResponse = (e: any) => {
    const fbAccessToken = e.accessToken;
    if (fbAccessToken) dispatch(facebookLogin(fbAccessToken));
  }

  const _handleTwitterSigninSuccess = (e: any) => {
    const queryUrl = e.url;
    const { query } = qs.parseUrl(queryUrl);
    const success = (data: any) => {
      if (!localStorage.getItem('userAgent')) {
        dispatch(setUserInfo(data));
      } else {
        const _userAgent = localStorage.getItem('userAgent');
        if (_userAgent === 'slsdk') window.location.href = `slsdk://login_token?refresh_token=${data.refreshToken}`;
      }
    }
    if (query.oauth_verifier && query.oauth_token) {
      dispatch(twitterSignin(query.oauth_verifier.toString(), query.oauth_token.toString(), success));
    }
  }

  const _handleTwitterSigninFail = (e: any) => { };


  const _handleTerm = () => {
    if (isTerm) {
      setIsTerm(false)
    } else {
      setIsTerm(true)
    }
  }

  const _handleTermCheck = () => {
    console.log("termCheck")
    if (!isTerm) {
      dispatch(setTerm(true, false));
    }
  }


  const _popupDiv = () => {
    if (infoPopup) {
      return (
        <styled.Dim>
          <InfoPopup title={infoPopup} onCancel={() => setInfoPopup("")} />
        </styled.Dim>
      )
    } else {
      return (null)
    }
  }

  const _setTwitterProcessing = () => {
    dispatch(setTwitterProcessing(true));
  }

  const _navigatePrivacy = () => {
    history.push("/terms?locale=" + langugeCode);
  }
  return (
    <styled.Container active={true}>
      <styled.Content>
        <styled.BtnGroups onClick={() => _handleTermCheck()}>
          <styled.BoxTop>
            <styled.LogoBox disabled={isTerm}>
              <styled.Btns>
                <FacebookLogin
                  icon={<styled.LoginButton src={iconFacebook} />}
                  appId="238162814122933"
                  autoLoad={false}
                  fields="name,email,picture"
                  buttonStyle={{ all: 'unset' }}
                  textButton=""
                  disableMobileRedirect={true}
                  onClick={_handleFacebookLoginClick}
                  callback={_handleFacebookResponse} />
              </styled.Btns>
              <styled.SocailType>
                Facebook
              </styled.SocailType>
            </styled.LogoBox>
            <styled.LogoBox disabled={isTerm}>
              <styled.Btns>
                <GoogleLogin
                  accessType="offline"
                  clientId='836757373586-52k8np59l95t4oh52itb1n6llrmc7nq4.apps.googleusercontent.com'
                  render={renderProps => (
                    <styled.LoginButton src={iconGoogle} onClick={renderProps.onClick} />
                  )}
                  onSuccess={onSuccess}
                  onFailure={result => console.log("err", result)}
                  cookiePolicy={'single_host_origin'}
                />
              </styled.Btns>
              <styled.SocailType>
                Google
              </styled.SocailType>
            </styled.LogoBox>
            <styled.LogoBox disabled={isTerm}>
              <AppleLogin />
              <styled.SocailType>
                Apple
              </styled.SocailType>
            </styled.LogoBox>
          </styled.BoxTop>
          <styled.BoxBottom>
            <styled.LogoBox disabled={isTerm}>
              <LineLogin />
              <styled.SocailType>
                LINE
              </styled.SocailType>
            </styled.LogoBox>
            <styled.LogoBox onClick={_setTwitterProcessing} disabled={isTerm}>
              <TwitterLogin
                loginUrl={TWITTER_SIGNIN_URL}
                onFailure={_handleTwitterSigninFail}
                onSuccess={_handleTwitterSigninSuccess}
                requestTokenUrl={TWITTER_REQUEST_TOKEN_URL}
                style={{ background: 'none', padding: 0, margin: 'auto' }}
              >
                <styled.Btns>
                  <styled.ImgSocialSigninLogo src={iconTwitterGray} style={{ width: '30px', height: '30px' }} />
                </styled.Btns>
              </TwitterLogin>
              <styled.SocailType>
                Twitter
              </styled.SocailType>
            </styled.LogoBox>
            <styled.LogoBox disabled={isTerm}>
              <KakaoLogin>
                <styled.Btns>
                  <styled.ImgSocialSigninLogo src={iconKakao} style={{ width: '30px', height: '30px' }} />
                </styled.Btns>
              </KakaoLogin>
              <styled.SocailType>
                Kakao
                </styled.SocailType>
            </styled.LogoBox>
          </styled.BoxBottom>
        </styled.BtnGroups>
        <styled.Line></styled.Line>
        <styled.TermCheck>
          <styled.Checkbox backgrounUrl={isTerm ? backgrounOnUrl : backgrounOffUrl} onClick={() => _handleTerm()}></styled.Checkbox>
          <styled.TermLink onClick={_navigatePrivacy}>{langugeCode == 'ko' ? '이용약관 및 개인정보 처리방침에 동의합니다.' : 'I agree to the terms & conditions and privacy policy.'} </styled.TermLink>
        </styled.TermCheck>
      </styled.Content>
    </styled.Container>
  )
}

export default Signin;
