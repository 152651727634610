import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyles = createGlobalStyle`
    ${reset}
    * {
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
    }
    body {

        font-family: 'NanumSquare', sans-serif;
    }
    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }
    input, button {
        border: none;
        outline: none;
    }
    ol, ul, li {
        list-style: none;
    }
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
  
`

export default GlobalStyles;
