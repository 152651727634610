import { callCancelOrder, callCreateOrder, callExecuteOrder } from 'core/api/orders';
import { setShowLoading } from 'modules/common';
import { TTicket } from 'modules/tickets';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import locale from './locale.json';
import PaypalButton from './PaypalButton';

declare global {
    interface Window { paypal: any; }
}

interface IPaypalPurchase {
    currentTicket: TTicket;
    referralCode: string;
    email: string;
    onSubmit: () => void;
}

const PaypalPurchase = ({ currentTicket, referralCode, onSubmit, email }: IPaypalPurchase) => {
    const [language, setLanguage] = useState<any>({});

    useEffect(() => {
        localStorage.setItem('referralCode', referralCode);
    }, [referralCode]);

    useEffect(() => {
        localStorage.setItem('ticketUuid', currentTicket.uuid);
    }, [currentTicket]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
        else setLanguage(locale.en)
    }, [language])

    const _createOrder = async (data, actions) => {
        try {
            const currentTicketUuid = localStorage.getItem('ticketUuid');
            const createdOrderRes = await callCreateOrder({ concertUuid: currentTicket.concertUuid, ticketUuid: currentTicketUuid || currentTicket.uuid, email: email });
            return createdOrderRes.orderId;
        } catch (e) {
            alert('failed to create payment');
            return '';
        }
    }
    const _handleApprove = async (data, _) => {
        const code = localStorage.getItem('referralCode');
        try {
            dispatch(setShowLoading(true));
            await callExecuteOrder({ orderId: data.orderID, payerId: data.payerID, referralCode: code || '' });
            onSubmit();
        } catch (e) {
            alert('charge error');
        }
    };

    const _handleCancel = async (data, _) => {
        try {
            await callCancelOrder({ orderId: data.orderID });
        } catch (e) {
            console.log('handle cancel failed');
        }
    }

    return (
        <PaypalButton
            createOrder={_createOrder}
            executeOrder={_handleApprove}
            cancelOrder={_handleCancel}
        />
    )
}

export default PaypalPurchase;
