import styled from "styled-components";
import MainCard1 from '../../images/big-69.png'
import TicketOn from '../../images/img-small-ticket.png'

interface ITabs {
    active: boolean;
}

interface IPaymentInfo {
    disabled: boolean;
}

interface IContainer {
    paddingTop: number;
}


export const Container = styled.div<IContainer>`
    width: 100%;
    height: 100%;
    background-color: #2A3139;
    padding-top: ${(props) => props.paddingTop ? props.paddingTop : 0}px;
    @media only screen and (min-width: 850px) {
      height: 100vh;
    }
`;


export const Header = styled.div`
    width: 100%;

    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: relative;

    height: 60px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: #1a1f25;
`;

export const HeaderCenterText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.2rem;
    font-weight:700;
`;

export const HeaderLeftButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const HeaderRightButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const EnterButton = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
`;

export const ContentCard = styled.div`
    background-color:#2A3139;
    display:block;
    width:95%;
    margin: auto;
    border-radius: 6px;
    background-color: #333a40;
    margin-top:10px;
    margin-bottom:10px;
    padding-left:10px;
`;

export const ContentContainer = styled.div`
    height: 60px;
    background-color:#2A3139;
    display:block;
    position:relative;
    width:90%;
    margin: auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const HelpText = styled.div`
    max-width: 240px;
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
`;

export const HelpSubText = styled.div`
    max-width: 240px;
    margin: 0 auto;
    margin-top: 15px;
    color: #999;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
`;

export const Form = styled.div`
    margin-top: 25px;
    width: 100%;
`;



export const MainCard = styled.div`
    width: 90%;
    height: 200px;
    padding-top:20px;
    padding-bottom:20px;
    top: 0;
    left: 0;
    border-bottom:1px solid rgba(255, 255, 250, 0.2);
    margin: auto;
`;



export const ProfileNickNameText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 10%;
    left: 38%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.2rem;
`;

export const ProfileInfoText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    left: 38%;
    color: #fff;
    font-size: 0.8rem;
    opacity: 0.5;
    left: 27%;
`;

export const PaymentCard = styled.div`
    display: grid;
    height: 100%;
    color: #ffffff;
`;

export const CardTitle = styled.div`
    display: block;
    font-size:0.8rem;
    line-height: 2.4;
`;


export const CardInfo = styled.div`
    display: block;
    font-size:1rem;
    padding-bottom:20px;
`;



export const PaymentInfo = styled.div<IPaymentInfo>`
    display: inline;
    height: 100%;
    line-height: 60px;
    color: #fff;
    font-size: 0.8rem;
    width:90%;
    transform: translate(-50%, -50%);
    left: 50%;
    font-size:1rem;
    font-weight:600;
    opacity:${(props) => props.disabled? '0.3' : '1'};
`;



export const NickNameText = styled.span`
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    font-size: 0.8rem;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    padding-top:2px;
    padding-left: 15px;
    display:block;
`;

export const Paypal = styled.div`
    width: 89%;
    height: 50px;
    color: #000000;
    background-color: #fff;
    line-height: 50px;
    border-radius: 7px;
    text-align: center;
    margin-top: 50px;
    border: none;
    font-size:1rem;
    margin:auto;
    display:block;
    padding-top: 12px;
    float:left;
    opacity: 0.9;
`;

export const PaypalImg = styled.img`
    width: 100px;
    height: 23px;
    margin: auto;
`;
