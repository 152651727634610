import { useScript } from 'hooks/useScript';
import React, { useEffect, useState } from 'react';
import * as styled from './Style';
import iconPeople from 'images/ic-people.png';
import DragIndicator from './DragIndicator';

declare global {
  interface Window { vr: any; player: any; videojs: any; RadiantMP: any; rmp_360: any; }
}

interface IRM360Player {
  landscape_mode: boolean;
  currentMobile: boolean;
  streamingUrl_2d: string | Array<number>;
  streamingUrl_360: string | Array<number>;
}

const RM360Player = ({ landscape_mode, currentMobile, streamingUrl_2d, streamingUrl_360 }: IRM360Player) => {
  if (Array.isArray(streamingUrl_360)) streamingUrl_360 = String.fromCharCode.apply(String, streamingUrl_360);

  const demoUrl = 'https://bitmovin-a.akamaihd.net/content/playhouse-vr/m3u8s/105560.m3u8';
  const demo = false;
  const [userAgent, setuserAgent] = useState("");

  const [rmpLoaded, error] = useScript('https://cdn.radiantmediatechs.com/rmp/5.9.2/js/rmp.min.js');
  // const [rmpLoaded, error] = useScript('https://cdn.radiantmediatechs.com/rmp/5.9.2/js/rmp.debug.js');

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  // useEffect(() => {
  //   window.addEventListener('resize', function (event) {
  //     if (window.orientation === 90 || window.orientation === -90) {
  //       document.body.style.backgroundColor = 'black';
  //       window.rmp_360.setFullscreen(true);
  //     } else {
  //       document.body.style.background = '#2A3139';
  //       window.rmp_360.setFullscreen(false);
  //     }
  //   });
  // }, []);

  const handleOverlayButton = () => {
    const levelArea = document.getElementsByClassName('rmp-overlay-level') as HTMLCollectionOf<HTMLDivElement>;
    const overlayArea = document.getElementsByClassName('rmp-module-overlay') as HTMLCollectionOf<HTMLDivElement>;

    for (var i = 0; i < levelArea.length; i++) {
      levelArea[i].ontouchend = () => { closeOverlay(); }
      levelArea[i].onclick = () => { closeOverlay(); }
    };

    for (var i = 0; i < overlayArea.length; i++) {
      overlayArea[i].ontouchend = () => { closeOverlay(); }
      overlayArea[i].onclick = () => { closeOverlay(); }
    };
  }
  const closeOverlay = () => {
    // const overlayArea = document.getElementsByClassName('rmp-module-overlay') as HTMLCollectionOf<HTMLDivElement>;
    // // const overlayArea = document.getElementsByClassName('rmp-module-overlay') as HTMLCollectionOf<HTMLDivElement>;
    // overlayArea[0].style.opacity ='0';
    // overlayArea[0].style.visibility ='hidden';
    // let element = document.getElementById("rmp360Player")?.classList;
    // element?.remove("rmp-container rmp-color-button rmp-container-fade-in rmp-360-ui rmp-no-central-play rmp-in-band-cc rmp-medium rmp-no-chrome");

    window.rmp.setModuleOverlayVisible('quality', false);
    window.rmp.setModuleOverlayVisible('captions', false);
  }

  // const changeBitrage = (src: string) => {
  //   const originSrc = src.split('.m3u8')[0];
  //   if (currentMobile) {
  //     return originSrc + '_720.m3u8';
  //   }
  //   return originSrc + '_1080.m3u8';
  // }

  useEffect(() => {
    if (rmpLoaded && window.RadiantMP) {
      let updatedUrl = streamingUrl_360;

      if (demo) updatedUrl = demoUrl;

      var src = {
        hls: updatedUrl
      };
      var settings = {
        licenseKey: 'Kl8ldT1rK2U1MmNzOXY/cm9tNWRhc2lzMzBkYjBBJV8q',
        src: src,
        autoHeightMode: false,
        automaticFullscreenOnLandscape: true,
        muted: true,
        video360MinFocalLength: 70,
        video360FocalLength: 110,
        video360: true,
        autoplay: true,
        loop: true,
        hideSeekBar: true,
        hideCentralPlayButton: true,
        hlsJSFetchXhrWithCredentials: !demo,
        forceHlsJSOnMacOSIpadOSSafari: true,
        video360FallbackSrc: {
          hls: updatedUrl
        },
        skin: 's3',
      };
      var elementID = 'rmp360Player';
      var rmp = window.rmp_360 = new window.RadiantMP(elementID);
      var rmpContainer = document.getElementById(elementID);
      rmp.setModuleOverlayVisible('quality', true);
      rmp.setModuleOverlayVisible('captions', true);
      rmp.init(settings);
      rmpContainer!.addEventListener('playing', function () {
        handleOverlayButton();
      })
    }
  }, [rmpLoaded, streamingUrl_360, streamingUrl_2d]);

  if (!rmpLoaded) return <styled.Video landscape={landscape_mode} isMobile={currentMobile} />;

  return (
    <styled.Video landscape={landscape_mode} isMobile={currentMobile}>
      <DragIndicator />
      <styled.CountBox landscape={landscape_mode}>
        <styled.CountImg src={iconPeople} />
        <styled.Count />
      </styled.CountBox>
      <div style={{ width: '100%', height: '100%' }} id="rmp360Player"></div>
    </styled.Video>
  )
}
export default RM360Player;
