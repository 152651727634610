import phoneCountryCodes from 'constants/phoneCountryCodes.json';
import React, { useState, useEffect, useRef } from 'react';
import * as styled from './Style';
import icOpenSelect from 'images/ic-open-select.png';

interface IPhoneSelectProps {
    onSelect: (data: string) => void;
}

const PhoneSelect = ({ onSelect }: IPhoneSelectProps) => {
    const [selected, setSelected] = useState('');
    const [showList, setShowList] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener('mousedown', _handleClickOutsideOfSelect);
        return () => {
            document.removeEventListener('mousedown', _handleClickOutsideOfSelect);
        }
    }, [showList]);

    const _handleClickOutsideOfSelect = (event: any) => {
        if (selectRef.current && popupRef.current) {
            if (!selectRef.current.contains(event.target) && showList && !popupRef.current.contains(event.target)) {
                setShowList(false);
            }
        }
    }

    useEffect(() => {
        const countryCode = localStorage.getItem('countryCode');
        const filtered = phoneCountryCodes.filter((p) => p.code === countryCode)[0];
        if (filtered) _handleSelect(filtered.dial_code);
    }, []);

    const _handleSelect = (phoneCode: string) => {
        setShowList(false);
        setSelected(phoneCode);
        onSelect(phoneCode);
    }

    const _renderPopup = () => {
        if (showList) {
            return (
                <styled.PopupWrapper ref={popupRef}>
                    {phoneCountryCodes.map((p) => {
                        return (
                            <styled.PopupContentWrapper key={p.code} onClick={() => _handleSelect(p.dial_code)}>
                                <styled.PopupContent>{p.dial_code}</styled.PopupContent>
                            </styled.PopupContentWrapper>
                        )
                    })}
                </styled.PopupWrapper>
            )
        }
        return null;
    }

    return (
        <styled.Container>
            <styled.SelectWrapper ref={selectRef} onClick={() => setShowList((v) => !v)}>
                <styled.TextSelect>{selected === '' ? '선택' : selected}</styled.TextSelect>
                <styled.BtnSelect src={icOpenSelect} />
            </styled.SelectWrapper>
            {_renderPopup()}
        </styled.Container>
    )
}

export default PhoneSelect;
