import React, { useCallback, useEffect, useState, useRef } from "react"
import SendBird from "sendbird"
import { SENDBIRD_APP_ID } from 'constants/apiInfo';
import { useDispatch, useSelector } from "react-redux"
import { loadChannels, enterChannel, loadPrevMessage, messageReceivedOnPrompter } from "../../modules/sendbird"
import { loadMe } from 'modules/auth';
import { useChannelHandler } from "hooks/sendbird"
import { Container, MessageBox, Message, NickName, ChatWrapper, ChatImg } from './Style';
import { RootState } from "modules/types";

interface ISendbirdProps {
  channelUrl: string;
}

const connectSelector = ({ sendbird: { connect } }: any) => connect;
const channelsSelector = ({ sendbird: { channels } }: any) => channels;
const enterSelector = ({ sendbird: { enterChannel } }: any) => enterChannel;
const messageSelector = ({ sendbird: { messages } }: any) => messages;

const Chat = ({ channelUrl }: ISendbirdProps) => {
  const [connect, setConnect] = useState(false);
  const [firstScrolled, setFirstScroll] = useState(false);

  const dispatch = useDispatch()

  const connected = useSelector(connectSelector);
  const userData = useSelector((state: RootState) => state.auth.userData);
  const channels = useSelector(channelsSelector);
  const enterChannels = useSelector(enterSelector);
  const messages = useSelector(messageSelector);
  const containerEl = useRef<null | HTMLDivElement>(null);


  // Compnent did mount
  useEffect(() => {
    dispatch(loadMe());
  }, []);

  useEffect(() => {
    const appId = SENDBIRD_APP_ID
    const sb = SendBird.getInstance()
    if (appId && userData) {
      const _successEnter = () => { }
      const _success = (user, error) => {
        setConnect(true)
        sb.updateCurrentUserInfo(userData.nickName, '', _successEnter)
        dispatch(enterChannel(channelUrl, _successEnter))
      }
      sb.connect(userData.uuid, _success);
    }
  }, [userData, dispatch])


  useEffect(() => {
    dispatch(loadChannels())
  }, [connected, dispatch])


  useEffect(() => {
    const _success = () => { }
    if (connect) dispatch(enterChannel(channelUrl, _success))
  }, [connected, dispatch])

  useEffect(() => {
    const _success = () => { }
    dispatch(loadPrevMessage(enterChannels, _success))
  }, [enterChannels])

  useChannelHandler(
    `fenbox-channel-sub`,
    [
      {
        name: 'onMessageReceived',
        callback: (enterChannels, message) => {
          dispatch(messageReceivedOnPrompter(enterChannels, message))
        },
      },
    ]
  )

  const _scrollToBottom = useCallback(() => {
    if (containerEl.current) {
      if (firstScrolled) {
        containerEl.current.scroll({
          top: containerEl.current.scrollHeight,
          behavior: 'smooth',
        })

      } else if (containerEl.current.scrollHeight) {
        containerEl.current.scrollTop = containerEl.current.scrollHeight
        setFirstScroll(true)
      }
    }
  }, [firstScrolled])

  useEffect(() => {
    _scrollToBottom()
  }, [messages.length])

  const _handleScroll = useCallback((e) => {
    const currentScroll = e.target.scrollTop
    if (currentScroll === 0 && enterChannels) {
      const _success = () => { }
      dispatch(loadPrevMessage(enterChannels, _success))
    }
  }, [enterChannels])

  const _renderChats = useCallback(() => {
    if (!userData) return null;

    return (
      <ChatWrapper ref={containerEl} onScroll={_handleScroll}>

        {enterChannels && messages && messages.map((message) => {
          if (message.messageType == 'user' && message.sender.userId === userData.uuid) {
            return (
              <MessageBox key={`${message.messageId}`} className="chat-row-wrapper my">
                <NickName>{message.sender ? `${message.sender.nickname}` : "-"}</NickName>
                <Message className="chat-wrapper">
                  {message.data ? <ChatImg src={message.data} /> : message.message}
                </Message>
              </MessageBox>
            )
          } else {
            return (
              <MessageBox key={`${message.messageId}`} className="chat-row-wrapper other">
                <NickName>{message.sender ? `${message.sender.nickname}` : "admin"}</NickName>
                <Message className="chat-wrapper">
                  {message.data ? <ChatImg src={message.data} /> : message.message}
                </Message>
              </MessageBox>
            )
          }
        })}
      </ChatWrapper>
    )
  }, [messages, userData])

  if (!channelUrl || channelUrl === '') {
    return null;
  }

  return (
    <Container>
      <div className="container" id="ChatRoom">
        <div className={`dimmed z-1 active`} />
        <div className="content chatroom">
          {_renderChats()}
        </div>
      </div>
    </Container>
  )
}

export default Chat
