import * as types from './types';
import { ThunkDispatch } from 'redux-thunk';
import { callGoogleSignin, callfakeSignin, callFacebookSignin, callRefreshToken, callLoadMe, callVerifyToken, callLogout, callAppleSignin, callLineSignin, callLineAuth, callTwitterSignin, callKakaoSignin, callAnotherVerifyToken } from 'core/api/auth';
import { T_SET_SHOW_LOADING } from 'modules/common';

export const refreshToken = (success: () => void, fail: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    const _refreshToken = localStorage.getItem('refresh_token');
    if (!_refreshToken) return fail();

    dispatch({ type: types.T_REFRESH_TOKEN.PENDING });
    try {
        const _data = await callRefreshToken({ refreshToken: _refreshToken });
        dispatch({ type: types.T_REFRESH_TOKEN.SUCCESS, payload: _data });
        success();
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_REFRESH_TOKEN.FAILURE, payload: e });
        fail();
    }
}

export const googleLogin = (accessToken: string) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    dispatch({ type: types.T_GOOGLE_SIGNIN.PENDING });
    try {
        const _data = await callGoogleSignin({ accessToken: accessToken });
        dispatch({ type: types.T_GOOGLE_SIGNIN.SUCCESS, payload: _data });
    } catch (e) {
        dispatch({ type: types.T_GOOGLE_SIGNIN.FAILURE, payload: e });
    }
}

export const facebookLogin = (fbAccessToken: string) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    dispatch({ type: types.T_FACEBOOK_SIGNIN.PENDING });
    try {
        const _data = await callFacebookSignin({ accessToken: fbAccessToken });
        dispatch({ type: types.T_FACEBOOK_SIGNIN.SUCCESS, payload: _data });
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_FACEBOOK_SIGNIN.FAILURE, payload: e });
    }
}

export const appleLogin = (idToken: string, cb: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    dispatch({ type: types.T_APPLE_SIGNIN.PENDING });
    try {
        const _data = await callAppleSignin({ accessToken: idToken });
        dispatch({ type: types.T_APPLE_SIGNIN.SUCCESS, payload: _data });
        cb();
    } catch (e) {
        dispatch({ type: types.T_APPLE_SIGNIN.FAILURE, payload: e });
    }
}

export const kakaoSignin = (accessToken: string, cb: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    dispatch({ type: types.T_APPLE_SIGNIN.PENDING });
    try {
        const _data = await callKakaoSignin({ accessToken: accessToken });
        dispatch({ type: types.T_APPLE_SIGNIN.SUCCESS, payload: _data });
        cb();
    } catch (e) {
        dispatch({ type: types.T_APPLE_SIGNIN.FAILURE, payload: e });
    }
}

export const lineSignin = (code: string, cb: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    dispatch({ type: types.T_LINE_SIGNIN.PENDING });
    try {
        const _data = await callLineSignin({ code: code });
        dispatch({ type: types.T_LINE_SIGNIN.SUCCESS, payload: _data });
        cb();
    } catch (e) {
        dispatch({ type: types.T_LINE_SIGNIN.FAILURE, payload: e });
    }
}

export const twitterSignin = (oauthVerifier: string, oauthToken: string, cb: (data: any) => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    dispatch({ type: types.T_TWITTER_SIGNIN.PENDING });
    try {
        dispatch({ type: T_SET_SHOW_LOADING, payload: true });
        const _data = await callTwitterSignin({ oauthVerifier: oauthVerifier, oauthToken: oauthToken });
        dispatch({ type: types.T_TWITTER_SIGNIN.SUCCESS, payload: _data });
        cb(_data);
        dispatch({ type: T_SET_SHOW_LOADING, payload: false });
    } catch (e) {
        dispatch({ type: types.T_TWITTER_SIGNIN.FAILURE, payload: e });
    }
}

export const lineAuth = (code: string, success: (data: any) => void, failure: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    dispatch({ type: types.T_LINE_AUTH.PENDING });
    try {
        const _data = await callLineAuth({ code: code });
        dispatch({ type: types.T_LINE_AUTH.SUCCESS, payload: _data });
        success(_data);
    } catch (e) {
        dispatch({ type: types.T_LINE_AUTH.FAILURE, payload: e });
        failure();
    }
}

export const fakeLogin = (accessToken: string) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    dispatch({ type: types.T_GOOGLE_SIGNIN.PENDING });
    try {
        const _data = await callfakeSignin({ accessToken: accessToken });
        dispatch({ type: types.T_GOOGLE_SIGNIN.SUCCESS, payload: _data });
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_GOOGLE_SIGNIN.FAILURE, payload: e });
    }
}

export const verifyToken = (queryString: any, success: () => void, error: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    if (!queryString) return;

    dispatch({ type: types.T_VERIFY_TOKEN.PENDING });
    try {
        const _data = await callVerifyToken({ queryString: queryString });
        dispatch({ type: types.T_VERIFY_TOKEN.SUCCESS, payload: _data });
        success();
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_VERIFY_TOKEN.FAILURE, payload: e });
        error();
    }
}

export const anotherVerifyToken = (queryString: any, success: () => void, error: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    if (!queryString) return;

    dispatch({ type: types.T_ANOTHER_VERIFY_TOKEN.PENDING });
    try {
        const _data = await callAnotherVerifyToken({ queryString: queryString });
        dispatch({ type: types.T_ANOTHER_VERIFY_TOKEN.SUCCESS, payload: _data });
        success();
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_ANOTHER_VERIFY_TOKEN.FAILURE, payload: e });
        error();
    }
}

export const loadMe = () => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_LOAD_ME.PENDING });
    try {
        const _data = await callLoadMe();
        dispatch({ type: types.T_LOAD_ME.SUCCESS, payload: _data });
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_LOAD_ME.FAILURE, payload: e });
    }
}

export const logout = (cb: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (!token || !refreshToken) return;

    dispatch({ type: types.T_LOGOUT.PENDING });
    try {
        const _data = await callLogout({ refreshToken: refreshToken });
        dispatch({ type: types.T_LOGOUT.SUCCESS, payload: _data });
        cb();
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_LOGOUT.FAILURE, payload: e });
        cb();
    }
}

export const setUserInfo = (userInfo: any) =>
    ({ type: types.T_SET_USER_INFO, payload: userInfo });

export const setTwitterProcessing = (isProcessing: boolean) =>
    ({ type: types.T_SET_TWITTER_PROCESSING, payload: isProcessing });
