import React, { useEffect, useState } from 'react';
import ListCard from './ListCard';
import locale from './locale.json';
import MainCard from './MainCard';
import { TConcert } from 'modules/main';


interface ICardListProps {
  data: any;
}

const CardList = ({ data }: ICardListProps) => {
  const [language, setLanguage] = useState<any>({});
  window.scrollTo(0, 0);
  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])



  if (data.length === 1) {
    return (
      <MainCard concert={data[0]} />
    )
  }

  return (
    data.map((item: TConcert, key) => {
      return (
        <ListCard key={item.id} concert={item} />
      )
    })
  )
}

export default CardList;
