import React from 'react';
import * as styled from './Style';

interface ButtonProps {
  buttonText: string;
  handleClick: () => void;
  style?: any;
}

const Button = ({ buttonText, handleClick, style }: ButtonProps) => {
  return <styled.Button onClick={handleClick} style={style}>{buttonText}</styled.Button>
}

export default Button;