import * as types from './types';
import { ThunkDispatch } from 'redux-thunk';
import { callConnectMethod, callEnter, callLoadChannels, callLoadPrevMessage, callSendMessage, callLoadEmotion } from 'core/api/sendbird';

export const sendbirdConnect = (appId: any, userId: any) => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  const token = localStorage.getItem('token');
  if (!token) return;

  dispatch({ type: types.T_SENDBIRD_CONNECT.PENDING });
  try {
    const _data = await callConnectMethod({ appId: appId, userId: userId });
    dispatch({ type: types.T_SENDBIRD_CONNECT.SUCCESS, payload: _data });
  } catch (e) {
    dispatch({ type: types.T_SENDBIRD_CONNECT.FAILURE, payload: e });
  }
}

export const enterChannel = (
  channelUrl: string,
  success: () => void
) => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  const token = localStorage.getItem('token');
  if (!token) return;
  dispatch({ type: types.T_SENDBIRD_ENTER_CHANNEL.PENDING });
  try {
    const _data = await callEnter({ channelUrl: channelUrl });
    dispatch({ type: types.T_SENDBIRD_ENTER_CHANNEL.SUCCESS, payload: _data });
    success();
  } catch (e) {
    dispatch({ type: types.T_SENDBIRD_ENTER_CHANNEL.FAILURE, payload: e });
  }
}


export const sendbirdSetConnect = () => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  try {
    dispatch({ type: types.T_SENDBIRD_SET_CONNECT.SUCCESS, });
  } catch (e) {
    dispatch({ type: types.T_SENDBIRD_SET_CONNECT.FAILURE, });
  }
}

//loadChannels
export const loadChannels = () => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  dispatch({ type: types.T_SENDBIRD_LOAD_OPEN_CHANNEL.PENDING });
  try {
    const _data = await callLoadChannels();
    dispatch({ type: types.T_SENDBIRD_LOAD_OPEN_CHANNEL.SUCCESS, payload: _data });
  } catch (e) {
    dispatch({ type: types.T_SENDBIRD_LOAD_OPEN_CHANNEL.FAILURE, payload: e });
  }
}

export const loadPrevMessage = (
  channel: object,
  success: () => void
) => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  dispatch({ type: types.T_SENDBIRD_PREV_MESSAGE.PENDING });
  try {
    const _data = await callLoadPrevMessage({ channel: channel });
    if (_data) dispatch({ type: types.T_SENDBIRD_PREV_MESSAGE.SUCCESS, payload: _data });
  } catch (e) {
    dispatch({ type: types.T_SENDBIRD_PREV_MESSAGE.FAILURE, payload: e });
  }
}


export const sendMessage = (
  channel: object,
  message: string,
  emotionUrl: string,
  success: () => void
) => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  dispatch({ type: types.T_SENDBIRD_SEND_MESSAGE.PENDING });
  try {
    const _data = await callSendMessage({ channel: channel, message: message, emotionUrl: emotionUrl });

    dispatch({ type: types.T_SENDBIRD_SEND_MESSAGE.SUCCESS, payload: _data });
    success()
  } catch (e) {
    dispatch({ type: types.T_SENDBIRD_SEND_MESSAGE.FAILURE, payload: e });
  }
}

export const messageReceived = (
  channel: object,
  message: string
) => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  dispatch({ type: types.T_SENDBIRD_MESSAGE_RECEIVED.SUCCESS, channel: channel, message: message });
}

export const loadEmotion = (
  concertUuid: string
) => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  dispatch({ type: types.T_SENDBIRD_LOAD_EMOTION.PENDING });
  try {
    const _data = await callLoadEmotion({ concertUuid: concertUuid });
    if (_data) dispatch({ type: types.T_SENDBIRD_LOAD_EMOTION.SUCCESS, payload: _data });
  } catch (e) {
    dispatch({ type: types.T_SENDBIRD_LOAD_EMOTION.FAILURE, payload: e });
  }
}

export const messageReceivedOnPrompter = (
  channel: object,
  message: string
) => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  dispatch({ type: types.T_SENDBIRD_MESSAGE_RECEIVED_ON_PROMPTER, channel: channel, message: message });
}

export const setPrompterThreshold = (
  threshold: number
) => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  if (threshold < 1) return;
  dispatch({ type: types.T_SENDBIRD_SET_PROMPTER_THRESHOLD, payload: threshold });
}

export const setAdminMessage = (
  adminMessage: any
) => async (dispatch: ThunkDispatch<types.SendbirdState, void, types.SendbirdAction>) => {
  try {
    dispatch({ type: types.T_SENDBIRD_ADMIN_CHAT.SUCCESS, payload: adminMessage });
  } catch (e) {
      console.log("e:", e)
  }
}
