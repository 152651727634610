import { ActionType } from 'typesafe-actions';
import { createAsyncActionTypes } from '../../core/utils/reduxUtils';
const prefix: string = 'messages';

export const T_FETCH_MESSAGES = createAsyncActionTypes(`${prefix}/FETCH_MESSAGES`);

export type MessagesAction = ActionType<any>;

export type TNotice = {
  createdAt: string;
  description: string;
  id: number;
  status: string;
  title: string;
  updatedAt: string;
  uuid: string;
}

export type TPush = {
  createdAt: string;
  description: string;
  id: number;
  kind: string;
  status: string;
  title: string;
  updatedAt: string;
  uuid: string;
}

export type TMessagesData = {
  notices: TNotice[],
  pushs: TPush[]
}

export type MessagesState = {
  isFetching: boolean,
  data: TMessagesData
};
