import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from 'components/Common/Header';
import StripeCheckoutForm from 'components/Payment/StripeCheckoutForm';
import { STRIPE_CLIENT_KEY } from 'constants/apiInfo';
import backgrounOffUrl from 'images/ic-check-blank.png';
import backgrounOnUrl from 'images/ic-check.png';
import paypalImg from 'images/img-paypal.png';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadPaymentMethods, registerPaymentMethods, updatePaymentMethods } from '../../modules/payment';
import locale from './locale.json';
import { BtnDone, Checkbox, Container, PaymentCheck, PaymentDiv, Paypal, PaypalImg } from './Style';


const stripePromise = loadStripe(STRIPE_CLIENT_KEY);
const paymentMethodsSelector = ({ payment: { paymentMethods } }: any) => paymentMethods;

const PaymentInformation = () => {
  const [userAgent, setuserAgent] = useState("");
  const [language, setLanguage] = useState<any>({});
  const [kind, setKind] = useState<string>('');

  const dispatch = useDispatch();
  const history = useHistory();

  const paymentMethods = useSelector(paymentMethodsSelector);

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  useEffect(() => {
    dispatch(loadPaymentMethods());
  }, []);

  const _handlePaymentMethodSubmit = (token: any, holderName: string, holderBirthDay: string, savePaymentMethod: boolean) => {
    if (paymentMethods && paymentMethods[0]) {
      const _success = () => {
        history.goBack();
      }
      dispatch(updatePaymentMethods(token.id, paymentMethods[0].uuid, holderName, holderBirthDay, kind, _success));
    } else {
      const _success = () => {
        history.goBack();
      }
      dispatch(registerPaymentMethods(token.id, holderName, holderBirthDay, kind, _success));
    }
  }

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  const _renderPaymentMethods = () => {
    if (!paymentMethods) return null;

    return (
      <Elements stripe={stripePromise}>
        <StripeCheckoutForm isUpdate={true} tickets={[]} onSubmit={_handlePaymentMethodSubmit} />
      </Elements>
    )
  }

  const _renderPaypal = () => {
    return (
      <BtnDone onClick={() => _handlePaymentMethodSubmit('', '', '', false)}>
        {language.Save}
      </BtnDone>
    )
  }

  useEffect(() => {
    if (paymentMethods && paymentMethods[0]) setKind(paymentMethods[0].kind);
  }, [paymentMethods])

  return (
    <Container paddingTop={userAgent ? 0 : 60}>
      <Header iHeaderCenter={language.PaymentInformation} iBackLeftArrowIcon={true} iBackRightSettingIcon={false} iconClose={false} />
      <PaymentDiv>
        {localStorage.getItem('countryCode') !== 'KR' &&
          <PaymentCheck>
            <Checkbox backgrounUrl={kind === 'paypal' ? backgrounOnUrl : backgrounOffUrl} onClick={() => setKind('paypal')}></Checkbox>
            <Paypal>
              <PaypalImg src={paypalImg} />
            </Paypal>
          </PaymentCheck>
        }
        <PaymentCheck>
          <Checkbox backgrounUrl={kind === 'stripe' ? backgrounOnUrl : backgrounOffUrl} onClick={() => setKind('stripe')}></Checkbox>
          {language.CreditDebit}
        </PaymentCheck>
      </PaymentDiv>
      {kind === 'stripe' ? _renderPaymentMethods() : ""}
      {kind === 'paypal' ? _renderPaypal() : ""}
    </Container >
  )
}

export default PaymentInformation;
