import { useEffect, useState } from "react"

const detectIsMobile = {
    Android: function () {
        return window.navigator.userAgent.match(/Android/i) == null ? false : true;
    },
    BlackBerry: function () {
        return window.navigator.userAgent.match(/BlackBerry/i) == null ? false : true;
    },
    IOS: function () {
        return window.navigator.userAgent.match(/iPhone|iPad|iPod/i) == null ? false : true;
    },
    Opera: function () {
        return window.navigator.userAgent.match(/Opera Mini/i) == null ? false : true;
    },
    Windows: function () {
        return window.navigator.userAgent.match(/IEMobile/i) == null ? false : true;
    },
    any: function () {
        return (detectIsMobile.Android() || detectIsMobile.BlackBerry() || detectIsMobile.IOS() || detectIsMobile.Opera() || detectIsMobile.Windows());
    }
};

export const useDetectMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isApplied, setIsApplied] = useState(false);

    useEffect(() => {
        setIsApplied(true);
        setIsMobile(detectIsMobile.any());
    }, [])

    return [isMobile, isApplied];
}
