import React, { useEffect, useState } from 'react';
import * as styled from './Style';
import { useDispatch } from 'react-redux';
import LanguagePopup from '../../components/Signin/LanguagePopup';
import ReactGA from 'react-ga';
import { SNS_ANDROID_KEY } from 'constants/apiInfo';

import ticketOn from 'images/img-btn-bg.png';
import logo from 'images/img-header-logo.png';
import footerimg from 'images/img-footer-logo.png';
import iconOpenSelect from 'images/ic-open-select.png';
import abxis_detail from 'images/abxis_detail.jpg';
import abxis_detail_en_0827 from 'images/abxis_detail_en_0827.jpg';

import { setModal } from 'modules/common';


const Yes24Page = () => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(false);
  const [langugeCode, setLangugeCode] = useState<string>('');
  const [infoPopup, setInfoPopup] = useState<string>("");


  const localCode = localStorage.getItem('languageCode') || 'ko';
  useEffect(() => {
    ReactGA.event({ category: 'Promotion_Landing', action: 'Landing_Enter', label: 'Landing' });

    console.log("langugeCode",localCode)
  }, [])


  useEffect(() => {
    setLangugeCode(localCode)
  }, [localCode])

  const _changeLanguge = () => {

    setLanguage(true);
  }

  const _langugePopupDiv = () => {
    if (language) {
      return (
        <styled.Dim>
          <LanguagePopup title={infoPopup} onCancel={() => setLanguage(false)} />
        </styled.Dim>
      )
    } else {
      return (null)
    }
  }


  var rootEL =  document.getElementById('root');
  if(rootEL){
    rootEL.setAttribute("style", "background-color:#1a1f25;backdrop-filter: blur(30px);");
  }

  const _openLink = () => {
    window.location.href = 'https://' + `${SNS_ANDROID_KEY}` + '/sns';
  }

  return (
    <styled.Container>
    <styled.Header>
      <styled.HeaderLogo src={logo} />
      <styled.Language onClick={_changeLanguge}>
        <styled.LangugeBox>{langugeCode == 'ko' ? '한국어' : 'English'}</styled.LangugeBox>
      </styled.Language>
    </styled.Header>
    <styled.Content>
        <styled.FooterLogo src={footerimg} />
        <styled.Copyright>Copyright © FANBOX https://fanbox.live</styled.Copyright>
      </styled.Content>
      {_langugePopupDiv()}
    </styled.Container>
  );
}

export default Yes24Page;
