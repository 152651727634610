import { API_URL } from 'constants/apiInfo';
import { getApiAxiosWithoutToken } from 'core/utils/api';
import { useDetectMobile } from 'hooks/useDetectMobile';
import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import button360Json from '../../constants/lotties/button_360_white.json';
import * as styled from './Style';
import { useHistory } from 'react-router-dom';

const animationData = button360Json;

const Demo = () => {

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.width = '100%';
      root.style.height = '100vh';
    }
  }, []);
  const history = useHistory();

  return (
    <styled.Container id="StageContainer" style={{ color: 'white'}}>
      <div>AB6IX Rehearsal Links</div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
        <styled.Button style={{marginRight: '10px' }} onClick={() => history.push('/demo2d')}>2D(메인 송출) LINK</styled.Button>
        <styled.Button onClick={() => history.push('/demo360')}>360 LINK</styled.Button>
      </div>
    </styled.Container>
  );
}

export default Demo;
