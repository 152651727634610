import { API_URL } from '../../constants/apiInfo';
import { getApiAxiosWithToken } from '../utils/api';

interface ILoadTickets {
  concertUuid: string;
}

export async function callLoadTickets({ concertUuid }: ILoadTickets) {
  const _axios = getApiAxiosWithToken();
  const response = await _axios.get(`${API_URL}/api/v1/concerts/${concertUuid}/tickets`);

  return response.data;
}
