import React, { useState } from 'react';
import * as styled from './Style';
import IcOpenSmall from 'images/ic-open-small.png';
import IcCloseSmall from 'images/ic-close-small.png';

type TFAQ = {
    question: string;
    answer: string;
}

interface IFAQListItem {
    faq: TFAQ;
}

const FAQListItem = ({ faq }: IFAQListItem) => {
    const [open, setOpen] = useState(false);

    return (
        <styled.FAQListItemWrapper onClick={() => setOpen((v) => !v)} >
            <styled.FAQListItemQuestionWrapper>
                <styled.FAQListItemQuestion>
                    {faq.question}
                </styled.FAQListItemQuestion>
                <styled.FAQListItemQuestionArrow src={open ? IcCloseSmall : IcOpenSmall} />
            </styled.FAQListItemQuestionWrapper>
            {open &&
                <styled.FAQListItemAnswer>
                    {faq.answer}
                </styled.FAQListItemAnswer>
            }
        </styled.FAQListItemWrapper>
    )
}

export default FAQListItem;
