import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from 'components/Common/Header';
import StripeCheckoutForm from 'components/Payment/StripeCheckoutForm';
import PaypalCheckoutForm from 'components/Payment/PaypalCheckoutForm';
import { STRIPE_CLIENT_KEY } from 'constants/apiInfo';
import backgrounOffUrl from 'images/ic-check-blank.png';
import backgrounOnUrl from 'images/ic-check.png';
import paypalImg from 'images/img-paypal.png';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerPaymentMethods, setKind, setStage, setStripeCardToken } from '../../modules/payment';
import locale from './locale.json';
import * as styled from './Style';
import TicketBox from 'components/Payment/TicketBox';
import PaymentMethodAgreement from 'components/Payment/PaymentMethodAgreeMent';
import Button from 'components/Common/Button';
import { setModal } from 'modules/common';
import { stepOpenPage } from 'hooks/stepOpen';
import moment from 'moment';


const stripePromise = loadStripe(STRIPE_CLIENT_KEY);
const paymentKindSelector = ({ payment: { kind } }: any) => kind;
const ticketsSelector = ({ tickets: { list } }: any) => list;

const PaymentSelect = () => {
  const [userAgent, setuserAgent] = useState("");
  const [language, setLanguage] = useState<any>({});
  const [savePaymentMethod, setSavePaymentMethod] = useState(true);
  const [agreeAll, setAgreeAll] = useState(false);
  const saleStatus = stepOpenPage();

  const dispatch = useDispatch();

  const kind = useSelector(paymentKindSelector);
  const tickets = useSelector(ticketsSelector);

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  useEffect(() => {
    if (saleStatus === 'fanclub_close' && language.open) dispatch(setModal(true, `${language.open}`, ''));
  }, [saleStatus, language]);

  const _registerStripePaymentMethod = (token: any, holderName: string, holderBirthDay: string, savePaymentMethod: boolean) => {
    if (savePaymentMethod) {
      const _success = () => { dispatch(setStage(1)); }
      dispatch(registerPaymentMethods(token.id, holderName, holderBirthDay, 'stripe', _success))
    } else {
      dispatch(setStripeCardToken({ ...token, ...{ holderName: holderName, holderBirthDay: holderBirthDay } }));
      dispatch(setStage(1));
    }
  }

  const _registerPaypalPaymentMethod = (savePaymentMethod: boolean) => {
    if (savePaymentMethod) {
      const _success = () => dispatch(setStage(1));
      dispatch(registerPaymentMethods('', '', '', 'paypal', _success))
    } else {
      dispatch(setStage(1));
    }
  }

  const _registerTransferPaymentMethod = () => {
    dispatch(setStage(1));
  }

  const _setKind = (k: string) => dispatch(setKind(k));

  const _renderPaymentMethodSelector = () => {
    if (kind === 'stripe') {
      return (
        <Elements stripe={stripePromise}>
          <StripeCheckoutForm isUpdate={false} tickets={tickets} onSubmit={_registerStripePaymentMethod} />
        </Elements>
      )
    } else if (kind === 'paypal') {
      if (localStorage.getItem('countryCode') === 'KR') return null;
      return (
        <PaypalCheckoutForm isUpdate={false} tickets={tickets} savePaypalPaymentMethod={savePaymentMethod} onSubmit={_registerPaypalPaymentMethod} />
      )
    } else if (kind === 'transfer') {
      return (
        <styled.PaymentBottomWrapper>
          <TicketBox tickets={tickets} />
          <PaymentMethodAgreement kind={kind} onAgreeAll={() => setAgreeAll(true)} onDisagree={() => setAgreeAll(false)} />
          {agreeAll ? (
            <Button buttonText={language.Next} handleClick={_registerTransferPaymentMethod} />
          ) : (
              <Button style={{ background: '#3d434a', color: 'rgba(255,255,255,1)' }} buttonText={language.Next} handleClick={() => { }} />
            )}
        </styled.PaymentBottomWrapper>
      )
    }
  }

  return (
    <styled.Container paddingTop={userAgent ? 0 : 60}>
      <Header iHeaderCenter={"Payment"} iBackLeftArrowIcon={true} iBackRightSettingIcon={false} iconClose={false} />
      <styled.Title>
        <styled.Text>
          {language.Registerpaymentcard}
        </styled.Text>
        <styled.Step>1/2</styled.Step>
      </styled.Title>
      <styled.PaymentDiv>
        {localStorage.getItem('countryCode') !== 'KR' &&
          <styled.PaymentCheck>
            <styled.Checkbox backgrounUrl={kind === 'paypal' ? backgrounOnUrl : backgrounOffUrl} onClick={() => _setKind('paypal')}></styled.Checkbox>
            <styled.Paypal>
              <styled.PaypalImg src={paypalImg} />
            </styled.Paypal>
          </styled.PaymentCheck>
        }
        {(localStorage.getItem('countryCode') !== 'KR' && kind === 'paypal') &&
          <styled.IndetedCheckboxWrapper>
            <styled.Checkbox backgrounUrl={savePaymentMethod ? backgrounOnUrl : backgrounOffUrl} onClick={() => setSavePaymentMethod((v) => !v)}></styled.Checkbox>
            <styled.SavePaymentMethodText>
              {language.SavePaymentInformation}
            </styled.SavePaymentMethodText>
          </styled.IndetedCheckboxWrapper>
        }
        <styled.PaymentCheck style={{ marginTop: kind === 'paypal' ? '0px' : '0px' }}>
          <styled.Checkbox backgrounUrl={kind === 'stripe' ? backgrounOnUrl : backgrounOffUrl} onClick={() => _setKind('stripe')}></styled.Checkbox>
          {language.CreditDebit}
        </styled.PaymentCheck>
        {(localStorage.getItem('countryCode') === 'KR' && moment().utc() < moment(1599811200000).utc())
          &&
          <styled.PaymentCheck>
            <styled.Checkbox backgrounUrl={kind === 'transfer' ? backgrounOnUrl : backgrounOffUrl} onClick={() => { _setKind('transfer'); }}></styled.Checkbox>
            <styled.TransferHelpText onClick={() => { _setKind('transfer'); }}>{language.Transfer}</styled.TransferHelpText>
          </styled.PaymentCheck>
        }
      </styled.PaymentDiv>
      {_renderPaymentMethodSelector()}
      {kind === '' &&
        <styled.PaymentBottomWrapper>
          <TicketBox tickets={tickets} />
          <PaymentMethodAgreement kind={kind} />
          <styled.BtnDone disabled={true}>{language.Next}</styled.BtnDone>
        </styled.PaymentBottomWrapper>}

        <>
          <styled.PaymentInfoText>{language.PaymentInfoText}</styled.PaymentInfoText>
        </>
    </styled.Container>
  )
}

export default PaymentSelect;
