import { createReducer } from 'typesafe-actions';
import * as types from './types';

const initialState: types.TicketsState = {
  isLoadingTickets: false,
  list: null,
};

const ticketsReducer = createReducer(initialState)
    .handleAction(types.T_LOAD_TICKETS.PENDING, (state: types.TicketsState, action: types.TicketsAction) => ({
        ...state,
        isLoadingTickets: false,
    }))
    .handleAction(types.T_LOAD_TICKETS.SUCCESS, (state: types.TicketsState, action: types.TicketsAction) => ({
        ...state,
        isLoadingTickets: false,
        list: action.payload,
    }))
    .handleAction(types.T_LOAD_TICKETS.FAILURE, (state: types.TicketsState, action: types.TicketsAction) => ({
        ...state,
        isLoadingTickets: false,
        list: null 
    }))

export default ticketsReducer;
