import { APP_SCHEME } from 'constants/apiInfo';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as styled from './Style';

interface OfflineTicketPopupProps {
  show: boolean;
  offlineTicketInfo: any;
  onCancel: () => void;
}

const noticeInEn = `■ To check your confirmation and reservation number.\n1. Visit “fanbox.live” and access to your account settings.\n2. Click “Payment Details” and “Apply the Ticket”\n3. Check your confirmation numbers and get your AR Ticket at “ticket.interpark.com”\n`
const noticeInKo = `■ 티켓신청인증번호 및 콘서트 예매번호 확인 방법\n1. PC 또는 휴대폰 웹페이지 통해 fanbox.live 접속\n2. 좌측 상단 계정 이미지 클릭\n3. 우측 상단 톱니바퀴(설정) 클릭\n4. ‘결제 내역’ 클릭\n5. 결제 내역란 내 “티켓 신청하기” 클릭\n6. 티켓신청인증번호 및 콘서트 예매번호 확인\n7. 인터파크 티켓 (http://ticket.interpark.com/)으로 접속,\nAR 티켓 신청(‘예매하기’ 버튼)'`;

const OfflineTicketPopup = ({ show, offlineTicketInfo, onCancel }: OfflineTicketPopupProps) => {
  const [languageCode, setLanguageCode] = useState<string>('');

  useEffect(() => {
    const localCode = localStorage.getItem('languageCode') || '';
    setLanguageCode(localCode)
  }, []);

  const copyToClipboard = (code: string) => {
    var t = document.createElement("textarea");
    document.body.appendChild(t);
    t.value = code;
    t.select();
    document.execCommand('copy');
    document.body.removeChild(t);
    let copyText = 'Copied';
    if (languageCode === 'ko') copyText = '복사 되었습니다.';
    alert(copyText);
  }

  const _handleConfirm = () => {
    if (localStorage.getItem('userAgent') === 'fanbox') window.location.href = `${APP_SCHEME}://close_activity`;
    else onCancel();
  }

  if (!show || !offlineTicketInfo) return <div />

  return (
    <>
      <styled.Dimmed />
      <styled.ModalWrapper>
        <Modal.Dialog className="transfer-popup">
          <styled.ModalBody>
            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
              <styled.ModalTitle>
                {languageCode === 'ko' ? 'AR티켓 관련 절차' : 'AR TICKET Announcement'}
              </styled.ModalTitle>
              <styled.InfoWrapper>
                <styled.Description>
                  <pre style={{ whiteSpace: 'break-spaces' }}>
                    {languageCode === 'ko' ? noticeInKo : noticeInEn}
                  </pre>
                </styled.Description>
              </styled.InfoWrapper>
              <styled.InfoWrapper>
                <styled.Title>
                  {languageCode === 'ko' ? '티켓신청인증번호' : 'Confirmation number of the ticket application'}
                </styled.Title>
                <styled.CodeWrapper onClick={() => copyToClipboard(offlineTicketInfo.short_code)}>
                  <styled.CodeText>{offlineTicketInfo.short_code}</styled.CodeText>
                  <styled.BtnCopy>{languageCode === 'ko' ? '복사' : 'Copy'}</styled.BtnCopy>
                </styled.CodeWrapper>
              </styled.InfoWrapper>
              <styled.InfoWrapper>
                <styled.Title>
                  {languageCode === 'ko' ? '콘서트 예매번호' : 'Concert reservation number'}
                </styled.Title>
                <styled.CodeWrapper onClick={() => copyToClipboard(offlineTicketInfo.long_code)}>
                  <styled.CodeText>{offlineTicketInfo.long_code}</styled.CodeText>
                  <styled.BtnCopy>{languageCode === 'ko' ? '복사' : 'Copy'}</styled.BtnCopy>
                </styled.CodeWrapper>
              </styled.InfoWrapper>
            </div>
            <Button style={{ width: '100%' }} variant="secondary" onClick={_handleConfirm} className="popup-btn">Confirm</Button>
          </styled.ModalBody>
        </Modal.Dialog>
      </styled.ModalWrapper>
    </>
  )
}


export default OfflineTicketPopup;
