import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import videojs from 'video.js';
import iconPeople from '../../images/ic-people.png';
import * as styled from './Style';

const streamingSelector = ({ common: { streamingUrl } }: any) => streamingUrl;

interface IVideoPlayer {
  landscape_mode: boolean;
  currentMobile: boolean;
}

const VideoPlayer = ({ landscape_mode, currentMobile }: IVideoPlayer) => {
  const videoNode = useRef<null | HTMLVideoElement>(null);
  const [userAgent, setuserAgent] = useState("");

  const streamingUrl = useSelector(streamingSelector);

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  useEffect(() => {
    if (streamingUrl) {
      let _type = 'application/x-mpegURL'
      const videoType = streamingUrl.toString().split('.').pop();
      if (videoType === 'mp4') _type = 'video/mp4';

      const videoJsOptions = {
        autoplay: true,
        controls: true,
        loop:true,
        muted:true,
        playsinline:true,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        sources: [{
          src: streamingUrl,
          type: _type,
          label: 1080
        }],
      }

      const player = videojs(videoNode.current, videoJsOptions, () => {
        player.src(streamingUrl);
      });

      return () => {
        //player.dispose();
      };
    }
  }, [streamingUrl]);


  return (
    <styled.Video landscape={landscape_mode} isMobile={currentMobile}>
      <styled.CountBox landscape={landscape_mode}>
        <styled.CountImg src={iconPeople} />
        <styled.Count />
      </styled.CountBox>
      <video style={{ width: '100%', height: '100%' }} ref={videoNode} className="video-js vjs-default-skin" ></video>
    </styled.Video>
  )
}
export default VideoPlayer;
