import React from 'react';
import * as styled from './Style';
import iconLike from 'images/ic-like@3x.png';
import { abbreviate } from 'core/utils/numberHelper';

interface IControlOverlay {
    videoState: any;
    isLandscape: boolean;
    enterObj: any;
    concertUuid: string;
}
const ControlOverlay = ({ videoState, isLandscape, enterObj, concertUuid }: IControlOverlay) => {
    return (
        <styled.Container id="control-overlay">
            {videoState.isLive && <styled.Live isMobile={false}><styled.Dot />Live</styled.Live>}
            <styled.LikeWrapper>
                {videoState.watching && <styled.Like><styled.IconPeople src={iconLike} /> {abbreviate(videoState.heart || 0)} </styled.Like>}
            </styled.LikeWrapper>
        </styled.Container>
    );
}
export default ControlOverlay;
