import { PAYPAL_CLIENT_KEY } from "constants/apiInfo";
import { useScript } from "hooks/useScript";
import React, { useEffect } from "react";

declare global {
    interface Window { paypal: any; }
}

interface IPaypalButtonProps {
    createOrder: (data: any, actions: any) => Promise<string>;
    executeOrder: (data: any, actions: any) => Promise<void>;
    cancelOrder: (data: any, actions: any) => Promise<void>;
}

const PaypalButton = ({ createOrder, executeOrder, cancelOrder }: IPaypalButtonProps) => {
    const [loaded, error] = useScript(`https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_KEY}&disable-funding=card`);

    useEffect(() => {
        if (loaded) initPaypal(window.paypal);
    }, [loaded]);

    const initPaypal = (paypal) => {
        if (paypal) {
            paypal.Buttons({
                createOrder: createOrder,
                onApprove: executeOrder,
                onCancel: cancelOrder
            }).render('#paypal-button');
        }
    }

    if (!loaded || error) return null;

    return (
        <div id="paypal-button" />
    )
}

export default PaypalButton;