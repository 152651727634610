import styled from "styled-components";


interface IStageHeader{
    landscape: boolean;
}

export const Container = styled.div<IStageHeader>`
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow: hidden;
    position:fixed;
    @media only screen and (min-width: 850px) {
      position:relative;
      overflow-y: auto;
    }
`;

export const StageHeader = styled.div<IStageHeader>`
    width: 100%;
    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    height: 60px;
`;

export const StageHeaderCenterText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.2rem;
    font-weight:700;
`;

export const StageHeaderLeftButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const StageHeaderRightButton = styled.img`
    width: 50px;
    height: 50px;
`;
