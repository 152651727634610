import env from './env';

export const API_URL = env.API_URL;
export const STRIPE_CLIENT_KEY = env.STRIPE_CLIENT_KEY;
export const APPLE_SIGNIN_REDIRECT_URL = env.APPLE_SIGNIN_REDIRECT_URL;
export const APP_SCHEME = env.APP_SCHEME;
export const SENDBIRD_APP_ID = env.SENDBIRD_APP_ID;
export const LINE_SIGNIN_CHANNEL_ID = env.LINE_SIGNIN_CHANNEL_ID;
export const LINE_SIGNIN_REDIRECT_URL = env.LINE_SIGNIN_REDIRECT_URL;
export const PAYPAL_CLIENT_KEY = env.PAYPAL_CLIENT_KEY;
export const SNS_IOS_KEY = env.SNS_IOS_KEY;
export const SNS_ANDROID_KEY = env.SNS_ANDROID_KEY;
export const TWITTER_SIGNIN_URL = env.TWITTER_SIGNIN_URL;
export const TWITTER_REQUEST_TOKEN_URL = env.TWITTER_REQUEST_TOKEN_URL;
export const KAKAO_JS_CLIENT_ID = env.KAKAO_JS_CLIENT_ID;
export const KAKAO_REDIRECT_URI = env.KAKAO_REDIRECT_URI;
export const STEP_1_LANGING_OPEN = env.STEP_1_LANGING_OPEN;
export const STEP_2_FANCLUB_OPEN = env.STEP_2_FANCLUB_OPEN;
export const STEP_2_FANCLUB_CLOSE = env.STEP_2_FANCLUB_CLOSE;
export const STEP_3_ALL_OPEN = env.STEP_3_ALL_OPEN;
export const STEP_4_VOD_OPEN = env.STEP_4_VOD_OPEN;
export const OFFLINE_TICKET_CONCERT_UUID = env.OFFLINE_TICKET_CONCERT_UUID;
