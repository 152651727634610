import * as types from './types';
import { ThunkDispatch } from 'redux-thunk';
import * as firebase from 'firebase/app';



export const firebaseVideoState = (concertUuid: string) => async (dispatch: ThunkDispatch<types.FirebaseState, void, types.FirebaseAction>) => {
    dispatch({ type: types.T_FIREBASE_VIDEO_STATE.PENDING });
    try {
        const _data = await firebase.database().ref("concerts/"+concertUuid + "/" );
        _data.on('value', function(snapshot) {
          const _res =  snapshot.val()
            dispatch({ type: types.T_FIREBASE_VIDEO_STATE.SUCCESS, payload: _res });
        })
    } catch (e) {
        console.log("callFirebaseVideoState e", e)
        dispatch({ type: types.T_FIREBASE_VIDEO_STATE.FAILURE, payload: e });
    }
}
