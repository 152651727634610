import styled from 'styled-components';

export const SectionWrapper = styled.div`
    margin-bottom: 20px;
`;

export const SectionTitle = styled.div`
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px;
`;

export const SectionSubTitle = styled.div`
`;

export const SectionListDetail = styled.div`
    font-size: 12px;
`

export const SectionTable = styled.table`
`
export const SectionTableHeader = styled.th`
    font-size: 11px;
    font-weight: 700;
`
export const SectionTableData = styled.td`
    font-size: 11px;
`

export const SectionContentDetail = styled.div`
`;

export const SectionContentDetailTtile = styled.div`
`;

export const SectionContentDetailListItem = styled.div`
`;

export const SectionContentDetailRawItem = styled.div`
`;