import React from 'react';
import * as styled from './Style';

interface IReferralInputBoxProps {
    title: string;
    placeholder: string;
    value: string;
    checkStatus: string;
    handleValueChange: (value: string) => void;
    handleSubmit: () => void;
    handleCancel: () => void;
}

const CustomInputBoxWithBtn = ({ title, placeholder, value, checkStatus, handleValueChange, handleSubmit, handleCancel }: IReferralInputBoxProps) => {
    return (
        <styled.CustomInputWrapperWithLine>
            <styled.CustomInputLeftWrapper>
                <styled.CustomInputTitleWrapper>
                    <styled.CustomInputTitle>
                        {title}
                    </styled.CustomInputTitle>
                    {checkStatus === 'error' && <styled.CustomInputError>유효하지 않는 예매번호입니다.</styled.CustomInputError>}
                </styled.CustomInputTitleWrapper>
                <styled.InputCustom placeholder={placeholder} value={value} onChange={(e) => handleValueChange(e.target.value)} />
            </styled.CustomInputLeftWrapper>
            {checkStatus === 'checked' ? (
                <styled.CustomInputBtn onClick={handleCancel}>
                    취소
                </styled.CustomInputBtn>
            ) : (
                    <styled.CustomInputBtn onClick={handleSubmit}>
                        조회
                    </styled.CustomInputBtn>
                )}
        </styled.CustomInputWrapperWithLine>
    )
}

export default CustomInputBoxWithBtn;
