import { SENDBIRD_APP_ID } from 'constants/apiInfo';
import { useDetectMobile } from 'hooks/useDetectMobile';
import { loadMe } from 'modules/auth';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SendBird from "sendbird";
import { enterChannel, loadEmotion, sendMessage } from "../../modules/sendbird";
import MessageForm from "./MessageForm";
import stickers from './stickers.json';
import * as styled from './Style';
import { RootState } from 'modules/types';

interface ISendbirdProps {
  channelUrl: string;
  landscape_mode: boolean;
  currentMobile: boolean;
  concertUuid: string;
}

const enterSelector = ({ sendbird: { enterChannel } }: any) => enterChannel;

const ChatForm = ({ channelUrl, landscape_mode, currentMobile, concertUuid }: ISendbirdProps) => {
  const [showMode, setShowMode] = useState<string>("");
  const [inputFocus, setInputFocus] = useState(false);

  const dispatch = useDispatch()

  const userData = useSelector((state: RootState) => state.auth.userData);
  const enterChannels = useSelector(enterSelector);

  const [isMobile, isApplied] = useDetectMobile();

  useEffect(() => {
    dispatch(loadMe());
    dispatch(loadEmotion(concertUuid))
    return () => {
    }
  }, []);

  useEffect(() => {
    const appId = SENDBIRD_APP_ID
    const sb = SendBird.getInstance()
    if (appId && userData) {
      const _successEnter = () => { }
      const _success = (user, error) => {
        sb.updateCurrentUserInfo(userData.nickName, '', _successEnter)
        dispatch(enterChannel(channelUrl, _successEnter))
      }
      sb.connect(userData.uuid, _success);
    }
  }, [userData, dispatch])

  useEffect(() => {
    if (isApplied) {
      if (!isMobile) {
        //      let chatRoomStyle = (document.getElementById("ChatRoom") as HTMLElement)
        //      chatRoomStyle.setAttribute("style", "height:calc(100vh - 100px) !important;");

        //      let chatChildrenRoomStyle = (document.getElementById("ChatRoomChildren") as HTMLElement)
        //      chatChildrenRoomStyle.setAttribute("style", "height:calc(100vh - 100px) !important;");
      }
    }
  }, [isMobile, isApplied]);


  const _sendEmotion = (datas: object) => {
    const _success = () => { }
    if (datas) {
      dispatch(sendMessage(enterChannels, datas['keyword'], datas['url'], _success))
      setShowMode("")
      setInputFocus(false)
    }
  }

  const _renderEmotionView = useCallback(() => {
    if (showMode == "emotion") {
      return (
        <styled.EmotionContent isMobile={currentMobile}>
          {stickers.packData.map((t) => {
            return (
              <styled.Emotion key={t.keyword} onClick={() => _sendEmotion(t)}>
                <styled.EmotionImg src={t.url} />
              </styled.Emotion>
            )
          })}
          <styled.EmotionNull>
          </styled.EmotionNull>
        </styled.EmotionContent>
      )
    } else if (showMode == "keyboard") {
      return (<styled.KeyboardNull id="keyboardNull"> </styled.KeyboardNull>)
    }
  }, [showMode])

  if (!channelUrl || channelUrl === '' || landscape_mode) return null;

  return (
    <styled.ChatContainer fullmode={inputFocus} id="messageForm">
      <div className="chat-message-form-wrapper">
        <MessageForm channel={enterChannels} showMode={showMode} inputFocus={inputFocus} onShowMode={(e) => setShowMode(e)} onInputFocus={(e) => setInputFocus(e)} />
      </div>
      {_renderEmotionView()}
    </styled.ChatContainer>
  )
}

export default ChatForm
