import React, { useState, useEffect } from 'react';
import Popup from "reactjs-popup";

import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import backgrounOnUrl from '../../images/ic-check.png'
import backgrounOffUrl from '../../images/ic-check-blank.png'

import { ModalInfo, ModalTitle, ModalContent, ModalPrice, StyledDiv, LanguageCheck, Checkbox, CheckText, Line } from './Style';


interface IDateFormProps {
  title: string;
  onCancel: () => void;
}


const InfoPopup = ({ title, onCancel }: IDateFormProps) => {
  const [language, setLanguage] = useState<string>("");

  useEffect(() => {
    const languageCode = localStorage.getItem('languageCode') || 'ko';

    setLanguage(languageCode);
  }, []);

  useEffect(() => {
    localStorage.setItem('countryCode', 'US')
    localStorage.setItem('languageCode', 'en')
    if (language == 'ko') {
      localStorage.setItem('countryCode', 'KR')
      localStorage.setItem('languageCode', 'ko')
    }
  }, [language]);

  const _handleCancel = (e: any) => {
    onCancel();
  }

  return (
    <StyledDiv>

      <Modal.Dialog className="popup-dialog">
        <LanguageCheck>
          <CheckText>한국어</CheckText><Checkbox backgrounUrl={language === 'ko' ? backgrounOnUrl : backgrounOffUrl} onClick={() => setLanguage("ko")} ></Checkbox>
        </LanguageCheck>
        <Line></Line>
        <LanguageCheck>
          <CheckText>English</CheckText><Checkbox backgrounUrl={language === 'en' ? backgrounOnUrl : backgrounOffUrl} onClick={() => setLanguage("en")}></Checkbox>
        </LanguageCheck>

        <Modal.Footer>
          <Button variant="secondary" onClick={(e: any) => _handleCancel(e)} className="cancel-btn">{language === 'ko' ? "취소" : "Cancel"} </Button>

          <Button onClick={(e: any) => _handleCancel(e)} className="btn-ok">{language === 'ko' ? "확인" : "Confirm"}</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </StyledDiv>
  )

}


export default InfoPopup;
