import { CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import * as styled from './Style';
import { useSelector, useDispatch } from 'react-redux';
import { TTicket } from 'modules/tickets';
import locale from './locale.json';
import TicketBox from './TicketBox';
import PaymentMethodAgreement from './PaymentMethodAgreeMent';
import { setModal } from 'modules/common';


interface ICheckoutFrom {
  tickets: TTicket[];
  isUpdate: boolean;
  onSubmit: (token: any, holderName: string, holderBirthDay: string, saveMethod: boolean) => void;
}

const paymentMethodsSelector = ({ payment: { paymentMethods } }: any) => paymentMethods;

const CheckoutForm = ({ tickets, isUpdate, onSubmit }: ICheckoutFrom) => {
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [expirationDateValid, setExpirationDateValid] = useState(false);
  const [name, setName] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [savePaymentMethod, setSavePaymentMethod] = useState(true);
  const [agreeAll, setAgreeAll] = useState(false);
  const [language, setLanguage] = useState<any>({});

  const paymentMethods = useSelector(paymentMethodsSelector);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  useEffect(() => {
    if (paymentMethods[0]) {
      setName(paymentMethods[0].holderName);
      setBirthDay(paymentMethods[0].holderBirthDay);
    }
  }, [paymentMethods]);

  const _handleChange = (e: any) => {
    if (e['elementType'] === 'cardNumber') setCardNumberValid(e['complete'] && !e['empty'] && !e['error']);
    if (e['elementType'] === 'cardExpiry') setExpirationDateValid(e['complete'] && !e['empty'] && !e['error']);
  }

  const _handleSubmit = async (ev: React.SyntheticEvent) => {
    if (cardNumberValid && expirationDateValid) {
      ev.preventDefault();

      if (!isUpdate && !agreeAll) {
        ev.preventDefault();
        alert('check agreement');
        return
      }

      const _cardNumber = elements?.getElement(CardNumberElement);
      if (_cardNumber) {
        const _stripeRes = await stripe?.createToken(_cardNumber);
        if (!_stripeRes || _stripeRes.error) {
          dispatch(setModal(true, language.InvalidCard, ''));
        } else {
          const _token = _stripeRes?.token;
          if (_token && _token.card) {
            const card = _token.card;
            if (card.brand.toLocaleLowerCase() !== 'visa' && card.brand.toLocaleLowerCase() !== 'mastercard' && localStorage.getItem('countryCode') === 'KR') {
              dispatch(setModal(true, '지원되지 않는 카드 브랜드입니다. Visa 또는 Mastercard로 결제해주세요.', ''));
            } else {
              onSubmit(_token, name, birthDay, savePaymentMethod);
            }
          }
        }
      }
    } else {
      ev.preventDefault();
      alert('wrong info');
    }
  }

  const _ableToCheckout = () => {
    if (isUpdate) {
      if (
        !cardNumberValid ||
        !expirationDateValid ||
        (name === '') ||
        (birthDay === '')
      ) return false;
      return true;
    } else {
      if (
        !cardNumberValid ||
        !expirationDateValid ||
        !agreeAll ||
        (name === '') ||
        (birthDay === '')) {
        return false
      }
      return true;
    }
  }

  return (
    <styled.Form onSubmit={(ev: any) => _handleSubmit(ev)} paddingTop={isUpdate ? 20 : 0} >
      <styled.InputWrapper>
        <CardNumberElement
          options={{ style: { base: { color: '#fff', fontSize: '14px' }, invalid: { color: 'red' } } }}
          onChange={_handleChange}
        />
      </styled.InputWrapper>
      <styled.InputWrapper>
        <CardExpiryElement
          options={{ placeholder: `${language.Expiration}`, style: { base: { color: '#fff', fontSize: '14px' }, invalid: { color: 'red' } } }}
          onChange={_handleChange}
        />
      </styled.InputWrapper>
      <styled.InputWrapper>
        <styled.InputName placeholder={language.Name} value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />
      </styled.InputWrapper>
      <styled.InputWrapper>
        <styled.InputBirthDay placeholder={language.Birthday} value={birthDay} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBirthDay(e.target.value)} />
      </styled.InputWrapper>
      {(!isUpdate && tickets) &&
        <>
          <styled.CheckboxWrapper>
            <styled.CheckboxSavePaymentMethod checked={savePaymentMethod} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSavePaymentMethod(!savePaymentMethod)} id="save-payment-method"></styled.CheckboxSavePaymentMethod>
            <styled.LabelSavePaymentMethod htmlFor="save-payment-method">
              {language.SavePaymentInformation}
            </styled.LabelSavePaymentMethod>
          </styled.CheckboxWrapper>
          <styled.Splitter />
          <TicketBox tickets={tickets} />
          <PaymentMethodAgreement kind='stripe' onAgreeAll={() => setAgreeAll(true)} onDisagree={() => setAgreeAll(false)} />
        </>
      }
      <styled.BtnDone disabled={!_ableToCheckout()}>
        {isUpdate ? language.Save : language.Next}
      </styled.BtnDone>
    </styled.Form>
  )
}

export default CheckoutForm;
