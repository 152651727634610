import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Main, MainCard, MainCardImg, Content, ContentInfo, ContentDetail, DetailTitle, DetailText, DetailBox, DetailInfoRight, ShowInfo, ShowTH, ShowTD, DetailInfo,Scroll } from './Style';
import locale from './locale.json';
import { setModal } from 'modules/common';

interface IDetail {
  datas: any;
  landscape_mode: boolean;
}
const Detail = ({ datas, landscape_mode }: IDetail) => {
  const [language, setLanguage] = useState<any>({});
  const [imgUrl, setImgUrl] = useState<string>("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  useEffect(() => {
    if(imgUrl){
      dispatch(setModal(true,"",imgUrl))
      setImgUrl("")
    }
  }, [imgUrl]);

  if (datas) {
    return (
      <Container>
        <Scroll>
          <ContentDetail>
            <DetailTitle>{datas.title}</DetailTitle>
            <DetailText>{datas.description} </DetailText>
          </ContentDetail>

          <ContentInfo>

              {datas.thumbnails.map((t: string) => {
                return (
                  <MainCard key={t} >
                      <MainCardImg src={t} onClick={() => setImgUrl(t)} />
                  </MainCard>
                )
              })}

          </ContentInfo>
          <DetailBox>
            <DetailInfoRight>
              <ShowInfo>
                <ShowTH>{language.concert_running_time}</ShowTH>
                <ShowTD>{datas.metadata.runningTime}</ShowTD>
              </ShowInfo>
              <ShowInfo>
                <ShowTH>{language.concert_rated}</ShowTH>
                <ShowTD>{datas.metadata.rated}</ShowTD>
              </ShowInfo>
            </DetailInfoRight>
            <DetailInfo>
              <ShowInfo>
                <ShowTH>{language.concert_cast}</ShowTH>
                <ShowTD>{datas.metadata.cast}</ShowTD>
              </ShowInfo>
              <ShowInfo>
                <ShowTH>{language.concert_sponsor}</ShowTH>
                <ShowTD>{datas.metadata.sponsor}</ShowTD>
              </ShowInfo>
            </DetailInfo>
          </DetailBox>
        </Scroll>
      </Container>
    )
  } else {
    return (null)
  }

}
export default Detail;
