export const forceTwoDigits = (n: number) => {
    return n > 9 ? "" + n : "0" + n;
}

export const amountToPrice = (n: number, currency: string = 'USD') => {
    if (currency === 'USD') n = (n / 100);

    if (currency === 'USD') return `$${(n).toFixed(2)}`;
    else if (currency === 'KRW') return `KRW ${n.toLocaleString()}`;
    return `${n.toLocaleString()}`;
}

export const abbreviate = (n: number) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};