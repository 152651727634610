import { setTwitterProcessing, setUserInfo, twitterSignin } from 'modules/auth';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from '../Signin/Style';

const isTwitterProcessingSelector = ({ auth: { isTwitterProcessing } }: any) => isTwitterProcessing;

const TwitterSigninCallback = (location: any, match: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isTwitterProcessing = useSelector(isTwitterProcessingSelector);

    useEffect(() => {
        if (isTwitterProcessing) {
            dispatch(setTwitterProcessing(false));
        } else {
            const query = qs.parse(location.location.search);
            const success = (data: any) => {
                if (!localStorage.getItem('userAgent')) {
                    dispatch(setUserInfo(data));
                    history.push('/main');
                } else {
                    const _userAgent = localStorage.getItem('userAgent');
                    if (_userAgent === 'slsdk') window.location.href = `slsdk://login_token?refresh_token=${data.refreshToken}`;
                }
            }
            if (query.oauth_verifier && query.oauth_token) {
                dispatch(twitterSignin(query.oauth_verifier.toString(), query.oauth_token.toString(), success));
            }
        }
    }, []);

    return (
        <Container active={true} />
    )
}

export default TwitterSigninCallback;
