import styled from "styled-components";

interface IPaymentStatus {
    active: boolean;
}

export const ContentCard = styled.div`
    height: 100px;
    display:block;
    width:95%;
    margin: auto;
    border-radius: 6px;
    margin-top:10px;
    margin-bottom:10px;
    padding:10px;
    position: relative;
    color:#fff;
    border: 1px solid rgba(255, 255, 250, 0.2);
`;

export const PaymentData = styled.div`
    position: relative;
    font-size: 1.1rem;
    width:100%;
    font-weight:500;
    line-height: 2.2;
`;

export const PaymentConcert = styled.div`
    position: relative;
    font-size:0.8rem;
    line-height: 2;
    width:100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const PaymentCardInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PaymentCardInfo = styled.div`
    position: relative;
    font-size:0.8rem;
    line-height: 1.5;
    opacity:0.5;
    width:70%;
`;

export const PayStatus = styled.div<IPaymentStatus>`
    font-size:0.9rem;
    height: 29px;
    border-radius: 6px;
    line-height:2;
    color:#fff;
    background-color: ${(props) => props.active? `rgba(51, 58, 64, 1)` : ``};
    opacity: ${(props) => props.active? `` : `0.5`};
    text-align:center;
    padding : 1px 8px 0px 8px;
`;

export const BtnOffline = styled.div`
    font-size:0.9rem;
    height: 29px;
    border-radius: 6px;
    line-height:2;
    color:#fff;
    background-color: rgba(51, 58, 64, 1);
    text-align:center;
    padding : 1px 8px 0px 8px;
    margin-right: 10px;
`;
export const BtnsWrapper = styled.div`
    position: absolute;
    top: 15px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Pay = styled.div`
  font-size:1rem;
  border-radius: 6px;
  line-height:1.7;
  color:#62e979;
`;
