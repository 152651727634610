import * as types from './types';
import { ThunkDispatch } from 'redux-thunk';
import { callRequestSMSVerificationCode, callSubmitSMSVerificationCode, callRequestEmailVerificationCode, callSubmitEmailVerificationCode } from 'core/api/verifications';

export const requestSMSVerificationCode = (concertUuid: string, phoneCountryCode: string, phoneNumber: string, promotionCode: string, success: () => void) =>
    async (dispatch: ThunkDispatch<types.VerificationsState, void, types.VerificationsAction>) => {
        const token = localStorage.getItem('token');
        if (!token) return;

        dispatch({ type: types.T_REQUEST_CONCERT_SMS_VERIFICATION_CODE.PENDING });
        try {
            const _data = await callRequestSMSVerificationCode(concertUuid, phoneCountryCode, phoneNumber, promotionCode);
            dispatch({ type: types.T_REQUEST_CONCERT_SMS_VERIFICATION_CODE.SUCCESS, payload: _data });
            success();
        } catch (e) {
            console.log('e : ', e);
            dispatch({ type: types.T_REQUEST_CONCERT_SMS_VERIFICATION_CODE.FAILURE, payload: e });
            alert('phone number and promotion code not matched');
        }
    }

export const submitSMSVerificationCode = (concertUuid: string, verificationCode: string, success: () => void, fail: () => void) =>
    async (dispatch: ThunkDispatch<types.VerificationsState, void, types.VerificationsAction>) => {
        dispatch({ type: types.T_SUBMIT_CONCERT_SMS_VERIFICATION_CODE.PENDING });
        try {
            const _data = await callSubmitSMSVerificationCode(concertUuid, verificationCode);
            dispatch({ type: types.T_SUBMIT_CONCERT_SMS_VERIFICATION_CODE.SUCCESS, payload: _data });
            success();
        } catch (e) {
            console.log('e : ', e);
            dispatch({ type: types.T_SUBMIT_CONCERT_SMS_VERIFICATION_CODE.FAILURE, payload: e });
            fail();
        }
    }

export const requestEmailVerificationCode = (concertUuid: string, email: string, promotionCode: string, success: () => void) =>
    async (dispatch: ThunkDispatch<types.VerificationsState, void, types.VerificationsAction>) => {
        const token = localStorage.getItem('token');
        if (!token) return;

        dispatch({ type: types.T_REQUEST_CONCERT_SMS_VERIFICATION_CODE.PENDING });
        try {
            const _data = await callRequestEmailVerificationCode(concertUuid, email, promotionCode);
            dispatch({ type: types.T_REQUEST_CONCERT_SMS_VERIFICATION_CODE.SUCCESS, payload: _data });
            success();
        } catch (e) {
            console.log('e : ', e);
            dispatch({ type: types.T_REQUEST_CONCERT_SMS_VERIFICATION_CODE.FAILURE, payload: e });
            alert('email and promotion code not matched');
        }
    }

export const submitEmailVerificationCode = (concertUuid: string, verificationCode: string, success: () => void, fail: () => void) =>
    async (dispatch: ThunkDispatch<types.VerificationsState, void, types.VerificationsAction>) => {
        dispatch({ type: types.T_SUBMIT_CONCERT_SMS_VERIFICATION_CODE.PENDING });
        try {
            const _data = await callSubmitEmailVerificationCode(concertUuid, verificationCode);
            dispatch({ type: types.T_SUBMIT_CONCERT_SMS_VERIFICATION_CODE.SUCCESS, payload: _data });
            success()
        } catch (e) {
            console.log('e : ', e);
            dispatch({ type: types.T_SUBMIT_CONCERT_SMS_VERIFICATION_CODE.FAILURE, payload: e });
            fail();
        }
    }

export const setIsVerified = (isVerified: boolean) =>
    ({ type: types.T_SET_IS_VERIFIED, payload: isVerified })