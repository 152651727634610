import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MainCard, Card, CardLeft, CardThumbnail, CardRight, CardTitle, CardText, CardDate, InfoText } from './Style';
import locale from './locale.json';


import { mainList } from 'modules/main';
const lastIdSelector = ({ main: { lastId } }: any) => lastId;
const selectIdSelector = ({ main: { selectId } }: any) => selectId;

interface IDateFormProps {
  data: any;
}

type Concerts = {
  id: string;
  uuid: string;
  title: string;
  amount: number;
  maxAudience: number;
  description: string;
  thumbnails: any;
  onClick: any;
  onClickId: any;
  startTime: string;
  updatedAt: string;
  usersconcert: any;
  isEnded: boolean;
  status: any;
}


const MyPage = ({ data }: IDateFormProps) => {
  const lastId = useSelector(lastIdSelector);
  const selectId = useSelector(selectIdSelector);

  const [mainId, setMainId] = useState<string>("");
  const [paymentId, setPaymentId] = useState<string>("");
  const [language, setLanguage] = useState<any>({});

  const history = useHistory();

  useEffect(() => {
    if (mainId) {
      localStorage.setItem('cardUUID', mainId);
      history.push(`/maindetail/${mainId}`);
    }
  }, [mainId]);

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])


  useEffect(() => {
    if (paymentId) {
      history.push(`/payment/${paymentId}`);
    }
  }, [paymentId]);

  if (data.length > 0) {
    return (
      data.map((item: Concerts) => {
        if (item.thumbnails) {
          var thumbnails = item.thumbnails[0]
        }
        const startTime = moment(item.startTime).format('YYYY.MM.DD');
        return (
          <MainCard key={item.id} id={item.id}>
            <CardLeft>
              <CardThumbnail thumbnailsUrl={item.thumbnails[0]}></CardThumbnail>
              {item.isEnded ? (
                <Card active={false}> {language.concert_end}</Card>
              ) : ("")}

              {item.isEnded === false && item.usersconcert.status === "reserved" ? (
                <Card active={true} onClick={() => setPaymentId(item.uuid)} >{language.pay}</Card>
              ) : ("")}
              {item.isEnded === false && item.usersconcert.status === "purchased" ? (
                <Card active={true} onClick={() => setMainId(item.uuid)} > {language.entrance}</Card>
              ) : ("")}
              {item.isEnded === false && item.usersconcert.status === "normal" ? (
                <Card active={true}>{language.reserve}</Card>
              ) : ("")}
              {item.isEnded === false && item.usersconcert.status === "pending" ? (
                <Card active={true}>{language.pending}</Card>
              ) : ("")}
            </CardLeft>
            <CardRight>
              <CardTitle>{item.title}</CardTitle>
              <CardText>{item.description} </CardText>
              <CardDate>{startTime}</CardDate>
            </CardRight>
          </MainCard>
        )
      })
    )
  } else {
    return (
      <InfoText>{language.NoHistory}</InfoText>
    )
  }




}

export default MyPage;
