import styled from "styled-components";

export const Dimmed = styled.div`
  position: fixed;
  background: rgba(0,0,0,0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 200;
`;

export const ModalWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  z-index: 500;
  position: fixed;
  > div.modal-dialog {
    padding:7px;
    width: 100%;
    max-width: 460px;
    position: fixed;
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    background-color: rgba(33, 38, 44, 1);
  }
  > div.modal-content{
    height: 100%;
  }
`;

export const ModalBody = styled.div`
  text-align: center;
  height: auto;
  font-size: 12px;
`;

interface IVodInfoImg{
    thumbnailsUrl: string;
}

export const VodInfoImg1 = styled.div<IVodInfoImg>`
  width: 100%;
  height: 480px;
  background:  url(${(props) => props.thumbnailsUrl? props.thumbnailsUrl : ""}) no-repeat center/cover;
  background-image: url(${(props) => props.thumbnailsUrl? props.thumbnailsUrl : ""});

`;


export const VodInfoImg = styled.img`
    width: 100%; height: auto;
`;

export const FontBox = styled.div`
    font-size:1rem;
    color: #fff;
    word-break: keep-all;
    line-height: 1.5;
    padding-top: 40px;
    padding-bottom: 40px;
`;
