import styled from "styled-components";


interface IContentContainer {
    marginTop?: number;
}

interface IContainer {
    paddingTop?: number;
}

export const Container = styled.div<IContainer>`
    width: 100%;
    height: 100%;
    background-color: #2A3139;
    padding-top: ${(props) => props.paddingTop ? props.paddingTop : 0}px;

    @media only screen and (min-width: 850px) {
      height: 100vh;
    }
`;


export const Header = styled.div`
    width: 100%;
    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top:0;
    height: 60px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: #1a1f25;
    @media only screen and (min-width: 850px) {
      height: 80px;
    }
`;

export const HeaderLogo = styled.img`
    width: 78.7px;
    height: 16.6px;
    margin: auto;
    position:relative;
    display:flex;
    align-items: center;
`;

export const HeaderProfile = styled.img`
    width: 36px;
    height: 36px;
    margin: auto;
    left: 5%;
    position:absolute;
    display:block;
`;


export const ContentContainer = styled.div<IContentContainer>`
    margin-top: ${(props) => props.marginTop ? props.marginTop : 0}px;
    height: 100%;
    padding: 0 15px;
    background-color:#2A3139;
    opacity: 0.9;
    padding-bottom:25px;
    padding-top: 5px;

    @media only screen and (min-width: 850px) {
      overflow-x: scroll;
      margin-top:0px;
      padding-top:80px;
    }
`;
