import React, { useState } from 'react';
import * as styled from './Style';

interface IReferralInputBoxProps {
    title: string;
    placeholder: string;
    value: string;
    isError: boolean;
    handleValueChange: (value: string) => void;
}

const CustomInputBox = ({ title, placeholder, value, isError, handleValueChange }: IReferralInputBoxProps) => {
    return (
        <styled.CustomInputWrapperWithLine>
            <styled.CustomInputLeftWrapper>
                <styled.CustomInputTitle>{title}</styled.CustomInputTitle>
                <styled.InputCustom placeholder={placeholder} value={value} isError={isError} onChange={(e) => handleValueChange(e.target.value)} />
            </styled.CustomInputLeftWrapper>
        </styled.CustomInputWrapperWithLine>
    )
}

export default CustomInputBox;
