import styled from "styled-components";


interface IStageHeader{
    landscape: boolean;
}

export const Container = styled.div<IStageHeader>`
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow: hidden;
  position:fixed;
`;

export const StageHeader = styled.div<IStageHeader>`
  width: 100%;
  display: flex;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index:155;
  height: 60px;
  @media all and (orientation: landscape) {
    height: 60px;
  }
  @media only screen and (min-width: 850px) {
    width: 70%;
    padding: 20px;
  }
`;

export const StageHeaderCenterText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.2rem;
    font-weight:700;
`;

export const StageHeaderLeftButton = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 10px;
  @media only screen and (min-width: 850px) {
    margin-left: 0px;
  }
`;

export const PeopleIcon = styled.img`
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
`;

export const Watch = styled.div`
  color:#fff;
  font-size: 0.8rem;
  line-height: 0.2;
  float:left;
  display: block;
  text-align: right;
  bottom:0;
  margin-right: 10px;
  @media all and (orientation: landscape) {

  }
  @media only screen and (min-width: 850px) {
    position: absolute;
  }
`;

export const Like = styled.div`
  color:#fff;
  font-size: 0.8rem;
  line-height: 0.2;
  float:left;
  display: block;
  left: 25%;
  text-align: right;
  bottom:0;
  @media all and (orientation: landscape) {
    right: 0px;
  }
  @media only screen and (min-width: 850px) {
    text-align: right;
    padding-left: 30px;
    position: absolute;
    right: 10px;
  }
`;

export const Stage360Button = styled.div`
  width: 60px;
  height: 60px;
  @media only screen and (min-width: 850px) {
    width: 70px;
    height: 70px;
  }
`;

export const StageLivwButton = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

export const ChatComponent = styled.div`
  position:fixed;
  height:calc(100vh - 250px);
  width: 100%;
`;

interface ILive{
    currentMobile: boolean;
}

export const Live = styled.div<ILive>`
  color:#fff;
  font-size: 0.9rem;
  line-height: 0.2;
  display: flex;
  align-items: center;
  right: 0;
  height: 20px;
  line-height: 20px;
  top: ${(props) => props.currentMobile?  `155px` : `96%;`};

  @media all and (orientation: landscape) {
    top: 88%;
  }
  @media only screen and (min-width: 850px) {
    top: 92%;
  }
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #ff0000;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 7px;
`;

export const Channel = styled.div`
  height: calc(100vh - 96vh);
  display: flex;
  flex-direction: column;
  z-index:2;
  @media all and (orientation: landscape) {
    float: right;
    width: 10%;
    height: 100vh;
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  @media only screen and (min-width: 850px) {
    width: 15% !important;
    height: 95vh;
  }
`;

export const ChannelInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-basis: 35%;
  padding: 10px;
  @media all and (orientation: landscape){
    flex-basis: 15%;
  }
  @media only screen and (min-width: 850px) {
    flex-basis: 11%;
  }

`;

interface IMultiChannel{
    isOpen: boolean;
}

export const MultiChannel = styled.div<IMultiChannel>`
  position: relative;
  overflow: auto;
  white-space:nowrap;
  flex-wrap: wrap;
  align-content: flex-end;
  flex-basis: 65%;
  padding-left: 10px;
  z-index: 2;
  @media all and (orientation: landscape) {
    right: ${(props) => props.isOpen?  `-45%` : `-85%;`};
    overflow-y: scroll;
    flex-basis: 85%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding-top: 10px;
  }
`;

interface IMultiThumbnail{
    thumbnailsUrl: string
}

export const MultiThumbnail = styled.div<IMultiThumbnail>`
    width: 60px;
    height: 60px;
    background: url(${(props) => props.thumbnailsUrl? props.thumbnailsUrl :props.thumbnailsUrl}) no-repeat center/cover;
    background-image: url(${(props) => props.thumbnailsUrl? props.thumbnailsUrl : props.thumbnailsUrl});
    display: inline-block;
    margin-right: 3%;
    opacity: 0.6;
    @media all and (orientation: landscape) {
      display:block;
      margin-bottom: 10%;
    }
`;

export const ChannelCover = styled.div`

  @media only screen and (min-width: 850px) {
    height: 70vh;
    display: block;
    width: 50px;
    right: 0;
    position: absolute;
    z-index: 9;
    top: 5%;
  }
`;

export const CaptionWrapper = styled.div`
  display:flex;
  justify-content:center;
  position:relative;
  @media only screen and (min-width: 850px) {
    position: absolute;
    width: 70%;
    left: 0%;
    bottom: 10%;
  }
`;

export const Caption = styled.div`
  position: absolute;
  display:block;
  bottom: 30px;
  z-index: 100;
  max-width: 70%;
  @media all and (orientation: landscape) {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    top: 50px;
  }
  @media only screen and (min-width: 850px) {
    position: absolute;
    max-width: 100%;
    text-align: center;
    background-color: transparent;
  }

`;

export const Subtitles = styled.div`
  color:#fff;
  font-size: 0.75rem;
  line-height: 1.5;
  display: inline-block;
  text-align:center;
  padding:5px;
  margin:auto;
  background-color: rgba(0,0,0,0.5);
  @media all and (orientation: landscape) {

  }
  @media only screen and (min-width: 850px) {
    min-width:200px;
    max-width:300px;
    background-color: rgba(0,0,0,0.5);
  }
`;


export const ChatLoading = styled.div`
  position: fixed;
  bottom: 0;
  height: calc(100vh - 200px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgLoading = styled.img`
  width: 80px;
  height: 80px;
`;
