import App from 'App';
import Main from 'containers/Main';
import MainDetail from 'containers/MainDetail';
import MyPage from 'containers/MyPage';
import MyProfile from 'containers/MyProfile';
import Setting from 'containers/Setting';
import PaymentHistroy from 'containers/Setting/PaymentHistory';
import Signin from 'containers/Signin';
import Stage from 'containers/Stage';
import SsoSignin from 'containers/SsoSignin';
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import PaymentInformation from 'containers/PaymentInformation';
import TermsAndPrivacy from 'containers/TermsAndPrivacy';
import Privacy from 'containers/Privacy';
import OpenSource from 'containers/OpenSource';
import AppleSigninCallback from 'containers/AppleSigninCallback';
import AppleSignin from 'containers/AppleSignin';
import LineSigninCallback from 'containers/LineSigninCallback';
import Prompter from 'containers/Prompter';
import Alarm from 'containers/Alarm';
import FAQs from 'containers/FAQs';
import PaymentRoute from './PaymentRoute';
import Preview from 'containers/Preview';
import { useDetectMobile } from 'hooks/useDetectMobile';
import MobileOnly from 'containers/MobileOnly';
import Sns from 'containers/Sns';
import TwitterSigninCallback from 'containers/TwitterSigninCallback';
import TwitterSignin from 'containers/TwitterSignin';
import KakaoSigninCallback from 'containers/KakaoSigninCallback';
import Referral from 'containers/Referral';
import LandingPage from 'containers/LandingPage';
import Yes24Page from 'containers/Yes24';
import { stepOpenPage } from 'hooks/stepOpen';
import Demo360 from 'containers/Demo360';
import Demo2d from 'containers/Demo2d';
import Demo from 'containers/Demo';
import RedeemCodes from 'containers/RedeemCodes';
import AnotherSsoSignin from 'containers/AnotherSsoSignin';

const Root: React.FC = () => {
  const [isMobile, isApplied] = useDetectMobile();
  const token = localStorage.getItem('token');
  console.log("stepOpenPage()",stepOpenPage())

  return (
    <BrowserRouter>
      <App>
        <Switch>
          <Route path="/promotion/ab6ix/0912" component={LandingPage} />
          <Route path="/appleSignin" component={AppleSignin} />
          <Route path="/demo" component={Demo} />
          <Route path="/demo360" component={Demo360} />
          <Route path="/demo2d" component={Demo2d} />
          <Route path="/twitterSignin" component={TwitterSignin} />
          <Route path="/appleCallback" component={AppleSigninCallback} />
          <Route path="/kakaoCallback" component={KakaoSigninCallback} />
          <Route path="/twitterCallback" component={TwitterSigninCallback} />
          <Route path="/lineCallback" component={LineSigninCallback} />
          <Route path="/sso_login" component={SsoSignin} />
          <Route path="/terms" component={TermsAndPrivacy} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/opensource" component={OpenSource} />
          <Route path="/faqs" component={FAQs} />
          <Route path="/sns" component={Sns} />
          <Route path="/setting" component={Setting} />
          <Route path="/paymentInformation" component={PaymentInformation} />
          <Route path="/paymenthistory" component={PaymentHistroy} />
          <Route path="/signin" component={Signin} />
          <Route path="/main" component={Main} />
          <Route path="/maindetail/:uuid/stage" component={Stage} />
          <Route path="/maindetail/:uuid/preview" component={Preview} />
          <Route path="/maindetail/:uuid" component={MainDetail} />
          <Route path="/maindetail" component={MainDetail} />
          <Route path="/mypage" component={MyPage} />
          <Route path="/myprofile" component={MyProfile} />
          <Route path="/payment/:uuid" component={PaymentRoute} />
          <Route path="/prompter" component={Prompter} />
          <Route path="/alarm" component={Alarm} />
          <Route path="/redeem-codes" component={RedeemCodes} />
          <Route path="/referral" component={Referral} />
          <Route path="/yes24/jukjae/1123" component={Yes24Page} />
          <Route path="/yes24/ssoToken" component={AnotherSsoSignin} />

          {token ? (
            <Redirect path="*" to="/main" />
          ) : (
              <Redirect path="*" to="/signin" />
            )}
        </Switch>
      </App>
    </BrowserRouter>
  )
}

export default Root;
