import styled from 'styled-components';

interface IContainer {
  paddingTop:number;
}

export const Container = styled.div<IContainer>`
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #1a1f25;
    padding: 0 20px;
    flex-direction: column;
    font-size: 14px;
    line-height: 24px;
    color: white;
    overflow-y: scroll;
    padding-bottom: 30px;
    padding-top: ${(props) => props.paddingTop ? props.paddingTop : 0}px;
`

export const MenuListWrapper = styled.div`
    display: flex;
    width: calc(100% - 40px);
    position: fixed;
    padding-top: 20px;
    height: 70px;
    background-color: #1a1f25;
`;

export const MenuItemWrapper = styled.div`
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
`;

interface IMenuItem {
    active: boolean;
}
export const MenuItem = styled.div<IMenuItem>`
    border-bottom: ${props => props.active? '3px solid #62e979' : 'none'};
    padding: 10px 20px;
`;

export const Title = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
`;

export const SubTitle = styled.div`
    margin: 20px 0;
    line-height: 24px;
`;

export const ContentContainer = styled.div`
    margin-top: 80px;
`;

export const ContentTitle = styled.div`
`;

interface IContentDetailWrapper {
    display: string;
}
export const ContentDetailWrapper = styled.div<IContentDetailWrapper>`
    padding-left: 20px;
    display: ${props => props.display};
    margin-bottom: 10px;
`;
export const ContentDetailIndex = styled.div`
`;
export const ContentDetail = styled.div`
    flex: 1;
    padding-left: 20px;
`;

export const ContentDetailDetailWrapper = styled.div`
    padding-left: 20px;
    display: flex;
    margin-bottom: 10px;
`;
export const ContentDetailDetailIndex = styled.div`
`;
export const ContentDetailDetail = styled.div`
    flex: 1;
    padding-left: 20px;
`;
