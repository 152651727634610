import styled from "styled-components";

export const CustomInputWrapperWithLine = styled.div`
    height: 70px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 5px;
    padding: 15px;
`;

export const CustomInputWrapper = styled.div`
    position: relative;
`;

export const CustomInputLeftWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const CustomInputContentWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
`;

export const CustomInputTitle = styled.div`
    flex: 1;
    font-size: 15px;
    font-weight: 700;
    color: white;
    margin-bottom: 10px;
`;

export const CustomInputSmallTitle = styled.div`
    flex: 1;
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-bottom: 10px;
`;

interface IInputCustom {
    isError: boolean;
}

export const InputCustom = styled.input
    .attrs({ type: 'text' }) <IInputCustom>`
    flex: 1;
    background: transparent;
    color: ${(props) => props.isError ? '#ff7676' : '#62e979'};
    font-size: 12px;
`;
