import * as types from './types';

export const setShowLoading = (show: boolean) => ({ type: types.T_SET_SHOW_LOADING, payload: show });
export const setModal = (showModal: boolean, modalTitle: string, modalImg: string) =>
    ({ type: types.T_SET_MODAL, payload: { showModal: showModal, modalTitle: modalTitle, modalImg: modalImg } });

export const setStreamingUrl = (streamingUrl: string) => ({ type: types.T_SET_STREAMING_URL, payload: streamingUrl });
export const setTerm = (showTerm: boolean, checkTerm: boolean) =>
    ({ type: types.T_SET_TERM, payload: { showTerm: showTerm, checkTerm: checkTerm } });
export const setTermsAndPrivacy = (showTermsAndPrivacy: boolean) =>
    ({ type: types.T_SET_TERMS_PRIVACY, payload: { showTermsAndPrivacy: showTermsAndPrivacy} });
