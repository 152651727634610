import * as types from './types';
import { ThunkDispatch } from 'redux-thunk';
import { callReservesList, callUpdateNickName } from 'core/api/mypage';



export const reservesList = () => async (dispatch: ThunkDispatch<types.MyPageState, void, types.MyPageAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_MYPAGE_RESERVES.PENDING });
    try {
        const _data = await callReservesList();
        dispatch({ type: types.T_MYPAGE_RESERVES.SUCCESS, payload: _data });

    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_MYPAGE_RESERVES.FAILURE, payload: e });
    }
}


export const updateNickName = ( nickname:string , success: () => void) => async (dispatch: ThunkDispatch<types.MyPageState, void, types.MyPageAction>) => {

    dispatch({ type: types.T_PROFILE_UPDATE.PENDING });
    try {
        const _data = await callUpdateNickName({nickname: nickname});
        dispatch({ type: types.T_PROFILE_UPDATE.SUCCESS, payload: _data });
        success()
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_PROFILE_UPDATE.FAILURE, payload: e });
    }
}
