import { ActionType } from 'typesafe-actions';
const prefix: string = 'common';

export const T_SET_SHOW_LOADING = `${prefix}/SET_SHOW_LOADING`;
export const T_SET_MODAL = `${prefix}/SET_MODAL`;
export const T_SET_STREAMING_URL = `${prefix}/SET_STREAMING_URL`;
export const T_SET_TERM = `${prefix}/SET_TERM`;
export const T_SET_TERMS_PRIVACY = `${prefix}/SET_TERMS_PRIVACY`;

export type CommonAction = ActionType<any>;

export type CommonState = {
  showLoading: boolean;
  showModal: boolean;
  modalTitle: string;
  modalImg: string;
  streamingUrl: string;
  showTerm: boolean;
  checkTerm: boolean;
  showTermsAndPrivacy:boolean;
};
