import styled from "styled-components";
import MainCard1 from '../../images/big-69.png'
import TicketOn from '../../images/img-small-ticket.png'

interface IButton {
    active: boolean;
}

interface IProfile {
    marginTop?: number;
}

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2A3139;
    @media only screen and (min-width: 850px) {
      height: 100vh;
    }
`;


export const ContentContainer = styled.div`
    height: 80px;
    background-color:#2A3139;
    opacity: 0.9;
    display:block;
    position:relative;
`;


export const BtnDone = styled.button<IButton>`
    width: 90%;
    height: 50px;
    color: #000000;
    background-color: #62e979;
    line-height: 50px;
    border-radius: 7px;
    text-align: center;
    margin-top: 50px;
    border: none;
    font-size:1rem;
    margin:auto;
    display:block;
    background-color: ${(props) => props.active? '#62e979' : '#333a40'};
`;


export const Profile = styled.div<IProfile>`
    width: 100%;
    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: relative;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: #2A3139;
    margin-top: ${(props) => props.marginTop ? props.marginTop : 0}px;
    @media only screen and (min-width: 850px) {
      margin-top: 58px;
    }
`;

export const ProfileImg = styled.img`
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    margin:auto;
    border-radius: 24px;
    
`;

export const ProfileEditImg = styled.img`
    width: 24px;
    height: 24px;
    margin-left:56%;
    margin-top:16%;
    z-index:1;

    display: none;
`;


export const NickName = styled.div`
    display: inline-block;
    position: absolute;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 0.8rem;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width:90%;
    transform: translate(-50%, -50%);
    left: 50%;
    margin-top:30px;
`;

export const NickNameLabel = styled.label`
    opacity: 0.5;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    display:block;
    padding-top: 10px;
    padding-left: 15px;
`;

export const NickNameText = styled.input.attrs({ type: 'text' })`
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    font-size: 0.8rem;
    letter-spacing: normal;
    text-align: left;
    color: white;
    padding-top:2px;
    padding-left: 15px;
    display:block;
    background: transparent;
`;

export const Dim = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid black;
  z-index:100
`;
