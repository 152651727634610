import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Privacy from '../../components/TermsAndPrivacy/Privacy';
import { Container, MenuItem, MenuItemWrapper, MenuListWrapper, ContentContainer } from './Style';
import Terms from '../../components/TermsAndPrivacy/Terms';
import Header from '../../components/Common/Header';
import { setTermsAndPrivacy } from 'modules/common';

const TermsAndPrivacy = () => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(0);
  const [userAgent, setuserAgent] = useState("");
  const [langugeCode, setLangugeCode] = useState<string>('');
  const localCode = localStorage.getItem('languageCode') || '';


  useEffect(() => {
    setLangugeCode(localCode)
  }, [localCode])

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
    dispatch(setTermsAndPrivacy(true));
  }, []);

  return (
    <Container paddingTop={userAgent ? 0 : 30}>
      <Header iHeaderCenter={langugeCode == 'ko' ? '이용약관 및 개인정보' : 'Terms & Privacy'} iBackLeftArrowIcon={false} iBackRightSettingIcon={false} iconClose={true} />

      <MenuListWrapper>
        <MenuItemWrapper>
          <MenuItem active={menu === 0} onClick={() => setMenu(0)}>Terms</MenuItem>
        </MenuItemWrapper>
        <MenuItemWrapper>
          <MenuItem active={menu === 1} onClick={() => setMenu(1)}>Privacy</MenuItem>
        </MenuItemWrapper>
      </MenuListWrapper>
      <ContentContainer>
        {menu === 0 ?
          <Terms /> : <Privacy />}
      </ContentContainer>
    </Container>
  )
}

export default TermsAndPrivacy;
