import { TTicket } from 'modules/tickets';
import React from 'react';
import * as styled from './Style';
import { amountToPrice } from 'core/utils/numberHelper';

interface ITicketBoxProps {
    tickets: TTicket[];
}

const TicketBox = ({ tickets }: ITicketBoxProps) => {
    const normalTicket = tickets.filter((t) => t.kind === 'NORMAL')[0];

    if (!normalTicket) return null;

    return (
        <styled.ConcertWrapper>
            <styled.ConcertTitle>{normalTicket.title}</styled.ConcertTitle>
            <styled.ConcertAmount>{amountToPrice(normalTicket.amount, normalTicket.currency)}</styled.ConcertAmount>
            <styled.ConcertIndicator></styled.ConcertIndicator>
        </styled.ConcertWrapper>
    )
}

export default TicketBox;
