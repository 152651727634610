import { TPaymentMethod } from 'modules/payment';
import React, { useEffect, useState } from 'react';
import locale from './locale.json';
import * as styled from './Style';

interface IStripeCardBoxProps {
    paymentMethod: TPaymentMethod;
    stripeCardToken: any;
    oneTimePurchase: boolean;
    handleMethodChange: () => void;
}

const StripeCardBox = ({ oneTimePurchase, stripeCardToken, paymentMethod, handleMethodChange }: IStripeCardBoxProps) => {
    const [language, setLanguage] = useState<any>({});

    useEffect(() => {
        if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
        else setLanguage(locale.en)
    }, [language])

    const _cardNumberGenerator = (cardInfo: any) => {
        return `${cardInfo.brand} **** **** **** ${cardInfo.last4}`;
    }

    return (
        <styled.PaymentMethodWrapper onClick={handleMethodChange}>
            {oneTimePurchase ? (
                <styled.PaymentMethodCardInfo>
                    <styled.PaymentMethodNumber>{stripeCardToken && _cardNumberGenerator(stripeCardToken.card)}</styled.PaymentMethodNumber>
                    <styled.PaymentMethodHolderName>{stripeCardToken && stripeCardToken.holderName}</styled.PaymentMethodHolderName>
                </styled.PaymentMethodCardInfo>
            ) : (
                    <styled.PaymentMethodCardInfo>
                        <styled.PaymentMethodNumber>{paymentMethod && _cardNumberGenerator(paymentMethod.cardInfo)}</styled.PaymentMethodNumber>
                        <styled.PaymentMethodHolderName>{paymentMethod && paymentMethod.holderName}</styled.PaymentMethodHolderName>
                    </styled.PaymentMethodCardInfo>
                )}
            <styled.BtnChangePaymentMethod>
                {language.Change}
            </styled.BtnChangePaymentMethod>
        </styled.PaymentMethodWrapper>
    )
}

export default StripeCardBox;
