import styled from 'styled-components';
import Background from '../../images/login-bg.png'

interface IContainer {
    active: boolean;
}


export const Container = styled.div<IContainer>`
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: ${(props) => props.active? '#1a1f25' : '#1a1f25'};
`
export const Content = styled.div`
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background: url(${Background}) no-repeat center/cover;
    background-image: url(${Background});
`;

export const BtnGroups = styled.div`
    bottom: 10%;
    display: block;
    position: absolute;
    left:92px;
    height: 80px;
    width:200px;
    transform: translate(-50%, 0);
    left: 50%;
`

export const Btns = styled.button`
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 50%;
    background-color: rgba( 255, 255, 255, 0.6 );

`

export const LoginButton = styled.img`
    width: 30px;
    height: 30px;
    margin: auto;
`;

export const LogoImg = styled.img`
    width: 146px;
    height: 31px;
    margin: auto;
    left: 31%;
    top: 45%;
    position:absolute;
    display:block;
    transform: translate(-50%, 0);
    left: 50%;
`;

export const LogoBox = styled.div`
    width:33%;
    float:left;
    text-align: center;
`;

export const LoginLine = styled.div`
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: #fff;
    line-height: 1.13;
    font-size: 1.1rem;
    margin: 9px 0px;
    &:before {
      content: "";
      flex-grow: 1;
      background-color: #ffffff;
      height: 1px;
      font-size: 0px;
      line-height: 0px;
      margin: 0px 11px;
    }
    &:after {
      content: "";
      flex-grow: 1;
      background-color: #ffffff;
      height: 1px;
      font-size: 0px;
      line-height: 0px;
      margin: 0px 11px;
    }

`;
