import { callPing, callLogout } from "core/api/auth";

const intervalPing = () => {
    const intervalId = localStorage.getItem('pingId');
    if (intervalId) {
        clearInterval(Number(intervalId));
    }

    const tick = async () => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');

        if (!token && !refreshToken) {
            localStorage.removeItem('pingId');
            return clearInterval(Number(intervalId));
        }

        try {
            await callPing();
        } catch (e) {
            let message = 'Singed on other device';
            if (localStorage.getItem('languageCode') === 'ko') message = '다른 곳에서 로그인되어서 로그아웃 됩니다.';
            alert(message);

            const currentIntervalId = localStorage.getItem('pingId');
            if (refreshToken) {
                await callLogout({ refreshToken: refreshToken });
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('token');
            }
            clearInterval(Number(currentIntervalId));
            clearInterval(Number(intervalId));
            localStorage.removeItem('pingId');
            window.location.href = '/signin';
        }
    }

    let id = setInterval(tick, 10000);
    localStorage.setItem('pingId', id.toString());
}

export default intervalPing;