import styled from "styled-components";

export const HeaderLogo = styled.img`
    width: 78.7px;
    height: 16.6px;
    margin: auto;
    position: relative;
    align-items: center;
    display: flex;
`;

export const HeaderAlarm = styled.img`
    width: 50px;
    height: 50px;
    margin: auto;
    right: 0;
    position:absolute;
    display:block;

`;

export const HeaderProfile = styled.img`
    width: 36px;
    height: 36px;
    margin: auto;
    left: 5%;
    position:absolute;
    display:block;
    border-radius: 18px;
`;


export const HelpText = styled.div`
    max-width: 240px;
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
`;

export const HelpSubText = styled.div`
    max-width: 240px;
    margin: 0 auto;
    margin-top: 15px;
    color: #999;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
`;

export const Form = styled.div`
    margin-top: 25px;
    width: 100%;
`;

export const BtnDone = styled.button`
    width: 100%;
    height: 40px;
    color: white;
    background-color: #fe6b6b;
    line-height: 40px;
    border-radius: 7px;
    text-align: center;
    margin-top: 50px;
    border: none;
`;

export const Point = styled.div`
  width:5px;
  height:5px;
  background-color: #60db74;
  position: absolute;
  display: block;
  right: 10px;
  top: 15px;
  border-radius: 10px;
`;

export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0.5;
  font-size: 0.5rem;
  letter-spacing: 0.24px;
  color:#fff;
  text-align:center;
  line-height: 1.88;
  padding-bottom:20px;
  padding-top: 20px;
`;

export const DimLogo = styled.img`
    width: 116px;
    height: 20px;
    margin: auto;
    position: relative;
    align-items: center;
    display: flex;
    margin-top:100px;
`;

export const Company = styled.div`


`;
