import { useScript } from 'hooks/useScript';
import React, { useEffect, useState } from 'react';
import * as styled from './Style';
import iconPeople from 'images/ic-people.png';
import DragIndicator from './DragIndicator';

declare global {
  interface Window { vr: any; player: any; videojs: any; RadiantMP: any; rmp_360: any; }
}

interface IRM360Player {
  landscape_mode: boolean;
  currentMobile: boolean;
  streamingUrl_2d: string;
  streamingUrl_360: string;
}

const RM360DemoPlayer = ({ landscape_mode, currentMobile, streamingUrl_2d, streamingUrl_360 }: IRM360Player) => {
  // streamingUrl_360 = 'https://bitmovin-a.akamaihd.net/content/playhouse-vr/m3u8s/105560.m3u8';
  const [userAgent, setuserAgent] = useState("");

  const [rmpLoaded, error] = useScript('https://cdn.radiantmediatechs.com/rmp/5.9.2/js/rmp.min.js');

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  useEffect(() => {
    window.addEventListener('resize', function (event) {
      if (window.orientation === 90 || window.orientation === -90) {
        document.body.style.backgroundColor = 'black';
        window.rmp_360.setFullscreen(true);
      } else {
        document.body.style.background = '#2A3139';
        window.rmp_360.setFullscreen(false);
      }
    });
  }, []);

  // const changeBitrage = (src: string) => {
  //   const originSrc = src.split('.m3u8')[0];
  //   if (currentMobile) {
  //     return originSrc + '_720.m3u8';
  //   }
  //   return originSrc + '_1080.m3u8';
  // }

  useEffect(() => {
    if (rmpLoaded && window.RadiantMP) {
      const updatedUrl = streamingUrl_360;

      var src = {
        hls: updatedUrl
      };
      var settings = {
        licenseKey: 'Kl8ldT1rK2U1MmNzOXY/cm9tNWRhc2lzMzBkYjBBJV8q',
        src: src,
        autoHeightMode: false,
        automaticFullscreenOnLandscape: true,
        video360MinFocalLength: 70,
        video360FocalLength: 115,
        muted: true,
        video360: true,
        autoplay: true,
        loop: true,
        hideSeekBar: true,
        hideCentralPlayButton: true,
        forceHlsJSOnMacOSIpadOSSafari: true,
        video360FallbackSrc: {
          hls: updatedUrl
        },
        skin: 's3',
      };
      var elementID = 'rmp360DemoPlayer';
      var rmp = window.rmp_360 = new window.RadiantMP(elementID);
      var rmpContainer = document.getElementById(elementID);
      rmp.setModuleOverlayVisible('quality', true);
      rmp.init(settings);
      rmpContainer!.addEventListener('playing', function () {
        console.log('player has received playing event');
        rmp.setBitrate(0);
      });
    }
  }, [rmpLoaded, streamingUrl_360, streamingUrl_2d]);

  if (!rmpLoaded) return <styled.Video landscape={landscape_mode} isMobile={currentMobile} />;

  return (
    <styled.Video landscape={landscape_mode} isMobile={currentMobile}>
      <DragIndicator />
      <styled.CountBox landscape={landscape_mode}>
        <styled.CountImg src={iconPeople} />
        <styled.Count />
      </styled.CountBox>
      <div style={{ width: '100%', height: '100%' }} id="rmp360DemoPlayer"></div>
    </styled.Video>
  )
}
export default RM360DemoPlayer;
