import ChatForm from 'components/Sendbird/ChatForm';
import Chat from 'components/Sendbird/Sendbird';
import Detail from 'components/Stage/Detail';
import { APP_SCHEME, SENDBIRD_APP_ID } from 'constants/apiInfo';
import { useDetectMobile } from 'hooks/useDetectMobile';
import iconLike from 'images/ic-like@3x.png';
import iconClose from 'images/ic-close@3x.png';
import iconLive from 'images/ic-live@3x.png';
import { setStreamingUrl } from 'modules/common';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SendBird from "sendbird";
import button360Json from '../../constants/lotties/button_360_white.json';
import * as styled from './Style';
// import * as compStyled from '../../components/Stage/Style';
import ControlOverlay from 'components/Stage/ControlOverlay';
import RM360Player from 'components/Stage/RM360Player';
import RM2dPlayer from 'components/Stage/RM2dPlayer';
import VrVideoPlayer from 'components/Stage/VrVideoPlayer';
import VideoPlayer from 'components/Stage/VideoPlayer';
import MobileControlOverlay from 'components/Stage/MobileControlOverlay';


const animationData = button360Json;

const listDetailSelector = ({ main: { listDetail } }: any) => listDetail;
const isEnteringSelector = ({ main: { isEntering } }: any) => isEntering;
const enterObjSelector = ({ main: { enterObj } }: any) => enterObj;
const videoStateSelector = ({ firebase: { videoState } }: any) => videoState;
const streamingSelector = ({ common: { streamingUrl } }: any) => streamingUrl;

new SendBird({ appId: SENDBIRD_APP_ID });

interface RouteParams {
  uuid: string;
}

const CloudFrontKeyPairIdKey = 'CloudFront-Key-Pair-Id';
const CloudFrontPolicy = 'CloudFront-Policy';
const CloudFrontSignature = 'CloudFront-Signature';

const Stage = () => {
  const { uuid } = useParams<RouteParams>();
  const [cookies, setCookie] = useCookies([CloudFrontKeyPairIdKey, CloudFrontPolicy, CloudFrontSignature]);
  const [landscape, setLandscape] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPick, setIsPick] = useState("");
  const [isCaption, setIsCaption] = useState<boolean>(false);
  const [show360, setShow360] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const [isMobile, isApplied] = useDetectMobile();
  const [showLoading, setShowLoading] = useState(false);

  const concertDetail = useSelector(listDetailSelector);
  const isEntering = useSelector(isEnteringSelector);
  const enterObj = useSelector(enterObjSelector);
  const videoState = useSelector(videoStateSelector);
  const streamingUrl = useSelector(streamingSelector);

  useEffect(() => {
    if (!isEntering && !enterObj) return history.push('/maindetail/' + uuid);
    setCookie(CloudFrontKeyPairIdKey, enterObj.cookies_2d[CloudFrontKeyPairIdKey], { domain: '.fanbox.live', path: '/' });
    setCookie(CloudFrontPolicy, enterObj.cookies_2d[CloudFrontPolicy], { domain: '.fanbox.live', path: '/' });
    setCookie(CloudFrontSignature, enterObj.cookies_2d[CloudFrontSignature], { domain: '.fanbox.live', path: '/' });
  }, [isEntering]);

  useEffect(() => {
    if (!concertDetail) return history.push('/maindetail/' + uuid);

    if (concertDetail.channels && concertDetail.channels[0]) {
      dispatch(setStreamingUrl(concertDetail.channels[0].streamingUrl));
    } else {
      dispatch(setStreamingUrl(concertDetail.streamingUrl_2d));
    }
  }, [concertDetail]);

  //isMobile
  useEffect(() => {
    if (!isMobile) {
      let rootStyle = (document.getElementById("root") as HTMLElement)
      rootStyle.setAttribute("style", "transform:none !important; left:0 !important ");
    }
  }, []);

  const _nextAppView = () => {
    window.location.href = `${APP_SCHEME}://open?name=concert&uuid=${concertDetail.uuid}`;
  }

  useEffect(() => {
    if (window.orientation === 90 || window.orientation === -90) handleLandscape();
    else handlePortrait();

    window.addEventListener('orientationchange', function () {
      if (window.orientation === 90 || window.orientation === -90) {
        handleLandscape();
        // setTimeout(handleLandscape, 400);
      }
      else {
        setShowLoading(true);
        setTimeout(handlePortrait, 500);
        // setTimeout(handlePortrait, 400);
      }
    }, false);

  //   window.addEventListener('resize', function (event) {
  //     if (window.orientation === 90 || window.orientation === -90) {
  //       console.log('from resize: landscape!');
  //       handleLandscape();
  //       setTimeout(handleLandscape, 1000);
  //     } else {
  //       console.log('from resize: portrait!');
  //       handlePortrait();
  //       setTimeout(handlePortrait, 1000);
  //     }
  //   });
  }, []);

  const handleLandscape = () => {
    setLandscape(true)
    const caption = document.getElementById('caption');
    if (caption) caption.style.top = (window.innerHeight - 50) + 'px';
  }
  const handlePortrait = () => {
    setLandscape(false)
    const caption = document.getElementById('caption');
    if (caption) {
      caption.style.bottom = '30px';
      caption.style.top = 'unset';
    }
    setShowLoading(false);
  }


  const _handleChannelOpen = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const _handleChannelPick = (url) => {
    dispatch(setStreamingUrl(url))
    setIsPick(url)
    setIsOpen(false)
  }

  const style = {
    opacity: 1,
    border: '1.5px solid #62e979',
    borderRadius: '5px',
  };

  const _renderMulti = useCallback(() => {
    if (concertDetail) {
      return (
        <styled.MultiChannel isOpen={isOpen}  >
          {concertDetail.channels.map((t, i) => {
            return (
              <>
                {(isPick == t.streamingUrl) ?
                  <styled.MultiThumbnail style={style} key={i} thumbnailsUrl={t.thumbnailUrl} onClick={() => _handleChannelPick(t.streamingUrl)} /> :
                  <styled.MultiThumbnail key={i} thumbnailsUrl={t.thumbnailUrl} onClick={() => _handleChannelPick(t.streamingUrl)} />
                }
              </>
            )
          })}
        </styled.MultiChannel>
      )
    }
  }, [isOpen])


  const _renderVideoPlayer = useCallback(() => {
    if (streamingUrl) {
      if (show360) return <RM360Player landscape_mode={landscape} currentMobile={isMobile} streamingUrl_360={concertDetail.streamingUrl_360} streamingUrl_2d={concertDetail.streamingUrl_2d} />
      else return <RM2dPlayer landscape_mode={landscape} currentMobile={isMobile} streamingUrl_2d={concertDetail.streamingUrl_2d} />
    }
  }, [streamingUrl, show360])

  const _renderCaption = useCallback(() => {
    if (isCaption) {
      return (
        <styled.CaptionWrapper>
          <styled.Caption id="caption">
            <styled.Subtitles id="caption" style={{ "display": `${isCaption ? "block" : "none"}` }}> {videoState.caption} </styled.Subtitles>
          </styled.Caption>
        </styled.CaptionWrapper>
      )
    }
  }, [isCaption])

  useEffect(() => {
    if (videoState.caption) {
      if (videoState.caption !== "[Clear]") setIsCaption(true);
    }
    const timer = setTimeout(() => { setIsCaption(false) }, 3000);

    return () => { clearInterval(timer) };
  }, [videoState.caption]);

  return (
    <styled.Container landscape={landscape} id="StageContainer">
      <styled.StageHeader landscape={landscape}>
        <styled.StageHeaderLeftButton src={iconClose} onClick={() => history.goBack()} />
        {/* <styled.StageHeaderCenterText></styled.StageHeaderCenterText> */}
        {(videoState.is360On && !show360) &&
          // <Stage360Button onClick={() => _nextAppView()}>
          <styled.Stage360Button onClick={() => setShow360(true)}>
            <Lottie
              width={'100%'}
              options={{
                animationData
              }}
            />
          </styled.Stage360Button>
        }
        {show360 && <styled.StageLivwButton src={iconLive} onClick={() => setShow360(false)} />}
      </styled.StageHeader>

      {_renderVideoPlayer()}
      {(isMobile && concertDetail) && <MobileControlOverlay videoState={videoState} />}
      {(!isMobile && concertDetail) && <ControlOverlay videoState={videoState} isLandscape={landscape} enterObj={enterObj} concertUuid={concertDetail.uuid} />}
    
      {_renderCaption()}
      {(isMobile && showLoading) && <styled.ChatLoading><styled.ImgLoading src={'/images/rmpLoading.gif'} /></styled.ChatLoading>}

      {(enterObj && enterObj.channelUrl !== '') ?
        <Chat channelUrl={enterObj.channelUrl} landscape_mode={landscape} currentMobile={isMobile} concertUuid={concertDetail.uuid} />
        : <Detail datas={concertDetail} landscape_mode={landscape} />}

      {(enterObj && enterObj.channelUrl !== '') && <ChatForm channelUrl={enterObj.channelUrl} landscape_mode={landscape} currentMobile={isMobile} concertUuid={concertDetail.uuid} />}
    </styled.Container>
  )
}

export default Stage;
