export type TTimeLeft = {
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
}
export const calculateTimeLeft = (intialDate: null | string) => {
    if (!intialDate) return {};

    const difference = +new Date(intialDate) - +new Date();

    let timeLeft: TTimeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }


    return timeLeft;
};