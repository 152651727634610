const firebaseConfig = {
    apiKey: "AIzaSyDVkNvub-gDrVGTqZh_4-qObK88EfKAfm8",
    authDomain: "odeon-4cc95.firebaseapp.com",
    databaseURL: "https://odeon-4cc95.firebaseio.com",
    projectId: "odeon-4cc95",
    storageBucket: "odeon-4cc95.appspot.com",
    messagingSenderId: "836757373586",
    appId: "1:836757373586:web:1778e851299b2219ef9c4b",
    measurementId: "G-LNDMPR54YK"
};

export default firebaseConfig;
