import axios from "axios"

export const getApiAxiosWithoutToken = () => {
    const countryCode = localStorage.getItem('countryCode');
    const languageCode = localStorage.getItem('languageCode');
    const deviceId = localStorage.getItem('browser_key') || '';
    const deviceFp = localStorage.getItem('browser_fp') ? '-' + localStorage.getItem('browser_fp') : '';

    return axios.create({
        headers: {
            'Platform': 'web',
            'Country': `${countryCode}`,
            'Content-Type': 'application/json',
            'Language': `${languageCode}`,
            'DeviceId': `${deviceId}${deviceFp}`
        }
    })
}

export const getApiAxiosWithToken = () => {
    const token = localStorage.getItem('token');
    const countryCode = localStorage.getItem('countryCode');
    const languageCode = localStorage.getItem('languageCode');
    const deviceId = localStorage.getItem('browser_key') || '';
    const deviceFp = localStorage.getItem('browser_fp') ? '-' + localStorage.getItem('browser_fp') : '';

    return axios.create({
        headers: {
            'x-access-token': `${token}`,
            'Platform': 'web',
            'Country': `${countryCode}`,
            'Language': `${languageCode}`,
            'DeviceId': `${deviceId}${deviceFp}`
        }
    })
}

export const getApiAxiosWithTokenAndLang = (lang) => {
    const token = localStorage.getItem('token');
    const countryCode = localStorage.getItem('countryCode');
    const deviceId = localStorage.getItem('browser_key') || '';
    const deviceFp = localStorage.getItem('browser_fp') ? '-' + localStorage.getItem('browser_fp') : '';

    return axios.create({
        headers: {
            'x-access-token': `${token}`,
            'Platform': 'web',
            'Country': `${countryCode}`,
            'Content-Type': 'application/json',
            'Language': `${lang}`,
            'DeviceId': `${deviceId}${deviceFp}`
        }
    })
}
