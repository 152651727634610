import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  > div.modal-dialog {
    height:75% !important;
    background-color: #21262c;
    z-index: 500;
    width: 95% !important;
    top:10px !important;
    transform: translate(-50%, 0) !important;
  }
  > div.modal-content{
    height: 100%;
  }
  > div.modal-header{
    border: 1px solid red !important;
    height: 65%;
  }
  > div.modal-title{
    font-size: 1.2rem;
  }
  .modal-body {
    height: 100% !important;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  overflow: scroll;
`;
export const Title = styled.div`
  text-align: center;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 20px;
`;

export const SubTitle = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
`;

export const ContentWrapper = styled.div`
  margin-bottom: 15px;
`;

export const ContentTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ContentDesc = styled.div`
  font-size: 12px;
  line-height: 20px;
`;
