import * as React from 'react';
import Lottie, { EventListener } from 'react-lottie';
import loginJson from './logo_login.json';

/* tslint:disable-next-line */
const animationData = loginJson;

const LoadingIndicator = () => (
  <Lottie
    options={{
      animationData
    }}
  />
);

const LoadingIndicatorStyled = () => (
  <Lottie
    options={{
      animationData
    }}
    style={{margin: '0 0 30px'}}
  />
);

const LoadIndicatorOnce = () => {
  const [isComplete, setIsComplete] = React.useState(false);
  const eventListeners = React.useMemo((): ReadonlyArray<EventListener> => [{
    eventName: 'complete',
    callback: (): void => setIsComplete(true)
  }], []);
  return isComplete ? null : <Lottie
    options={{
      animationData
    }}
    eventListeners={eventListeners}
  />;
};

export default LoadingIndicator;
