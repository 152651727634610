import VideoPlayer from 'components/Stage/VideoPlayer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setStreamingUrl } from 'modules/common';
import icon360 from '../../images/ic_360.png';
import iconClose from '../../images/ic_close.png';
import { Container, StageHeader, StageHeaderCenterText, StageHeaderLeftButton, StageHeaderRightButton } from './Style';
import { useCookies } from 'react-cookie';
import Chat from 'components/Sendbird/Sendbird';
import Detail from 'components/Stage/Detail';
import SendBird from "sendbird"
import {  mainDetail } from 'modules/main';

import { SENDBIRD_APP_ID } from 'constants/apiInfo';
import { APP_SCHEME } from 'constants/apiInfo';

const listDetailSelector = ({ main: { listDetail } }: any) => listDetail;

new SendBird({ appId: SENDBIRD_APP_ID });

interface RouteParams {
  uuid: string;
}

const Stage = () => {
  const { uuid } = useParams<RouteParams>();
  const [ landscape, setLandscape ] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const concertDetail = useSelector(listDetailSelector);
  
  useEffect(() => {
    dispatch(mainDetail(uuid));
  }, []);


  const _nextAppView = () => {
    window.location.href = `${APP_SCHEME}://open?name=concert&uuid=${concertDetail.uuid}`;
  }

  window.addEventListener('resize', function(event){
      var newWidth = window.innerWidth;
      var newHeight = window.innerHeight;

      if(window.orientation == 90 || window.orientation == -90){
        setLandscape(true)
      }else{
        setLandscape(false)
      }
  });

  useEffect(() => {
    if(concertDetail){
      dispatch(setStreamingUrl(concertDetail.videos[0]))
    }
  }, [concertDetail]);

  return (
    <Container landscape={landscape}>
      <StageHeader landscape={landscape}>
        <StageHeaderLeftButton src={iconClose} onClick={() => history.goBack()} />
        <StageHeaderCenterText>
        </StageHeaderCenterText>
      </StageHeader>

      {concertDetail &&
        <VideoPlayer landscape_mode={landscape} currentMobile={true}/>
      }
      {concertDetail &&
        <Detail datas={concertDetail} landscape_mode={landscape}/>
      }

    </Container>
  )
}

export default Stage;
