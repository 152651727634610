import React, { useEffect, useState } from 'react';
import locale from './locale.json';
import * as styled from './Style';
import { TTicket } from 'modules/tickets';
import { amountToPrice } from 'core/utils/numberHelper';

interface IPaymentDetailProps {
    currentTicket: TTicket | null;
}

const PaymentDetail = ({ currentTicket }: IPaymentDetailProps) => {
    const [language, setLanguage] = useState<any>({});

    useEffect(() => {
        if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
        else setLanguage(locale.en)
    }, [language])

    if (!currentTicket) return null;

    return (
        <styled.Container>
            <styled.DetailWrapper>
                <styled.Title>{language.OriginalAmount}</styled.Title>
                <styled.Amount> {amountToPrice(currentTicket.originalAmount, currentTicket.currency)}</styled.Amount>
            </styled.DetailWrapper>
            <styled.DetailWrapper>
                <styled.Title>{language.DiscountAmount}</styled.Title>
                <styled.Amount> {amountToPrice(currentTicket.discountAmount, currentTicket.currency)}</styled.Amount>
            </styled.DetailWrapper>
            <styled.DetailWrapper>
                <styled.Title>{language.FinalAmount}</styled.Title>
                <styled.TotalAmount> {amountToPrice(currentTicket.amount, currentTicket.currency)}</styled.TotalAmount>
            </styled.DetailWrapper>
        </styled.Container>
    )
}

export default PaymentDetail;
