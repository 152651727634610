import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

import { anotherVerifyToken } from '../../modules/auth';
import { Container } from './Style';


const usersSelector = ({ auth: { userData } }: any) => userData;

const AnotherSsoSignin = (location:any, match:any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userDatas = useSelector(usersSelector);

  const query = qs.parse(location.location.search);

   useEffect(() => {
     console.log("ssoLogin OK!!")
     if (!query){
       alert("error query")
     }

     const _success = () => {
       const dURL = decodeURIComponent(`${query.returnUrl}`);

       if(dURL){
         let urlPath = "/"+`${dURL.split("/")[3]}`;
         if (dURL.split("/")[4]) urlPath = urlPath.concat("/"+`${dURL.split("/")[4]}`)
         history.replace(urlPath);
       }
     }

     const _error = () => {
       alert("error")
     }

     dispatch(anotherVerifyToken(query, _success, _error))
   }, []);

  useEffect(() => {
    if (userDatas && userDatas.uuid) {
      history.push("/yes24/jukjae/1123");
    }
  }, []);


  return (
    <Container active={true}>
    </Container>
  )
}

export default AnotherSsoSignin;
