import styled from "styled-components";
import colors from "../../constants/styles/colors";
import MainCard1 from '../../images/51.png'
import TicketOn from '../../images/img-small-ticket.png'
import TicketOff from '../../images/img-small-ticket-disabled.png'

interface ICustomLabel {
    mt: number
}

interface ICardUrl{
    thumbnailsUrl: string
}

interface ICard {
    active: boolean;
}


export const CustomInput = styled.input`
    display: block;
    width: 100%;
    height: 40px;
    background: none;
    border: none;
    border-bottom: 0.5px solid #666;
    color: white;
    padding-left: 5px;
    font-size: 26px;
    &:focus {
        border-bottom-color: ${colors.pink}
    }
`;



export const CustomLabel = styled.label<ICustomLabel>`
    width: 100%;
    height: 40px;
    display: block;
    color: #666;
    line-height: 40px;
    font-size: 26px;
    border-bottom: 0.5px solid #666;
    margin-top: ${(props) => props.mt}px;
`;

export const CustomSelect = styled.select`
    width: 100%;
    height: 40px;
    background: transparent;
    border: none;
    border-bottom: 0.5px solid #666;
    display: block;
    color: white;
    line-height: 40px;
    font-size: 26px;
    &:focus {
        border-bottom-color: ${colors.pink}
    }
`;




export const MainCard = styled.div`
    width: 95%;
    height: 200px;
    padding-top:20px;
    padding-bottom:20px;
    top: 0;
    left: 0;
    border-bottom:1px solid rgba(255, 255, 250, 0.2);
    margin: auto;
`;


export const CardLeft = styled.div`
    width: 37%;
    float:left;
    height: 150px;

`;

export const CardThumbnail = styled.div<ICardUrl>`
    width: 120px;
    height: 120px;
    border-radius: 5px 5px 0px 0px;
    background: url(${(props) => props.thumbnailsUrl? props.thumbnailsUrl : MainCard1}) no-repeat center/cover;
    background-image: url(${(props) => props.thumbnailsUrl? props.thumbnailsUrl : MainCard1});
    position:relative;
    float:left;
`;

export const Card = styled.div<ICard>`
    width: 120px;
    height: 40px;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    color: ${(props) => props.active? `#000` : `#fff`};
    opacity: ${(props) => props.active? `1` : `0.5`};
    font-size:0.9rem;
    text-align:center;
    line-height:38px;
    display:inline-block;
    position:relative;
    background: url(${TicketOn}) no-repeat center/cover;
    background-image: url(${TicketOn});

    background: url(${(props) => props.active? TicketOn : TicketOff}) no-repeat center/cover;
    background-image: url(${(props) => props.active? TicketOn : TicketOff});

    float:left;
    bottom:0;
    font-weight:600;
`;

export const CardRight = styled.div`
    width: 58%;
    height: 148px;
    float:right;

`;


export const CardTitle = styled.p`
    color:#fff;
    line-height: 1.33;
    font-size: 1.1rem;
    letter-spacing: 0px;
    font-weight:600;
    height:15%;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const CardText = styled.p`
    color:#999999;
    line-height: 1.57;
    font-size: 0.8rem;
    width:100%;
    overflow-x: auto;
    height: 82%;
`;

export const CardDate = styled.p`
    color:#999999;
    line-height: 1.3;
    font-size: 1.1rem;
    float:right;
    letter-spacing: -1.2px;
    height:10%;
`;

export const InfoText = styled.div`
    position: relative;
    font-size: 1rem;
    width:100%;
    font-weight:500;
    line-height: 1.5;
    color:#999;
    text-align:center;
    top:20%;
`;
