import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2A3139;
`;

export const Card = styled.div`
  height:80px;
  color: white;
  font-size:1.2rem;
  border-bottom:1px solid #ddd;
  line-height:80px;
  padding-left:20%;

`;

export const Btn = styled.button`
    width: 80px;
    height: 40px;
    color: #000;
    background-color:  #ffffff;
    line-height: 40px;
    border-radius: 7px;
    text-align: center;
    border: block;
    font-size:1rem;
    font-weight:600;
    z-index: 2;
    float:right;
    margin-right:30%;
    margin-top:20px;
`;

export const ThresholdInputWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 99;
  padding: 10px;
  display: flex;
  align-items: center;
`;

export const ThresholdLabel = styled.label`
  color: white;
  font-weight: 700;
  margin-right: 10px;
`;

export const ThresholdInput = styled.input.attrs({ type: 'tel' })`
  margin-right: 10px;
  width: 40px;
  padding: 5px;
  color: #ccc;
`;

export const ThresholdButton = styled.button`
  width: 30px;
  height: 40px;
  background-color: white;
  color: #ccc;
  &:active {
    background-color: white;
    color: white;
  }
`;
