import { lineAuth, lineSignin } from 'modules/auth';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const LineSigninCallback = (location: any, match: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = qs.parse(location.location.search);

    useEffect(() => {
        let { code } = query;

        if (!code) return history.push('/signin');

        if (!localStorage.getItem('userAgent')) {
            const codeString = code.toString();
            const lineAuthSuccess = (data: any) => {
                dispatch(lineSignin(data.idToken, () => history.push('/')));
            }
            const lineAuthFailure = () => {
                history.push('/');
            }
            dispatch(lineAuth(codeString, lineAuthSuccess, lineAuthFailure));
        }
    }, []);

    return (
        <div />
    )
}

export default LineSigninCallback;
