import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as styled from './Style';

const Privacy = () => {
    const history = useHistory();
    const [privacy, setPrivacy] = useState<null | any>(null);

    useEffect(() => {
        if (history.location.search.includes('en')) {
            const enPrivacy = require('./privacy.en.json');
            setPrivacy(enPrivacy);
        } else {
            const koPrivacy = require('./privacy.ko.json');
            setPrivacy(koPrivacy);
        }
    }, [history]);

    if (!privacy) return null;

    return (
        <>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section1.title}
                </styled.SectionTitle>
                <styled.SectionSubTitle>
                    {privacy.section1.subtitle}
                </styled.SectionSubTitle>
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section2.title}
                </styled.SectionTitle>
                <styled.SectionSubTitle>
                    {privacy.section2.subtitle}
                </styled.SectionSubTitle>
                {privacy.section2.listDetail.map((k) => {
                    return (
                        <styled.SectionListDetail>
                            • {k}
                        </styled.SectionListDetail>
                    )
                })}
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section3.title}
                </styled.SectionTitle>
                <styled.SectionSubTitle>
                    {privacy.section3.subtitle}
                </styled.SectionSubTitle>
                {privacy.section3.listDetail.map((k) => {
                    return (
                        <styled.SectionListDetail>
                            • {k}
                        </styled.SectionListDetail>
                    )
                })}
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section4.title}
                </styled.SectionTitle>
                <styled.SectionSubTitle>
                    {privacy.section4.subtitle}
                </styled.SectionSubTitle>
                {privacy.section4.listDetail.map((k) => {
                    return (
                        <styled.SectionListDetail>
                            • {k}
                        </styled.SectionListDetail>
                    )
                })}
                <styled.SectionTable>
                    <thead>
                        {privacy.section4.table.headers.map((h) => {
                            return (
                                <styled.SectionTableHeader>{h}</styled.SectionTableHeader>
                            )
                        })}
                    </thead>
                    <tbody>
                        {privacy.section4.table.contents.map((h, idx) => {
                            if (idx === 1) {
                                return (
                                    <styled.SectionTableData>
                                        {h.map((d) => {
                                            return (
                                                <div>• {d}</div>
                                            )
                                        })}
                                    </styled.SectionTableData>
                                )
                            } else {
                                return (
                                    <styled.SectionTableData>{h}</styled.SectionTableData>
                                )
                            }
                        })}
                    </tbody>
                </styled.SectionTable>
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section5.title}
                </styled.SectionTitle>
                <styled.SectionSubTitle>
                    {privacy.section5.subtitle}
                </styled.SectionSubTitle>
                <styled.SectionContentDetail>
                    <styled.SectionContentDetailTtile>
                        {privacy.section5.contentDetail.title}
                    </styled.SectionContentDetailTtile>
                    {privacy.section5.contentDetail.contentDetailList.map((l) => {
                        return (
                            <styled.SectionContentDetailListItem>
                                • {l}
                            </styled.SectionContentDetailListItem>
                        )
                    })}
                </styled.SectionContentDetail>
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section6.title}
                </styled.SectionTitle>
                {privacy.section6.listDetail.map((k) => {
                    return (
                        <styled.SectionListDetail>
                            • {k}
                        </styled.SectionListDetail>
                    )
                })}
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section7.title}
                </styled.SectionTitle>
                {privacy.section7.listDetail.map((k) => {
                    return (
                        <styled.SectionListDetail>
                            • {k}
                        </styled.SectionListDetail>
                    )
                })}
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section8.title}
                </styled.SectionTitle>
                <styled.SectionSubTitle>
                    {privacy.section8.subtitle}
                </styled.SectionSubTitle>
                <styled.SectionContentDetail>
                    {privacy.section8.contentDetails.map((c) => {
                        return (
                            <>
                                <styled.SectionContentDetailTtile>
                                    {c.title}
                                </styled.SectionContentDetailTtile>
                                {c.rawDetails.map((r) => {
                                    return (
                                        <styled.SectionContentDetailRawItem>
                                            {r}
                                        </styled.SectionContentDetailRawItem>
                                    )
                                })}
                            </>
                        )
                    })}
                </styled.SectionContentDetail>
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section9.title}
                </styled.SectionTitle>
                <styled.SectionSubTitle>
                    {privacy.section9.subtitle}
                </styled.SectionSubTitle>
                <styled.SectionContentDetail>
                    {privacy.section9.contentDetails.map((c) => {
                        return (
                            <>
                                <styled.SectionContentDetailTtile>
                                    {c.title}
                                </styled.SectionContentDetailTtile>
                                {c.rawDetails.map((r) => {
                                    return (
                                        <styled.SectionContentDetailRawItem>
                                            {r}
                                        </styled.SectionContentDetailRawItem>
                                    )
                                })}
                            </>
                        )
                    })}
                </styled.SectionContentDetail>
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section10.title}
                </styled.SectionTitle>
                <styled.SectionSubTitle>
                    {privacy.section10.subtitle}
                </styled.SectionSubTitle>
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section11.title}
                </styled.SectionTitle>
                <styled.SectionSubTitle>
                    {privacy.section11.subtitle}
                </styled.SectionSubTitle>
            </styled.SectionWrapper>
            <styled.SectionWrapper>
                <styled.SectionTitle>
                    {privacy.section12.title}
                </styled.SectionTitle>
                <styled.SectionSubTitle>
                    {privacy.section12.subtitle}
                </styled.SectionSubTitle>
            </styled.SectionWrapper>
        </>
    )
}

export default Privacy;
