import styled from "styled-components";
import MainCard1 from '../../images/big-69.png'
import TicketOn from '../../images/img-small-ticket.png'

interface ITabs {
    active: boolean;
}

interface IContentContainer {
    marginTop?: number;
}


export const HeaderCenterText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
`;

export const HeaderLeftButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const HeaderRightButton = styled.img`
    width: 50px;
    height: 50px;
`;


export const HelpText = styled.div`
    max-width: 240px;
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
`;

export const HelpSubText = styled.div`
    max-width: 240px;
    margin: 0 auto;
    margin-top: 15px;
    color: #999;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
`;

export const Form = styled.div`
    margin-top: 25px;
    width: 100%;
`;

export const BtnDone = styled.button`
    width: 100%;
    height: 50px;
    color: #000000;
    background-color: #ffffff;
    line-height: 40px;
    border-radius: 7px;
    text-align: center;
    margin-top: 50px;
    border: none;
    font-size:1.2rem;
`;


export const MainCard = styled.div`
    width: 90%;
    height: 200px;
    padding-top:20px;
    padding-bottom:20px;
    top: 0;
    left: 0;
    border-bottom:1px solid rgba(255, 255, 250, 0.2);
    margin: auto;
`;

export const Content = styled.div`
    position: fixed;
    z-index:1;
    width:100%;
    background-color: #1a1f25;
    border-bottom:1px solid rgba(255, 255, 255, 0.1);
`;

export const Profile = styled.div`
    width: 100%;
    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: #1a1f25;
    margin-top:60px;
    padding: 0 20px;
`;

export const ProfileImgWrapper = styled.div`
    position: relative;
`;
export const ProfileImg = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 18px;
`;

export const ProfileEditImg = styled.img`
    width: 30px;
    height: 30px;
    position: absolute;
    right: -15px;
    bottom: 0;
    margin-right:3%;
`;

export const ProfileNickNameText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 10%;
    left: 27%;
    color: #fff;
    font-size: 1.2rem;
    font-weight:600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
`;

export const ProfileInfoText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 0.8rem;
    opacity: 0.5;
    top: 30%;
    left: 27%;
`;

export const TabContainer = styled.div<IContentContainer>`
    margin-top: ${(props) => props.marginTop ? props.marginTop : 0}px;
    height: 100%;
    padding: 0 15px;
    background-color:#2A3139;
    opacity: 0.9;
    padding-bottom:25px;
    padding-top: 5px;
    width:100%;
    display: block;
    position: absolute;
    top: 210px;
    @media only screen and (min-width: 850px) {
      overflow-x: scroll;
    }
`;


export const Tabs = styled.div<ITabs>`
    display: inline-block;
    position: relative;
    height: 60px;
    line-height: 60px;
    top: 50%;
    background-color: #1a1f25;
    width: 33.3%;
    color: ${(props) => props.active? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)'};
    &:before {
      content: '';
      position: absolute;
      top: 97%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60%;
      height: 3px;
      background-color: ${(props) => props.active? '#62e979' : ''};
     }
`;



export const TabsFont = styled.div`
    height: 60px;
    line-height: 60px;
    font-size:1rem;
    text-align: center;
    font-weight:600;
`;

export const CardLeft = styled.div`
    width: 37%;
    float:left;
    height: 150px;

`;

export const CardThumbnail = styled.div`
    width: 120px;
    height: 120px;
    border-radius:5px;
    background: url(${MainCard1}) no-repeat center/cover;
    background-image: url(${MainCard1});
    position:relative;
    float:left;
`;

export const Card = styled.div`
    width: 120px;
    height: 40px;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    color: #000000;
    font-size:0.9rem;
    text-align:center;
    line-height:38px;
    display:inline-block;
    position:relative;
    background: url(${TicketOn}) no-repeat center/cover;
    background-image: url(${TicketOn});
    float:left;
    bottom:0;
    font-weight:600;
`;

export const CardRight = styled.div`
    width: 58%;
    height: 148px;
    float:right;

`;


export const CardTitle = styled.p`
    color:#fff;
    line-height: 1.33;
    font-size: 1.2rem;
    letter-spacing: 0px;
    font-weight:600;
`;

export const CardText = styled.p`
    color:#999999;
    line-height: 1.57;
    font-size: 0.8rem;
    padding-top:10px;
    width:85%;
`;

export const CardDate = styled.p`
    color:#999999;
    line-height: 4.3;
    font-size: 1.1rem;
    float:right;
    letter-spacing: -1.2px;
`;

export const Line = styled.p`
  background-color: rgba(255,255,255,0.1);
  position: absolute;
  width: 1px;
  display: inline-block;
  height: 1.25rem;
  margin-top: 1.25rem;
  z-index:1;
`;

export const BtnReadRedeemCode = styled.button`
    color: white;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(255,255,255,0.1);
    text-align: center;
    line-height: 40px;
`;