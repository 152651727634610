import TicketLabel from 'components/Ticket/Label';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import locale from './locale.json';
import * as styled from './Style';
import { TConcert } from 'modules/main';


interface ICardProps {
  concert: TConcert;
}

const MainCard = ({ concert }: ICardProps) => {
  const history = useHistory();
  const [language, setLanguage] = useState<any>({});

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  const _navigateToDetail = (concertUuid: string) => {
    history.push(`/maindetail/${concertUuid}`);
  }

  const startTime = moment(concert.startTime).format('YYYY.MM.DD HH:mm:ss');

  return (
    <div key={concert.id}>
      <styled.OpenDate>{startTime}</styled.OpenDate>
      <styled.MainCardWrapper id={concert.id.toString()} key={concert.id} onClick={() => _navigateToDetail(concert.uuid)} thumbnailsUrl={concert.mainPosterUrl}>
        <TicketLabel concert={concert} language={language} />
      </styled.MainCardWrapper>
    </div >
  )
}

export default MainCard;
