import { ActionType } from 'typesafe-actions';
import { createAsyncActionTypes } from '../../core/utils/reduxUtils';
const prefix: string = 'auth';

export const T_GOOGLE_SIGNIN = createAsyncActionTypes(`${prefix}/GOOGLE_SIGNIN`);
export const T_FACEBOOK_SIGNIN = createAsyncActionTypes(`${prefix}/FACEBOOK_SIGNIN`);
export const T_APPLE_SIGNIN = createAsyncActionTypes(`${prefix}/APPLE_SIGNIN`);
export const T_KAKAO_SIGNIN = createAsyncActionTypes(`${prefix}/KAKAO_SIGNIN`);
export const T_LINE_SIGNIN = createAsyncActionTypes(`${prefix}/LINE_SIGNIN`);
export const T_TWITTER_REQUEST_TOKEN = createAsyncActionTypes(`${prefix}/TWITTER_REQUEST_TOKEN`);
export const T_TWITTER_SIGNIN = createAsyncActionTypes(`${prefix}/TWITTER_SIGNIN`);
export const T_LINE_AUTH = createAsyncActionTypes(`${prefix}/LINE_AUTH`);
export const T_VERIFY_TOKEN = createAsyncActionTypes(`${prefix}/T_VERIFY_TOKEN`);
export const T_REFRESH_TOKEN = createAsyncActionTypes(`${prefix}/REFRESH_TOKEN`);
export const T_LOAD_ME = createAsyncActionTypes(`${prefix}/LOAD_ME`);
export const T_LOGOUT = createAsyncActionTypes(`${prefix}/LOGOUT`);
export const T_SET_USER_INFO = `${prefix}/SET_USER_INFO`;
export const T_SET_TWITTER_PROCESSING = `${prefix}/SET_TWITTER_PROCESSING`;
export const T_ANOTHER_VERIFY_TOKEN = createAsyncActionTypes(`${prefix}/T_ANOTHER_VERIFY_TOKEN`);

export const T_SET_EMAIL = `${prefix}/SET_EMAIL`;

export type AuthAction = ActionType<any>;

export type TUserData = {
  uuid: string;
  id: number;
  nickName: string;
  profileUrl: string;
  email: string;
  status: string;
  pushFlag: boolean;
  initial: boolean;
  registrationToken: string;
  lastLanguage: string;
  lastDeviceId: string;
  lastWebDeviceId: string;
  role: string;
  lastMessageId: number;
  lastSeenMessageId: number;
  isNickNameUpdated: boolean;
  createdAt: string;
  updatedAt: string;
  extType: string;
}

export type AuthState = {
  isLoadingAuth: any,
  userData: null | TUserData,
  lineAuthRes: any,
  isTwitterProcessing: boolean
};
