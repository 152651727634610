import styled from "styled-components";
import MainCard1 from '../../images/big-69.png'
import TicketOn from '../../images/img-bg-ticket-on.png'
import TicketOff from '../../images/img-bg-ticket-off.png'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2A3139;
`;

interface ICard {
  active: boolean;
}

interface IButton {
  active: boolean;
  isOne: boolean;
}

export const Header = styled.div`
  width: 100%;
  display: flex;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 60px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: #1a1f25;
`;

export const HeaderLeftButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const ContentContainer = styled.div`
    height: 100%;
    background-color:#2A3139;
    opacity: 0.9;
    padding-bottom: 60%;
    @media only screen and (min-width: 850px) {
      overflow-x: scroll;
      height: 100vh;
    }
`;

export const HelpText = styled.div`
    max-width: 240px;
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
`;

export const HelpSubText = styled.div`
    max-width: 240px;
    margin: 0 auto;
    margin-top: 15px;
    color: #999;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
`;

export const Form = styled.div`
    margin-top: 25px;
    width: 100%;
`;

export const BtnDone = styled.button<IButton>`
    width: ${(props) => props.isOne ? `69%;` : `100%`};
    height: 50px;
    opacity: ${(props) => props.active ? `1` : `0.5`};
    color: #000;
    background-color:  #ffffff;
    line-height: 40px;
    border-radius: 7px;
    text-align: center;
    border: none;
    font-size:1rem;
    font-weight:600;
    z-index: 2;
    display: block;
    pointer-events:${(props) => props.active ? 'unset' : 'none'};
`;
//BtnGroup
export const BtnGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
    align-self: flex-end;
    padding-top:10px;
    padding-bottom:10px;
`;

export const Btnpreview = styled.button<ICard>`
    width: 28%;
    height: 50px;
    border:1px solid #fff;
    line-height: 40px;
    border-radius: 7px;
    text-align: center;
    font-size:1rem;
    font-weight:600;
    z-index: 2;
    display: block;
    bottom: 5%;
    pointer-events:${(props) => props.active ? 'unset' : 'none'};
    right: 5%;
    color:#fff;
    background:rgba(255,255,255,0.01);
    bottom: 55px;
`;

export const Referral = styled.button<IButton>`
  height: 50px;
  color: #000;
  background-color:  #ffffff;
  line-height: 40px;
  border-radius: 7px;
  text-align: center;
  border: none;
  font-size:1rem;
  font-weight:600;
  z-index: 2;
  display: block;
  position: absolute;
  left: 5%;
  bottom: 0;
  width: 90%;
`;

export const BtnTest = styled.button`
    width: 50px;
    height: 50px;
    color: #000;
    background-color:  #ffffff;
    line-height: 40px;
    border-radius: 7px;
    text-align: center;
    border: none;
    font-size:1rem;
    font-weight:600;
    z-index: 2;
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
`;

export const MainCard = styled.div`
    width: 100%;
    top: 0;
    left: 0;
`;

export const MainCardImg = styled.img`
  max-width: 100%;
  height: auto;
`;


export const ContentCard = styled.div`
    width: 100%;
    position:fixed;
    bottom:330px;
`;

export const LeftButton = styled.div`
    width: 100%;
    position:fixed;
    top:1%;
    z-index:3;
`;

export const Card = styled.div<ICard>`
    width: 80%;
    height: 50px;
    border-radius:5px;
    display:inline-block;
    position:fixed;
    background: ${(props) => props.active ? `url(${TicketOn}) no-repeat center/cover` : `url(${TicketOff}) no-repeat center/cover`};
    background-image: ${(props) => props.active ? `url(${TicketOn})` : `url(${TicketOff})`};
    transform: translate(-50%,-50%);
    left:50%;
`;

export const AmountNumber = styled.span`
    width: 23%;
    height: 50px;
    font-size: 1.4rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #000000;
    font-weight:bold;
    text-align: right;
    padding-right:5px;
    vertical-align:middle;
    position: relative;
    top:27%;

`;

export const MaxNumber = styled.span<ICard>`
  width: 33px;
  height: 17px;
  opacity: 0.4;
  font-size: 1.1rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.active ? `#000` : `#fff`};
`;


export const ContentInfo = styled.div`
    width: 100%;
    position:fixed;
    bottom:0;
    height: 300px;
    background-color: rgb(26, 31, 37, 0.9);
    z-index:1;
    border-radius: 10% 10% 0% 0%;
    display:flex;
    flex-direction:column;
    justify-content: space-around;
    @media only screen and (min-width: 850px) {
      height: 350px;
    }
`;

export const ContentInfo1 = styled.div`
    width: 100%;
    position:fixed;
    bottom:0;
    border:1px solid red;
    height: 40%;

    -webkit-backdrop-filter: blur(160px);
    backdrop-filter: blur(160px);
    filter: blur(160px);
    background-color: #021a1f25;
    z-index:1;
    border-radius: 10% 10% 0% 0%;

`;

export const ContentDetail = styled.div`
    z-index:2;
    width: 100%;
    display: inline;
    padding-left:5%;
    padding-right:5%;
    flex-direction: column;
    display: flex;
    @media only screen and (min-width: 850px) {
       height: 50%;
     }

`;

export const DetailTitle = styled.p`
    color:#fff;
    line-height: 1.67;
    font-size: 1.125rem;
    font-weight:600;
    padding-top:20px;
    display: block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const DetailText = styled.p`
    color:#fff;
    font-size: 0.75rem;
    line-height: 1.67;
    display:bolck;
    opacity: 0.7;
    overflow:hidden;
    height: 110px;
    overflow-y: auto;
    @media only screen and (min-width: 850px) {
      height:45%;
      font-size: 0.9rem;
    }
`;


export const DetailInfoRight = styled.div`
  position: relative;
  flex:1;
  padding-top:5%;
  padding-right:28px;
  line-height: 10px;
  border-right: 1px solid rgba(255,255,255,0);
  @media only screen and (min-width: 850px) {
    padding-top:0;
  }
`;

export const DetailInfo = styled.div`
  position: relative;
  flex: 1;
  padding-top:5%;
  padding-left:28px;
  @media only screen and (min-width: 850px) {
    padding-top:0;
  }
`;

export const ShowInfo = styled.div`
  display: inline-block;
  width:100%;
  display: flex;
  justify-content: space-between;
`;

export const ShowTH = styled.div`
    color:#fff;
    font-size: 0.75rem;
    line-height: 1.67;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    opacity: 0.7;
    font-weight: 500;
    @media only screen and (min-width: 850px) {
      font-size: 0.8rem;
    }
`;
export const ShowTD = styled.pre`
    text-align: right;
    color:#fff;
    font-size: 0.7rem;
    line-height: 1.67;
    font-weight: 400;
    white-space: pre;
    @media only screen and (min-width: 850px) {
      font-size: 0.8rem;
    }
`;

export const Dim = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid black;
  z-index: 3;
`;

export const DetailBox = styled.div`
  display: flex;
  position: relative;
  height: 31%;

  @media only screen and (min-width: 850px) {
    height: 30%;
  }
`;
