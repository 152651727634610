import { APPLE_SIGNIN_REDIRECT_URL } from 'constants/apiInfo';
import React, { useEffect } from 'react';
import { Container } from '../Signin/Style';


declare global {
    interface Window { AppleID: any; }
}

const AppleSignin = () => {
    useEffect(() => {
        const _appleId = window.AppleID;
        if (_appleId) {
            _appleId.auth.init({
                clientId: 'odeon.seerslab.com',
                scope: 'name email',
                redirectURI: APPLE_SIGNIN_REDIRECT_URL,
                state: 'odeon.seerslab.com.test',
                responseMode: 'form_post'
            });
            _appleId.auth.signIn();
        }
    }, []);

    return (
        <Container style={{ background: 'white' }} active={true}>
            <div className="apple-login-wrapper hide">
                <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in" />
            </div>
        </Container>
    )

}

export default AppleSignin;
