import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2A3139;
    @media only screen and (min-width: 850px) {
      height: 100vh;
    }
`;

export const ContentContainer = styled.div`
    margin-top: 60px;
    padding: 20px;
`;

export const DescriptionContainer = styled.div`
    position: absolute;
    bottom: 70px;
    height: auto;
    margin: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(255,255,255,0.1);
`;
export const Description = styled.pre`
    color: #999999;
    font-size: 12px;
    line-height: 1.67;
    white-space: pre-wrap;
`;

export const BtnDone = styled.button`
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #62e979;
    color: #333333;
    font-size: 15px;
    font-weight: bold;
    &:disabled {
        background: rgba(255,255,255,0.1);
        color: rgba(255,255,255,0.2);
    }
`;


export const ConcertInfoWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ConcertMainPoster = styled.img`
    width: 120px;
`;

export const ConcertDescriptionWrapper = styled.div`
    flex: 1;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
`;

export const ConcertTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: white;
    max-width: 90%;
    line-height: 1.33;
`;

export const ConcertStartTime = styled.div`
    font-size: 15px;
    color: #999999;
    margin-top: 20px;
    text-align: right;
`;