import Axios from 'axios';
import { TWITTER_REQUEST_TOKEN_URL } from 'constants/apiInfo';
import React, { useEffect } from 'react';
import { Container } from '../Signin/Style';


const TwitterSignin = () => {
    useEffect(() => {
        _autoSignin();
    }, []);

    const _autoSignin = async () => {
        const { data } = await _getRequestToken();
        const openUrl = `https://api.twitter.com/oauth/authenticate?oauth_token=${data.oauth_token}&force_login=false`;
        window.location.href = openUrl;
    }

    const _getRequestToken = async () => {
        return await Axios.post(TWITTER_REQUEST_TOKEN_URL);
    }

    return (
        <Container active={true}>
        </Container>
    )

}

export default TwitterSignin;
