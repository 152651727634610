import Payment from 'containers/Payment';
import PaymentSelect from 'containers/PaymentSelect';
import { mainDetail } from 'modules/main';
import { loadPaymentMethods, setKind, setStage } from 'modules/payment';
import { loadTickets } from 'modules/tickets';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProps, useHistory, useParams } from 'react-router';
import { RootState } from 'modules/types';
import { loadMe } from 'modules/auth';

export interface IPaymentRouteProps extends RouteProps {
  // isAuth: any; // is authenticate route
  // redirectPath: string; // redirect path if don't authenticate route
}

interface RouteParams {
  uuid: string;
}

const paymentMethodsSelector = ({ payment: { paymentMethods } }: any) => paymentMethods;
const paymentStageSelector = ({ payment: { stage } }: any) => stage;
const ticketsSelector = ({ tickets: { list } }: any) => list;
const isLoadingTicketsSelector = ({ tickets: { isLoadingTickets } }: any) => isLoadingTickets;

const PaymentRoute: React.FC<IPaymentRouteProps> = (props) => {
  // const [stage, setStage] = useState(0);
  const { uuid } = useParams<RouteParams>()

  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector((state: RootState) => state.auth.userData);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const paymentStage = useSelector(paymentStageSelector);
  const tickets = useSelector(ticketsSelector);
  const isLoadingTickets = useSelector(isLoadingTicketsSelector);

  useEffect(() => {
    if (!userData) dispatch(loadMe());
  }, [userData]);

  useEffect(() => {
    dispatch(loadTickets(uuid));
    dispatch(loadPaymentMethods());
    dispatch(mainDetail(uuid));
    return () => {
      dispatch(setStage(0));
      dispatch(setKind(''));
    }
  }, []);

  useEffect(() => {
    if (isLoadingTickets || tickets === null) return;

    if (tickets.length === 0) return history.goBack();
  }, [tickets, isLoadingTickets]);

  if (paymentMethods === null || tickets === null) return <div />;

  if (paymentStage === 1 || paymentMethods.length > 0) return <Payment />

  return <PaymentSelect />
}

export default PaymentRoute