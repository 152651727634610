import Lottie from 'components/Signin/lottie';
import iconOpenSelect from 'images/ic-open-select.png';
import iconLogo from 'images/img-logo-big.png';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InfoPopup from '../../components/Main/InfoPopup';
import LanguagePopup from '../../components/Signin/LanguagePopup';
import SocialLogin from '../../components/Signin/SocialLogin';
import * as styled from './Style';
import { setTerm } from 'modules/common';
import { RootState } from 'modules/types';

const termSelector = ({ common: { showTerm } }: any) => showTerm;
const termsAndPrivacySelector = ({ common: { showTermsAndPrivacy } }: any) => showTermsAndPrivacy;

const Signin = () => {
  const [language, setLanguage] = useState(false);
  const [langugeCode, setLangugeCode] = useState<string>('');
  const [infoPopup, setInfoPopup] = useState<string>("");
  const [isSocial, setIsSocial] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector((state: RootState) => state.auth.userData);
  const showTerm = useSelector(termSelector);
  const showTermsAndPrivacy = useSelector(termsAndPrivacySelector);
  const localCode = localStorage.getItem('languageCode') || '';

  useEffect(() => {
    if (userData) history.push("/main");
  }, [userData]);

  useEffect(() => {
    setLangugeCode(localCode)
  }, [localCode])

  const _changeLanguge = () => setLanguage(true);

  const _langugePopupDiv = () => {
    if (language) {
      return (
        <styled.Dim>
          <LanguagePopup title={infoPopup} onCancel={() => setLanguage(false)} />
        </styled.Dim>
      )
    } else {
      return (null)
    }
  }

  const _socialLogin = useCallback(() => {
    if (isSocial) {
      return (
        <SocialLogin />
      )
    }
  }, [isSocial])

  useEffect(() => {
    if (showTermsAndPrivacy) setIsSocial(true)
  }, [showTermsAndPrivacy]);

  const _openSocial = () => {
    setIsSocial(true);
  }

  useEffect(() => {
    const clickCB = () => {
      setIsSocial(false);
    }
    if (isSocial) document.addEventListener("click", clickCB)
  }, []);

  const _popupDiv = useCallback(() => {
    let popupText = '';
    if (showTerm) {
      if (langugeCode == 'ko') popupText = "이용약관 및 개인정보 처리방침에 동의하셔야\nFanBox 이용이 가능합니다.";
      if (langugeCode == 'en') popupText = "To use Fanbox.live, you must agree to our terms & conditions and privacy policy.";
      return (
        <styled.Dim>
          <InfoPopup title={popupText} onCancel={() => dispatch(setTerm(false, false))} />
        </styled.Dim>
      )
    } else {
      return ("")
    }
  }, [showTerm])

  return (
    <styled.Container active={true}>
      <styled.Language onClick={_changeLanguge}>
        <styled.LangugeBox>{langugeCode == 'ko' ? '한국어' : 'English'}</styled.LangugeBox>
        <styled.OpenSelect src={iconOpenSelect} />
      </styled.Language>
      <styled.Content>
        <styled.LottieBox>
          <Lottie />
          <styled.LogoImg src={iconLogo} />
        </styled.LottieBox>
        <styled.LoginButton onClick={_openSocial}>
          LOGIN
        </styled.LoginButton>
        <styled.Copyright>Copyright 2020© FANBOX https://fanbox.live</styled.Copyright>
      </styled.Content>
      {_langugePopupDiv()}
      {_popupDiv()}
      {_socialLogin()}
    </styled.Container>
  )
}

export default Signin;
