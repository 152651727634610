import { TTicket } from 'modules/tickets';
import React, { useEffect, useState } from 'react';
import locale from './locale.json';
import * as styled from './Style';
import TicketBox from './TicketBox';
import PaymentMethodAgreement from './PaymentMethodAgreeMent';


interface IPaypalCheckoutFrom {
  tickets: TTicket[];
  isUpdate: boolean;
  savePaypalPaymentMethod: boolean;
  onSubmit: (saveMethod: boolean) => void;
}

const PaypalCheckoutForm = ({ tickets, isUpdate, savePaypalPaymentMethod, onSubmit }: IPaypalCheckoutFrom) => {
  const [agreeAll, setAgreeAll] = useState(false);
  const [language, setLanguage] = useState<any>({});
  const [savePaymentMethod, setSavePaymentMethod] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])


  useEffect(() => {
    setSavePaymentMethod(savePaypalPaymentMethod)
  }, [savePaypalPaymentMethod])

  const _handleSubmit = async (ev: React.SyntheticEvent) => {
    if (!agreeAll) {
      ev.preventDefault();
      alert('check agreement');
      return
    }
    onSubmit(savePaymentMethod);
  }

  const _ableToCheckout = () => {
    if (!agreeAll) return false;
    return true;
  }

  return (
    <styled.Form onSubmit={(ev: any) => _handleSubmit(ev)} paddingTop={isUpdate ? 20 : 0} >
      {(!isUpdate && tickets) &&
        <>
          <TicketBox tickets={tickets} />
          <PaymentMethodAgreement kind='paypal' onAgreeAll={() => setAgreeAll(true)} onDisagree={() => setAgreeAll(false)} />
        </>
      }
      <styled.BtnDone disabled={!_ableToCheckout()}>
        {isUpdate ? language.Save : language.Next}
      </styled.BtnDone>
    </styled.Form>
  )
}

export default PaypalCheckoutForm;
