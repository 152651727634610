import styled from "styled-components";
import Colors from '../../constants/styles/colors';

interface IContainer {
    paddingTop?: number;
}

export const Container = styled.div<IContainer>`
    width: 100%;

    background-color: #2A3139;
    padding-top: ${(props) => props.paddingTop ? props.paddingTop : 0}px;

`;

export const Title = styled.div`
  height:90px;
  padding-left:20px;
  padding-right:20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.div`
   float:left;
   font-size: 1.2rem;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.33;
   letter-spacing: normal;
   color: #ffffff;
   font-weight:600;
`;

export const Step = styled.div`
  float:right;
  color:#fff;
  opacity:0.7;
`;

export const TicketsWrapper = styled.div`
`;

export const TicketWrapper = styled.div`
    width: 100%;
    height: 70px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.05);
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const TicketIndicator = styled.span`
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 6px;
    top: 10px;
    right: 10px;
    background-color: ${Colors.mainGreen};
`;

export const TicketTitle = styled.div`
    margin-bottom: 10px;
    font-size: 15px;
    color: white;
`;

export const TicketAmount = styled.div`
    font-size: 12px;
    color: ${Colors.mainGreen};
`;

export const ContentWrapper = styled.div`
    width: 100%;
    padding: 0 20px;
    height:100vh;
`;

export const PaymentDetailWrapper = styled.div`
`;

export const PaymentDetail = styled.div`
`;

export const PaymentMethodWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
    height: 70px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PaymentMethodNumber = styled.div`
     color: white;
     font-size: 12px;
     font-weight: 700;
`;

export const BtnChangePaymentMethod = styled.button`
    color: ${Colors.mainGreen};
    font-size: 12px;
    font-weight: 700;
    background: none;
`;

export const PaymentInfo = styled.div`
  padding-top: 20px;
  text-align: center;
  color: white;
  opacity: 0.7;
  font-size: 0.8rem;
`;
