import { API_URL } from '../../constants/apiInfo';
import { getApiAxiosWithToken } from '../utils/api';

interface IMainListParams {
}

export async function callMainList() {
  const _axios = getApiAxiosWithToken();
  const response = await _axios.get(`${API_URL}/api/v1/concerts`);

  return response.data;
}

interface IMainListDetailParams {
  concertUuid: string;
}

export async function callMainDetail({ concertUuid }: IMainListDetailParams) {
  const _axios = getApiAxiosWithToken();
  const response = await _axios.get(`${API_URL}/api/v1/concerts/${concertUuid}`);

  return response.data;
}

interface IMainReservesParams {
  concertUuid: string;
}

export async function callMainReserves({ concertUuid }: IMainReservesParams) {
  const _axios = getApiAxiosWithToken();
  const _body = {
    concertUuid: concertUuid
  }
  const response = await _axios.post(`${API_URL}/api/v1/reserves/concerts`, _body);

  return response.data;
}

interface IEnterConcertParams {
  concertUuid: string;
}

export async function callEnterConcert({ concertUuid }: IEnterConcertParams) {
  const _axios = getApiAxiosWithToken();
  const _body = {
    concertUuid: concertUuid
  }
  const response = await _axios.post(`${API_URL}/api/v1/concerts/enter`, _body);

  return response.data;
}



interface ISendConcertHeartParams {
  concertUuid: string;
}

export async function callSendConcertHeart({ concertUuid }: ISendConcertHeartParams) {
  const _axios = getApiAxiosWithToken();
  const _body = {
    concertUuid: concertUuid
  }
  const response = await _axios.post(`${API_URL}/api/v1/concerts/hearts`, _body);
  return response.data;
}
