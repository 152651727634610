import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  margin-right: 15px;
`;

export const SelectWrapper = styled.div`
  width: 75px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(255,255,255,0.2);
  padding: 12px;
`;

export const TextSelect = styled.div`
  font-size: 12px;
  color: white;
  font-weight: 700;
`;

export const BtnSelect = styled.img`
  width: 20px;
  height: 20px;
`;

export const PopupWrapper = styled.div`
  width: 75px;
  height: 130px;
  background-color: #21262c;
  padding: 10px 15px;
  overflow-y: scroll;
  border-radius: 5px;
  position: absolute;
  margin-top: 5px;
  z-index: 100;
`;

export const PopupContentWrapper = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  margin-bottom: 10px;
`;

export const PopupContent = styled.div`
  font-size: 12px;
  color: white;
`;