import { useScript } from 'hooks/useScript';
import React, { useEffect, useState } from 'react';
import * as styled from './Style';
import iconPeople from 'images/ic-people.png';

declare global {
  interface Window { vr: any; player: any; videojs: any; RadiantMP: any; rmp: any; }
}

interface IRM2dPlayer {
  landscape_mode: boolean;
  currentMobile: boolean;
  streamingUrl_2d: string;
}

const RM2dDemoPlayer = ({ landscape_mode, currentMobile, streamingUrl_2d }: IRM2dPlayer) => {
  // streamingUrl_2d = 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8';
  const [userAgent, setuserAgent] = useState("");

  const [rmpLoaded, error] = useScript('https://cdn.radiantmediatechs.com/rmp/5.9.2/js/rmp.min.js');

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  const changeBitrage = (src: string) => {
    const originSrc = src.split('.m3u8')[0];
    if (currentMobile) {
      return src;
    }
    return originSrc + '_1080.m3u8';
  }

  useEffect(() => {
    if (rmpLoaded && window.RadiantMP) {
      const updatedUrl = changeBitrage(streamingUrl_2d);

      var src = {
        hls: updatedUrl,
      };
      var settings = {
        licenseKey: 'Kl8ldT1rK2U1MmNzOXY/cm9tNWRhc2lzMzBkYjBBJV8q',
        src: src,
        autoHeightMode: false,
        video360: false,
        autoplay: true,
        hideSeekBar: true,
        loop: true,
        hideCentralPlayButton: true,
        forceHlsJSOnMacOSIpadOSSafari: true,
        skin: 's3',
      };
      var elementID = 'rmp2dDemoPlayer';
      var rmp = window.rmp = new window.RadiantMP(elementID);
      var rmpContainer = document.getElementById(elementID);
      rmp.setModuleOverlayVisible('quality', true);
      rmp.init(settings);
      rmpContainer!.addEventListener('playing', function () {
        console.log('player has received playing event');
        rmp.setBitrate(0);
      });
    }
  }, [rmpLoaded, streamingUrl_2d]);

  if (!rmpLoaded) return <styled.Video landscape={landscape_mode} isMobile={currentMobile} />;

  return (
    <styled.Video landscape={landscape_mode} isMobile={currentMobile}>
      <styled.CountBox landscape={landscape_mode}>
        <styled.CountImg src={iconPeople} />
        <styled.Count />
      </styled.CountBox>
      <div style={{ width: '100%', height: '100%' }} id="rmp2dDemoPlayer"></div>
    </styled.Video>
  )
}
export default RM2dDemoPlayer;
