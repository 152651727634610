import { useEffect } from "react"
import SendBird from "sendbird"

export const useChannelHandler = (uniqHandlerId, handlers) => {

  useEffect(() => {
    const sb = SendBird.getInstance()
    const ChannelHandler = new sb.ChannelHandler()
    handlers.forEach((handler) => {
      ChannelHandler[handler.name] = handler.callback
    })
    sb.addChannelHandler(uniqHandlerId, ChannelHandler)
    return () => sb.removeChannelHandler(uniqHandlerId)
  }, [handlers, uniqHandlerId])
}
