import { ActionType } from 'typesafe-actions';
import { createAsyncActionTypes } from '../../core/utils/reduxUtils';
const prefix: string = 'firebase';

export const T_FIREBASE_VIDEO_STATE = createAsyncActionTypes(`${prefix}/FIREBASE_VIDEO`);
export const T_FIREBASE_LIKE = createAsyncActionTypes(`${prefix}/FIREBASE_LIKE`);


export type FirebaseAction = ActionType<any>;

export type FirebaseState = {
  isLoadingFirebase:any,
  videoState: any,
  like: number,
};
