import styled from "styled-components";
import Colors from '../../../constants/styles/colors';

export const PurchaseWrapper = styled.div`
    width: 100%;
    padding: 0 20px;
`;

export const PaymentMethodWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
    height: 70px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 15px;
    display: flex;
    justify-content: space-between;
`;

export const PaymentMethodCardInfo = styled.div`
     display: flex;
     flex-direction: column;
     justify-content: space-between;
`;
export const PaymentMethodNumber = styled.div`
     color: white;
     font-size: 12px;
     font-weight: 700;
`;

export const PaymentMethodHolderName = styled.div`
     opacity: 0.8;
     color: white;
     font-size: 12px;
`;

export const BtnChangePaymentMethod = styled.button`
    color: ${Colors.mainGreen};
    font-size: 12px;
    font-weight: 700;
    background: none;
`;
