import { ActionType } from 'typesafe-actions';
import { createAsyncActionTypes } from '../../core/utils/reduxUtils';
const prefix: string = 'tickets';

export const T_LOAD_TICKETS = createAsyncActionTypes(`${prefix}/LOAD_TICKETS`);

export type TicketsAction = ActionType<any>;

export type TTicket = {
  uuid: string,
  id: number,
  concertUuid: string,
  status: string,
  title: string,
  country: string,
  originalAmount: number,
  discountAmount: number,
  amount: number,
  kind: string,
  currency: string,
  provideLive: boolean,
  provideVods: boolean,
  provideStickers: boolean,
  createdAt: string,
  updatedAt: string,
}

export type TicketsState = {
  isLoadingTickets: any,
  list: null | TTicket[],
};
