import { ActionType } from 'typesafe-actions';
import { createAsyncActionTypes } from '../../core/utils/reduxUtils';
const prefix: string = 'payment';

export const T_SET_STAGE = `${prefix}/SET_STAGE`;
export const T_SET_KIND = `${prefix}/SET_KIND`;
export const T_LOAD_PAYMENT_METHODS = createAsyncActionTypes(`${prefix}/LOAD_PAYMENT_METHODS`);
export const T_REGISTER_PAYMENT_METHODS = createAsyncActionTypes(`${prefix}/REGISTER_PAYMENT_METHOD`);
export const T_UPDATE_PAYMENT_METHODS = createAsyncActionTypes(`${prefix}/UPDATE_PAYMENT_METHOD`);
export const T_CHARGE_CONCERT = createAsyncActionTypes(`${prefix}/CHARGE_CONCERT`);
export const T_ONE_TIME_CHARGE_CONCERT = createAsyncActionTypes(`${prefix}/ONE_TIME_CHARGE_CONCERT`);
export const T_LOAD_PAYMENT_HISTORIES = createAsyncActionTypes(`${prefix}/LOAD_PAYMENT_HISTORIES`);
export const T_REFUND_CONCERT = createAsyncActionTypes(`${prefix}/T_REFUND_CONCERT`);
export const T_SET_CONCERT = `${prefix}/SET_CONCERT`;
export const T_SET_STRIPE_CARD_TOKEN = `${prefix}/STRIPE_CARD_TOKEN`;

export type PaymentAction = ActionType<any>;

export type TPaymentMethod = {
  uuid: string;
  kind: string;
  cardInfo: any;
  holderName: string;
}

export type TPayment = {
  uuid: string;
  type: string;
  kind: string;
  currency: string;
  status: string;
  concertUuid: string;
  ticketUuid: string;
  show: string;
  amount: string;
  metadata: any;
  source: any;
  paypalOrderId: string;
}

export type PaymentState = {
  stage: number,
  kind: string,
  isFetchingPaymentMethods: boolean,
  isRegisteringPaymentMethods: boolean,
  isFetchingHistoryMethods: boolean,
  paymentMethods: null | TPaymentMethod,
  stripeCardToken: any,
  concert: any,
  paymentHistorys:any,
  paymentRefunds:any
};
