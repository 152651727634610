import { createReducer } from 'typesafe-actions';
import * as types from './types';

const initialState: types.MainState = {
    isLoadingMain: false,
    list: [],
    lastId: '',
    selectId: '',
    cardId: 1,
    isLoadingMainDetail: false,
    listDetail: null,
    isLoadingReserves: false,
    listReserves: false,
    isEntering: false,
    enterObj: null,
    hasNewMessage: false
};


const mainReducer = createReducer(initialState)
    .handleAction(types.T_MAIN_LIST.PENDING, (state: types.MainState, action: types.MainAction) => ({
        ...state,
        isLoadingMain: true
    }))
    .handleAction(types.T_MAIN_LIST.SUCCESS, (state: types.MainState, action: types.MainAction) => ({
        ...state,
        isLoadingMain: false,
        list: action.payload.results,
        hasNewMessage: action.payload.hasNewMessage
    }))
    .handleAction(types.T_MAIN_DETAIL.PENDING, (state: types.MainState, action: types.MainAction) => ({
        ...state,
        isLoadingMainDetail: true
    }))
    .handleAction(types.T_MAIN_DETAIL.SUCCESS, (state: types.MainState, action: types.MainAction) => ({
        ...state,
        isLoadingMainDetail: false,
        listDetail: action.payload,
    }))
    .handleAction(types.T_MAIN_RESERVES.PENDING, (state: types.MainState, action: types.MainAction) => ({
        ...state,
        isLoadingReserves: true
    }))
    .handleAction(types.T_MAIN_RESERVES.SUCCESS, (state: types.MainState, action: types.MainAction) => ({
        ...state,
        isLoadingReserves: false,
        listReserves: action.payload.reserved,
    }))
    .handleAction(types.T_ENTER_CONCERT.PENDING, (state: types.MainState, action: types.MainAction) => ({
        ...state,
        isEntering: true
    }))
    .handleAction(types.T_ENTER_CONCERT.SUCCESS, (state: types.MainState, action: types.MainAction) => ({
        ...state,
        isEntering: false,
        enterObj: action.payload
    }))
    .handleAction(types.T_ENTER_CONCERT.FAILURE, (state: types.MainState, action: types.MainAction) => {
        const data = action.payload.response.data;
        window.alert(data.message);
        return {
            ...state,
            isEntering: false,
            enterObj: null
        }
    })
    .handleAction(types.T_SEND_CONCERT_HEART.SUCCESS, (state: types.MainState, action: types.MainAction) => ({
        ...state,
    }))
export default mainReducer;
