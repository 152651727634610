import NoticeList from 'components/Alarm/NoticeList';
import PushList from 'components/Alarm/PushList';
import Header from 'components/Common/Header';
import { fetchMessages, TMessagesData } from 'modules/messages';
import { loadPaymentMethods } from 'modules/payment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './Style';
import ReactGA from 'react-ga';

const messagesDataSelector = ({ messages: { data } }: any): TMessagesData => data;

const Alarm = () => {
  const dispatch = useDispatch();
  const [userAgent, setuserAgent] = useState("");

  const messagesData = useSelector(messagesDataSelector);

  useEffect(() => {
    dispatch(loadPaymentMethods());
    dispatch(fetchMessages());
  }, []);

  useEffect(() => {
    ReactGA.event({ category: 'Alarm', action: 'Enter_Alarm', label: '' });

    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  if (!messagesData) return null;

  return (
    <Container paddingTop={userAgent ? 0 : 60}>
      <Header iHeaderCenter={"Alarm"} iBackLeftArrowIcon={true} iBackRightSettingIcon={false} iconClose={false} />
      <NoticeList notices={messagesData.notices} />
      <PushList pushs={messagesData.pushs} />
    </Container>
  );
}

export default Alarm;
