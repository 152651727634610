import { ActionType } from 'typesafe-actions';
import { createAsyncActionTypes } from '../../core/utils/reduxUtils';
const prefix: string = 'main';

export const T_MAIN_LIST = createAsyncActionTypes(`${prefix}/MAIN_LIST`);
export const T_MAIN_DETAIL = createAsyncActionTypes(`${prefix}/MAIN_DETAIL`);
export const T_MAIN_RESERVES = createAsyncActionTypes(`${prefix}/MAIN_RESERVES`);
export const T_ENTER_CONCERT = createAsyncActionTypes(`${prefix}/ENTER_CONCERT`);
export const T_SEND_CONCERT_HEART = createAsyncActionTypes(`${prefix}/CONCERT_HEART`);


export type MainAction = ActionType<any>;

export type TConcert = {
  uuid: string;
  amount: number;
  angle_360: number;
  createdAt: string;
  description: string;
  dynamicLink: string;
  endTime: string;
  id: number;
  isBackStageOn: boolean;
  isEnded: boolean;
  mainPosterUrl: string;
  metadata: any;
  pushStateInMinute: number;
  startTime: string;
  status: string;
  streamingUrl_2d: string;
  streamingUrl_360: string;
  thumbnails: string[];
  title: string;
  updatedAt: string;
  usersconcert: any;
  videos: string[];
  currency: string;
}

export type MainState = {
  isLoadingMain: any,
  list: any,
  lastId: any,
  selectId: any,
  cardId: any,
  isLoadingMainDetail: any,
  listDetail: null | TConcert,
  isLoadingReserves: any,
  listReserves: any,
  isEntering: boolean,
  enterObj: any,
  hasNewMessage: boolean
};
