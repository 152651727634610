import moment from 'moment';
import React, { useState, useEffect } from 'react';
import * as styled from './Style';
import { amountToPrice } from 'core/utils/numberHelper';
import { OFFLINE_TICKET_CONCERT_UUID } from 'constants/apiInfo';

type paymentHistorys = {
  amount: any;
  concertUuid: any;
  id: any;
  kind: any;
  metadata: any;
  paymentthistories: any;
  source: any;
  status: any;
  type: any;
  createdAt: any;
  currency: string;
  onClick: any;
  onClickId: any;
  onListClick: any;
  onListClickId: any;
  startTime: any;
  isEnded: boolean;
  uuid: any;
  brand: string;
  availableToRefund: boolean;
}

interface IPaymentHistoryList {
  data: any;
  offlineTicketInfo: any;
  onRefundClick: (onClickId: any) => void;
  onItemClick: (onListClickId: any) => void;
  onOfflineClick: () => void;
}

const PaymentHistoryList = ({ data, offlineTicketInfo, onRefundClick, onItemClick, onOfflineClick }: IPaymentHistoryList) => {
  const [langugeCode, setLangugeCode] = useState<string>('');

  useEffect(() => {
    const localCode = localStorage.getItem('languageCode') || '';
    setLangugeCode(localCode)
  }, []);

  const _handleRefund = (e: React.MouseEvent, refundData: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (refundData.availableToRefund) onRefundClick(refundData);
  }

  const _handleOffline = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onOfflineClick();
  }

  const _handleReceipt = (metadata: any) => {
    onItemClick(metadata)
  }

  if (data) {
    return (
      data.map((item: paymentHistorys) => {
        if (item.status === 'pending' && item.kind !== 'transfer') return null;

        let createdAt = moment(item.createdAt).format('MMM D, YYYY');
        if (langugeCode === 'ko') createdAt = moment(item.createdAt).format("YYYY년 MM월 DD일");

        var btnText;
        var cardActive;
        if (item.type === 'charge') {
          if (item.kind === 'transfer') {
            if (item.status === 'pending') {
              cardActive = true;
              btnText = '결제 대기중';
            } else {
              if (item.availableToRefund) {
                if (item.status === 'succeeded') {
                  cardActive = true;
                  btnText = 'Refund';
                  if (langugeCode == 'ko') btnText = '환불'
                }
              } else {
                cardActive = false;
                btnText = 'Refund Unavailable';
                if (langugeCode == 'ko') btnText = '환불불가'
              }
            }
          }
          else {
            if (item.availableToRefund) {
              if (item.status === 'succeeded') {
                cardActive = true;
                btnText = 'Refund';
                if (langugeCode == 'ko') btnText = '환불'
              }
            } else {
              cardActive = false;
              btnText = 'Refund Unavailable';
              if (langugeCode == 'ko') btnText = '환불불가'
            }
          }
        } else {
          if (item.status == 'succeeded') {
            cardActive = false;
            btnText = 'Refund complete';
            if (langugeCode == 'ko') btnText = '환불완료'
          } else {
            cardActive = false;
            btnText = 'Refund Failed';
            if (langugeCode == 'ko') btnText = '환불실패'
          }
        }

        // TODO: refactoring - payment history card 종류별로 컴포넌트화
        return (
          <styled.ContentCard key={item.uuid} onClick={() => _handleReceipt(item)}>
            <styled.PaymentData>
              {createdAt}
            </styled.PaymentData>
            <styled.PaymentConcert>
              {item.metadata.concertName}
            </styled.PaymentConcert>
            <styled.PaymentCardInfoWrapper>
              {item.kind === 'stripe' ?
                <styled.PaymentCardInfo>
                  {item.source === null ? (`Visa **** **** **** 4242`) : (`${item.source.brand} **** **** **** ${item.source.last4}`)}
                </styled.PaymentCardInfo>
                :
                item.kind === 'transfer' ? (
                  <styled.PaymentCardInfo>
                    무통장 입금
                  </styled.PaymentCardInfo>
                ) : (
                    <styled.PaymentCardInfo>
                      {item.kind}
                    </styled.PaymentCardInfo>
                  )
              }
              <styled.Pay>{amountToPrice(item.amount, item.currency)}</styled.Pay>
            </styled.PaymentCardInfoWrapper>
            <styled.BtnsWrapper>
              {/* {(item.concertUuid === '5faefa70-a60d-11ea-b49a-cda91b7aab1c' && offlineTicketInfo && item.type === 'charge') && <styled.BtnOffline onClick={_handleOffline}>{langugeCode === 'ko' ? '티켓 신청하기' : 'Apply the Ticket'}</styled.BtnOffline>} */}
              {(item.concertUuid === OFFLINE_TICKET_CONCERT_UUID && item.type === 'charge' && offlineTicketInfo && offlineTicketInfo.short_code) && <styled.BtnOffline onClick={_handleOffline}>{langugeCode === 'ko' ? '티켓 신청하기' : 'Apply the Ticket'}</styled.BtnOffline>}
              {btnText ? (<styled.PayStatus active={cardActive} onClick={(e: React.MouseEvent) => _handleRefund(e, item)}>{btnText}</styled.PayStatus>) : ("")}
            </styled.BtnsWrapper>
          </styled.ContentCard>
        )
      })
    )
  } else {
    return (
      <styled.ContentCard />
    )
  }
}

export default PaymentHistoryList;
