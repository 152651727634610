import React from 'react';
import { KAKAO_JS_CLIENT_ID, KAKAO_REDIRECT_URI } from 'constants/apiInfo';

const KakaoLogin = (props: any) => {
    return (
        <a style={{ textAlign: 'center' }} href={`https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_JS_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`} >
            {props.children}
        </a>
    )
}

export default KakaoLogin;