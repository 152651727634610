import qs from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { kakaoSignin } from 'modules/auth';

const KakaoSigninCallback = (location: any, match: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = qs.parse(location.location.search);

    useEffect(() => {
        const { access_token } = query;

        if (!access_token) return history.push('/signin');

        dispatch(kakaoSignin(access_token.toString(), () => history.push('/')));
    }, []);

    return (
        <div />
    )
}

export default KakaoSigninCallback;
