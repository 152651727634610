import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from 'components/Common/Header';
import imgprofiledefault from 'images/img-profile-default-s.png';
import participate from 'images/Participate.png';
import * as styled from './Style';
import Popup from '../../components/Referral/Popup';


const Referra = () => {

  const history = useHistory();
  const [rankingPopup, setRankingPopup] = useState<boolean>(false);

  useEffect(() => {

  }, []);

  const _popupRanking = useCallback(() => {
    if (rankingPopup) {
      return (
        <styled.Dim>
          <Popup onCancel={() => setRankingPopup(false)} />
        </styled.Dim>
      )
    } else {
      return (null)
    }
  }, [rankingPopup])

  return (
    <styled.Container>
      <Header iHeaderCenter={"친구초대 이벤트"} iBackLeftArrowIcon={true} iBackRightSettingIcon={false} iconClose={false} />
      <styled.Section>
        <styled.ContentWrapper>
        </styled.ContentWrapper>
      </styled.Section>
      <styled.Section>
        <styled.RankingWrapper>
          <styled.Title>참여현황</styled.Title>
          <styled.Ranking>
              <styled.RankingImg>
                <styled.Profile src={imgprofiledefault} />
              </styled.RankingImg>
              <styled.RankingEmail> po****@naver.com </styled.RankingEmail>
              <styled.RankingData> 10.125 건 </styled.RankingData>
          </styled.Ranking>
          <styled.Ranking>
              <styled.RankingImg>
                <styled.Profile src={imgprofiledefault} />
              </styled.RankingImg>
              <styled.RankingEmail> po****@naver.com </styled.RankingEmail>
              <styled.RankingData> 10.125 건 </styled.RankingData>
          </styled.Ranking>
          <styled.Ranking>
              <styled.RankingImg>
                <styled.Profile src={imgprofiledefault} />
              </styled.RankingImg>
              <styled.RankingEmail> po****@naver.com </styled.RankingEmail>
              <styled.RankingData> 10.125 건 </styled.RankingData>
          </styled.Ranking>
          <styled.Btn onClick={() => setRankingPopup(true)}>참여현황 보기</styled.Btn>
        </styled.RankingWrapper>
      </styled.Section>
      <styled.Section>
        <styled.ParticipateWrapper>
          <styled.Title>참여방법</styled.Title>
          <styled.Participate src={participate} />
        </styled.ParticipateWrapper>
      </styled.Section>
      <styled.Section>
        <styled.GoodsWrapper>
          <styled.Title>상품</styled.Title>
          <styled.Participate src={participate} />
        </styled.GoodsWrapper>
      </styled.Section>
      <styled.Section>
        <styled.NoticeWrapper>
          <styled.Title>유의사항</styled.Title>
          <styled.Notice>- 초대받은 친구가 추천인 코드 입력 후, 티켓 구매시 건 수 누적됨.</styled.Notice>
          <styled.Notice>- 추천인 코드는 횟수 상관 없이 공유 가능</styled.Notice>
          <styled.Notice>- 결제취소 시, 누적 건 수에서 제외</styled.Notice>
          <styled.Notice>- 제공되는 상품은 최대 15일 이내 지급</styled.Notice>
          <styled.Notice>- 제공되는 모바일 기프트콘은 최대 1주일 이내 지급</styled.Notice>
        </styled.NoticeWrapper>
      </styled.Section>
      {_popupRanking()}
    </styled.Container>
  )
}

export default Referra;
