import React, { useCallback, useEffect, useState, useRef, CSSProperties } from "react"
import SendBird from "sendbird"
import { SENDBIRD_APP_ID } from 'constants/apiInfo';
import { useDispatch, useSelector } from "react-redux"
import { loadChannels, enterChannel, loadPrevMessage, messageReceived, loadEmotion, sendMessage, setAdminMessage } from "../../modules/sendbird"
import { loadMe } from 'modules/auth';
import { useChannelHandler } from "hooks/sendbird"
import { Container, MessageBox, Message, NickName, ChatWrapper, EmotionContent, Emotion, ChatImg, EmotionImg, MessageAdmin, IconClose, CloseBtn } from './Style';
import stickers from './stickers.json';
import { useDetectMobile } from 'hooks/useDetectMobile';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

import iconClose from '../../images/ic-close.png';
import "moment-timezone";
import { RootState } from "modules/types";

interface ISendbirdProps {
  channelUrl: string;
  landscape_mode: boolean;
  currentMobile: boolean;
  concertUuid: string;
}

const connectSelector = ({ sendbird: { connect } }: any) => connect;
const currentChannelSelector = ({ sendbird: { currentChannel } }: any) => currentChannel;
const channelsSelector = ({ sendbird: { channels } }: any) => channels;
const enterSelector = ({ sendbird: { enterChannel } }: any) => enterChannel;
const messageSelector = ({ sendbird: { messages } }: any) => messages;
const adminMessageSelector = ({ sendbird: { adminMessage } }: any) => adminMessage;


const Chat = ({ channelUrl, landscape_mode, currentMobile, concertUuid }: ISendbirdProps) => {

  const connected = useSelector(connectSelector);
  const userData = useSelector((state: RootState) => state.auth.userData);
  const currentChannel = useSelector(currentChannelSelector);
  const channels = useSelector(channelsSelector);
  const enterChannels = useSelector(enterSelector);
  const messages = useSelector(messageSelector);
  const adminMessage = useSelector(adminMessageSelector);

  const containerEl = useRef<null | HTMLDivElement>(null);

  const [connect, setConnect] = useState(false);
  const [firstScrolled, setFirstScroll] = useState(false);
  const [emotionView, setEmotionView] = useState<boolean>(false);
  const [keyboardView, setKeyboardView] = useState(false);
  const [isMobile, isApplied] = useDetectMobile();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [adminChat, setAdminChat] = useState({});
  const { height, width } = useWindowDimensions();
  // const [flag, setFlag] = useState(true);

  const dispatch = useDispatch()


  // Compnent did mount
  useEffect(() => {

   
    setFirstScroll(true)

    dispatch(loadMe());
    dispatch(loadEmotion(concertUuid))
    return () => {

    }
  }, []);

  useEffect(() => {
    const appId = SENDBIRD_APP_ID
    const sb = SendBird.getInstance()
    if (appId && userData) {
      const _successEnter = () => { }
      const _success = (user, error) => {
        setConnect(true)
        sb.updateCurrentUserInfo(userData.nickName, '', _successEnter)
        dispatch(enterChannel(channelUrl, _successEnter))
      }

      sb.connect(userData.uuid, _success);
    }
  }, [userData, dispatch])


  useEffect(() => {
    dispatch(loadChannels())
  }, [connected, dispatch])


  useEffect(() => {
    const _success = () => { }
    if (connect) dispatch(enterChannel(channelUrl, _success))
  }, [connected, dispatch])

  useEffect(() => {
    const _success = () => { }
    dispatch(loadPrevMessage(enterChannels, _success))
  }, [enterChannels])


  useEffect(() => {
    if (isApplied) {
      if (!isMobile) {
        //    let chatRoomStyle = (document.getElementById("ChatRoom") as HTMLElement)
        //    chatRoomStyle.setAttribute("style", "height:calc(100vh - 100px) !important;");

        //    let chatChildrenRoomStyle = (document.getElementById("ChatRoomChildren") as HTMLElement)
        //    chatChildrenRoomStyle.setAttribute("style", "height:calc(100vh - 100px) !important;");
      }
    }
  }, [isMobile, isApplied]);

  useChannelHandler(
    `fenbox-channel-sub`,
    [
      {
        name: 'onMessageReceived',
        callback: (enterChannels, message) => {
          dispatch(messageReceived(enterChannels, message))
        },
      },
    ]
  )


  // useEffect(() => {
  //   if (containerEl.current) {
  //     if (firstScrolled) {
  //       containerEl.current.scroll({
  //         top: containerEl.current.scrollHeight,
  //         behavior: 'smooth',
  //       })
  //     }
  //   }
  // }, [flag]);

  const _scrollToBottom = useCallback(() => {
    if (containerEl.current) {
      if (firstScrolled) {
        console.log("scrolle")

        containerEl.current.scroll({
          top: containerEl.current.scrollHeight,
          behavior: 'smooth',
        })

      }
      else if (containerEl.current.scrollHeight) {
        // containerEl.current.scrollTop = containerEl.current.scrollHeight
        setFirstScroll(true)
      }
    }
  }, [firstScrolled])




  useEffect(() => {
    if (messages.length > 30)
      messages.splice(0, 1);
    // console.log(messages.length);
    _scrollToBottom()
  }, [messages.length])

  useEffect(() => {
    _scrollToBottom()
  }, [landscape_mode])

  const _handleScroll = useCallback((e) => {
    const currentScroll = e.target.scrollTop
    if (currentScroll === 0 && enterChannels) {
      const _success = () => { }
      dispatch(loadPrevMessage(enterChannels, _success))
    }
  }, [enterChannels])

  const _sendEmotion = (datas: object) => {
    const _success = () => { }
    if (datas) {
      dispatch(sendMessage(enterChannels, datas['keyword'], datas['url'], _success))
      setEmotionView(false)
    }
  }


  const _renderChats = useCallback(() => {
    if (!userData) return null;

    return (
      <ChatWrapper id="chatWrapper" ref={containerEl} onScroll={_handleScroll} landscape={landscape_mode} isMobile={currentMobile} isHeight={height - 270}>
        {enterChannels && messages && messages.map((message) => {
          if (message) {
            if (message.messageType == 'user' && message.sender.userId === userData.uuid) {
              return (
                <MessageBox key={`${message.messageId}`} className="chat-row-wrapper my">
                  <NickName>{message.sender ? `${message.sender.nickname}` : "-"}</NickName>
                  <Message className="chat-wrapper">
                    {message.data ? <ChatImg src={message.data} /> : message.message}
                  </Message>
                </MessageBox>
              )
            } else if (message.messageType == 'admin') {
              return ("")
            } else {
              return (
                <MessageBox key={`${message.messageId}`} className="chat-row-wrapper other">
                  <NickName>{message.sender ? `${message.sender.nickname}` : "admin"}</NickName>
                  <Message className="chat-wrapper">
                    {message.data ? <ChatImg src={message.data} /> : message.message}
                  </Message>
                </MessageBox>
              )
            }
          }
        })}
      </ChatWrapper>
    )
  }, [messages, userData, landscape_mode])

  useEffect(() => {
    dispatch(setAdminMessage(messages));
    let res = messages.filter(it => it.messageType.includes('admin'));
    let adminMessage = res.slice(-1)[0];
    if (adminMessage && adminChat['message'] !== adminMessage['message']) {
      setAdminChat(adminMessage)
      setIsAdmin(true)
    }

    // let chatWrapper = document.getElementById("chatWrapper");
    // if (chatWrapper) {
    //   if (chatWrapper.childNodes[0]) chatWrapper.removeChild(chatWrapper.childNodes[0]);
    // }

  }, [messages])


  const _renderAdminView = useCallback(() => {
    if (adminChat && isAdmin) {
      if (adminChat['message'].indexOf("[Clear]") < 0) {
        return (
          <MessageAdmin className="chat-row-wrapper admin" style={{ "display": `${isAdmin ? "block" : "none"}` }}>
            <CloseBtn onClick={() => setIsAdmin(false)}> <IconClose src={iconClose} /> </CloseBtn>
            <Message className="chat-wrapper" style={{ paddingTop: "10px" }}>
              {adminChat['message']}
            </Message>
          </MessageAdmin>
        )
      }
    }

  }, [adminChat, isAdmin])


  if (!channelUrl || channelUrl === '' || landscape_mode) {
    return null;
  }

  return (
    <Container landscape={landscape_mode} isMobile={currentMobile}>
      {_renderChats()}
      {_renderAdminView()}
    </Container>
  )
}

export default Chat
