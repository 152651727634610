import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    display:block;
    position:absolute;
    color:#000;
`;


export const Message = styled.div`
  font-size:9rem;
  font-weight:bold;
`;

export const NickName = styled.div`
  font-size:4rem;
  padding-bottom:5px;
`;

export const MessageBox = styled.div`
    padding-bottom:15px;
    width: 60%;
    word-break:break-all;


`;



export const ChatWrapper = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    height:100vh;
    background-color:#fff;
    padding-left: 17%;
`;

export const ChatImg = styled.img`
  width: 150px;
  height: 150px;
`;
