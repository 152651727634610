import { useEffect, useState } from "react"

const toLowerCase = window.navigator.userAgent.toLowerCase()

const detectUserNavigator = {
    fanbox: function () {
        return toLowerCase.match(/fanbox/i) == null ?  '' : 'app';
    },
    KaKao: function () {
        return toLowerCase.match(/kakaotalk/i) == null ? '' : 'sns';
    },
    Fackbook: function () {
        return toLowerCase.match(/facebook|facebot/i) == null ? '' : 'sns';
    },
    Twitter: function () {
        return toLowerCase.match(/twitter/i) == null ? '' : 'browser';
    },
    any: function () {
        return (detectUserNavigator.fanbox() ||  detectUserNavigator.Fackbook() || detectUserNavigator.Twitter() || detectUserNavigator.KaKao());
    }
};

export const useDetectBrowser = () => {
    const [userNavigator, setAgentNavigator] = useState<string>("notchanged");

    useEffect(() => {
        const detectChange = detectUserNavigator.any();
        setAgentNavigator(detectChange);
    }, [])

    return userNavigator;
}
