import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  border-radius:20px;
  z-index: 155;
  width: 100%;
  margin-top: -40px;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 0px;
  @media all and (orientation: landscape) {
    margin-top: 0px;
  }
`;

interface ILive{
    isMobile: boolean;
}
export const Live = styled.div<ILive>`
  color:#fff;
  font-size: 0.9rem;
  line-height: 0.2;
  display: flex;
  align-items: center;
  right: 0;
  height: 20px;
  line-height: 20px;
  margin-top: 40px;
  top: ${(props) => props.isMobile?  `155px` : `96%;`};
  @media all and (orientation: landscape) {
    top: 88%;
    margin-top: -40px;
  }
  @media only screen and (min-width: 850px) {
    top: 92%;
  }
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #ff0000;
  border-radius: 5px;
  display: block;
  margin-right: 5px;
`;

export const Like = styled.div`
  color:#fff;
  font-size: 0.8rem;
  line-height: 0.2;
  display: flex;
  align-items: center;
  margin-top: 40px;
  @media all and (orientation: landscape) {
    top: 88%;
    margin-top: -40px;
  }
`;

export const IconPeople = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;
