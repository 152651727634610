import styled from 'styled-components';
import backgrounOffUrl from '../../images/ic-check-blank.png'

interface IContainer {
    active: boolean;
}

interface ICheckbox {
    backgrounUrl: string;
}

interface ILogoBox {
    disabled: boolean;
}

export const Container = styled.div<IContainer>`
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: ${(props) => props.active? '#1a1f25' : '#1a1f25'};
    overflow-x:hidden;
    flex-direction: column;
`
export const Content = styled.div`
    width: 100%;
    height: 50vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow:hidden;
    overflow-y: hidden; overflow-x: hidden;
`;

export const BtnGroups = styled.div`
    bottom: 15%;
    display: block;
    position: absolute;
    left:92px;
    height: 80px;
    width:80%;
    transform: translate(-50%, 0);
    left: 50%;
`

export const Btns = styled.button`
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 50%;
    background-color: rgba( 255, 255, 255, 0.6 );
`

export const LoginButton = styled.div`
    width: 90%;
    height: 60px;
    margin: auto;
    bottom:15%;
    color: #fff;
    font-size: 1.1rem;
    border:1px solid rgba(255, 255, 250, 0.2);
    text-align: center;
    border-radius: 6px;
    line-height:60px;
    position: absolute;
    bottom:10%;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: 500;
`;
export const LogoImg = styled.img`
    width: 146px;
    height: 31px;
    margin: auto;
    left: 31%;
    top: 170px;
    position:absolute;
    display:block;
    transform: translate(-50%, 0);
    left: 50%;
    @media only screen and (min-width: 850px) {
      top: 230px;
    }
`;

export const LottieBox = styled.div`
    margin:auto;
    position: absolute;
    top: 15%;
    width:100%;
`;

export const LogoBox = styled.div<ILogoBox>`
    width:15%;
    float:left;
    text-align: center;
    pointer-events:${(props) => props.disabled? 'unset' : 'none'};
`;

export const LoginLine = styled.div`
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: #fff;
    line-height: 1.13;
    font-size: 1.1rem;
    margin: 9px 0px;
    &:before {
      content: "";
      flex-grow: 1;
      background-color: #ffffff;
      height: 1px;
      font-size: 0px;
      line-height: 0px;
      margin: 0px 11px;
    }
    &:after {
      content: "";
      flex-grow: 1;
      background-color: #ffffff;
      height: 1px;
      font-size: 0px;
      line-height: 0px;
      margin: 0px 11px;
    }

`;


export const Language = styled.div`
    top: 15px;
    display: block;
    position: absolute;
    width: 109px;
    height: 36px;
    background-color: rgba(0, 0, 0, 0.4);
    right:15px;
    border-radius:5px;
`

export const LangugeBox = styled.div`
    color: #fff;
    font-size: 0.9rem;
    line-height:36px;
    padding-left:15px;
`

export const Dim = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid black;
`;

export const OpenSelect = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;
    right: 10px;
`;
export const TermCheck = styled.div`
  font-size:0.9rem;
  color:#fff;
  bottom: 7%;
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 1;

`;

export const Copyright = styled.div`
    font-size:0.8rem;
    color:#fff;
    bottom: 3%;
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    opacity: 0.6;
`;

export const Checkbox = styled.div<ICheckbox>`
  width:20px;
  height:20px;
  background: url(${(props) => props.backgrounUrl? props.backgrounUrl : backgrounOffUrl}) no-repeat center/cover;
  background-image: url(${(props) => props.backgrounUrl? props.backgrounUrl : backgrounOffUrl});
  display:inline-block;
  top:5px;
  position: relative;
  margin-right: 15px;
`;

export const TermLink = styled.div`
  display:inline;
`;

export const ImgSocialSigninLogo = styled.img`
    width: 50px;
    height: 50px;
`;
