import styled from "styled-components";
import backgrounOffUrl from '../../images/ic-check-blank.png'
import Colors from '../../constants/styles/colors';


interface ITabs {
    active: boolean;
}

interface IContainer {
    paddingTop?: number;
}

export const Container = styled.div<IContainer>`
    width: 100%;
    height: 100%;
    background-color: #2A3139;
    padding-top: ${(props) => props.paddingTop ? props.paddingTop : 0}px;
    padding-bottom: 20px;
    @media only screen and (min-width: 850px) {
      height: 100vh;
    }
`;


export const Header = styled.div`
    width: 100%;
    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: relative;

    height: 60px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: #1a1f25;
`;

export const HeaderCenterText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.2rem;
    font-weight:700;
`;

export const HeaderLeftButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const HeaderRightButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const ContentContainer = styled.div`
    height: 80px;
    background-color:#2A3139;
    opacity: 0.9;
    display:block;
    position:relative;
`;

export const BtnDone = styled.button`
    width: 100%;
    height: 50px;
    color: #000000;
    background-color: #62e979;
    line-height: 50px;
    border-radius: 7px;
    text-align: center;
    margin-top: 20px;
    border: none;
    font-size:1rem;
    display:block;
    &:hover {
        background-color: #333a40;
    }
    &:disabled {
        background-color: #3d434a;
        color: white;
    }
`;


export const Title = styled.div`
  height:90px;
  padding-left:20px;
  padding-right:20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.div`
   float:left;
   font-size: 1.2rem;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.33;
   letter-spacing: normal;
   color: #ffffff;
   font-weight:600;
`;

export const Step = styled.div`
  float:right;
  color:#fff;
  opacity:0.7;
`;

export const Dim = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid black;
  z-index: 2;
`;

export const PaymentDiv = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 20px;
    margin-top: 0px;
`;

export const PaymentCheck = styled.div`
  font-size:1.1rem;
  color:#fff;
  bottom: 7%;
  display: flex;
  position: relative;
  width: 100%;
  text-align: center;
  align-items: center;
  opacity: 1;
  height:60px;
  width:100%;
  line-height: 50px;
  text-align: left;
`;

interface ICheckbox {
    backgrounUrl: string;
}

export const Checkbox = styled.div<ICheckbox>`
  width:20px;
  height:20px;
  background: url(${(props) => props.backgrounUrl ? props.backgrounUrl : backgrounOffUrl}) no-repeat center/cover;
  background-image: url(${(props) => props.backgrounUrl ? props.backgrounUrl : backgrounOffUrl});
  position: relative;
  margin-right: 20px;
`;

export const IndetedCheckboxWrapper = styled.div`
    margin-left: 9%;
    margin-top: 15px;
    display: flex;
    align-items: center;
`;

export const SavePaymentMethodText = styled.div`
    color: white;
`;

export const Paypal = styled.div`
    flex: 1;
    height: 50px;
    color: #000000;
    background-color: #fff;
    line-height: 50px;
    border-radius: 7px;
    text-align: center;
    border: none;
    font-size:1rem;
    display: flex;
    align-items: center;
    float:left;
    opacity: 0.9;
`;

export const PaypalImg = styled.img`
    width: 100px;
    height: 23px;
    margin: auto;
`;

export const CheckboxSavePaymentMethod = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 3px;
    background: transparent;
    &:checked {
        background: ${Colors.mainGreen};
    }
`
export const LabelSavePaymentMethod = styled.label`
    font-size: 12px;
    color: white;
`
export const SaveBox = styled.div`
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
    top: 50px;
    position: absolute;
    left: 9%;
`

export const PaymentBottomWrapper = styled.div`
    padding: 0 20px;
    padding-top: 0px;
`

export const TransferHelpText = styled.div`
    font-size: 1.1rem;
    color: white;
`

export const PaymentInfoText = styled.div`
    text-align: center;
    color: #ffff;
    opacity: 0.7;
    font-size: 0.8rem;
    padding-top: 10px;
`
