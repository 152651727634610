import TicketLabel from 'components/Ticket/Label';
import TransferPopup from 'components/TransferPopup';
import VodInfoPopup from 'components/VodInfoPopup';
import { callGetTransferTransactionCode } from 'core/api/transfer_transactions';
import { stepOpenPage } from 'hooks/stepOpen';
import { useDetectMobile } from 'hooks/useDetectMobile';
import iconBackLeftArrow from 'images/icon-back-leftarrow.png';
import { logout } from 'modules/auth';
import { setModal } from 'modules/common';
import { enterConcert, mainDetail, mainReserves } from 'modules/main';
import { setConcert } from 'modules/payment';
import moment from 'moment';
import "moment-timezone";
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { firebaseVideoState } from "../../modules/firebase";
import locale from './locale.json';
import * as styled from './Style';
import { CurrentDateTime } from 'core/utils/momentHelper';
import { STEP_4_VOD_OPEN } from 'constants/apiInfo';


const listDetailSelector = ({ main: { listDetail } }: any) => listDetail;
const enterSelector = ({ sendbird: { enterChannel } }: any) => enterChannel;
const videoStateSelector = ({ firebase: { videoState } }: any) => videoState;

interface RouteParams {
  uuid: string;
}

const MainDetail = () => {
  const { uuid } = useParams<RouteParams>();

  const dispatch = useDispatch();
  const history = useHistory();
  const [isMobile, isApplied] = useDetectMobile();

  const concertDetail = useSelector(listDetailSelector);
  const enterChannels = useSelector(enterSelector);
  const videoState = useSelector(videoStateSelector);

  const [language, setLanguage] = useState<any>({});
  const [checkClick, setCheckClick] = useState(false);
  const [checkprevClick, setprevCheckClick] = useState(false);

  const [generatedCode, setGeneratedCode] = useState('');
  const [transferAmount, setTransferAmount] = useState(0);
  const [showTransferPopup, setShowTransferPopup] = useState(false);
  const [showVodPopup, setShowVodPopup] = useState(false);
  const localCode = localStorage.getItem('languageCode') || '';

  useEffect(() => {
    //콘서트 페이지 진입
    // window.location.reload()

    ReactGA.event({ category: 'Main', action: 'View_Concert', label: uuid });
    dispatch(mainDetail(uuid));
    dispatch(firebaseVideoState(uuid));
    _getCode();
    // if(!window.location.hash) {
    //   window.location.href = window.location.href + '#loaded';
    //   console.log(window.location.href)
    //   window.location.reload();
    // }

    window.addEventListener('popstate', function(event) {
      if (event) {
        window.location.reload(); //reload page if it has been loaded from cache
      }
    })

  }, []);

  useEffect(() => {
    if(checkClick){
      _handleDoneClick();
    }
  }, [checkClick]);

  useEffect(() => {
    if(checkprevClick){
      _handlePreview();
    }
  }, [checkprevClick]);


  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  useEffect(() => {
    if (isApplied) {
      if (!isMobile) {
        let rootStyle = (document.getElementById("root") as HTMLElement)
        rootStyle.setAttribute("style", "transform:translate(-50%, 0) !important; left:50% !important ");
      }
    }
  }, [isMobile, isApplied]);

  const _getCode = async () => {
    const cb = (data: any) => {
      setGeneratedCode(data.code);
      setTransferAmount(data.amount);
    }
    await callGetTransferTransactionCode(uuid, cb);
  }

  const _handleDoneClick = async() => {

      console.log(checkClick);




    if (!concertDetail) return;

    if (concertDetail.uuid === 'c9716c4a-96ed-4bd5-a8af-5c1c1f146d06' || concertDetail.uuid === '972aa290-e674-11ea-94af-bd3715f76984') {
      return setShowVodPopup(true);
    }

    const _userStatus = concertDetail.usersconcert.status;

    const ttime = moment().format();
    const eTime = moment(concertDetail.endTime).format();

    if (ttime >= eTime) return history.push('/maindetail/' + concertDetail.uuid + '/preview');

    if (_userStatus == 'normal') {
      dispatch(mainReserves(concertDetail!.uuid));
      dispatch(setModal(true, `${language.successfully_booked}`, ''));

      ReactGA.event({ category: 'Main', action: 'Reserve_Concert', label: uuid });
    } else if (_userStatus == 'purchased') {
      ReactGA.event({ category: 'Main', action: 'Enter_Concert', label: uuid });

      const _success = (enterResponseData: any) => {
        if (enterResponseData) {
          if (enterResponseData.channelUrl) history.push('/maindetail/' + concertDetail.uuid + '/stage')
          else history.push('/maindetail/' + concertDetail.uuid + '/preview');
        }
      }
      const _error = (enterErrorMessage: any) => {
        _nextLogout();
      }

      dispatch(enterConcert(concertDetail.uuid, _success, _error))

    } else if (_userStatus == 'reserved') {
      if (stepOpenPage() === 'fanclub_close') {
        dispatch(setModal(true, `${language.open}`, ''));
        return
      }

      dispatch(setConcert(concertDetail));
      history.push(`/payment/${concertDetail!.uuid}`);
    } else if (_userStatus === 'pending') {
      setShowTransferPopup(true);
    }
  }

  const _prevStage = () => {
    history.push("/main");
  }

  const _nextLogout = () => {
    const _success = () => {
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token');
      window.location.href = '/';
    }
    dispatch(logout(_success));
  }

  const _handlePreview = async() => {
    ReactGA.event({ category: 'Main', action: 'Preview_Concert', label: uuid });
    history.push('/maindetail/' + concertDetail.uuid + '/preview');
  }

  if (concertDetail && concertDetail.uuid) {
    const ttime = moment().format();
    const stime = moment().add(1, 'seconds').format();
    const sTime = moment(concertDetail.startTime).format();
    const eTime = moment(concertDetail.endTime).format();

    var cardActive = false;
    var btnText = language.concert_end;
    var isPreview = false;

    if (concertDetail.isEnded) {
      cardActive = true;
      btnText = language.concert_entrance;
    } else {
      const _userStatus = concertDetail.usersconcert.status;

      if (sTime > stime) {
        cardActive = true;
        if (_userStatus === "reserved") {
          isPreview = true;
          btnText = language.concert_pay;
        } else if (_userStatus === "purchased") {
          btnText = language.concert_entrance;
        } else if (_userStatus === "pending") {
          isPreview = true;
          btnText = '결제 대기중';
        } else if (_userStatus === "normal") {
          isPreview = true;
          btnText = language.concert_reserve;
        }
      } else if (sTime <= stime && ttime <= eTime) {
        cardActive = true;
        if (_userStatus === "reserved") {
          btnText = language.concert_pay;
          isPreview = true;
        } else if (_userStatus === "purchased") {
          btnText = language.concert_entrance;
        } else if (_userStatus === "normal") {
          isPreview = true;
          btnText = language.concert_reserve;
        } else if (_userStatus === "pending") {
          isPreview = true;
          btnText = '결제 대기중';
        } else {
          cardActive = false;
          btnText = language.concert_end;
        }
      } else {
        cardActive = false;
        btnText = language.concert_end;
      }
      cardActive = false;
      btnText = language.concert_end;
    }

    return (
      <styled.Container>
        <TransferPopup show={showTransferPopup} onCancel={() => setShowTransferPopup(false)} code={generatedCode} amount={transferAmount} currency={''} />
        <VodInfoPopup show={showVodPopup} language={localCode} onCancel={() => setShowVodPopup(false)} />

        <styled.LeftButton>
          <styled.HeaderLeftButton src={iconBackLeftArrow} onClick={_prevStage} />
        </styled.LeftButton>
        <styled.ContentContainer>
          {concertDetail.thumbnails.map((t: string) => {
            return (
              <styled.MainCard key={t} >
                <styled.MainCardImg src={t} />
              </styled.MainCard>
            )
          })}
        </styled.ContentContainer>
        <TicketLabel key={concertDetail.uuid} concert={concertDetail!} language={language} isDetail={true} />
        <styled.ContentInfo>
          <styled.ContentDetail>
            <styled.DetailTitle>{concertDetail.title}</styled.DetailTitle>
            <styled.DetailText>{concertDetail.description} </styled.DetailText>
            <styled.DetailBox>
              <styled.DetailInfoRight>
                <styled.ShowInfo>
                  <styled.ShowTH>{language.concert_running_time}</styled.ShowTH>
                  <styled.ShowTD>{concertDetail.metadata.runningTime}</styled.ShowTD>
                </styled.ShowInfo>
                <styled.ShowInfo>
                  <styled.ShowTH>{language.concert_rated}</styled.ShowTH>
                  <styled.ShowTD>{concertDetail.metadata.rated}</styled.ShowTD>
                </styled.ShowInfo>
              </styled.DetailInfoRight>
              <styled.DetailInfo>
                <styled.ShowInfo>
                  <styled.ShowTH>{language.concert_cast}</styled.ShowTH>
                  <styled.ShowTD>{concertDetail.metadata.cast}</styled.ShowTD>
                </styled.ShowInfo>
                <styled.ShowInfo>
                  <styled.ShowTH>{language.concert_sponsor}</styled.ShowTH>
                  <styled.ShowTD>{concertDetail.metadata.sponsor}</styled.ShowTD>
                </styled.ShowInfo>
              </styled.DetailInfo>
            </styled.DetailBox>
          </styled.ContentDetail>
          <styled.BtnGroup>
            <styled.BtnDone isOne={isPreview} active={cardActive} onClick={()=> setCheckClick(true)} disabled={checkClick}>{btnText}</styled.BtnDone>
            {isPreview ? (
              concertDetail.usersconcert.status === 'pending' ? (
                <styled.Btnpreview active={cardActive} onClick={() => history.push(`/payment/${concertDetail!.uuid}`)}>{`결제변경`}</styled.Btnpreview>
              ) : (
                  <styled.Btnpreview active={cardActive} onClick={()=>setprevCheckClick(true)}>{language.preview}</styled.Btnpreview>
                )
            )
              :
              ""
            }
          </styled.BtnGroup>
        </styled.ContentInfo>
      </styled.Container>

    )
  } else {
    return (
      <styled.Container>
        <styled.LeftButton>
          <styled.HeaderLeftButton src={iconBackLeftArrow} onClick={_prevStage} />
        </styled.LeftButton>
      </styled.Container>
    )
  }
}

export default MainDetail;
