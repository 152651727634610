import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import locale from './locale.json';
import * as styled from './Style';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from 'modules/common';
import iconCloseBtn from 'images/ic_close_white.png';
import { APP_SCHEME } from 'constants/apiInfo';

const showModalSelector = ({ common: { showModal } }: any) => showModal;
const modalTitleSelector = ({ common: { modalTitle } }: any) => modalTitle;
const modalImgSelector = ({ common: { modalImg } }: any) => modalImg;

const InfoPopup = () => {
  const [language, setLanguage] = useState<any>({});
  const dispatch = useDispatch();

  const showModal = useSelector(showModalSelector);
  const modalTitle = useSelector(modalTitleSelector);
  const modalImg = useSelector(modalImgSelector);

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  const _handleCancel = (e: any) => {
    if (localStorage.getItem('userAgent') === 'fanbox') window.location.href = `${APP_SCHEME}://close_activity`;
    else dispatch(setModal(false, '', ''));
  }

  if (!showModal) return <div />

  if (modalImg) {
    return (
      <styled.ImgModalWrapper>
        <Modal.Dialog className="popup-dialog">
          <Modal.Header style={{ height: "auto", border: "none" }}>
            <styled.HeaderLeftButton src={iconCloseBtn} onClick={(e: any) => _handleCancel(e)} />
          </Modal.Header>
          <Modal.Body style={{ height: "auto" }}>
            <styled.Img src={modalImg} />
          </Modal.Body>
        </Modal.Dialog>
      </styled.ImgModalWrapper>
    )
  } else {
    return (
      <styled.ModalWrapper>
        <Modal.Dialog className="popup-dialog">
          <Modal.Header closeButton className="popup-header">
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="secondary" onClick={(e: any) => _handleCancel(e)} className="popup-btn">{language.confirm}</Button>
          </Modal.Footer>
        </Modal.Dialog>
      </styled.ModalWrapper>
    )
  }
}

export default InfoPopup;
