import Header from 'components/Common/Header';
import { loadMe } from 'modules/auth';
import { setModal } from 'modules/common';
import { updateNickName } from 'modules/mypage';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import iconProfileEdit from '../../images/ic-edit.png';
import iconProfile from '../../images/img-profile-default.png';
import locale from './locale.json';
import { BtnDone, Container, ContentContainer, NickName, NickNameLabel, NickNameText, Profile, ProfileEditImg, ProfileImg } from './Style';
import { RootState } from 'modules/types';


const modalSelector = ({ common: { showModal } }: any) => showModal;

const MyProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [language, setLanguage] = useState<any>({});

  const userData = useSelector((state: RootState) => state.auth.userData);
  const showModal = useSelector(modalSelector);

  const [isActive, setIsActive] = useState(true);
  const [isModal, setIsModal] = useState(true);
  const [userAgent, setuserAgent] = useState("");
  const [newNickName, setNewNickName] = useState("");


  useEffect(() => {
    dispatch(loadMe());
    return () => { }
  }, []);

  useEffect(() => {
    if (!isModal) history.goBack();

  }, [showModal]);

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  useEffect(() => {
    if (userData) setNewNickName(userData.nickName)
  }, [userData]);

  const _prevStage = () => {
    history.push("/mypage");
  }

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    const _success = () => {

    }
    if (newNickName) {
      dispatch(updateNickName(newNickName, _success))
      setNewNickName("")
    }
  }, [newNickName])

  const _updateProfile = () => {
    setIsActive(false)
    const _success = () => {
      dispatch(setModal(true, language.edit_updated, ''))
      setIsModal(false)
    }
    if (newNickName) {
      dispatch(updateNickName(newNickName, _success))
    }
  }
  return (
    <Container>
      <Header iHeaderCenter={"Profile"} iBackLeftArrowIcon={true} iBackRightSettingIcon={false} iconClose={false} />
      <Profile marginTop={userAgent ? 0 : 130}>
        <ProfileImg src={userData ? userData.profileUrl : iconProfile} onClick={_prevStage} />
        <ProfileEditImg src={iconProfileEdit} onClick={_prevStage} />
      </Profile>
      <ContentContainer>
        <NickName>
          <NickNameLabel>{language.nickname}</NickNameLabel>
          <NickNameText placeholder="닉네임을 입력해주세요." value={newNickName || ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewNickName(e.target.value)} />
        </NickName>
      </ContentContainer>
      <ContentContainer>
        <BtnDone onClick={_updateProfile} active={isActive}>{language.save}</BtnDone>
      </ContentContainer>
    </Container>
  )
}

export default MyProfile;
