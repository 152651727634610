import styled from "styled-components";

export const Dimmed = styled.div`
  position: fixed;
  background: rgba(0,0,0,0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 200;
`;

export const ModalWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  z-index: 500;
  position: fixed;
  > div.modal-dialog {
    padding:20px;
    width: calc(100% - 40px);
    max-width: 460px;
    position: fixed;
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    background-color: rgba(33, 38, 44, 1);
  }
  > div.modal-content{
    height: 100%;
  }

  > div.modal-header{
    border: 1px solid red !important;
    height: 65%;
  }
  > div.modal-title{
    font-size: 1.2rem;
  }
`;

export const ModalBody = styled.div`
  text-align: center;
  height: auto;
  font-size: 12px;
`;

export const ModalTitle = styled.div`
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 18px;
`;

export const TransferInfo = styled.div`
  text-align: center;
  color: #ffffff;
  line-height: 1.67;
  margin-bottom: 18px;
`;
export const ColoredSpan = styled.span`
  color: #62e979;
`;

export const AccountInfoTitle = styled.div`
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
`;

export const AccountInfo = styled.div`
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.67;
  margin-bottom: 18px;
`;

export const Instrunctions = styled.div`
  text-align: center;
  color: #ffffff;
  line-height: 1.67;
  margin-bottom: 20px;
`;

export const InfoWrapper = styled.div`
  font-size: 12px;
  margin-bottom: 20px;
  color: white;
  text-align: left;
`;

export const Title = styled.div`
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  font-weight: 400;
  opacity: 0.75;
  line-height: 1.67;
`;

export const CodeWrapper = styled.div`
  width: 100%;
  height: 50px;
  background: rgba(255,255,255,0.05);
  border-radisu: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-size: 12px;
`;

export const CodeText = styled.div`
  color: white;
`;

export const BtnCopy = styled.div`
  color: #62e979;
`;