import styled from "styled-components";
import backgrounOffUrl from '../../images/ic-check-blank.png'

interface ICheckbox {
    backgrounUrl: string;
}

export const BtnLineSignin = styled.a`
    width: 50px;
    height: 50px;
    background-color: rgba( 255,255,255,0.6 );
    border-radius: 25px;
    display: block;
    margin: 0 auto;
    position: relative;
`;
export const ImgLineIcon = styled.img`
    position: absolute;
    width: 30px;
    height: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;


export const ModalContent = styled.div`
  position: relative;
  font-size: 0.9rem;
  width: 100%;
  font-weight: 500;
  line-height: 2.2;
  padding-left: 100px;
  opacity: 0.8;
  height: 20px;
  overflow-y: scroll;
`;

export const ModalPrice = styled.div`
  position: relative;
  font-size: 1.0rem;
  width: 100%;
  font-weight: 500;
  line-height: 2.2;
  padding-left: 100px;
  text-align:right;
  padding-right:5px;
  color: #62e979;
  font-weight: 600;
`;


export const StyledDiv = styled.div`
    > div.modal-dialog {
        height:180px !important;
        backdrop-filter: blur(30px);
        background-color: rgba(0,0,0,0.6);
        z-index:3;
        width: 90%;
        padding: 10px;
      }
      > div.modal-content{
        height: 100%;
      }

      > div.modal-header{
        border: 1px solid red !important;
        height: 65%;
      }
      > div.modal-title{
        font-size: 1.2rem;
      }
      > button.btn-ok{
         background-color: #62e979 !important;
         color:#000;
      }
`;

export const ModalInfo = styled.span`
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    font-size: 0.9rem;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:2px;
    padding-left: 15px;
    display:block;
    opacity: 0.8;
`;

export const ModalTitle = styled.div`
  position: relative;
  font-size: 1.0rem;
  width: 100%;
  font-weight: 500;
  line-height: 2.2;
  padding-left: 100px;
  font-weight: 500;

`;

export const LanguageCheck = styled.div`
  color:#fff;
  font-size:0.9375rem;
  position: relative;
  width: 100%;
  margin:auto;
  display:inline-block;
  padding-left: 15px;
  padding-right: 15px;
  height:50px;
  line-height:50px;
`;

export const CheckText = styled.div`
  width:50px;
  height:20px;
  float:left;
`;


export const Checkbox = styled.div<ICheckbox>`
  width:20px;
  height:20px;
  float:right;
  background: url(${(props) => props.backgrounUrl? props.backgrounUrl : backgrounOffUrl}) no-repeat center/cover;
  background-image: url(${(props) => props.backgrounUrl? props.backgrounUrl : backgrounOffUrl});
  margin-top: 10px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: #ffffff;
`;
