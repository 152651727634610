import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  z-index: 500;
  position: fixed;
  > div.modal-dialog {
    height:180px !important;
    background-color: #21262c;
    z-index: 500;
  }
  > div.modal-content{
    height: 100%;
  }

  > div.modal-header{
    border: 1px solid red !important;
    height: 65%;
  }
  > div.modal-title{
    font-size: 1.2rem;
  }
`;


export const ImgModalWrapper = styled.div`
   display: block;
   width: 100%;
   height: 100vh;
   z-index: 500;
   position: fixed;
   > div.modal-dialog {
     background-color: #000;
     z-index: 500;
     height: 100vh;
     width: 100%;
     padding:0px;
   }
   > div.modal-content{
     height: 90vh;
   }

   > div.modal-header{
     border: 0px solid !important;
     height: auto !important;
   }
 `;


export const HeaderLeftButton = styled.img`
     width: 50px;
     height: 50px;
     float:left;
`;

export const Img = styled.img`
   max-width: 100%;
   max-height: 100%;
   height: auto;
   margin: 0;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   @media all and (orientation: landscape) {
     width: auto;
     height: auto;
   }
   @media only screen and (min-width: 850px) {
     max-width: 85%;
     max-height: 85%;
   }
`;
