import { TPush } from 'modules/messages';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as styled from './Style';

interface IPushListProps {
  pushs: TPush[];
}

const PushList = ({ pushs }: IPushListProps) => {
  const [langugeCode, setLangugeCode] = useState<string>('');

  const localCode = localStorage.getItem('languageCode') || '';

  useEffect(() => {
    setLangugeCode(localCode)
  }, [localCode])

  if (pushs.length === 0) return (<styled.NoPushsContainer> { langugeCode == 'ko'? '알림이 없습니다.' : 'You have no notifications.' }  </styled.NoPushsContainer>);

  return (
    <>
      {pushs.map((item: TPush) => {
        const diffTime = moment().diff(moment(item.createdAt))
        var duration = moment.duration(diffTime);
        var days = duration.days(),
          hrs = duration.hours(),
          mins = duration.minutes(),
          secs = duration.seconds();

        let term = '';
        if (days > 0) term = days + '일전';
        else if (hrs > 1) term = hrs + '시간전';
        else if (mins > 1) term = mins + '분전';
        else term = secs + '초전';

        if (item.title) {
          return (
            <styled.PushListItemWrapper key={item.uuid}>
              <styled.Title>{item.title}</styled.Title>
              <styled.Time>{term}</styled.Time>
            </styled.PushListItemWrapper>
          )
        }
      })}
    </>
  )
}

export default PushList;
