import React, { useEffect, useState } from 'react';
import locale from './locale.json';
import * as styled from './Style';
import { calculateTimeLeft } from 'hooks/timer';
import moment from 'moment';
import { forceTwoDigits } from 'core/utils/numberHelper';
import { useDispatch } from 'react-redux';
import { requestSMSVerificationCode, requestEmailVerificationCode, submitSMSVerificationCode, submitEmailVerificationCode, setIsVerified } from 'modules/verifications';
import PhoneSelect from './PhoneSelect';

interface IPromotionVerificationProps {
    concertUuid: string;
    isVerified: boolean;
}

const PromotionVerification = ({ concertUuid, isVerified }: IPromotionVerificationProps) => {
    const [language, setLanguage] = useState<any>({});
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [verificationType, setVerificationType] = useState('sms');
    const [verificationCode, setVerificationCode] = useState('');
    const [promotionCode, setPromotionCode] = useState('');
    const [isSent, setIsSent] = useState(false);
    const [showPromotionCodeTitle, setShowPromotionCodeTitle] = useState(true);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(null));
    const [codeExpireAt, setCodeExpireAt] = useState('');
    const [phoneCountryCode, setPhoneCountryCode] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setIsVerified(false));
        }
    }, []);

    useEffect(() => {
        if (codeExpireAt === '') return;

        let intervalId = 0;
        intervalId = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(codeExpireAt));
        }, 1000);

        return () => {
            clearInterval(intervalId);
        }
    });

    useEffect(() => {
        if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
        else setLanguage(locale.en)
    }, [language])

    const _handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) setShowPromotionCodeTitle(true);
        else setShowPromotionCodeTitle(false);
    }

    const _handleSend = () => {
        const success = () => {
            const threeMinutesAfter = moment().add(3, 'minutes').add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss');
            setIsSent(true);
            setCodeExpireAt(threeMinutesAfter);
        }
        if (verificationType === 'sms') dispatch(requestSMSVerificationCode(concertUuid, phoneCountryCode, phoneNumber, promotionCode, success));
        else if (verificationType === 'email') dispatch(requestEmailVerificationCode(concertUuid, email, promotionCode, success));
    }

    const _handleVerify = () => {
        const success = () => { };
        const fail = () => alert('wrong verification code');

        if (verificationType === 'sms') dispatch(submitSMSVerificationCode(concertUuid, verificationCode, success, fail));
        else if (verificationType === 'email') dispatch(submitEmailVerificationCode(concertUuid, verificationCode, success, fail));
    }

    const _handleChangeVerificationType = (passedVeirficationType: string) => {
        setIsSent(false);
        setVerificationType(passedVeirficationType);
        setTimeLeft(calculateTimeLeft(null));
    }

    const timerComponents: JSX.Element[] = [];

    if (timeLeft['seconds'] && timeLeft['minutes']) {
        Object.keys(timeLeft).forEach(interval => {
            if ((interval === 'minutes' || interval === 'seconds')) {
                timerComponents.push(
                    <span key={'countdown-' + interval}>
                        {forceTwoDigits(Number(timeLeft[interval]))}
                    </span>
                );
            }
            if (interval === 'minutes') {
                timerComponents.push(
                    <span key={`colon-${interval}`}>:</span>
                );
            }
        })
    }


    return (
        <styled.Container>
            <styled.CustomInputWrapperWithLine>
                <styled.CustomInputLeftWrapper>
                    {showPromotionCodeTitle && <styled.CustomInputTitle>{language.TypePromotionCode}</styled.CustomInputTitle>}
                    {/* <styled.InputCustom placeholder="할인 코드를 입력해주세요." onFocus={() => setShowPromotionCodeTitle(false)} onBlur={_handleBlur} value={promotionCode} onChange={(e) => setPromotionCode(e.target.value)} /> */}
                    <styled.InputCustom placeholder={language.PromotionCodeInputPlaceholder} value={promotionCode} onChange={(e) => setPromotionCode(e.target.value)} />
                </styled.CustomInputLeftWrapper>
                {/* <styled.ButtonCustomInput>확인</styled.ButtonCustomInput> */}
            </styled.CustomInputWrapperWithLine>
            <styled.VerificationSelectWraper>
                <styled.VerificationSelectTitle>
                    {language.SelectVerificationType}
                </styled.VerificationSelectTitle>
                <styled.RadiosWrapper>
                    <styled.RadioWrapper onClick={() => _handleChangeVerificationType('sms')}>
                        <styled.Radio isOn={verificationType === 'sms'} />
                        <styled.Label>{language.SmsVerificationType}</styled.Label>
                    </styled.RadioWrapper>
                    <styled.RadioWrapper onClick={() => _handleChangeVerificationType('email')}>
                        <styled.Radio isOn={verificationType === 'email'} />
                        <styled.Label>{language.EmailVerificationType}</styled.Label>
                    </styled.RadioWrapper>
                </styled.RadiosWrapper>
                {verificationType === 'sms' ? (
                    <>
                        <styled.CustomInputWrapper style={isSent ? { marginBottom: '10px' } : { marginBottom: '0px' }}>
                            <styled.CustomInputSmallTitle>{language.SmsVerificationType}</styled.CustomInputSmallTitle>
                            <styled.CustomInputContentWrapper>
                                <PhoneSelect onSelect={(data) => setPhoneCountryCode(data)} />
                                <styled.InputCustom value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder={language.SmsInputPlaceholder} />
                                <styled.ButtonCustomInput onClick={_handleSend}>{language.Confirm}</styled.ButtonCustomInput>
                            </styled.CustomInputContentWrapper>
                        </styled.CustomInputWrapper>
                        {isSent &&
                            <styled.CustomInputWrapper>
                                <styled.CustomInputSmallTitle>
                                    {language.VerificationCode}
                                    <styled.timerWrapper>
                                        {timerComponents.length ? timerComponents : '03:00'}
                                    </styled.timerWrapper>
                                </styled.CustomInputSmallTitle>
                                {!isVerified ? (
                                    <styled.CustomInputContentWrapper>
                                        <styled.InputCustom placeholder={language.VerificationCodePlaceholder} value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
                                        <styled.ButtonCustomInput onClick={_handleSend}>{language.Resend}</styled.ButtonCustomInput>
                                        <styled.ButtonCustomInput onClick={_handleVerify}>{language.Confirm}</styled.ButtonCustomInput>
                                    </styled.CustomInputContentWrapper>
                                ) : (
                                        <styled.TextSuccess>{language.VerificationSuccess}</styled.TextSuccess>
                                    )}
                            </styled.CustomInputWrapper>
                        }
                    </>
                ) : (
                        <>
                            <styled.CustomInputWrapper style={isSent ? { marginBottom: '10px' } : { marginBottom: '0px' }}>
                                <styled.CustomInputSmallTitle>{language.EmailVerificationType}</styled.CustomInputSmallTitle>
                                <styled.CustomInputContentWrapper>
                                    <styled.InputCustom value={email} onChange={(e) => setEmail(e.target.value)} placeholder={language.EmailInputPlaceholder} />
                                    <styled.ButtonCustomInput onClick={_handleSend}>{language.Send}</styled.ButtonCustomInput>
                                </styled.CustomInputContentWrapper>
                            </styled.CustomInputWrapper>
                            {isSent &&
                                <styled.CustomInputWrapper>
                                    <styled.CustomInputSmallTitle>
                                        {language.VerificationCode}
                                        <styled.timerWrapper>
                                            {timerComponents.length ? timerComponents : '03:00'}
                                        </styled.timerWrapper>
                                    </styled.CustomInputSmallTitle>
                                    {!isVerified ? (
                                        <styled.CustomInputContentWrapper>
                                            <styled.InputCustom placeholder="인증번호 6자리를 입력해주세요." value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
                                            <styled.ButtonCustomInput onClick={_handleSend}>{language.Resend}</styled.ButtonCustomInput>
                                            <styled.ButtonCustomInput onClick={_handleVerify}>{language.Confirm}</styled.ButtonCustomInput>
                                        </styled.CustomInputContentWrapper>
                                    ) : (
                                            <styled.TextSuccess>{language.VerificationSuccess}</styled.TextSuccess>
                                        )}
                                </styled.CustomInputWrapper>
                            }
                        </>
                    )}
            </styled.VerificationSelectWraper>
        </styled.Container >
    )
}

export default PromotionVerification;
