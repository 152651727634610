import SendBird from "sendbird";
import { API_URL } from '../../constants/apiInfo';
import { getApiAxiosWithToken } from '../utils/api';
import axios from 'axios';

interface connectMethodParams {
  appId: any;
  userId: any;
}

export async function callConnectMethod({
  appId,
  userId
}: connectMethodParams) {
  const sb = SendBird.getInstance()
  const response = await sb.connect(userId, appId);

  return response
}


interface enterMethodParams {
  channelUrl: string;
}

export async function callEnter({
  channelUrl
}: enterMethodParams) {

  const sb = SendBird.getInstance()
  return new Promise(function(resolve, reject) {
    sb.OpenChannel.getChannel(channelUrl, (channel, error) => {
      channel.enter((response, error) => {
        if (error) {
          reject(error)
        }
      })
      resolve(channel);
      return
    });
  });
}



export async function callLoadChannels() {
  const sb = SendBird.getInstance()

  return new Promise(function(resolve, reject) {
    const channelListQuery = sb.OpenChannel.createOpenChannelListQuery();
    channelListQuery.limit = 15;
    if (channelListQuery.hasNext) {
      channelListQuery.next((channels, error) => {

        return resolve(channels)
      });
    }
  });
}


interface loadPrevMessageMethodParams {
  channel: any;
}

export async function callLoadPrevMessage({
  channel
}: loadPrevMessageMethodParams) {
  const sb = SendBird.getInstance()

  return new Promise(function(resolve, reject) {
    const messageListQuery = channel.createPreviousMessageListQuery();
    messageListQuery.limit = 30

    messageListQuery.load((messageList, error) => {
      return resolve(messageList)
    });

  });
}


interface loadSendMessageMethodParams {
  channel: any;
  message: string;
  emotionUrl: string;
}

export async function callSendMessage({
  channel,
  message,
  emotionUrl
}: loadSendMessageMethodParams) {
  const sb = SendBird.getInstance()
  const params = new sb.UserMessageParams()
  params.message = message
  params.data = emotionUrl;

  return new Promise(function(resolve, reject) {
    channel.sendUserMessage(params, (message, error) => {
      return resolve(message)
    });
  });
}

interface loadEmotionParams {
  concertUuid: string;
}

export async function callLoadEmotion({
  concertUuid
}: loadEmotionParams){
  const response = await axios.get(`${API_URL}/api/v1/concerts/${concertUuid}/stickers`);
  return response;
}
