import TicketOff from 'images/img-bg-ticket-off.png';
import Line from 'images/img-main-ui.png';
import styled from "styled-components";

interface IHeaderCenterText{
  paddingRight: number;
}


export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    height: 60px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: #1a1f25;
`;

export const HeaderCenterText = styled.div<IHeaderCenterText>`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.1rem;
    font-weight:700;
    padding-right: ${(props) => props.paddingRight ? props.paddingRight : 0}px;
`;

export const HeaderLeftButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const HeaderRightButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const HeaderCloseButton = styled.img`
    width: 50px;
    height: 50px;
    display:block;
    position: absolute;
    right:25px;
`;

export const HeaderNull = styled.div`
    margin-top:0px;
`;