import { CurrentDateTime } from 'core/utils/momentHelper';
import { STEP_1_LANGING_OPEN, STEP_2_FANCLUB_OPEN, STEP_2_FANCLUB_CLOSE, STEP_3_ALL_OPEN } from 'constants/apiInfo';

export const stepOpenPage = () => {
    let stepOpen = "";
    if(  CurrentDateTime("DateTime") > STEP_1_LANGING_OPEN && CurrentDateTime("DateTime") < STEP_2_FANCLUB_OPEN ){
      stepOpen = "landing"
    }else if(  STEP_2_FANCLUB_OPEN < CurrentDateTime("DateTime") && CurrentDateTime("DateTime") < STEP_2_FANCLUB_CLOSE ){
      stepOpen = "fanclub"
    }else if(  STEP_2_FANCLUB_CLOSE < CurrentDateTime("DateTime") && CurrentDateTime("DateTime") < STEP_3_ALL_OPEN){
      stepOpen = "fanclub_close"
    }else if(  STEP_3_ALL_OPEN < CurrentDateTime("DateTime") ){
      stepOpen = "all"
    }
    return stepOpen;
};
