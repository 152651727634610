import { createReducer } from 'typesafe-actions';
import * as types from './types';

const initialState: types.FirebaseState = {
    isLoadingFirebase: false,
    videoState: {},
    like:0,
};


const firebaseReducer = createReducer(initialState)
    .handleAction(types.T_FIREBASE_VIDEO_STATE.PENDING, (state: types.FirebaseState, action: types.FirebaseAction) => ({
        ...state,
        isLoadingFirebase: true
    }))
    .handleAction(types.T_FIREBASE_VIDEO_STATE.SUCCESS, (state: types.FirebaseState, action: types.FirebaseAction) => {
        return {
            ...state,
            isLoadingFirebase: false,
            videoState: action.payload
        }
    })


export default firebaseReducer;
