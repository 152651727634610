import React, { useEffect, useState } from 'react';
import * as styled from './Style';
import qs from 'query-string';
import ReactGA from 'react-ga';

import imgSymbol from 'images/img-symbol.png';
import imgLogo from 'images/img-text.png';
import { useDetectBrowser } from 'hooks/useDetectBrowser';
import { useHistory } from 'react-router-dom';
import { SNS_IOS_KEY, SNS_ANDROID_KEY } from 'constants/apiInfo';

const SNS = (location: any) => {
  const userNavigator = useDetectBrowser();
  const history = useHistory();

  const query = qs.parse(location.location.search);

  useEffect(() => {
    const toLowerCase = window.navigator.userAgent.toLowerCase()

    if (userNavigator === 'notchanged') {

    } else {
      const { referral } = query;
      const _referral = referral?.toString();
      ReactGA.event({ category: 'Referral', action: 'Web', label: _referral });
      if (userNavigator == 'sns') {
        if (toLowerCase.match(/iphone|ipad|ipod/i)) window.location.href = "ftp://bridge.fanbox.live/" + `${SNS_IOS_KEY}`;
        if (toLowerCase.match(/android/i)) window.location.href = 'intent://' + `${SNS_ANDROID_KEY}` + '#Intent;scheme=http;package=com.android.chrome;end';
      } else {
        history.push("/");
      }
    }
  }, [userNavigator]);

  return (
    <styled.Container>
      <styled.Content>
        <styled.Symbol src={imgSymbol} />
        <styled.Logo src={imgLogo} />
        <styled.Text>
          <a href={"https://fanbox.live"}>https://fanbox.live</a>
        </styled.Text>
      </styled.Content>
    </styled.Container>
  );
}

export default SNS;
