import { forceTwoDigits, amountToPrice } from 'core/utils/numberHelper';
import { calculateTimeLeft } from 'hooks/timer';
import TicketOff from 'images/img-bg-ticket-off.png';
import Ticket from 'images/img-bg-ticket-on.png';
import TicketOn from 'images/img-bg-ticket.png';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import * as styled from './Style';
import { useDispatch } from 'react-redux';
import { mainDetail, TConcert } from 'modules/main';

interface ITicketLabelProps {
    concert: TConcert;
    language: any;
    isDetail?: boolean;
}

const TicketLabel = ({ concert, language, isDetail = false }: ITicketLabelProps) => {
    const dispatch = useDispatch();
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(concert.startTime));
    const today = moment().format();

    const term = moment(concert.startTime).diff(today);

    const ttime = moment().format();
    const sTime = moment(concert.startTime).format();
    const eTime = moment(concert.endTime).format();
    const amount = amountToPrice(concert.amount, concert.currency);

    let concertStatus;
    let cardActive;
    let cardDay;
    let cardUrl = TicketOff;

    useEffect(() => {
        let intervalId = 0;
        intervalId = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(concert.startTime));
        }, 1000);

        return () => {
            clearInterval(intervalId);
        }
    });

    useEffect(() => {
        if (!isDetail) return;

        if (timeLeft.hours !== undefined && timeLeft.minutes !== undefined && timeLeft.seconds !== undefined) {
            if (timeLeft.hours < 1 && timeLeft.minutes < 1 && timeLeft.seconds < 1) {
                const _userStatus = concert.usersconcert.status;
                if (_userStatus !== 'purchased') dispatch(mainDetail(concert.uuid));
            }
        }
    }, [timeLeft]);


    if (concert.isEnded) {
        concertStatus = language.concert_end;
        cardActive = false;
        cardDay = language.concert_end;
    } else {
        if (sTime > ttime) {
            concertStatus = language.concert_preview;
            cardActive = true;
            cardDay = 'D-' + moment(term).subtract(9, 'hours').format('DD');
            cardUrl = TicketOn;
        } else if (sTime <= ttime && ttime <= eTime) {
            concertStatus = language.concert_start;
            cardActive = true;
            cardDay = language.concert_start;
            cardUrl = Ticket;
        } else {
            concertStatus = language.concert_end;
            cardActive = false;
            cardDay = language.concert_end;
        }
    }

    const timerComponents: JSX.Element[] = [];

    Object.keys(timeLeft).forEach(interval => {
        timerComponents.push(
            <styled.TimeValue key={concert.uuid + '-' + interval}>
                {forceTwoDigits(timeLeft[interval])}
            </styled.TimeValue>
        );
        if (interval !== 'seconds') {
            timerComponents.push(
                <styled.TimeValueColon key={`colon-${interval}`}>:</styled.TimeValueColon>
            );
        }
    })

    return (
        <styled.TicketLabelWrapper isDetail={isDetail} ticketUrl={cardUrl}>
            {moment('2020-08-28T23:59:59+09:00') < moment()? (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white', fontWeight: 'bold', textAlign: 'center' }}>
                    {timerComponents.length ?
                        <>
                            <styled.TimerLabelWrapper>
                                <styled.TimeLabel key="label-days">Days</styled.TimeLabel>
                                <styled.TimeValueColon></styled.TimeValueColon>
                                <styled.TimeLabel key="label-hours">Hours</styled.TimeLabel>
                                <styled.TimeValueColon></styled.TimeValueColon>
                                <styled.TimeLabel key="label-mins">Mins</styled.TimeLabel>
                                <styled.TimeValueColon></styled.TimeValueColon>
                                <styled.TimeLabel key="label-secs">Secs</styled.TimeLabel>
                            </styled.TimerLabelWrapper>
                            <styled.TimerValueWrapper>
                                {timerComponents}
                            </styled.TimerValueWrapper>
                        </>
                        :
                        <styled.CardDay>{cardDay}</styled.CardDay>}
                </div>
            ) : (
                    <>
                        <styled.CardTime active={cardActive}>
                            {timerComponents.length ?
                                <>
                                    <styled.TimerLabelWrapper>
                                        <styled.TimeLabel key="label-days">Days</styled.TimeLabel>
                                        <styled.TimeValueColon></styled.TimeValueColon>
                                        <styled.TimeLabel key="label-hours">Hours</styled.TimeLabel>
                                        <styled.TimeValueColon></styled.TimeValueColon>
                                        <styled.TimeLabel key="label-mins">Mins</styled.TimeLabel>
                                        <styled.TimeValueColon></styled.TimeValueColon>
                                        <styled.TimeLabel key="label-secs">Secs</styled.TimeLabel>
                                    </styled.TimerLabelWrapper>
                                    <styled.TimerValueWrapper>
                                        {timerComponents}
                                    </styled.TimerValueWrapper>
                                </>
                                :
                                <styled.CardDay>{cardDay}</styled.CardDay>}
                        </styled.CardTime>
                        <styled.CardNumber active={cardActive}>{amount}</styled.CardNumber>
                    </>
                )}
        </styled.TicketLabelWrapper >
    )
}

export default TicketLabel;
