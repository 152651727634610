import { ActionType } from 'typesafe-actions';
import { createAsyncActionTypes } from '../../core/utils/reduxUtils';
const prefix: string = 'verifications';

export const T_REQUEST_CONCERT_SMS_VERIFICATION_CODE = createAsyncActionTypes(`${prefix}/REQUEST_CONCERT_SMS_VERIFICATION_CODE`);
export const T_SUBMIT_CONCERT_SMS_VERIFICATION_CODE = createAsyncActionTypes(`${prefix}/SUBMIT_CONCERT_SMS_VERIFICATION_CODE`);
export const T_REQUEST_CONCERT_EMAIL_VERIFICATION_CODE = createAsyncActionTypes(`${prefix}/REQUEST_CONCERT_EMAIL_VERIFICATION_CODE`);
export const T_SUBMIT_CONCERT_EMAIL_VERIFICATION_CODE = createAsyncActionTypes(`${prefix}/SUBMIT_CONCERT_EMAIL_VERIFICATION_CODE`);
export const T_SET_IS_VERIFIED = `${prefix}/SET_IS_VERIFIED`;

export type VerificationsAction = ActionType<any>;

export type VerificationsState = {
  isVerified: boolean
};
