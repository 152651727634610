import React from 'react';
import { Container, Title, SubTitle, ContentDetail, ContentTitle, ContentDetailIndex, ContentDetailWrapper } from './Style';
import opensource from './opensource.json';

const OpenSource = () => {

    return (
        <Container>
            {/* <Title>{opensource.title}</Title> */}
            <SubTitle style={{ marginTop: '30px' }}>
                {opensource.subtitle}
            </SubTitle>
            {opensource.content.map((c, c_idx) => {
                return (
                    <>
                        <ContentTitle>{`${c_idx + 1}. ${c.title}`}</ContentTitle>
                        {c.contentDetail.map((d, d_idx) => {
                            return (
                                <ContentDetailWrapper>
                                    <ContentDetailIndex>
                                        {`${c_idx + 1}.${d_idx + 1}`}
                                    </ContentDetailIndex>
                                    <ContentDetail>
                                        {`${d}`}
                                    </ContentDetail>
                                </ContentDetailWrapper>
                            )
                        })}
                    </>
                )
            })}
        </Container>
    )
}

export default OpenSource;
