import styled from "styled-components";

export const Container = styled.div`
  margin-top:2%;
  border-radius:20px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  bottom: 0;
  z-index: 120;
  width: 100%;
  bottom: 50px;
  @media only screen and (min-width: 850px) {
    width: 70%;
    bottom:0;
    height: 0;
  }
`;

interface ILive{
    isMobile: boolean;
}
export const Live = styled.div<ILive>`
  color:#fff;
  font-size: 0.9rem;
  line-height: 0.2;
  display: flex;
  align-items: center;
  right: 0;
  height: 20px;
  line-height: 20px;
  top: ${(props) => props.isMobile?  `155px` : `96%;`};

  @media all and (orientation: landscape) {
    top: 88%;
  }
  @media only screen and (min-width: 850px) {
    top: 92%;
    margin-top: -40px;
  }
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #ff0000;
  border-radius: 5px;
  display: block;
  margin-right: 5px;
`;

export const Like = styled.div`
  color:#fff;
  font-size: 0.8rem;
  line-height: 0.2;
  text-align: right;
  bottom:0;
  display: flex;
  align-items: center;
  @media all and (orientation: landscape) {
    right: 0px;
  }
  @media only screen and (min-width: 850px) {
    text-align: right;
    position: absolute;
    bottom: 10px;
    right: 30px;
    
    display: none;
  }
`;

export const IconPeople = styled.img`
  width: 0px;
  height: 20px;
  margin-right: 5px;
`;

export const LikeWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (min-width: 850px) {
    margin-top: -40px;
  }
`;
