import TicketLabel from 'components/Ticket/Label';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import locale from './locale.json';
import * as styled from './Style';
import { TConcert } from 'modules/main';


interface ICardProps {
  concert: TConcert;
}

const ListCard = ({ concert }: ICardProps) => {
  const history = useHistory();
  const [language, setLanguage] = useState<any>({});

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  const _navigateToDetail = (concertUuid: string) => {
    history.push(`/maindetail/${concertUuid}`);
  }

  return (
    <styled.ListCardWrapper id={concert.id.toString()} key={concert.id} onClick={() => _navigateToDetail(concert.uuid)} thumbnailsUrl={concert.mainPosterUrl}>
      <TicketLabel key={`ticket-label-${concert.id}`} concert={concert} language={language} />
    </styled.ListCardWrapper>
  )
}

export default ListCard;
