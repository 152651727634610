import { API_URL } from '../../constants/apiInfo';
import { getApiAxiosWithToken } from '../utils/api';

export async function callCreateTransferTransactions(concertUuid: string, ticketUuid: string, cb: (code: string) => void, fail: (code: number) => void) {
  try {
    const _axios = getApiAxiosWithToken();
    const data = {
      concertUuid: concertUuid,
      ticketUuid: ticketUuid
    }
    const response = await _axios.post(`${API_URL}/api/v1/transfer_transactions`, data);

    cb(response.data);
    return response.data;
  } catch (e) {
    if (e.response.data && e.response.data.code) fail(e.response.data.code);
    else fail(0);
  }
}

export async function callGetTransferTransactionCode(concertUuid: string, cb: (code: string) => void) {
  const _axios = getApiAxiosWithToken();
  const response = await _axios.get(`${API_URL}/api/v1/transfer_transactions/code?concertUuid=${concertUuid}`);

  cb(response.data);
  return response.data;
}