import React from 'react';
import * as styled from './Style';
import imgprofiledefault from 'images/img-profile-default-s.png';
import Header from 'components/Common/Header';

interface IReferralListProps {
  onCancel: () => void;
}

const ReferralList = ({ onCancel }: IReferralListProps) => {

  const _handleCancel = (e: any) => {
    onCancel();
  }

  return (
    <styled.Wrapper>
      <Header iHeaderCenter={"참여현황"} iBackLeftArrowIcon={false} iBackRightSettingIcon={false} iconClose={true} />
      <styled.MyRanking>
        <div>나의 추천 현황</div>
        <div>
          <styled.RankingImg>
            <styled.Profile src={imgprofiledefault} />
          </styled.RankingImg>
          <styled.MyEmail>
            po****@naver.com
          </styled.MyEmail>
          <styled.MyRankingData>
            10.125 건
          </styled.MyRankingData>
        </div>

      </styled.MyRanking>
      <styled.RankingListWrapper>
        <styled.Ranking>
          <styled.RankingImg>
            <styled.Profile src={imgprofiledefault} />
          </styled.RankingImg>
          <styled.RankingEmail> po****@naver.com </styled.RankingEmail>
          <styled.RankingData> 10.125 건 </styled.RankingData>
        </styled.Ranking>
        <styled.Ranking>
          <styled.RankingImg>
            <styled.Profile src={imgprofiledefault} />
          </styled.RankingImg>
          <styled.RankingEmail> po****@naver.com </styled.RankingEmail>
          <styled.RankingData> 10.125 건 </styled.RankingData>
        </styled.Ranking>
        <styled.Ranking>
          <styled.RankingImg>
            <styled.Profile src={imgprofiledefault} />
          </styled.RankingImg>
          <styled.RankingEmail> po****@naver.com </styled.RankingEmail>
          <styled.RankingData> 10.125 건 </styled.RankingData>
        </styled.Ranking><styled.Ranking>
          <styled.RankingImg>
            <styled.Profile src={imgprofiledefault} />
          </styled.RankingImg>
          <styled.RankingEmail> po****@naver.com </styled.RankingEmail>
          <styled.RankingData> 10.125 건 </styled.RankingData>
        </styled.Ranking>
      </styled.RankingListWrapper>
    </styled.Wrapper>
  )
}

export default ReferralList;
