import { API_URL } from '../../constants/apiInfo';
import { getApiAxiosWithToken } from '../utils/api';

interface ICreateOrderParams {
  concertUuid: string;
  ticketUuid: string;
  email: string;
}

export async function callCreateOrder({
  concertUuid,
  ticketUuid,
  email
}: ICreateOrderParams) {
  const _axios = getApiAxiosWithToken();
  const data = {
    concertUuid: concertUuid,
    ticketUuid: ticketUuid,
    email: email
  }
  const response = await _axios.post(`${API_URL}/api/v1/orders`, data);

  localStorage.removeItem('ticketUuid');
  return response.data;
}

interface IExecuteOrderParams {
  orderId: string;
  payerId: string;
  referralCode: string;
}

export async function callExecuteOrder({
  orderId,
  payerId,
  referralCode
}: IExecuteOrderParams) {
  const _axios = getApiAxiosWithToken();
  const data = {
    orderId: orderId,
    payerId: payerId,
    referralCode: referralCode
  }
  const response = await _axios.post(`${API_URL}/api/v1/orders/charge`, data);

  localStorage.removeItem('referralCode');
  return response.data;
}

interface ICancelOrderParams {
  orderId: string;
}

export async function callCancelOrder({
  orderId,
}: ICancelOrderParams) {
  const _axios = getApiAxiosWithToken();
  const data = {
    orderId: orderId,
  }
  const response = await _axios.post(`${API_URL}/api/v1/orders/cancel`, data);

  return response.data;
}