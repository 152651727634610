import CardList from 'components/Main/CardList';
import iconLogo from 'images/img-logo-small@3x.png';
import iconAlarm from 'images/ic-alarm@3x.png';
import iconProfile from 'images/img-profile-default-s.png';
import iconDim from 'images/img-logo-dim@3x.png';
import { loadMe } from 'modules/auth';
import { mainList } from 'modules/main';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, ContentContainer, Header } from '../../constants/styles/layout';
import { HeaderAlarm, HeaderLogo, HeaderProfile, Point, CompanyWrapper, Company, DimLogo } from './Style';
import ReactGA from 'react-ga';
import { useDetectMobile } from 'hooks/useDetectMobile';
import { RootState } from 'modules/types';

const listSelector = ({ main: { list } }: any) => list;
const hasNewMessageSelector = ({ main: { hasNewMessage } }: any) => hasNewMessage;

const MainList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const concerts = useSelector(listSelector);
  const hasNewMessage = useSelector(hasNewMessageSelector);
  const userData = useSelector((state: RootState) => state.auth.userData);
  const [isMobile, isApplied] = useDetectMobile();

  const [langugeCode, setLangugeCode] = useState<string>('');

  useEffect(() => {
    const localCode = localStorage.getItem('languageCode') || 'ko';
    setLangugeCode(localCode)
  }, []);


  useEffect(() => {
    ReactGA.event({ category: 'Main', action: 'Enter_Main', label: '' });
    dispatch(loadMe());
    dispatch(mainList());
    return () => {
      ReactGA.event({ category: 'Main', action: 'Exit_Main', label: '' });
    }
  }, []);

  useEffect(() => {
    if (isApplied) {
      if(!isMobile){
        let rootStyle = (document.getElementById("root") as HTMLElement)
        rootStyle.setAttribute("style", "transform:translate(-50%, 0) !important; left:50% !important ");
      }
    }
  }, [isMobile, isApplied]);

  const _navigateToMypage = () => {
    console.log(userData?.profileUrl);
    history.push("/mypage");
  }

  const _navigateAlarm = () => {
    history.push("/alarm");
  }

  return (
    <Container>
      <Header>
        <HeaderLogo src={iconLogo} />
        <HeaderProfile src={userData && userData.profileUrl ? userData.profileUrl : iconProfile} onClick={_navigateToMypage} />
        <HeaderAlarm src={iconAlarm} onClick={_navigateAlarm} />
        {hasNewMessage ? <Point></Point> : ""}
      </Header>
      <ContentContainer marginTop={60}>
        <CardList data={concerts} />

        <DimLogo src={iconDim} />
        <CompanyWrapper>
          <Company>
            {langugeCode == 'ko' ? '회사명:  주식회사 시어스랩  |  사업자번호 : 105-88-03237  |  대표자명 : 정진욱' : 'Copyright 2020 © FANBOX https://fanbox.live'}
          </Company>
          <Company>
            {langugeCode == 'ko' ? '통신판매신고번호 : 제2020-서울강남-00126 호' : '435 Tasso Street Suite 315 Palo Alto, CA , USA 94301'}
          </Company>
          <Company>
            {langugeCode == 'ko' ? '사업장 주소 (소재지) : 서울특별시 강남구 역삼로17길 16, 2층(역삼동, 해오름빌딩)' : 'Please direct inquiries to: support@fanbox.live'}
          </Company>
          <Company style={{ paddingTop: '15px' }}>
            {langugeCode == 'ko' ? '고객센터 : 070-7702-6800  |  이메일 주소 : support@fanbox.live' : ''}
          </Company>
        </CompanyWrapper>
      </ContentContainer>
    </Container>
  )
}

export default MainList;
