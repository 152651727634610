import styled from "styled-components";
import BtnRadioOn from 'images/btn-radio.png';

export const Container = styled.div`
    width: 100%;
`;

export const CustomInputWrapperWithLine = styled.div`
    height: 70px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 5px;
    padding: 15px;
`;

export const CustomInputWrapper = styled.div`
    position: relative;
`;

export const CustomInputLeftWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const CustomInputContentWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
`;

export const CustomInputTitle = styled.div`
    flex: 1;
    font-size: 15px;
    font-weight: 700;
    color: white;
    margin-bottom: 10px;
`;

export const CustomInputSmallTitle = styled.div`
    flex: 1;
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-bottom: 10px;
`;

export const InputCustom = styled.input
    .attrs({ type: 'text' })`
    flex: 1;
    background: transparent;
    color: white;
    font-size: 12px;
`;

export const TextSuccess = styled.div`
    color: #62e979;
    font-size: 12px;
`;

export const TextError = styled.div`
    color: red;
    font-size: 12px;
`;

export const ButtonCustomInput = styled.button`
    font-size: 12px;
    color: #62e979;
    background: none;
    border: none; 
    cursor: pointer;
`;

export const VerificationSelectWraper = styled.div`
    width: 100%;
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 6px;
    padding: 15px;
`;

export const VerificationSelectTitle = styled.div`
    font-size: 15px;
    color: white;
    font-weight: 700;
    margin-bottom: 20px;
`;

export const RadiosWrapper = styled.div`
    display: flex;
    align-item: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
`;

export const RadioWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

interface RadioProps {
    isOn: boolean;
}
export const Radio = styled.span<RadioProps>`
    width: 20px;
    height: 20px;
    background-image: ${(props) => props.isOn ? `url(${BtnRadioOn})` : ''};
    border: ${(props) => props.isOn? 'none' : '1px solid rgba(255,255,255,0.1)'};
    border-radius: 10px;
    display: block;
    margin-right: 10px;
`;

export const Label = styled.span`
    font-size: 12px;
    color: rgba(255,255,255,0.5);
`;

export const timerWrapper = styled.span`
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
`;