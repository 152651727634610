import { createReducer } from 'typesafe-actions';
import * as types from './types';

const initialState: types.PaymentState = {
    stage: 0,
    kind: '',
    isFetchingPaymentMethods: false,
    isRegisteringPaymentMethods: false,
    isFetchingHistoryMethods:false,
    paymentMethods: null,
    stripeCardToken: null,
    paymentHistorys:[],
    concert: null,
    paymentRefunds:null,
};

const paymentReducer = createReducer(initialState)
    .handleAction(types.T_SET_STAGE, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        stage: action.payload
    }))
    .handleAction(types.T_SET_KIND, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        kind: action.payload
    }))
    .handleAction(types.T_SET_CONCERT, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        concert: action.payload
    }))
    .handleAction(types.T_SET_STRIPE_CARD_TOKEN, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        stripeCardToken: action.payload
    }))
    .handleAction(types.T_REGISTER_PAYMENT_METHODS.PENDING, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isRegisteringPaymentMethods: true
    }))
    .handleAction(types.T_REGISTER_PAYMENT_METHODS.SUCCESS, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isRegisteringPaymentMethods: false,
        paymentMethods: [action.payload]
    }))
    .handleAction(types.T_REGISTER_PAYMENT_METHODS.FAILURE, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isRegisteringPaymentMethods: false,
    }))
    .handleAction(types.T_UPDATE_PAYMENT_METHODS.PENDING, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isRegisteringPaymentMethods: true
    }))
    .handleAction(types.T_UPDATE_PAYMENT_METHODS.SUCCESS, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isRegisteringPaymentMethods: false,
        paymentMethods: [action.payload]
    }))
    .handleAction(types.T_UPDATE_PAYMENT_METHODS.FAILURE, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isRegisteringPaymentMethods: false,
    }))
    .handleAction(types.T_LOAD_PAYMENT_METHODS.PENDING, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isFetchingPaymentMethods: true
    }))
    .handleAction(types.T_LOAD_PAYMENT_METHODS.SUCCESS, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isFetchingPaymentMethods: false,
        paymentMethods: action.payload
    }))
    .handleAction(types.T_LOAD_PAYMENT_METHODS.FAILURE, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isFetchingPaymentMethods: false,
        paymentMethods: null
    }))
    .handleAction(types.T_LOAD_PAYMENT_HISTORIES.PENDING, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isFetchingHistoryMethods: true
    }))
    .handleAction(types.T_LOAD_PAYMENT_HISTORIES.SUCCESS, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isFetchingHistoryMethods: false,
        paymentHistorys: action.payload
    }))
    .handleAction(types.T_LOAD_PAYMENT_HISTORIES.FAILURE, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        isFetchingHistoryMethods: false,
        paymentHistorys: null
    }))
    .handleAction(types.T_REFUND_CONCERT.SUCCESS, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        paymentRefunds: action.payload
    }))
    .handleAction(types.T_REFUND_CONCERT.FAILURE, (state: types.PaymentState, action: types.PaymentAction) => ({
        ...state,
        paymentRefunds: null
    }))
export default paymentReducer;
