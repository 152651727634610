import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #1a1f25;
    padding: 0 20px;
    flex-direction: column;
    font-size: 14px;
    line-height: 24px;
    color: white;
    overflow-y: scroll;
    padding-bottom: 30px;
`

export const Title = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
`;

export const SubTitle = styled.div`
    margin-bottom: 20px;
    line-height: 24px;
`;

export const ContentTitle = styled.div`
`;

export const ContentDetailWrapper = styled.div`
    padding-left: 20px;
    display: flex;
    margin-bottom: 10px;
`;
export const ContentDetailIndex = styled.div`
`;
export const ContentDetail = styled.div`
    flex: 1;
    padding-left: 20px;
`;