import Header from 'components/Common/Header';
import PaypalPurchase from 'components/Payment/PaypalPurchase';
import { APP_SCHEME } from 'constants/apiInfo';
import { setModal, setShowLoading } from 'modules/common';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { chargeConcert, oneTimeChargeConcert, setKind, setStage } from '../../modules/payment';
import locale from './locale.json';
import * as styled from './Style';
import ReactGA from 'react-ga';
import { TTicket } from 'modules/tickets';
import StripeCardBox from 'components/Payment/StripeCardBox';
import Button from 'components/Common/Button';
import PromotionVerification from 'components/Payment/PromotionVerification';
import PaymentDetail from 'components/Payment/PaymentDetail';
import { amountToPrice } from 'core/utils/numberHelper';
import * as verificationStyled from 'components/Payment/PromotionVerification/Style';
import { stepOpenPage } from 'hooks/stepOpen';
import { callCreateTransferTransactions } from 'core/api/transfer_transactions';
import TransferPopup from 'components/TransferPopup';
import moment from 'moment';
import CustomInputBox from 'components/Payment/CustomInputBox';
import { RootState } from 'modules/types';


const paymentMethodsSelector = ({ payment: { paymentMethods } }: any) => paymentMethods;
const stripeCardTokenSelector = ({ payment: { stripeCardToken } }: any) => stripeCardToken;
const ticketsSelector = ({ tickets: { list } }: any) => list;
const paymentKindSelector = ({ payment: { kind } }: any) => kind;
const isVerifiedSelector = ({ verifications: { isVerified } }: any) => isVerified;

interface RouteParams {
  uuid: string;
}

const Payment = () => {
  const [userAgent, setuserAgent] = useState("");
  const [oneTimePurchase, setOnetimePurchase] = useState(false);
  const [language, setLanguage] = useState<any>({});
  const [currentTicket, setCurrentTicket] = useState<TTicket | null>(null);
  const [referralCode, setReferralCode] = useState('');
  const [showTransferPopup, setShowTransferPopup] = useState(false);
  const [generatedCode, setGeneratedCode] = useState('');
  const [transferAmount, setTransferAmount] = useState(0);
  const [email, setEmail] = useState('');
  const [isWrongEmail, setIsWrongEmail] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const saleStatus = stepOpenPage();

  const kind = useSelector(paymentKindSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const stripeCardToken = useSelector(stripeCardTokenSelector);
  const tickets = useSelector(ticketsSelector);
  const isVerified = useSelector(isVerifiedSelector);
  const userData = useSelector((state: RootState) => state.auth.userData);

  const { uuid } = useParams<RouteParams>()

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  useEffect(() => {
    if (userData) {
      if (!userData.email.includes('@fanbox.live') && !userData.email.includes('@privaterelay.appleid.com')) {
        setEmail(userData.email);
        setIsWrongEmail(false);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  useEffect(() => {
    if (saleStatus === 'fanclub_close' && language.open) dispatch(setModal(true, `${language.open}`, ''));
  }, [saleStatus, language]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setOnetimePurchase(false);
      dispatch(setKind(paymentMethods[0].kind));
    } else {
      setOnetimePurchase(true);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (tickets) {
      const normalTicket = tickets.filter((t) => t.kind === 'NORMAL')[0];
      if (!normalTicket || (normalTicket && normalTicket.concertUuid !== uuid)) {
        history.goBack();
      }
      setCurrentTicket(normalTicket);
    }
  }, [tickets]);

  useEffect(() => {
    if (tickets && isVerified) {
      const promotionTicket = tickets.filter((t) => t.kind === 'PROMOTION')[0];
      if (!promotionTicket || (promotionTicket && promotionTicket.concertUuid !== uuid)) {
        history.goBack();
      }
      setCurrentTicket(promotionTicket);
    }
  }, [isVerified]);

  const _chargeConcert = () => {
    if (!currentTicket) return;

    const concertUuid = uuid;
    const _success = () => {
      dispatch(setModal(true, language.PaymentSuccess, ''));
      ReactGA.event({ category: 'Main', action: 'Payment_Concert', label: concertUuid });
      if (localStorage.getItem('userAgent') === 'fanbox') window.location.href = `${APP_SCHEME}://close_activity`;
      else history.push('/mainDetail/' + concertUuid);
    }
    const _fail = (kind: string) => {
      if (kind === 'NOT_SUPPORTED') {
        if (localStorage.getItem('countryCode') === 'KR') {
          dispatch(setModal(true, '지원되지 않는 카드 브랜드입니다. Visa 또는 Mastercard로 결제해주세요.', ''));
        }
      } else if (kind === 'FAILED') {
        dispatch(setModal(true, 'Failed to pay', ''));
      }
    }

    if (oneTimePurchase) {
      dispatch(oneTimeChargeConcert(concertUuid, currentTicket.uuid, stripeCardToken.id, referralCode, email, _success, _fail));
    } else {
      dispatch(chargeConcert(concertUuid, currentTicket.uuid, paymentMethods[0].uuid, referralCode, email, _success, _fail));
    }
  }

  //페이팔 결제요청시 결제정보 저장 또는 저장안함으로 결제
  const _chargeConcertPaypal = () => {
    dispatch(setShowLoading(false));
    dispatch(setModal(true, language.PaymentSuccess, ''));

    ReactGA.event({ category: 'Main', action: 'Payment_Concert', label: uuid });

    if (localStorage.getItem('userAgent') === 'fanbox') window.location.href = `${APP_SCHEME}://close_activity`;
    else history.push('/mainDetail/' + uuid);
  }

  const _createTransferTransaction = async () => {
    dispatch(setShowLoading(true));
    const cb = (data: any) => {
      dispatch(setShowLoading(false));
      setShowTransferPopup(true);
      setGeneratedCode(data.code);
      setTransferAmount(data.amount);
    }
    const handleFail = (code: number) => {
      if (code === 8030) alert('무통장입금 신청 기한이 지났습니다.')
      else alert('무통장입금 신청에 실패하였습니다.');

      dispatch(setShowLoading(false));
      history.push('/');
    }
    if (currentTicket) await callCreateTransferTransactions(uuid, currentTicket.uuid, cb, handleFail);
  }

  const _handleMethodChange = () => {
    if (oneTimePurchase) dispatch(setStage(0));
    else history.push('/paymentInformation');
  }

  const _renderPaymentMethods = () => {
    if (!paymentMethods) return null;

    const paymentMethod = paymentMethods[0];

    if (kind === 'stripe') {
      return (
        <StripeCardBox paymentMethod={paymentMethod} oneTimePurchase={oneTimePurchase} stripeCardToken={stripeCardToken} handleMethodChange={_handleMethodChange} />
      )
    } else if (kind === 'transfer' && moment().utc() < moment(1599811200000).utc()) {
      return (
        <styled.PaymentMethodWrapper>
          <styled.PaymentMethodNumber>무통장 입금</styled.PaymentMethodNumber>
          <styled.BtnChangePaymentMethod onClick={_handleMethodChange}>변경</styled.BtnChangePaymentMethod>
        </styled.PaymentMethodWrapper>
      )
    }
    return null;
  }

  const _renderPayButton = () => {
    if (!paymentMethods || !currentTicket) return null;

    if (saleStatus === 'fanclub') {
      if (currentTicket.kind !== 'PROMOTION' || !isVerified) {
        const needVerificationText = localStorage.getItem('languageCode') === 'en' ? 'Need Verification' : '팬클럽 인증해주세요.';
        return (
          <Button style={{ background: 'gray', color: 'rgba(255,255,255,0.7)' }} buttonText={needVerificationText} handleClick={() => { }} />
        )
      }
    }

    if (saleStatus === 'fanclub_close') {
      const needVerificationText = localStorage.getItem('languageCode') === 'en' ? 'Preparing General ticket sales' : '일반 판매 준비중';
      return (
        <Button style={{ background: 'gray', color: 'rgba(255,255,255,0.7)' }} buttonText={needVerificationText} handleClick={() => { }} />
      )
    }

    if (isWrongEmail) {
      const needEmailText = localStorage.getItem('languageCode') === 'en' ? 'Need valid email' : '올바른 이메일을 입력해주세요.';
      return (
        <Button style={{ background: 'gray', color: 'rgba(255,255,255,0.7)' }} buttonText={needEmailText} handleClick={() => { }} />
      )
    }

    if (kind === 'stripe') {
      return (
        <Button buttonText={language.Pay} handleClick={_chargeConcert} />
      )
    } else if (kind === 'paypal') {
      return (
        <PaypalPurchase currentTicket={currentTicket} referralCode={referralCode} onSubmit={_chargeConcertPaypal} email={email} />
      )
    } else if (kind === 'transfer') {
      return (
        <Button buttonText={language.Pay} handleClick={_createTransferTransaction} />
      )
    }
    return null;
  }

  const handleEmailChange = (value: string) => {
    setEmail(value);

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(String(value).toLowerCase())) setIsWrongEmail(false);
    else setIsWrongEmail(true);
  }

  return (
    <styled.Container paddingTop={userAgent ? 0 : 60}>
      <Header iHeaderCenter={"Payment"} iBackLeftArrowIcon={true} iBackRightSettingIcon={false} iconClose={false} />
      <TransferPopup show={showTransferPopup} onCancel={() => history.push('/maindetail/' + uuid)} code={generatedCode} amount={transferAmount} currency={''} />
      <styled.Title>
        <styled.Text>
          {language.PleaseCheck}
        </styled.Text>
        <styled.Step>2/2</styled.Step>
      </styled.Title>
      <styled.ContentWrapper>
        <styled.TicketsWrapper>
          {currentTicket &&
            <styled.TicketWrapper key={currentTicket.uuid}>
              <styled.TicketTitle>{currentTicket.title}</styled.TicketTitle>
              <styled.TicketAmount> {amountToPrice(currentTicket.amount, currentTicket.currency)}</styled.TicketAmount>
              <styled.TicketIndicator />
            </styled.TicketWrapper>
          }
        </styled.TicketsWrapper>
        {_renderPaymentMethods()}
        {false &&
          <verificationStyled.CustomInputWrapperWithLine>
            <verificationStyled.CustomInputLeftWrapper>
              <verificationStyled.CustomInputTitle>{language.ReferralCode}</verificationStyled.CustomInputTitle>
              <verificationStyled.InputCustom placeholder={language.ReferralCodePlaceholder} value={referralCode} onChange={(e) => setReferralCode(e.target.value)} />
            </verificationStyled.CustomInputLeftWrapper>
          </verificationStyled.CustomInputWrapperWithLine>
        }
        {saleStatus === 'fanclub' &&
          <PromotionVerification concertUuid={uuid} isVerified={isVerified} />
        }
        <CustomInputBox title={language.EmailInputTitle} placeholder={language.EmailInputPlaceholder} value={email} isError={isWrongEmail} handleValueChange={handleEmailChange} />
        <PaymentDetail currentTicket={currentTicket} />
        {_renderPayButton()}
        <styled.PaymentInfo>
          {language.PaymentInfo}
        </styled.PaymentInfo>
      </styled.ContentWrapper>
    </styled.Container>
  )
}

export default Payment;
