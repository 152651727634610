import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top:60px;
  background-color: #2a3139;
`;

export const Section = styled.div`
  flex: 1;
  overflow: auto;
`;

export const ContentWrapper = styled.div`
  height: calc(100vh - (70vh));
`;

export const ParticipateWrapper = styled.div`
  height: calc(100vh - (55vh));
`;

export const Participate = styled.img`
    width: 90%;
    height: 85%;
    margin: auto;
    vertical-align: middle;
`;


export const RankingWrapper = styled.div`
  height: calc(100vh - (65vh));
`;

export const GoodsWrapper = styled.div`
  height: calc(100vh - (55vh));
`;

export const NoticeWrapper = styled.div`
  height: calc(100vh - (70vh));
`;
export const Notice = styled.div`
  color: #fff;
  font-size: 0.8rem;
  line-height:1.2rem;
  padding:5px;
  opacity: 0.5;
`;

export const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
  padding:5px;

`;

export const Ranking = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
  width: 90%;
  margin: auto;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  padding: 10px 0;
`;

export const RankingImg = styled.div`
  color: white;
  width: 20%;
`;

export const Profile = styled.img`
    width: 30px;
    height: 30px;
    margin: auto;
    vertical-align: middle;
`;

export const RankingEmail = styled.div`
  display: inline-block;
  height: 100%;
  color: #fff;
  font-size: 0.8rem;
  width: 50%;
  font-size: 15px;
  font-weight: 600;
  float: left;
  line-height: 24px;
`;
export const RankingData = styled.div`
  display: inline-block;
  height: 100%;
  color: #fff;
  font-size: 0.8rem;
  width: 30%;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
`;

export const Btn = styled.div`
  position: relative;
  width: 90%;
  margin: auto;
  padding: 20px 0;
  background-color: #ffffff;
  border-radius: 7px;
  bottom: 0;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Dim = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid black;
`;
