import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { HeaderContainer, HeaderLeftButton, HeaderCenterText, HeaderRightButton, HeaderCloseButton, HeaderNull } from './Style';
import * as styled from './Style';
import imgBackLeftArrow from 'images/icon-back-leftarrow.png';
import imgBackRightSetting from 'images/ic-setting.png';
import imgClose from 'images/ic_close_white.png';

interface HeaderProps {
  iHeaderCenter: string;
  iBackLeftArrowIcon: boolean;
  iBackRightSettingIcon: boolean;
  iconClose: boolean;
}


const Header = ({ iHeaderCenter, iBackLeftArrowIcon, iBackRightSettingIcon, iconClose }: HeaderProps) => {
  const history = useHistory();
  const _prevStage = () => {
    history.goBack();
  }
  const _nextSetting = () => {
    history.push("/setting");
  }

  if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) {
    return <styled.HeaderNull />

  } else {
    return (
      <styled.HeaderContainer>
        {iconClose ? "" : <styled.HeaderLeftButton src={imgBackLeftArrow} onClick={_prevStage} />}

        <styled.HeaderCenterText paddingRight={iconClose ? 35 : 0}>
          {iHeaderCenter}
        </styled.HeaderCenterText>
        {iBackRightSettingIcon ? <styled.HeaderRightButton src={imgBackRightSetting} onClick={_nextSetting} /> : ""}
        {iconClose ? <styled.HeaderCloseButton src={imgClose} onClick={_prevStage} /> : ""}

      </styled.HeaderContainer>
    )
  }
}

export default Header;
