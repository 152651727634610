import { ActionType } from 'typesafe-actions';
import { createAsyncActionTypes } from '../../core/utils/reduxUtils';
const prefix: string = 'sendbird';


export const T_SENDBIRD_CONNECT = createAsyncActionTypes(`${prefix}/SENDBIRD_CONNECT`);
export const T_SENDBIRD_ENTER_CHANNEL = createAsyncActionTypes(`${prefix}/SENDBIRD_ENTER_CHANNEL`);
export const T_SENDBIRD_SET_CONNECT = createAsyncActionTypes(`${prefix}/SENDBIRD_SET_CONNECT`);
export const T_SENDBIRD_LOAD_OPEN_CHANNEL = createAsyncActionTypes(`${prefix}/SENDBIRD_LOAD_OPEN_CHANNEL`);
export const T_SENDBIRD_PREV_MESSAGE = createAsyncActionTypes(`${prefix}/SENDBIRD_PREV_MESSAGE`);
export const T_SENDBIRD_SEND_MESSAGE = createAsyncActionTypes(`${prefix}/SENDBIRD_SEND_MESSAGE`);
export const T_SENDBIRD_MESSAGE_RECEIVED = createAsyncActionTypes(`${prefix}/SENDBIRD_MESSAGE_RECEIVED`);
export const T_SENDBIRD_MESSAGE_RECEIVED_ON_PROMPTER = `${prefix}/SENDBIRD_MESSAGE_RECEIVED_ON_PROMPTER`;
export const T_SENDBIRD_SET_PROMPTER_THRESHOLD = `${prefix}/SENDBIRD_SET_PROMPTER_THRESHOLD`;
export const T_SENDBIRD_LOAD_EMOTION = createAsyncActionTypes(`${prefix}/SENDBIRD_LOAD_EMOTION`);
export const T_SENDBIRD_ADMIN_CHAT = createAsyncActionTypes(`${prefix}/SENDBIRD_ADMIN_CHAT`);


export type SendbirdAction = ActionType<any>;



export type SendbirdState = {
  channels: object,
  messages:  object[],
  connected: boolean;
  enterMethods: boolean;
  currentChannel: object;
  prevListQuery: null | object[];
  enterChannel: object;
  emotions: object;
  prompterMessagesThreshold: number;
  prompterMessagesCount: number;
  adminMessage: any;
};
