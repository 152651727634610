import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as styled from './Style';
import { APP_SCHEME } from 'constants/apiInfo';
import moment from 'moment';

interface TransferPopupProps {
  show: boolean;
  code: string;
  amount: number;
  currency: string;
  onCancel: () => void;
}

const TransferPopup = ({ show, code, amount, currency, onCancel }: TransferPopupProps) => {
  if (!show || code === '') return <div />

  const copyToClipboard = () => {
    var t = document.createElement("textarea");
    document.body.appendChild(t);
    t.value = code;
    t.select();
    document.execCommand('copy');
    document.body.removeChild(t);
    alert('복사 되었습니다.');
  }

  const _handleConfirm = () => {
    if (localStorage.getItem('userAgent') === 'fanbox') window.location.href = `${APP_SCHEME}://close_activity`;
    else onCancel();
  }

  return (
    <>
      <styled.Dimmed />
      <styled.ModalWrapper>
        <Modal.Dialog className="transfer-popup">
          <styled.ModalBody>
            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
              <styled.ModalTitle>
                무통장입금 진행절차 안내
              </styled.ModalTitle>
              <styled.InfoWrapper>
                <styled.Title>
                  결제 금액
                </styled.Title>
                <styled.Description>
                  {amount === 39000 ? (
                    '39,000원'
                  ) : (
                      '36,000원'
                    )}
                </styled.Description>
              </styled.InfoWrapper>
              <styled.InfoWrapper>
                <styled.Title>
                  {amount === 39000 ? (
                    '일반예매 입금기한'
                  ) : (
                    '팬클럽 선예매 입금기한'
                  )}
                </styled.Title>
                <styled.Description>
                  {amount === 39000 ? (
                    '2020년 8월 29일(토) 12:00 ~ 9월 11일(금) 17:00 (KST)'
                  ) : (
                    '2020년 8월 26일(수) 12:00 ~ 8월 28일(금) 23:59 (KST)'
                  )}
                </styled.Description>
              </styled.InfoWrapper>
              <styled.InfoWrapper>
                <styled.Title>
                  은행정보
                </styled.Title>
                <styled.Description>
                  {moment('2020-08-28T03:30:00.000Z') < moment() ? '입금계좌번호 : 1005-304-028888' : '입금계좌번호 : 1005-602-508417'}
                  <br />
                은행/예금주 : 우리은행/(주)시어스랩
                </styled.Description>
              </styled.InfoWrapper>
              <styled.InfoWrapper>
                <styled.Title>
                  입금코드 (메모에 기입 필수)
                </styled.Title>
                <styled.CodeWrapper onClick={copyToClipboard}>
                  <styled.CodeText>{code}</styled.CodeText>
                  <styled.BtnCopy>복사</styled.BtnCopy>
                </styled.CodeWrapper>
              </styled.InfoWrapper>
              <styled.InfoWrapper>
                <styled.Title>
                  절차 안내
                </styled.Title>
                <styled.Description>
                  1. 입금계좌번호로 결제 금액을 입금해주세요. <br />
                  <span style={{ color: '#62e979' }}>메모난에 입금코드를 입력해주세요.</span><br />
                사용자와 입금자를 매칭하기 위해 정확히 입력해주세요.<br />
                2. 무통장입금은 입금일 기준 하루 뒤, 관람 권한이 부여됩니다.<br />
                상황에 따라 약간의 지연이 발생할 수 있습니다.<br />
                3. 입금확인이 완료되면 입장하기를 통해 공연을 즐기실 수
                있습니다.
                </styled.Description>
              </styled.InfoWrapper>
              <styled.InfoWrapper>
                <styled.Title>
                  추가 안내
                </styled.Title>
                <styled.Description>
                  1. 환불이 필요하신 경우, 입금내역, 환불계좌와 입금코드를
                support@fanbox.live로 전달 및 요청 부탁드리겠습니다. <br />
                입금정보 확인 후 개별 연락을 통해 환불이 진행됩니다.<br />
                2. 무통장입금은 국내 사용자에 한하여 가능합니다.<br />
                해외 무통장입금은 지원하지 않습니다.<br />
                3. 입금코드는 복사하기를 통해 복사할 수 있습니다.<br />
                4. 이후 결제 진행중 버튼을 클릭하시면 무통장입금 진행
                절차 안내를 다시 확인하실 수 있습니다.
                </styled.Description>
              </styled.InfoWrapper>
            </div>
            <Button style={{ width: '100%' }} variant="secondary" onClick={_handleConfirm} className="popup-btn">Confirm</Button>
          </styled.ModalBody>
        </Modal.Dialog>
      </styled.ModalWrapper>
    </>
  )
}


export default TransferPopup;
