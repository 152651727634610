import * as types from './types';
import { ThunkDispatch } from 'redux-thunk';
import { callMainList, callMainDetail, callMainReserves, callEnterConcert, callSendConcertHeart } from 'core/api/main';
import { T_SET_CONCERT } from '../payment/types';


export const mainList = () => async (dispatch: ThunkDispatch<types.MainState, void, types.MainAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_MAIN_LIST.PENDING });
    try {
        const _data = await callMainList();
        dispatch({ type: types.T_MAIN_LIST.SUCCESS, payload: _data });
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_MAIN_LIST.FAILURE, payload: e });
    }
}


export const mainDetail = (concertUuid: string) => async (dispatch: ThunkDispatch<types.MainState, void, types.MainAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_MAIN_DETAIL.PENDING });
    try {
        const _data = await callMainDetail({ concertUuid: concertUuid });
        dispatch({ type: types.T_MAIN_DETAIL.SUCCESS, payload: _data });
        dispatch({ type: T_SET_CONCERT, payload: _data });
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_MAIN_DETAIL.FAILURE, payload: e });
    }
}
//reserves

export const mainReserves = (concertUuid: string) => async (dispatch: ThunkDispatch<types.MainState, void, types.MainAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_MAIN_RESERVES.PENDING });
    try {
        const _data = await callMainReserves({ concertUuid: concertUuid });
        dispatch({ type: types.T_MAIN_RESERVES.SUCCESS, payload: _data });
        dispatch(mainDetail(concertUuid));
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_MAIN_RESERVES.FAILURE, payload: e });
    }
}

export const enterConcert = (concertUuid: string, cb: (enterResponseData: any) => void, error: (enterErrorMessage: any) => void) => async (dispatch: ThunkDispatch<types.MainState, void, types.MainAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_ENTER_CONCERT.PENDING });
    try {
        const _data = await callEnterConcert({ concertUuid: concertUuid });
        dispatch({ type: types.T_ENTER_CONCERT.SUCCESS, payload: _data });
        cb(_data);
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_ENTER_CONCERT.FAILURE, payload: e });
        error(e);
    }
}


export const sendConcertHeart = ( concertUuid: string ) => async (dispatch: ThunkDispatch<types.MainState, void, types.MainAction>) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    dispatch({ type: types.T_SEND_CONCERT_HEART.PENDING });
    try {
        const _data = await callSendConcertHeart({ concertUuid: concertUuid });
        dispatch({ type: types.T_SEND_CONCERT_HEART.SUCCESS, payload: _data });
    } catch (e) {
        console.log('e : ', e);
        dispatch({ type: types.T_SEND_CONCERT_HEART.FAILURE, payload: e.response.data });
    }
}
