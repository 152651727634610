import { API_URL } from '../../constants/apiInfo';
import { getApiAxiosWithToken } from '../utils/api';

export async function callRequestSMSVerificationCode(concertUuid: string, phoneCountryCode: string, phoneNumber: string, promotionCode: string) {
    const _axios = getApiAxiosWithToken();
    const body = {
        phoneCountryCode: phoneCountryCode,
        phoneNumber: phoneNumber,
        promotionCode: promotionCode
    }
    const response = await _axios.post(`${API_URL}/api/v1/verifications/concerts/${concertUuid}/sms`, body);

    return response.data;
}

export async function callSubmitSMSVerificationCode(concertUuid: string, verificationCode: string) {
    const _axios = getApiAxiosWithToken();
    const body = {
        verificationCode: verificationCode
    }
    const response = await _axios.post(`${API_URL}/api/v1/verifications/concerts/${concertUuid}/verifySms`, body);

    return response.data;
}

export async function callRequestEmailVerificationCode(concertUuid: string, email: string, promotionCode: string) {
    const _axios = getApiAxiosWithToken();
    const body = {
        email: email,
        promotionCode: promotionCode
    }
    const response = await _axios.post(`${API_URL}/api/v1/verifications/concerts/${concertUuid}/email`, body);

    return response.data;
}

export async function callSubmitEmailVerificationCode(concertUuid: string, verificationCode: string) {
    const _axios = getApiAxiosWithToken();
    const body = {
        verificationCode: verificationCode
    }
    const response = await _axios.post(`${API_URL}/api/v1/verifications/concerts/${concertUuid}/verifyEmail`, body);

    return response.data;
}
