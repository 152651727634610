import { APPLE_SIGNIN_REDIRECT_URL } from 'constants/apiInfo';
import React, { useEffect } from 'react';

declare global {
    interface Window { AppleID: any; }
}

const AppleLogin = () => {
    useEffect(() => {
        const _appleId = window.AppleID;
        if (_appleId) {
            _appleId.auth.init({
                clientId: 'odeon.seerslab.com',
                redirectURI: APPLE_SIGNIN_REDIRECT_URL,
                scope: 'name email',
                state: 'odeon.seerslab.com.test',
                responseMode: 'form_post'
            });
        }
    }, []);
    return (
        <div className="apple-login-wrapper">
            <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in" />
        </div>
    )
}

export default AppleLogin;