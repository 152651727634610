import * as React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import dragJson from '../../constants/lotties/ar_guide_drag.json';
const animationData = dragJson;

const Dim = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DragIndicator = () => {
    const [show, setShow] = React.useState(true);

    React.useEffect(() => {
        setInterval(() => {
            setShow(false);
        }, 2000);
    }, []);

    if (!show) return <div />

    return (
        <Dim>
            <div>
                <Lottie
                    width={'20%'}
                    options={{
                        animationData
                    }}
                />
                <div style={{ textAlign: 'center', color: 'white', marginTop: '20px' }}>드래그 해주세요.</div>
            </div>
        </Dim>
    )
};

export default DragIndicator;
