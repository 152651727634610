import styled from "styled-components";

interface IContainer {
    paddingTop: number;
}

export const Container = styled.div<IContainer>`
    width: 100%;
    height: 100vh;
    background-color: #2A3139;
    padding-top: ${(props) => props.paddingTop ? props.paddingTop : 0}px;
    overflow-x:scroll;
`;

export const Header = styled.div`
    width: 100%;

    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: relative;

    height: 60px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: #1a1f25;
`;
