import { TNotice } from 'modules/messages';
import React from 'react';
import * as styled from './Style';

interface INoticeListProps {
  notices: TNotice[];
}

const NoticeList = ({ notices }: INoticeListProps) => {
  if (notices.length === 0) return null;

  return (
    <styled.NoticeListWrapper>
      <styled.NoticeListItemWrapper>
        <styled.NoticeListItemTitle>{notices[0].title}</styled.NoticeListItemTitle>
        <styled.NoticeListItemDesc>{notices[0].description}</styled.NoticeListItemDesc>
      </styled.NoticeListItemWrapper>
    </styled.NoticeListWrapper>
  )
}

export default NoticeList;
