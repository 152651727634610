import { createReducer } from 'typesafe-actions';
import * as types from './types';

const initialState: types.SendbirdState = {
  channels: [],
  messages: [],
  connected: false,
  enterMethods: false,
  currentChannel: {},
  prevListQuery: null,
  enterChannel: {},
  emotions: {},
  prompterMessagesThreshold: 1,
  prompterMessagesCount: 0,
  adminMessage:null
};

const sendbirdReducer = createReducer(initialState)
  .handleAction(types.T_SENDBIRD_CONNECT.SUCCESS, (state: types.SendbirdState, action: types.SendbirdAction) => ({
    ...state,
    connected: true,
    channels: action.payload
  }))
  .handleAction(types.T_SENDBIRD_CONNECT.FAILURE, (state: types.SendbirdState, action: types.SendbirdAction) => ({
    ...state,
    connected: false,
  }))
  .handleAction(types.T_SENDBIRD_ENTER_CHANNEL.SUCCESS, (state: types.SendbirdState, action: types.SendbirdAction) => {
    return {
      ...state,
      enterMethods: true,
      enterChannel: action.payload,
    }
  })
  .handleAction(types.T_SENDBIRD_ENTER_CHANNEL.PENDING, (state: types.SendbirdState, action: types.SendbirdAction) => ({
    ...state,
    enterMethods: false,
  }))
  .handleAction(types.T_SENDBIRD_ENTER_CHANNEL.FAILURE, (state: types.SendbirdState, action: types.SendbirdAction) => ({
    ...state,
    initialState
  }))
  .handleAction(types.T_SENDBIRD_SET_CONNECT.SUCCESS, (state: types.SendbirdState, action: types.SendbirdAction) => {
    return {
      ...state,
      connected: true,
    }
  })
  .handleAction(types.T_SENDBIRD_LOAD_OPEN_CHANNEL.SUCCESS, (state: types.SendbirdState, action: types.SendbirdAction) => {
    return {
      ...state,
      channels: action.payload
    }
  })
  .handleAction(types.T_SENDBIRD_PREV_MESSAGE.SUCCESS, (state: types.SendbirdState, action: types.SendbirdAction) => {
    return {
      ...state,
      messages: action.payload
    }
  })
  .handleAction(types.T_SENDBIRD_SEND_MESSAGE.SUCCESS, (state: types.SendbirdState, action: types.SendbirdAction) => {
    state.messages = [...state.messages, action.payload]
    return {
      ...state,
    }
  })
  .handleAction(types.T_SENDBIRD_SEND_MESSAGE.FAILURE, (state: types.SendbirdState, action: types.SendbirdAction) => {
    state.messages = [...state.messages, action.payload]
    return {
      ...state,
    }
  })
  .handleAction(types.T_SENDBIRD_MESSAGE_RECEIVED.SUCCESS, (state: types.SendbirdState, action: types.SendbirdAction) => {
    state.messages = [...state.messages, action.message]
    return {
      ...state,
      channel: action.channel
    }
  })
  .handleAction(types.T_SENDBIRD_LOAD_EMOTION.SUCCESS, (state: types.SendbirdState, action: types.SendbirdAction) => ({
    ...state,
    emotions: action.payload
  }))
  .handleAction(types.T_SENDBIRD_MESSAGE_RECEIVED_ON_PROMPTER, (state: types.SendbirdState, action: types.SendbirdAction) => {
    if (state.prompterMessagesCount % state.prompterMessagesThreshold === 0) state.messages = [...state.messages, action.message];
    return {
      ...state,
      channel: action.channel,
      prompterMessagesCount: state.prompterMessagesCount + 1
    }
  })
  .handleAction(types.T_SENDBIRD_SET_PROMPTER_THRESHOLD, (state: types.SendbirdState, action: types.SendbirdAction) => {
    return {
      ...state,
      prompterMessagesThreshold: action.payload
    }
  })
  .handleAction(types.T_SENDBIRD_ADMIN_CHAT.SUCCESS, (state: types.SendbirdState, action: types.SendbirdAction) => {
    return {
      ...state,
      adminMessage: action.payload
    }
  })

export default sendbirdReducer;
