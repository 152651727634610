import moment from 'moment';
import "moment-timezone";

export const CurrentDateTime = (s: string) => {
    let current = moment().utc().format();

    if(s == "DateTime"){
      current = moment().utc().format();
    }

    return current;
}
