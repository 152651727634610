import styled from "styled-components";
import MainCard1 from '../../images/big-69.png'

interface ITabs {
    active: boolean;
}

interface ICardUrl{
    thumbnailsUrl: string
}

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2A3139;

`;

export const Header = styled.div`
    width: 100%;

    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: relative;

    height: 60px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: #1a1f25;
`;

export const HeaderCenterText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.2rem;
    font-weight:700;
`;

export const HeaderLeftButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const HeaderRightButton = styled.img`
    width: 50px;
    height: 50px;
`;

export const HelpText = styled.div`
    max-width: 240px;
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
`;

export const HelpSubText = styled.div`
    max-width: 240px;
    margin: 0 auto;
    margin-top: 15px;
    color: #999;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
`;

export const Form = styled.div`
    margin-top: 25px;
    width: 100%;
`;

export const MainCard = styled.div`
    width: 90%;
    height: 200px;
    padding-top:20px;
    padding-bottom:20px;
    top: 0;
    left: 0;
    border-bottom:1px solid rgba(255, 255, 250, 0.2);
    margin: auto;
`;

export const ProfileNickNameText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    top: 10%;
    left: 38%;
    transform: translate(-50%, 0);
    color: #fff;
    font-size: 1.2rem;
`;

export const ProfileInfoText = styled.div`
    display: inline-block;
    position: absolute;
    height: 60px;
    line-height: 60px;
    left: 38%;
    color: #fff;
    font-size: 0.8rem;
    opacity: 0.5;
    left: 27%;
`;

export const PaymentCard = styled.div`
    display: block;
    position: relative;
    height: 105px;
    width:100%;
    color: #ffffff;
`;

export const InfoText = styled.div`
    position: relative;
    font-size: 0.8rem;
    width:100%;
    font-weight:500;
    line-height: 1.5;
    color:#999;
    padding:15px;
    padding-top:30px;
    text-align:center;
`;

export const NickNameText = styled.span`
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    font-size: 0.8rem;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    padding-top:2px;
    padding-left: 15px;
    display:block;
`;

export const ModalInfo = styled.span`
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    font-size: 0.9rem;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:2px;
    display:block;
    opacity: 0.8;
`;

export const ModalThumbnail = styled.div<ICardUrl>`
    width: 90px;
    height: 90px;
    border-radius:5px;
    background: url(${(props) => props.thumbnailsUrl? props.thumbnailsUrl : MainCard1}) no-repeat center/cover;
    background-image: url(${(props) => props.thumbnailsUrl? props.thumbnailsUrl : MainCard1});
    position:relative;
    float:left;
    border-radius: 6px;
    position: absolute;
    top: 105px;
    left: 20px;
    text-align: center;
`;

export const ModalTitle = styled.div`
  position: relative;
  font-size: 1.0rem;
  width: 100%;
  font-weight: 500;
  line-height: 1.2;
  padding-left: 100px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ModalContent = styled.div`
  position: relative;
  font-size: 0.9rem;
  width: 100%;
  font-weight: 500;
  line-height: 1.2;
  padding-left: 100px;
  opacity: 0.8;
  height: 40px;
  overflow: auto;
  margin-top:5px;
`;

export const ModalPrice = styled.div`
  position: relative;
  font-size: 1.0rem;
  width: 100%;
  font-weight: 500;
  line-height: 1.2;
  padding-left: 100px;
  text-align:right;
  padding-right:5px;
  color: #62e979;
  font-weight: 600;
  padding-top:5px;
`;
