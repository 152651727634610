import { API_URL } from '../../constants/apiInfo';
import { getApiAxiosWithToken } from '../utils/api';

interface IReadRedeemCode {
  code: string;
}

export async function callReadRedeemCode({ code }: IReadRedeemCode) {
  const _axios = getApiAxiosWithToken();
  const payload = {
    code: code
  }
  const response = await _axios.post(`${API_URL}/api/v1/redeem-codes/read`, payload);

  return response.data;
}

interface IRegisterRedeemCode {
  code: string;
}

export async function callRegisterRedeemCode({ code }: IRegisterRedeemCode) {
  const _axios = getApiAxiosWithToken();
  const payload = {
    code: code
  }
  const response = await _axios.post(`${API_URL}/api/v1/redeem-codes/verify`, payload);

  return response.data;
}
