import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as styled from './Style';
import { APP_SCHEME } from 'constants/apiInfo';
import moment from 'moment';
import Info_en from 'images/vod_info_en.png';
import Info_kr from 'images/vod_info_kr.png';

interface VodInfoPopupProps {
  show: boolean;
  language:string;
  onCancel: () => void;
}

const VodInfoPopup = ({ show, language, onCancel }: VodInfoPopupProps) => {
  if (!show ) return <div />

  const _handleConfirm = () => {
    if (localStorage.getItem('userAgent') === 'fanbox') window.location.href = `${APP_SCHEME}://close_activity`;
    else onCancel();
  }

  return (
    <>
      <styled.Dimmed />
      <styled.ModalWrapper>
        <Modal.Dialog>
          <styled.ModalBody>
            <styled.FontBox>
            {language == "ko" ? "VOD 서비스는 모바일 Fanbox앱에서 시청해 주세요."
              :
              (
                <div><p>Please watch the VOD service on</p>the mobile Fanbox app.</div>
              )
            }
            </styled.FontBox>
            <Button style={{ width: '100%', marginTop:'10px', marginBottom:'10px', fontSize:'1rem' }} variant="secondary" onClick={_handleConfirm} className="popup-btn"> {language == "ko" ? "확인" : "Confirm" } </Button>
          </styled.ModalBody>
        </Modal.Dialog>
      </styled.ModalWrapper>
    </>
  )
}


export default VodInfoPopup;
