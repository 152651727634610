import qs from 'query-string';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { appleLogin } from 'modules/auth';

const AppleSigninCallback = (location: any, match: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = qs.parse(location.location.search);

    useEffect(() => {
        let { appleIdToken } = query;

        if (!appleIdToken) return history.push('/signin');

        if (!localStorage.getItem('userAgent')) {
            dispatch(appleLogin(appleIdToken.toString(), () => history.push('/')));
        } else {
            const _userAgent = localStorage.getItem('userAgent');
            if (_userAgent === 'slsdk') window.location.href = `slsdk://login_token?auth_token=${appleIdToken}`;
        }
    }, []);

    return (
        <div />
    )
}

export default AppleSigninCallback;
