import { useDetectMobile } from 'hooks/useDetectMobile';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import iconEmoticon from '../../images/ic-emoticon@3x.png';
import iconKeyboard from '../../images/ic-keyboard@3x.png';
import iconSend from '../../images/ic-send@3x.png';
import { sendMessage } from "../../modules/sendbird";
import * as styled from './Style';


const preventDefault = (e) => {
  e.preventDefault();
}

const MessageForm = ({ channel, showMode, inputFocus, onShowMode, onInputFocus }) => {
  const [message, setMessage] = useState("");
  const [keyboard, setKeyboard] = useState(false);
  const [isMobile, isApplied] = useDetectMobile();
  const [isEmotion, setIsEmotion] = useState(false);

  const dispatch = useDispatch()
  const inputEl = useRef(null);
  const stickerButtonEl = useRef(null);
  const keyboardButtonEl = useRef(null);
  const sendButtonEl = useRef(null);


  useEffect(() => {
    if (channel && dispatch) {
      const touchendCB = (e) => {
        const chatMessage = document.getElementById("chatInput").value;
        sendButtonEl.current.removeEventListener('touchend', touchendCB);
        handleSubmit(chatMessage, channel);
        setIsEmotion(false);
      }
      document.addEventListener('focusout', (e) => {
        //sendButtonEl.current.addEventListener('touchend', touchendCB, {once: true});
        document.removeEventListener('touchmove', preventDefault);
        setIsEmotion(false);
        var rootEL = document.getElementById('StageContainer');

        if (rootEL) {
          rootEL.setAttribute("style", "padding-top:0px;");
        }
        if (sendButtonEl.current) sendButtonEl.current.removeEventListener('touchend', touchendCB);
      });
    }
  }, [channel, dispatch]);

  useEffect(() => {
    document.addEventListener('touchstart', (e) => {

      if (e.target.className == "sc-pZCbX gyPByy") {
        return
      }
      if (e.target.className == "sc-prPLn ocSlp") {
        return
      }
      if (e.target.className == "sc-qQlyE tqJKl") {
        return
      }
      if (e.target.className == "sc-psbuI ilHlHr") {
        return
      }
      //onShowMode("")
    });
  }, []);


  const _handleShow = (e) => {
    onShowMode(e)
    onInputFocus(true)

    if (e == 'emotion') {
      setIsEmotion(true)
    } else {
      setIsEmotion(false)
    }
  }
  //이모티콘뷰, 키보드올라왔을 때 메세지입력폼 사라지는 경우가 있어 높이수정
  useEffect(() => {
    if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i) == null) {
      return
    }
    let keyboardNullEL = document.getElementById('keyboardNull');
    if (showMode == 'keyboard' && inputFocus) {
      if (keyboardNullEL) {
        if (isEmotion) {
          setTimeout(keyboardNullEL.setAttribute("style", "height:55px"), 3000);
        } else {
          keyboardNullEL.setAttribute("style", "height:0px")
        }
      }
    } else if (showMode == 'emotion' && inputFocus) {
      if (keyboardNullEL) {
        keyboardNullEL.setAttribute("style", "height:0px")
      }
    } else {
      if (keyboardNullEL) {
        keyboardNullEL.setAttribute("style", "height:0px")
      }
    }
    if (isEmotion) {
      if (showMode == "keyboard") {
        if (keyboardNullEL) {
          keyboardNullEL.setAttribute("style", "height:0px")
        }
      } else {
        if (keyboardNullEL) {
          keyboardNullEL.setAttribute("style", "height:55px")
        }
      }
    } else {
      if (keyboardNullEL) {
        setTimeout(keyboardNullEL.setAttribute("style", "height:0px"), 1000);
      }
    }
    if (!inputFocus) {
      if (keyboardNullEL) {
        setTimeout(keyboardNullEL.setAttribute("style", "height:0px"), 1000);
      }
    }

  }, [isEmotion, showMode, inputFocus]);

  const _handleHide = (e) => {

    document.addEventListener("touchmove", preventDefault, { passive: false });
    onShowMode("keyboard");
    onInputFocus(true);

    var rootEL = document.getElementById('StageContainer');

    if (rootEL && isMobile) {
      if (rootEL && isMobile) {
        if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
          rootEL.setAttribute("style", "padding-top:150px;");
        }
      }
    }
  }

  const _handleEnter = (e) => {
    if (e.key === 'Enter') {
      if (document.getElementById('send-button-wrapper')) {
        document.getElementById('send-button-wrapper').click()
      }
      e.preventDefault();
    }
  }


  useEffect(() => {
    const clickCB = () => {
      //   setTimeout("window.scrollTo(0, 0)", 150);
      //   document.getElementById('root').scrollTo(0, 0);
    }
    inputEl.current.addEventListener("touchend", clickCB, { passive: false })

  }, []);




  const handleSubmit = useCallback((eMessage, eChannel) => {
    if (!eMessage.trim().length) return setMessage("");

    const _success = () => {
      setMessage("")
      onShowMode("")
      onInputFocus(false);

    }
    if (eMessage && Object.keys(eChannel).length !== 0) {
      dispatch(sendMessage(eChannel, eMessage, "", _success))
      // document.removeEventListener("touchmove", preventDefault);
    }
  }, [channel, dispatch, message])
  
  const handleIconClick = (kind) => {
    _handleShow(kind);
    window.focused = kind !== "emotion";
  };

  const _renderEmotionView = useCallback(() => {
    if (showMode == "emotion") {
      return ("")
      return (
        <styled.IconChat style={{ zIndex: 99 }} onClick={(e) => handleIconClick("keyboard")} ref={keyboardButtonEl} id="keyboard-button-wrapper">
          <styled.IconKeyboardImg src={iconKeyboard} />
        </styled.IconChat>
      )
    } else {
      return (
        <styled.IconChat style={{ zIndex: 99 }} onClick={(e) => handleIconClick("emotion")} ref={stickerButtonEl} id="sticker-button-wrapper">
          <styled.IconEmoticonImg src={iconEmoticon} />
        </styled.IconChat>
      )
    }

  }, [showMode])

  useEffect(() => {
    window.focused = false;
    window.addEventListener('resize', function (event) {
      if (window.orientation === 90 || window.orientation === -90) {
        window.focused = false;
        window.currentOrientation = 'landscape';
        var rootEL = document.getElementById('StageContainer');
        if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
          rootEL.setAttribute("style", "padding-top:0px;");
        }
      } else {
        if (window.currentOrientation === 'portrait') {
          if (inputEl.current && inputEl.current === document.activeElement) {
            if (window.focused) inputEl.current.blur();
            window.focused = !window.focused;
          }
        } else {
          if (window.currentOrientation !== 'landscape') window.focused = true;
        }
        window.currentOrientation = 'portrait';
      }
    });
  }, []);

  return (
    <form className="chat-message-form" id="chatForm">
      <styled.MessageInput
        data-channel={channel}
        ref={inputEl}
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onFocus={(e) => _handleHide(e)}
        onKeyPress={(e) => _handleEnter(e)}
        placeholder={"대화를 나눠보세요."}
        id="chatInput"
        autocomplete="false"
      />
      {_renderEmotionView()}
      <styled.IconChat style={{ zIndex: 99 }} onClick={(e) => handleSubmit(message, channel)} ref={sendButtonEl} id="send-button-wrapper">
        <styled.IconSendImg src={iconSend} />
      </styled.IconChat>
    </form>

  )
}

export default MessageForm
