import styled from "styled-components";
import Colors from '../../constants/styles/colors';

interface ITabs {
  active: boolean;
}

interface IForm {
  paddingTop?: number;
}

export const Form = styled.form<IForm>`
    padding: 0 20px;
    padding-top: ${(props) => props.paddingTop ? props.paddingTop : 0}px;
`

export const InputWrapper = styled.div`
    display: block;
    width: 100%;
    height: 50px;
    padding: 15px 10px;
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 5px;
    margin-bottom: 10px;
`;

export const InputName = styled.input.attrs({ type: 'text' })`
    width: 100%;
    height: 100%;
    color: white;
    font-size: 14px;
    background: transparent;
`;

export const InputBirthDay = styled.input.attrs({ type: 'tel' })`
    width: 100%;
    height: 100%;
    color: white;
    font-size: 14px;
    background: transparent;
`;

export const CheckboxWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
`;

export const CheckboxSavePaymentMethod = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 3px;
    background: transparent;
    margin-left: 9%;
    &:checked {
        background: ${Colors.mainGreen};
    }
`
export const LabelSavePaymentMethod = styled.label`
    font-size: 12px;
    color: white;
`
export const Splitter = styled.div`
    width: 100%;
    height: 1px;
    opacity: 0.1;
    background: #fff;
    margin: 20px 0;
`;

export const ConcertWrapper = styled.div`
    width: 100%;
    height: 70px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.05);
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ConcertIndicator = styled.span`
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 6px;
    top: 10px;
    right: 10px;
    background-color: ${Colors.mainGreen};
`;

export const ConcertTitle = styled.div`
    margin-bottom: 10px;
    font-size: 15px;
    color: white;
`;

export const ConcertAmount = styled.div`
    font-size: 12px;
    color: ${Colors.mainGreen};
`;

export const CheckboxAgreeToProvide = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 3px;
    background: transparent;
    &:checked {
        background: ${Colors.mainGreen};
    }
`
export const LabelAgreeToProvide = styled.label`
    font-size: 0.68rem;
    color: white;
`

export const PaymentInfo = styled.div`
    display: inline-block;
    position: absolute;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 0.8rem;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width:90%;
    transform: translate(-50%, -50%);
    left: 50%;
    margin-top:30px;
`;

export const PaymentInput = styled.input`
    opacity: 0.5;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    display:block;
    padding-left: 15px;
    background-color:transparent;
    width:100%;
    height:100%;
    &::-webkit-input-placeholder {
      line-height: 50px;
      color: #ffffff;
    }
`;

export const BtnDone = styled.button`
  width: 100%;
  height: 50px;
  color: #000000;
  background-color: #62e979;
  line-height: 50px;
  border-radius: 7px;
  text-align: center;
  border: none;
  font-size:1rem;
  margin-top: 20px;
  display:block;
  &:disabled {
    background-color: #3d434a;
    color: white;
  }
`;

export const PurchaseWrapper = styled.div`
    width: 100%;
    padding: 0 20px;
`;

export const PaymentMethodWrapper = styled.div`
    width: 100%;
    margin-bottom: 40px;
    height: 70px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 15px;
    display: flex;
    justify-content: space-between;
`;

export const PaymentMethodCardInfo = styled.div`
     display: flex;
     flex-direction: column;
     justify-content: space-between;
`;
export const PaymentMethodNumber = styled.div`
     color: white;
     font-size: 12px;
     font-weight: 700;
`;

export const PaymentMethodHolderName = styled.div`
     opacity: 0.8;
     color: white;
     font-size: 12px;
`;

export const BtnChangePaymentMethod = styled.button`
    color: ${Colors.mainGreen};
    font-size: 12px;
    font-weight: 700;
    background: none;
`;


export const ModalInfo = styled.span`
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    font-size: 0.9rem;
    letter-spacing: normal;
    color: #ffffff;
    padding-top:2px;
    padding-left: 15px;
    display:block;
    opacity: 0.8;
`;

export const ModalTitle = styled.div`
  position: relative;
  font-size: 1.0rem;
  width: 100%;
  font-weight: 500;
  line-height: 2.2;
  padding-left: 100px;
  font-weight: 500;

`;
export const ModalContent = styled.div`
  position: relative;
  font-size: 0.9rem;
  width: 100%;
  font-weight: 500;
  line-height: 2.2;
  padding-left: 100px;
  opacity: 0.8;
  height: 20px;
  overflow-y: scroll;
`;

export const ModalPrice = styled.div`
  position: relative;
  font-size: 1.0rem;
  width: 100%;
  font-weight: 500;
  line-height: 2.2;
  padding-left: 100px;
  text-align:right;
  padding-right:5px;
  color: #62e979;
  font-weight: 600;
`;


export const StyledDiv = styled.div`
    > div.modal-dialog {
        height: 100vh;
        background-color: #fff;
        z-index: 100;
        width:100% !important;
      }
      > div.modal-content{
        height: 100%;
      }

      > div.modal-header{
        border: 1px solid red !important;
        height: 65%;
      }
      > div.modal-title{
        font-size: 1.2rem;
      }

`;

export const RightButton = styled.div`
    z-index: 103;
    height: 60px;
    display: block;
    width: 100%;
    margin: auto;
    border-radius: 6px;
    position: relative;
`;

export const HeaderRightButton = styled.img`
    width: 50px;
    height: 50px;
    float:right;
    margin-right:10px;
    margin-top:10px;
`;

export const LogoTitle = styled.div`
    z-index: 103;
    height: 50px;
    display: block;
    width: 95%;
    margin: auto;
    border-radius: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    color: #000;
`;

export const Logo = styled.img`
  width:63px;
  height:12px;
  left:0;
  margin-bottom:10px;
`;


export const Title = styled.span`
  font-size:1.25rem;
  font-weight: 500;

`;

export const TotalPrice = styled.div`
    z-index: 103;
    height: 30px;
    display: block;
    width: 95%;
    margin: auto;
    border-radius: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    color: #000;
    text-align: right;
`;

export const Price = styled.div`
  color:#62e979;
  font-size:1.25rem;
  display:inline-block;
  font-weight:bold;
`;

export const Total = styled.div`
  color:#000;
  opacity: 0.5;
  font-size:0.9rem;
  display:inline-block;
  margin-right:25px;
`;

export const Line = styled.img`
  width: 106%;
  height: 20px;
  z-index: 109;
  display: inline-block;
  position: absolute;
  margin-left: -10px;
`;

export const ModalTr = styled.div`
    z-index: 103;
    height: 25px;
    line-height:35px;
    display: flex;
    width: 95%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    color: #000;
    text-align: right;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ModalTh = styled.div`
  color:#000;
  font-size:0.7rem;
  display:inline-block;
  font-weight:400;
  width:25%;
  text-align: left;
  display: block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ModalTd = styled.div`
  color:#000;
  font-size:0.75rem;
  display:inline-block;
  font-weight:bold;
  flex: 1;
  font-weight:600;
  text-align: right;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ReceiptFooter = styled.div`
  position: absolute;
  width: 100%;
  margin-bottom:20px;
  bottom:0;
`;


export const ResultTotal = styled.div`
    z-index: 103;
    height: 40px;
    display: block;
    width: 95%;
    margin: auto;
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 10px;
    position: relative;
    color: #000;
    text-align: right;
    line-height: 40px;
`;

export const ResultTitle = styled.div`
  color:#000;
  opacity: 0.5;
  font-size:0.7rem;
  display:inline-block;
  margin-right:25px;
  float: left;
`;

export const ResultPrice = styled.div`
  color:#000;
  font-size:0.75rem;
  display:inline-block;
  font-weight:bold;
  float: right;
`;



export const SumPrice = styled.div`
    z-index: 103;
    height: 30px;
    display: block;
    width: 95%;
    margin: auto;
    border-radius: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    color: #000;
    text-align: right;
`;

export const SumPriceRes = styled.div`
  color:#62e979;
  font-size:1rem;
  display:inline-block;
  font-weight:bold;
  float: right;
`;

export const Sum = styled.div`
  color:#000;
  font-size:0.75rem;
  font-weight:bold;
  display:inline-block;
  margin-right:25px;
  float: left;
`;

export const LineLast = styled.div`
  width: 100%;
  margin: auto;
  display: block;
  position: absolute;
  margin-left: -20px;
`;

export const PaypalPaymentMethodWrapper = styled.div`
    width: 100%;
    margin-bottom: 40px;
    height: 70px;
    display: flex;
    justify-content: space-between;
`;


export const Paypal = styled.div`
    width: 100%;
    height: 70px;
    color: #000000;
    background-color: #fff;
    line-height: 50px;
    border-radius: 7px;
    text-align: center;
    margin-top: 50px;
    border: none;
    font-size:1rem;
    margin:auto;
    display:block;
    padding-top: 25px;
    float:left;
    opacity: 0.9;
`;

export const PaypalImg = styled.img`
    width: 100px;
    height: 23px;
    margin: auto;
`;

export const PaypalPadding = styled.div`
    width: 100%;
    height: 30px;
`;
