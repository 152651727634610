import { createReducer } from 'typesafe-actions';
import * as types from './types';

const initialState: types.VerificationsState = {
    isVerified: false,
};

const mypageReducer = createReducer(initialState)
    .handleAction(types.T_REQUEST_CONCERT_SMS_VERIFICATION_CODE.PENDING, (state: types.VerificationsState, action: types.VerificationsAction) => ({
        ...state,
    }))
    .handleAction(types.T_REQUEST_CONCERT_SMS_VERIFICATION_CODE.SUCCESS, (state: types.VerificationsState, action: types.VerificationsAction) => ({
        ...state,
    }))
    .handleAction(types.T_REQUEST_CONCERT_EMAIL_VERIFICATION_CODE.PENDING, (state: types.VerificationsState, action: types.VerificationsAction) => ({
        ...state,
    }))
    .handleAction(types.T_REQUEST_CONCERT_EMAIL_VERIFICATION_CODE.SUCCESS, (state: types.VerificationsState, action: types.VerificationsAction) => ({
        ...state,
    }))
    .handleAction(types.T_SUBMIT_CONCERT_SMS_VERIFICATION_CODE.PENDING, (state: types.VerificationsState, action: types.VerificationsAction) => ({
        ...state,
    }))
    .handleAction(types.T_SUBMIT_CONCERT_SMS_VERIFICATION_CODE.SUCCESS, (state: types.VerificationsState, action: types.VerificationsAction) => ({
        ...state,
        isVerified: true
    }))
    .handleAction(types.T_SUBMIT_CONCERT_EMAIL_VERIFICATION_CODE.PENDING, (state: types.VerificationsState, action: types.VerificationsAction) => ({
        ...state,
    }))
    .handleAction(types.T_SUBMIT_CONCERT_EMAIL_VERIFICATION_CODE.SUCCESS, (state: types.VerificationsState, action: types.VerificationsAction) => ({
        ...state,
        isVerified: true
    }))
    .handleAction(types.T_SET_IS_VERIFIED, (state: types.VerificationsState, action: types.VerificationsAction) => ({
        ...state,
        isVerified: action.payload
    }))

export default mypageReducer;
