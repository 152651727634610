import Header from 'components/Common/Header';
import CardList from 'components/MyPage/CardList';
import { Container } from 'constants/styles/layout';
import iconProfileEdit from 'images/btn-edit-circle.png';
import iconProfile from 'images/img-profile-default-s.png';
import { loadMe } from 'modules/auth';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reservesList } from '../../modules/mypage';
import locale from './locale.json';
import * as styled from './Style';
import { RootState } from 'modules/types';

const listSelector = ({ mypage: { list } }: any) => list;

const watchedSelector = ({ mypage: { watched } }: any) => watched;
const reservedSelector = ({ mypage: { reserved } }: any) => reserved;
const passedSelector = ({ mypage: { passed } }: any) => passed;

type Mypages = {
  userDataData: any;
  uData: any;
}

const MainList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lists = useSelector(listSelector);
  const watched = useSelector(watchedSelector);
  const reserved = useSelector(reservedSelector);
  const passed = useSelector(passedSelector);
  const userData = useSelector((state: RootState) => state.auth.userData);


  const [count, setCount] = useState(0);
  const [language, setLanguage] = useState<any>({});


  // Compnent did mount
  useEffect(() => {
    dispatch(loadMe());
    dispatch(reservesList());
  }, []);

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])


  const _editStage = () => {
    history.push("/myprofile")
  }

  const _isActiveNextBtn = () => {
    return false;
  }

  if (!userData) return null;

  return (
    <Container>
      <Header iHeaderCenter={"My"} iBackLeftArrowIcon={true} iBackRightSettingIcon={true} iconClose={false} />
      <styled.Content>
        <styled.Profile>
          <styled.ProfileImgWrapper>
            <styled.ProfileImg src={userData.profileUrl ? userData.profileUrl : iconProfile} onClick={_editStage} />
            <styled.ProfileEditImg src={iconProfileEdit} onClick={_editStage} />
          </styled.ProfileImgWrapper>
          <styled.ProfileNickNameText>
            {userData.nickName && userData.nickName}
          </styled.ProfileNickNameText>
          <styled.ProfileInfoText>
            {userData.extType === '' ? '' : userData.extType} {language.my_login}
          </styled.ProfileInfoText>
          <styled.BtnReadRedeemCode onClick={() => history.push('redeem-codes')}>예매번호 조회</styled.BtnReadRedeemCode>
        </styled.Profile>
        <styled.Tabs active={count == 0 ? true : false} onClick={() => setCount(0)}><styled.TabsFont>{language.my_reservation_list}</styled.TabsFont></styled.Tabs>
        <styled.Line/>
        <styled.Tabs active={count == 1 ? true : false} onClick={() => setCount(1)}><styled.TabsFont>{language.my_watch_list}</styled.TabsFont></styled.Tabs>
        <styled.Line/>
        <styled.Tabs active={count == 2 ? true : false} onClick={() => setCount(2)}><styled.TabsFont>{language.my_past_list}</styled.TabsFont></styled.Tabs>
      </styled.Content>
      <styled.TabContainer>
        {count == 0 ? <CardList data={reserved} /> : false}
        {count == 1 ? <CardList data={watched} /> : false}
        {count == 2 ? <CardList data={passed} /> : false}
      </styled.TabContainer>
    </Container>
  )
}

export default MainList;
