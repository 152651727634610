import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #1a1f25;
  padding: 0 20px;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;
  color: white;
  overflow-y: none;
  top: 0;
  position: fixed;
  z-index: 999;
  padding-top: 50px;
`;

export const MyRanking = styled.div`
  height: calc(100vh - (85vh));
  border: 1px solid red;
  display: block;
  width: 95%;
  margin: auto;
  border-radius: 6px;
  background-color: #333a40;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;

  display: flex;
  position: relative;
  width: 90%;
  flex-direction: column;

`;
export const MyEmail = styled.div`
  display: inline-block;
  height: 100%;
  color: #fff;
  font-size: 0.8rem;
  width: 50%;
  font-size: 15px;
  font-weight: 600;
  float: left;
  line-height: 24px;
`;

export const MyRankingData = styled.div`
  display: inline-block;
  height: 100%;
  color: #fff;
  font-size: 0.8rem;
  width: 30%;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
`;

export const RankingListWrapper = styled.div`
  border-radius: 6px;
`;

export const Ranking = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
  width: 90%;
  margin: auto;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  padding: 10px 0;
`;

export const RankingImg = styled.div`
  color: white;
  width: 20%;
  display: inline-block;
  float: left;
`;

export const Profile = styled.img`
  width: 30px;
  height: 30px;
  margin: auto;
  vertical-align: middle;
`;

export const RankingEmail = styled.div`
  display: inline-block;
  height: 100%;
  color: #fff;
  font-size: 0.8rem;
  width: 50%;
  font-size: 15px;
  font-weight: 600;
  float: left;
  line-height: 24px;
`;
export const RankingData = styled.div`
  display: inline-block;
  height: 100%;
  color: #fff;
  font-size: 0.8rem;
  width: 30%;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
`;

export const HeaderRightButton = styled.img`
  width: 50px;
  height: 50px;
`;
