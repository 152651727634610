import { LINE_SIGNIN_CHANNEL_ID, LINE_SIGNIN_REDIRECT_URL } from 'constants/apiInfo';
import React from 'react';
import { BtnLineSignin, ImgLineIcon } from "./Style";
import iconLine from 'images/ic-line.png';

const LineLogin = () => {
    return (
        <BtnLineSignin href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_SIGNIN_CHANNEL_ID}&redirect_uri=${LINE_SIGNIN_REDIRECT_URL}&state=12345abcde&scope=profile%20openid%20email&nonce=09876xyz`}>
            <ImgLineIcon src={iconLine} />
        </BtnLineSignin>
    )
}

export default LineLogin;