import styled from "styled-components";

export const Button = styled.button`
  width: 100%;
  height: 50px;
  color: #000000;
  background-color: #62e979;
  line-height: 50px;
  border-radius: 7px;
  text-align: center;
  border: none;
  font-size:1rem;
  margin-top: 20px;
  display:block;
  &:disabled {
    background-color: #3d434a;
    color: white;
  }
`;