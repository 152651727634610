import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import locale from './locale.json';
import * as styled from './Style';

interface IPaymentPolicyModalProps {
  show: boolean;
  onCancel: () => void;
}

const PaymentPolicyModal = ({ show, onCancel }: IPaymentPolicyModalProps) => {
  const [language, setLanguage] = useState<any>({});

  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  if (!show || !language.title) return <div />

  return (
    <styled.ModalWrapper>
      <Modal.Dialog className="popup-dialog">
        <Modal.Body>
          <styled.Wrapper>
            <styled.Title>{language.title}</styled.Title>
            <styled.SubTitle>{language.subtitle}</styled.SubTitle>
            {language.contents.map((c, idx) => {
              return (
                <styled.ContentWrapper key={'policy-' + idx}>
                  <styled.ContentTitle>{c.title}</styled.ContentTitle>
                  <styled.ContentDesc>{c.desc}</styled.ContentDesc>
                </styled.ContentWrapper>
              )
            })}
          </styled.Wrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel} className="popup-btn">{language.confirm}</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </styled.ModalWrapper>
  )
}

export default PaymentPolicyModal;
