import React from 'react';
import * as styled from './Style';
import iconLike from 'images/ic-like@3x.png';

interface IControlOverlay {
    videoState: any;
}
const MobileControlOverlay = ({ videoState }: IControlOverlay) => {
    return (
        <styled.Container>
            {videoState.isLive && <styled.Live isMobile={true}><styled.Dot />Live</styled.Live>}
        </styled.Container>
    );
}
export default MobileControlOverlay;
