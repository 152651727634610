import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import locale from './locale.json';
import { StyledDiv } from './Style';


interface IInfoPopupProps {
    title: string;
    onCancel: () => void;
}


const InfoPopup = ({ title, onCancel }: IInfoPopupProps) => {
    const [language, setLanguage] = useState<any>({});

    useEffect(() => {
      if(localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
      else setLanguage(locale.en)
    }, [language])

    const _handleCancel = (e: any) => {
      onCancel();
    }

    return (
      <StyledDiv>
        <Modal.Dialog className="popup-dialog">
          <Modal.Header closeButton className="popup-header">
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="secondary" onClick={(e: any) => _handleCancel(e)} className="popup-btn">{language.confirm}</Button>
          </Modal.Footer>
        </Modal.Dialog>
      </StyledDiv>
    )

}


export default InfoPopup;
