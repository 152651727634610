import React, { useEffect, useState } from 'react';
import * as styled from './Style';
import { useDispatch } from 'react-redux';
import LanguagePopup from '../../components/Signin/LanguagePopup';
import ReactGA from 'react-ga';
import { SNS_ANDROID_KEY } from 'constants/apiInfo';

import poster_main from 'images/landing_main.png';
import poster_1 from 'images/poster_1.jpg';
import poster_2 from 'images/poster_2.png';
import poster_3 from 'images/poster_3.png';
import poster_4 from 'images/poster_4.png';
import poster_5 from 'images/poster_5.png';
import ticketOn from 'images/img-btn-bg.png';
import logo from 'images/img-header-logo.png';
import footerimg from 'images/img-footer-logo.png';
import iconOpenSelect from 'images/ic-open-select.png';
import abxis_detail from 'images/abxis_detail.jpg';
import abxis_detail_en_0827 from 'images/abxis_detail_en_0827.jpg';

import { setModal } from 'modules/common';


const LandingPage = () => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(false);
  const [langugeCode, setLangugeCode] = useState<string>('');
  const [infoPopup, setInfoPopup] = useState<string>("");


  const localCode = localStorage.getItem('languageCode') || 'ko';
  useEffect(() => {
    ReactGA.event({ category: 'Promotion_Landing', action: 'Landing_Enter', label: 'Landing' });

    console.log("langugeCode",localCode)
  }, [])


  useEffect(() => {
    setLangugeCode(localCode)
  }, [localCode])

  const _changeLanguge = () => {

    setLanguage(true);
  }

  const _langugePopupDiv = () => {
    if (language) {
      return (
        <styled.Dim>
          <LanguagePopup title={infoPopup} onCancel={() => setLanguage(false)} />
        </styled.Dim>
      )
    } else {
      return (null)
    }
  }


  var rootEL =  document.getElementById('root');
  if(rootEL){
    rootEL.setAttribute("style", "background-color:#1a1f25;backdrop-filter: blur(30px);");
  }

//.modal-dialog
  const _openTicketFan = () => {
    dispatch(setModal(true, `${langugeCode == 'ko' ? `${"AB6IX 팬클럽 한정 예매기간 8월 26일~28일까지 입니다."}` : `${"Fan Club Pre-Sales is from August 26th to 28th."}`}`, ''));
  }

  const _openTicket = () => {
    dispatch(setModal(true, `${langugeCode == 'ko' ? `${"AB6IX 일반 티켓 예매기간은 8월 29일~9월 12일까지 입니다."}` : `${"General Sales is from August 29th to September 12th."}`}`, ''));
  }
  const _openLink = () => {
    window.location.href = 'https://' + `${SNS_ANDROID_KEY}` + '/sns';
  }

  return (
    <styled.Container>
    <styled.Header>
      <styled.HeaderLogo src={logo} />
      <styled.Language onClick={_changeLanguge}>
        <styled.LangugeBox>{langugeCode == 'ko' ? '한국어' : 'English'}</styled.LangugeBox>
        <styled.OpenSelect src={iconOpenSelect} />
      </styled.Language>
    </styled.Header>
      <styled.Content onClick={_openLink} style={{ marginBottom: '55px' , padding: '5%', marginTop: '10px' }}>
        <styled.MainCardWrapper  thumbnailsUrl={poster_main}>
          <styled.TicketLabelWrapper isDetail={true} ticketUrl={ticketOn}>
            {langugeCode == 'ko' ? 'FANBOX 바로가기' : 'Open FANBOX'}
          </styled.TicketLabelWrapper>
        </styled.MainCardWrapper>
      </styled.Content>
      <styled.Content>
        <styled.ConcertWrapper>
          <styled.ConcertTitle>2020 AB6IX ONLINE CONCERT</styled.ConcertTitle>
          <styled.ConcertDate>2020.09.12.SAT 18:00(KST)</styled.ConcertDate>
        </styled.ConcertWrapper>
        <styled.OpenWrapper onClick={_openLink}>
          <styled.OpenType>{langugeCode == 'ko' ? '팬클럽 선예매' : 'Fan Club Pre-Sales'}</styled.OpenType>
          <styled.Line></styled.Line>
          <styled.OpenDate>2020. 08. 26 WED 12:00 ~ 08. 28 FRI 23:59 (KST)</styled.OpenDate>
        </styled.OpenWrapper>
        <styled.OpenWrapper onClick={_openLink}>
          <styled.OpenType>{langugeCode == 'ko' ? '일반예매' : 'General Sales'}</styled.OpenType>
          <styled.Line></styled.Line>
          <styled.OpenDate>2020. 08. 29 SAT 12:00 ~ 09. 12 SAT 17:59 (KST)</styled.OpenDate>
        </styled.OpenWrapper>

      </styled.Content>
      <styled.Content>
        <styled.Poster>
          <styled.PosterImg src={`${langugeCode == 'ko' ? abxis_detail : abxis_detail_en_0827}` }/>
        </styled.Poster>
      </styled.Content>
      <styled.Content>

        <styled.Poster>
          <styled.PosterImg src={poster_2} />
        </styled.Poster>
        <styled.Poster>
          <styled.PosterImg src={poster_3} />
        </styled.Poster>
        <styled.Poster>
          <styled.PosterImg src={poster_4} />
        </styled.Poster>
        <styled.Poster>
          <styled.PosterImg src={poster_5} />
        </styled.Poster>
      </styled.Content>
      <styled.Content>
        <styled.FooterLogo src={footerimg} />
        <styled.Copyright>Copyright © FANBOX https://fanbox.live</styled.Copyright>
      </styled.Content>
      {_langugePopupDiv()}
    </styled.Container>
  );
}

export default LandingPage;
