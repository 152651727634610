import React, { useState, useEffect } from 'react';
import * as styled from './Style';
import FAQListItem from 'components/FAQs/FAQListItem';
import { callLoadFAQs } from '../../core/api/faqs';
import Header from '../../components/Common/Header';
import { useHistory } from 'react-router-dom';

type TFAQ = {
  question: string;
  answer: string;
}

const FAQs = () => {
  const [faqs, setFAQs] = useState<TFAQ[]>([]);
  const [userAgent, setuserAgent] = useState("");
  const [langugeCode, setLangugeCode] = useState<string>('en');
  const localCode = localStorage.getItem('languageCode') || '';

  const history = useHistory();

  useEffect(() => {
    if (history.location.search.includes('en')) {
      setLangugeCode('en');
      _loadFAQs('en');
    }
    else {
      setLangugeCode(localCode);
      _loadFAQs(localCode);
    }
  }, [history]);

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) setuserAgent("fanbox")
  }, []);

  const _loadFAQs = async (lang) => {
    const data = await callLoadFAQs(lang);
    setFAQs(data);
  }

  const _renderContact = () => {
    const supportEmail = 'support@fanbox.live';

    if (langugeCode === 'ko') {
      return (
        <div>추가 지원이 필요한 경우  <styled.MainLink href={`mailto:${supportEmail}`}>{supportEmail}</styled.MainLink> 으로 문의하십시오.</div>
      )
    } else {
      return (
        <div style={{fontSize: '0.9375rem'}}>
          <div>If you require additional assistance, please contact <styled.MainLink href={`mailto:${supportEmail}`}>{supportEmail}</styled.MainLink></div>
        </div>
      )
    }
  }

  const _renderCompanyInfo = () => {
    if (langugeCode === 'en') {
      return (
        <div>
          <div style={{paddingTop:'10px', fontSize: '0.8375rem'}}>
            <div style={{paddingTop:'2px'}}>435 Tasso Street Suite 315 Palo Alto, CA , USA 94301</div>
          </div>
        </div>
      )
    }
  }

  return (
    <styled.Container paddingTop={userAgent ? 0 : 60}>
      <Header iHeaderCenter={'FAQ'} iBackLeftArrowIcon={false} iBackRightSettingIcon={false} iconClose={true} />
      <styled.FaqsListWrapper>
        {faqs.map((f: TFAQ, idx: number) => {
          return (
            <FAQListItem key={`faq-` + idx} faq={f} />
          )
        })}
      </styled.FaqsListWrapper>
      <styled.Contact>
        {_renderContact()}
        {_renderCompanyInfo()}
      </styled.Contact>
    </styled.Container>
  )
}

export default FAQs;
