import styled from 'styled-components';

interface IContainer {
  paddingTop:number;
}

export const Container = styled.div<IContainer>`
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #1a1f25;
    padding: 20px;
    flex-direction: column;
    font-size: 14px;
    line-height: 24px;
    color: white;
    overflow-y: scroll;
    padding-bottom: 30px;
    padding-top: ${(props) => props.paddingTop ? props.paddingTop : 0}px;
`

export const FaqsListWrapper = styled.div`
    max-height: calc(100vh - 150px);
    overflow: scroll;
`;


export const Contact = styled.div`
    font-size:0.8rem;
    color:#fff;
    bottom: 10%;
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    opacity: 0.6;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 350px;

    left:50%;
    transform: translate(-50%, 0);
`;


export const MainLink = styled.a`
  text-decoration: underline;
`;
