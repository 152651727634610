import { createReducer } from 'typesafe-actions';
import * as types from './types';

const initialState: types.CommonState = {
    showLoading: false,
    showModal: false,
    modalImg: '',
    modalTitle: '',
    streamingUrl:'',
    showTerm: false,
    checkTerm: false,
    showTermsAndPrivacy: false,
};

const commonReducer = createReducer(initialState)
    .handleAction(types.T_SET_SHOW_LOADING, (state: types.CommonState, action: types.CommonAction) => ({
        ...state,
        showLoading: action.payload
    }))
    .handleAction(types.T_SET_MODAL, (state: types.CommonState, action: types.CommonAction) => ({
        ...state,
        showModal: action.payload.showModal,
        modalTitle: action.payload.modalTitle,
        modalImg: action.payload.modalImg
    }))
    .handleAction(types.T_SET_STREAMING_URL, (state: types.CommonState, action: types.CommonAction) => {
      return {
        ...state,
        streamingUrl: action.payload
      }
    })
    .handleAction(types.T_SET_TERM, (state: types.CommonState, action: types.CommonAction) => ({
        ...state,
        showTerm: action.payload.showTerm,
        checkTerm: action.payload.checkTerm
    }))
    .handleAction(types.T_SET_TERMS_PRIVACY, (state: types.CommonState, action: types.CommonAction) => ({
        ...state,
        showTermsAndPrivacy: action.payload.showTermsAndPrivacy
    }))
export default commonReducer;
