import { createReducer } from 'typesafe-actions';
import * as types from './types';

const initialState: types.MessagesState = {
    isFetching: false,
    data: {
        pushs: [],
        notices: []
    }
};


const authReducer = createReducer(initialState)
    .handleAction(types.T_FETCH_MESSAGES.PENDING, (state: types.MessagesState, action: types.MessagesAction) => ({
        ...state,
        isFetching: true,
    }))
    .handleAction(types.T_FETCH_MESSAGES.SUCCESS, (state: types.MessagesState, action: types.MessagesAction) => ({
        ...state,
        isFetching: false,
        data: action.payload
    }))
    .handleAction(types.T_FETCH_MESSAGES.FAILURE, (state: types.MessagesState, action: types.MessagesAction) => ({
        ...state,
        isFetching: false,
        data: null
    }))

export default authReducer;
