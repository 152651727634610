import iconCloseBtn from 'images/ic-close-black.png';
import iconLogoGray from 'images/img-logo-text-small-gray.png';
import iconLine from 'images/img-receipt-line.png';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { HeaderRightButton, Line, LineLast, Logo, LogoTitle, ModalTd, ModalTh, ModalTr, Price, ReceiptFooter, ResultPrice, ResultTitle, ResultTotal, RightButton, StyledDiv, Sum, SumPrice, SumPriceRes, Title, Total, TotalPrice } from './Style';
import locale from './locale.json';
import { amountToPrice } from 'core/utils/numberHelper';

type TReceiptData = {
  uuid: string;
  amount: number;
  concertStartTime: string;
  createdAt: string;
  kind: string;
  metadata: {
    concertName: string;
  }
  source: any;
  currency: string;
  status: string;
  type: string;
}

interface IDateFormProps {
  receiptData: null | TReceiptData;
  onCancel: () => void;
}

const InfoPopup = ({ receiptData, onCancel }: IDateFormProps) => {
  const _handleCancel = (e: any) => {
    onCancel();
  }

  const [language, setLanguage] = useState<any>({});


  useEffect(() => {
    if (localStorage.getItem('languageCode') == 'ko') setLanguage(locale.ko)
    else setLanguage(locale.en)
  }, [language])

  if (!receiptData) return null;

  return (
    <StyledDiv>
      <RightButton>
        <HeaderRightButton src={iconCloseBtn} onClick={(e: any) => _handleCancel(e)} />
      </RightButton>
      <LogoTitle>
        <Logo src={iconLogoGray} />
        <Title>{receiptData.type === 'charge' ? language.Pay2 : language.Refund } {language.Receipt}</Title>
      </LogoTitle>

      <TotalPrice>
        <Total>{language.TotalAmount}</Total>
        <Price>{amountToPrice(receiptData.amount, receiptData.currency)}</Price>
      </TotalPrice>

      <Line src={iconLine} />

      <Modal.Dialog className="popup-dialog">
        <Modal.Header closeButton className="receipt-popup-header">
        </Modal.Header>

        <ModalTr>
          <ModalTh>Receipt #</ModalTh>
          <ModalTd>{receiptData.uuid}</ModalTd>
        </ModalTr>
        <ModalTr>
          <ModalTh>{language.PaymentInformation}</ModalTh>
          {receiptData.kind === 'stripe' ? (
            <ModalTd>{`${receiptData.source.brand} xxxx xxxx xxxx ${receiptData.source.last4}`}</ModalTd>
          ) : (
            <ModalTd>{`${receiptData.kind}`}</ModalTd>
          )}
        </ModalTr>
        <ModalTr>
          <ModalTh>{language.PaymentDate}</ModalTh>
          <ModalTd>{moment(receiptData.createdAt).format('MMM D, YYYY')}</ModalTd>
        </ModalTr>
        <ModalTr>
          <ModalTh>{language.EventName}</ModalTh>
          <ModalTd>{receiptData.metadata.concertName}</ModalTd>
        </ModalTr>
        <ModalTr>
          <ModalTh>{language.EventDate}</ModalTh>
          <ModalTd>{moment(receiptData.concertStartTime).format('MMM D, YYYY')}</ModalTd>
        </ModalTr>
        <ModalTr>
          <ModalTh>{language.CompanyName}</ModalTh>
          <ModalTd>Seerslab Inc.</ModalTd>
        </ModalTr>
        <ModalTr>
          <ModalTh>{language.Address}</ModalTh>
          <ModalTd>435 Tasso street, Suite 315, Palo Alto CA</ModalTd>
        </ModalTr>
        <ModalTr className="last-line">
          <ModalTh>{language.BusinessRegistrationNumber}</ModalTh>
          <ModalTd>81-0833269</ModalTd>
        </ModalTr>
        <LineLast>
          <Line src={iconLine} />
        </LineLast>
        <ResultTotal>
          <ResultTitle>{language.Amount}</ResultTitle>
          <ResultPrice>{amountToPrice(receiptData.amount, receiptData.currency)}</ResultPrice>
        </ResultTotal>
        <SumPrice>
          <Sum>{language.Total}</Sum>
          <SumPriceRes>{amountToPrice(receiptData.amount, receiptData.currency)}</SumPriceRes>
        </SumPrice>
        <ReceiptFooter>
          <Button variant="secondary" onClick={(e: any) => _handleCancel(e)} className="popup-btn">{language.Confirm}</Button>
        </ReceiptFooter>
      </Modal.Dialog>
    </StyledDiv>
  )
}

export default InfoPopup;
