import styled from "styled-components";
import Background from '../../images/img-bacground.png'


export const Header = styled.div`
    width: 100%;
    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top:0;
    height: 70px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: #1a1f25;
    @media only screen and (min-width: 700px) {
      height: 80px;
    }
`;

export const HeaderLogo = styled.img`
    width: 120px;
    height: auto;
    margin: auto;
    position: relative;
    align-items: center;
    display: flex;
`;


export const Container = styled.div`
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(30px);
    background-color: #1a1f25;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`

  flex-direction:column;
  justify-content: space-around;
  margin-bottom:10px;

`;

export const Poster = styled.div`
  margin:7px;
  margin-right: 5%;
  margin-left: 5%;
  border-radius:5px;
`;

export const PosterImg = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
`;

export const ConcertWrapper = styled.div`
  display:flex;
  flex-direction:column;
  text-align: center;
  height: 80px;
  font-family: 'Montserrat', sans-serif;
`;

export const ConcertTitle = styled.div`
  color: #fff;
  font-weight: 700;
  font-size: 1.3rem;
  height: 50px;
  letter-spacing: 1px;
  @media only screen and (min-width: 700px) {
    font-size: 1.4rem;
    letter-spacing: 1.48px;
  }

`;

export const ConcertDate = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 1.1rem;
  height: 50px;
  letter-spacing: 1.48px;
`;

export const OpenWrapper = styled.div`
  display:flex;
  flex-direction:column;
  text-align: center;
  border-radius: 10px;
  border: solid 1px rgba(255,255,255,0.3);
  height: 120px;
  margin: 10px;
  margin-right: 5%;
  margin-left: 5%;
`;

export const OpenType = styled.div`
  color: #24ee57;
  font-weight: 500;
  font-size: 1.1rem;
  height:58px;
  line-height: 58px;
`;

export const Line = styled.div`
  width:90%;
  height:2px;
  margin:auto;
  opacity: 0.2;
  background-color: #ffffff;
`;

export const OpenDate = styled.div`
  color: #fffefe;
  font-weight: 300;
  font-size: 0.9rem;
  height:58px;
  line-height: 58px;
`;

interface ICardWrapper{
    thumbnailsUrl: string;
}

export const MainCardWrapper = styled.div<ICardWrapper>`
    width: 100%;
    height: 450px;
    border-radius: 10px;
    background:  url(${(props) => props.thumbnailsUrl? props.thumbnailsUrl : ""}) no-repeat top/cover;
    background-image: url(${(props) => props.thumbnailsUrl? props.thumbnailsUrl : ""});
    position:relative;
    top: 50px;
    margin-top: 10px;
    margin-bottom:50px;
    @media only screen and (min-width: 700px) {
      height: 600px;
    }
`;

interface ITicket {
  ticketUrl: string;
  isDetail: boolean;
}

export const TicketLabelWrapper = styled.div<ITicket>`
  background-image: url(${(props) => props.ticketUrl? props.ticketUrl : props.ticketUrl});
  width: 90%;
  height: 50px;
  bottom: -20px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 100%;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-weight: 800;
  background-size: 100%;
  line-height: 50px;
  font-size: 1.2rem;
  letter-spacing: 1.8px;
  font-family: 'Montserrat', sans-serif;
  @media only screen and (min-width: 700px) {
    height: 65px;
    line-height: 65px;
    font-size: 1.6rem;
  }
`;

export const TicketImg = styled.div`
  max-width: 100%;
  height: auto;

`;

export const FooterLogo = styled.img`
    width: 120px;
    height: auto;
    margin: auto;
    position: relative;
    align-items: center;
    display: flex;
    padding-top:20px;
    padding-bottom:20px;
`;

export const Copyright = styled.div`
    font-size:0.8rem;
    color:#fff;
    bottom: 3%;
    display: block;
    width: 100%;
    text-align: center;
    opacity: 0.3;
`;

export const Dim = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid black;
`;

export const Language = styled.div`
    top: 15px;
    display: block;
    position: absolute;
    width: 95px;
    height: 36px;
    background-color: rgba(0, 0, 0, 0.4);
    right:15px;
    border-radius:5px;
`

export const LangugeBox = styled.div`
    color: #fff;
    font-size: 0.9rem;
    line-height:36px;
    padding-left:15px;
`

export const OpenSelect = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;
    right: 10px;
`;
