import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as styled from './Style';
import PrompterChat from 'components/Prompter/Chat';

import { mainList, enterConcert } from '../../modules/main';
import { loadMe } from '../../modules/auth';
import { setPrompterThreshold } from 'modules/sendbird';

type Concerts = {
  id: string;
  uuid: string;
  title: string;
  amount: number;
  maxAudience: number;
  description: string;
  thumbnails: any;
  mainPosterUrl: string;
  onClick: any;
  onClickId: any;
  isEnded: boolean;
  startTime: string;
  endTime: string;
}


const listSelector = ({ main: { list } }: any) => list;
const enterObjSelector = ({ main: { enterObj } }: any) => enterObj;

const Prompter = () => {
  const [threshold, setThreshold] = useState<number>(1);
  const dispatch = useDispatch();

  const concerts = useSelector(listSelector);
  const enterObj = useSelector(enterObjSelector);

  const [chatInfo, setChatInfo] = useState(false);

  useEffect(() => {
    let rootStyle = (document.getElementById("root") as HTMLElement)
    rootStyle.setAttribute("style", "transform:none !important; left:0 !important; width:100% !important; position:unset !important;");
  }, []);

  useEffect(() => {
    dispatch(loadMe());
    dispatch(mainList());
  }, [])

  const _handleEnter = (concertUuid) => {
    const _success = () => {
      setChatInfo(true)
    }
    const _error = (enterErrorMessage: any) => {
      console.log("_error", enterErrorMessage)
    }
    //enterErrorMessage
    dispatch(enterConcert(concertUuid, _success, _error))
  }

  const _renderChat = () => {
    if (!chatInfo) {
      return (
        concerts.map((item: Concerts) => {
          return (
            <styled.Card key={item.uuid}>
              {item.title}
              <styled.Btn onClick={() => _handleEnter(item.uuid)}>입장</styled.Btn>
            </styled.Card>
          )
        })
      )
    } else {
      return (
        <PrompterChat channelUrl={enterObj.channelUrl} />
      )
    }
  }


  if (concerts.length > 0) {
    return (
      <styled.Container>
          {chatInfo &&
        <styled.ThresholdInputWrapper>
          <styled.ThresholdLabel>threshold</styled.ThresholdLabel>
          <styled.ThresholdInput value={threshold} onChange={(e) => setThreshold(Number(e.target.value))} />
          <styled.ThresholdButton onClick={() => dispatch(setPrompterThreshold(threshold))}>
            Version
          </styled.ThresholdButton>
        </styled.ThresholdInputWrapper>
          }
        {_renderChat()}
      </styled.Container>
    )
  } else {
    return (<div>XX</div>)
  }

}

export default Prompter;
