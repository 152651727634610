import { API_URL } from '../../constants/apiInfo';
import { getApiAxiosWithToken } from '../utils/api';

export async function callReservesList() {
  const _axios = getApiAxiosWithToken();
  const response = await _axios.get(`${API_URL}/api/v1/users/mypage`);

  return response.data;
}

interface IUpdateNickName {
  nickname: string;
}

export async function callUpdateNickName({ nickname}: IUpdateNickName) {
  const _axios = getApiAxiosWithToken();
  const _body = {
    nickname: nickname,
  }
  const response = await _axios.patch(`${API_URL}/api/v1/users/updateNickName`, _body);

  return response.data;
}
