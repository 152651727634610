import { createReducer } from 'typesafe-actions';
import * as types from './types';

const initialState: types.AuthState = {
    isLoadingAuth: false,
    userData: null,
    lineAuthRes: null,
    isTwitterProcessing: false
};


const authReducer = createReducer(initialState)
    .handleAction(types.T_GOOGLE_SIGNIN.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_GOOGLE_SIGNIN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('refresh_token', action.payload.refreshToken);
        return {
            ...state,
            isLoadingAuth: false,
            userData: action.payload
        }
    })
    .handleAction(types.T_KAKAO_SIGNIN.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_KAKAO_SIGNIN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('refresh_token', action.payload.refreshToken);
        return {
            ...state,
            isLoadingAuth: false,
            userData: action.payload
        }
    })
    .handleAction(types.T_FACEBOOK_SIGNIN.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_FACEBOOK_SIGNIN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('refresh_token', action.payload.refreshToken);
        return {
            ...state,
            isLoadingAuth: false,
            userData: action.payload
        }
    })
    .handleAction(types.T_APPLE_SIGNIN.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_APPLE_SIGNIN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('refresh_token', action.payload.refreshToken);
        return {
            ...state,
            isLoadingAuth: false,
            userData: action.payload
        }
    })
    .handleAction(types.T_LINE_SIGNIN.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_LINE_SIGNIN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('refresh_token', action.payload.refreshToken);
        return {
            ...state,
            isLoadingAuth: false,
            userData: action.payload
        }
    })
    .handleAction(types.T_LOAD_ME.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_LOAD_ME.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
        return {
            ...state,
            isLoadingAuth: false,
            userData: action.payload
        }
    })

    .handleAction(types.T_VERIFY_TOKEN.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_VERIFY_TOKEN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refresh_token', action.payload.refreshToken);
      localStorage.setItem('countryCode', action.payload.country);
      localStorage.setItem('languageCode', action.payload.language);
      return {
        ...state,
        userData: action.payload,
        isLoadingAuth: false,
      }
    })
    .handleAction(types.T_REFRESH_TOKEN.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_REFRESH_TOKEN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('refresh_token', action.payload.refreshToken);
        return {
            ...state,
            isLoadingAuth: false
        }
    })
    .handleAction(types.T_REFRESH_TOKEN.FAILURE, (state: types.AuthState, action: types.AuthAction) => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        return {
            ...state,
            isLoadingAuth: false,
            userData: {}
        }
    })
    .handleAction(types.T_LINE_AUTH.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
    }))
    .handleAction(types.T_LINE_AUTH.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
        return {
            ...state,
            lineAuthRes: action.payload
        }
    })
    .handleAction(types.T_LINE_AUTH.FAILURE, (state: types.AuthState, action: types.AuthAction) => {
        return {
            ...state,
            lineAuthRes: null
        }
    })
    .handleAction(types.T_TWITTER_SIGNIN.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_TWITTER_SIGNIN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
        return {
            ...state,
            isLoadingAuth: false
        }
    })
    .handleAction(types.T_TWITTER_SIGNIN.FAILURE, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_SET_USER_INFO, (state: types.AuthState, action: types.AuthAction) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('refresh_token', action.payload.refreshToken);
        return {
            ...state,
            isLoadingAuth: false,
            userData: action.payload
        }
    })
    .handleAction(types.T_SET_TWITTER_PROCESSING, (state: types.AuthState, action: types.AuthAction) => {
        return {
            ...state,
            isTwitterProcessing: action.payload,
        }
    })
    .handleAction(types.T_ANOTHER_VERIFY_TOKEN.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))
    .handleAction(types.T_ANOTHER_VERIFY_TOKEN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refresh_token', action.payload.refreshToken);
      localStorage.setItem('countryCode', action.payload.country);
      localStorage.setItem('languageCode', action.payload.language);
      return {
        ...state,
        userData: action.payload,
        isLoadingAuth: false,
      }
    })
    .handleAction(types.T_ANOTHER_VERIFY_TOKEN.PENDING, (state: types.AuthState, action: types.AuthAction) => ({
        ...state,
        isLoadingAuth: true
    }))

export default authReducer;
