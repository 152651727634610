import { useScript } from 'hooks/useScript';
import React, { useEffect, useState } from 'react';
import * as styled from './Style';
import iconPeople from 'images/ic-people.png';

declare global {
  interface Window { vr: any; player: any; videojs: any; RadiantMP: any; rmp: any; }
}

interface IRM2dPlayer {
  landscape_mode: boolean;
  currentMobile: boolean;
  streamingUrl_2d: string | Array<number>;
}

const RM2dPlayer = ({ landscape_mode, currentMobile, streamingUrl_2d }: IRM2dPlayer) => {
  if (Array.isArray(streamingUrl_2d)) streamingUrl_2d = String.fromCharCode.apply(String, streamingUrl_2d);

  const demoUrl = 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8';
  const demo = false;
  const [userAgent, setuserAgent] = useState("");

  const [rmpLoaded, error] = useScript('https://cdn.radiantmediatechs.com/rmp/5.9.2/js/rmp.min.js');
  // const [rmpLoaded, error] = useScript('https://cdn.radiantmediatechs.com/rmp/5.9.2/js/rmp.debug.js');

  useEffect(() => {

    if (window.navigator.userAgent.toLowerCase().indexOf('fanbox') > -1) {



      setuserAgent("fanbox");

    }
  }, []);

  const handleOverlayButton = () => {

    const levelArea = document.getElementsByClassName('rmp-overlay-level') as HTMLCollectionOf<HTMLDivElement>;
    const overlayArea = document.getElementsByClassName('rmp-module-overlay') as HTMLCollectionOf<HTMLDivElement>;

    for (var i = 0; i < levelArea.length; i++) {
      levelArea[i].ontouchend = () => { closeOverlay(); }
      levelArea[i].onclick = () => { closeOverlay(); }
    };

    for (var i = 0; i < overlayArea.length; i++) {
      overlayArea[i].ontouchend = () => { closeOverlay(); }
      overlayArea[i].onclick = () => { closeOverlay(); }
    };
  }
  const closeOverlay = () => {

    window.rmp.setModuleOverlayVisible('quality', false);
    window.rmp.setModuleOverlayVisible('captions', false);
  }

  // useEffect(() => {
  //   window.addEventListener('resize', function (event) {
  //     if (window.orientation === 90 || window.orientation === -90) {
  //       window.rmp.setFullscreen(true);
  //       document.body.style.backgroundColor = 'black';
  //     } else {
  //       window.rmp.setFullscreen(false);
  //       document.body.style.background = '#2A3139';
  //     }
  //   });
  // }, []);

  // const changeBitrage = (src: string) => {
  //   const originSrc = src.split('.m3u8')[0];
  //   if (currentMobile) {
  //     return src;
  //   }
  //   return originSrc + '_1080.m3u8';
  // }

  useEffect(() => {
    if (rmpLoaded && window.RadiantMP) {
      let updatedUrl = streamingUrl_2d;

      if (demo) updatedUrl = demoUrl;

      var src = {
        hls: updatedUrl,
      };
      var settings = {
        licenseKey: 'Kl8ldT1rK2U1MmNzOXY/cm9tNWRhc2lzMzBkYjBBJV8q',
        src: src,
        autoHeightMode: false,
        video360: false,
        autoplay: true,
        hideSeekBar: true,
        automaticFullscreenOnLandscape: true,
        loop: true,
        hideCentralPlayButton: true,
        hlsJSFetchXhrWithCredentials: !demo,
        forceHlsJSOnMacOSIpadOSSafari: true,
        skin: 's3',
      };
      var elementID = 'rmp2dPlayer';
      var rmp = window.rmp = new window.RadiantMP(elementID);
      var rmpContainer = document.getElementById(elementID);
      rmp.setModuleOverlayVisible('quality', true);
      rmp.setModuleOverlayVisible('captions', true);
      rmp.init(settings);

      rmpContainer!.addEventListener('playing', function () {
        handleOverlayButton();
      });
    }
  }, [rmpLoaded, streamingUrl_2d]);

  if (!rmpLoaded) return <styled.Video landscape={landscape_mode} isMobile={currentMobile} />;

  return (
    <styled.Video landscape={landscape_mode} isMobile={currentMobile}>
      <styled.CountBox landscape={landscape_mode}>
        <styled.CountImg src={iconPeople} />
        <styled.Count />
      </styled.CountBox>
      <div style={{ width: '100%', height: '100%' }} id="rmp2dPlayer"></div>
    </styled.Video>
  )
}
export default RM2dPlayer;
