import { API_URL } from '../../constants/apiInfo';
import { getApiAxiosWithToken } from '../utils/api';

export async function callpaymentMethod() {
  const _axios = getApiAxiosWithToken();
  const response = await _axios.get(`${API_URL}/api/v1/paymentmethods`);

  return response.data;
}

interface registerPaymentMethodParams {
  tokenId: string;
  holderName: string;
  holderBirthDay: string;
  paymentKind: string;
}

export async function callRegisterPaymentMethod({
  tokenId,
  holderName,
  holderBirthDay,
  paymentKind
}: registerPaymentMethodParams) {
  const _axios = getApiAxiosWithToken();
  const data = {
    kind: paymentKind,
    tokenId: tokenId,
    holderName: holderName,
    holderBirthDay: holderBirthDay
  }
  const response = await _axios.post(`${API_URL}/api/v1/paymentmethods`, data);

  return response.data;
}

interface IUpdatePaymentMethodParams {
  paymentMethodUuid: string;
  tokenId: string;
  holderName: string;
  holderBirthDay: string;
  paymentKind: string;
}

export async function callUpdatePaymentMethod({
  paymentMethodUuid,
  tokenId,
  holderName,
  holderBirthDay,
  paymentKind
}: IUpdatePaymentMethodParams) {
  const _axios = getApiAxiosWithToken();
  const data = {
    kind: paymentKind,
    tokenId: tokenId,
    holderName: holderName,
    holderBirthDay: holderBirthDay
  }
  const response = await _axios.put(`${API_URL}/api/v1/paymentmethods/${paymentMethodUuid}`, data);

  return response.data;
}

interface chargeConcertParams {
  concertUuid: string;
  ticketUuid: string;
  paymentMethodUuid: string;
  referralCode: string;
  email: string;
}

export async function callChargeConcert({
  concertUuid,
  ticketUuid,
  paymentMethodUuid,
  referralCode,
  email
}: chargeConcertParams) {
  const _axios = getApiAxiosWithToken();
  const data = {
    methodUuid: paymentMethodUuid,
    concertUuid: concertUuid,
    ticketUuid: ticketUuid,
    referralCode: referralCode,
    email: email
  }
  const response = await _axios.post(`${API_URL}/api/v1/payments/charge`, data);

  return response.data;
}

interface IOneTimeChargeConcertParams {
  concertUuid: string;
  ticketUuid: string;
  tokenId: string;
  referralCode: string;
  email: string;
}

export async function callOneTimeChargeConcert({
  concertUuid,
  ticketUuid,
  tokenId,
  referralCode,
  email
}: IOneTimeChargeConcertParams) {
  const _axios = getApiAxiosWithToken();
  const data = {
    tokenId: tokenId,
    concertUuid: concertUuid,
    ticketUuid: ticketUuid,
    referralCode: referralCode,
    email: email
  }
  const response = await _axios.post(`${API_URL}/api/v1/payments/onetimeCharge`, data);

  return response.data;
}

export async function callLoadPaymentHistories() {
  const _axios = getApiAxiosWithToken();
  const response = await _axios.get(`${API_URL}/api/v1/paymenthistories`);

  return response.data;
}

interface refundConcertParams {
  paymentUuid: string;
  paymentKind: string;
}

export async function callRefundConcert({ paymentUuid, paymentKind }: refundConcertParams) {
  const _axios = getApiAxiosWithToken();
  const data = {
    paymentUuid: paymentUuid,
    kind: paymentKind
  }
  const response = await _axios.post(`${API_URL}/api/v1/payments/refund`, data);

  return response.data;
}
